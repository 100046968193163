import React from "react";
import { useNavigate } from "react-router-dom";
import CommonBtn from "../CommonBtn/CommonBtn";
import style from "./CommonCardsecond.module.scss";
import personIcon from "../../../../Assets/images/person_icon.jpg";
import { useSelector } from "react-redux";
import { Routes_Urls } from "../../../../Constant/Route_url";

const CommonCardsecond = ({
  className,
  bordergrayBtntitle,
  classtextfields,
  followUser,
  item,
  type,
}) => {
  const navigate = useNavigate();

  const profileData = useSelector((state) => state?.users?.profileData);

  const followUnfollowUser = (item) => {
    if (type == "self") {
      followUser("remove", item);
    } else {
      if (item.follow_type == 0) {
        followUser("follow", item);
      } else {
        followUser("unfollow", item);
      }
    }
  };
  const goToUserProfile = (item) => {
    if (item.user?.username) {
      navigate(`/profile/${item.user?.username}`);
    }
  };

  return (
    <>
      <div className={`${style.commonCard_second} ${className || ""}`}>
        <img
          src={
            item?.user?.image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.user?.image_path}`
              : personIcon
          }
          alt="imagArt"
          onClick={() => goToUserProfile(item)}
        />
        <div className={`${style.card_textfields} ${classtextfields || ""}`}>
          <h5 onClick={() => goToUserProfile(item)}>{item?.user.name}</h5>
          <p onClick={() => goToUserProfile(item)}>@{item?.user.username}</p>

          <div className={`btn_border ${style.btnsselandview}`}>
            {bordergrayBtntitle && (
              <CommonBtn
                role="btn"
                className={`bordred-gray ${style.btneditsize}`}
                title={bordergrayBtntitle}
                onClick={() => followUnfollowUser(item)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonCardsecond;
