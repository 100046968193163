import React, { useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import { useFormik } from "formik";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import FaqItem from "./FaqItem/FaqItem";
import styles from "./FaqPage.module.scss";
import searchIcon from "../../../Assets/icons/search-icon.png";
import crossIcon from "../../../Assets/icons/closeIcon-green.png";
import { Helmet } from "react-helmet";
import faq from "../../../data/faq.json";

const FaqPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const initialValues = {
    search: "",
  };

  const formik = useFormik({
    initialValues,
  });

  const questions = [
    {
      id: 1,
      title: "What is NewArtX?",
      content:
        "NewArtX is a premier digital fine art marketplace dedicated to showcasing and trading digital art masterpieces from artists worldwide.",
    },
    {
      id: 2,
      title: "What is an NFT?",
      content:
        "An NFT (Non-Fungible Token) is a unique digital asset verified on the blockchain. It represents ownership or provenance of digital items like art, music, or collectibles.",
    },
    {
      id: 3,
      title: "What is DFA? Is it the same as NFT?",
      content: (
        <>
          <p>
            Digital Fine Art is a form of visual art considered to have been
            created digitally primarily for aesthetic purposes and is judged for
            its beauty and meaningfulness.
          </p>
          <p>
            DFAs on NewArtX are created with meticulous attention to detail
            capturing every brushstroke, colour and texture with utmost
            fidelity. Artistic brilliance combines with cutting edge technology
            to give the user an interactive experience.
          </p>
          <p>
            Not all DFAs are NFTs but the DFAs on NewArtX are minted on the
            Polygon blockchain which do make them NFTs. This guarantees the DFA
            an immutable provenance, authenticity, and ownership, thus
            preserving the value of each digital masterpiece and making them
            investable assets.
          </p>
        </>
      ),
    },
    {
      id: 4,
      title: "What is Dynamic DFA?",
      content:
        "Dynamic DFAs are artworks that are dependent on real time events – they could be the time of the day, weather at a particular location, the air quality or even other factors like the traffic on the road or stock market prices. Adding dynamic elements to the DFA, Artists gain greater creative freedom, infusing their creations with life and responsiveness.",
    },
    {
      id: 5,
      title: "What are the benefits of minting Digital Art on the Blockchain?",
      content: (
        <>
          <ul className="listFaq">
            <li>
              <p>
                <span>Provenance and Authenticity: </span>Minting on the
                blockchain establishes an immutable record of an artwork’s
                origin and history.
              </p>
            </li>
            <li>
              <p>
                <span>Future Value and Investment: </span>Blockchain-backed art
                becomes an investable asset.
              </p>
            </li>
            <li>
              <p>
                <span>Transparency: </span>Public ledger ensures transparency in
                art transactions.
              </p>
            </li>
            <li>
              <p>
                <span>Smart Contracts and Royalties: </span>Smart contracts
                embedded in DFAs enable automated royalties. Artists receive a
                percentage of secondary sales, benefiting from their work’s
                success.
              </p>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 6,
      title: "Do I need cryptocurrency to make purchases on NewArtX?",
      content:
        "No, NewArtX operates solely with fiat currency for transactions to make the collector community more inclusive. You can purchase digital fine art using traditional payment methods such as credit cards, debit cards, net banking and other traditional payment options.",
    },
    {
      id: 7,
      title: "How can I buy a DFA on NewArtX?",
      content: (
        <>
          <p>
            Buying Digital Fine Art on NewArtX is simple and can be done in two
            ways – through our Curated Drops or through the Marketplace.
          </p>
          <p>
            Curated Drops would have the first release of a DFA on any platform
            globally and is released in unique or limited edition at a
            predetermined price or through an auction. The Creator or the Artist
            is the seller of the DFA in Curated Drops.
          </p>
          <p>
            The Marketplace would have an exhaustive list of all DFAs that are
            released and sold on NewArtX including the ones collectors have
            listed for selling. The DFAs listed in the Marketplace would have
            been sold at least once in the past on NewArtX or some other
            platform.
          </p>
        </>
      ),
    },
    {
      id: 8,
      title: "What are the different type of Drops offered of NewArtX?",
      content: (
        <>
          <ul className="listFaq_Sec">
            <li>
              <p>
                <span>Open Edition: </span>Available in unlimited quantities for
                a specific timeframe allow collectors to purchase DFAs without
                any fear of DFAs getting sold out. The edition number is
                determined by the number of DFAs that are sold during this
                period. Collectors can buy available copies from the marketplace
                or give an offer to other collectors to acquire the artwork
                after the drop closes.
              </p>
            </li>
            <li>
              <p>
                <span>First Come First Buy Limited Edition: </span>Limited in
                quantity editions of the DFAs are offered for purchase to
                collectors on a first-come, first-served basis. Once the edition
                is sold out, no more copies are available in the drop, adding
                exclusivity and urgency to the buying process. Collectors can
                buy available copies of the DFA from the marketplace or give an
                offer to other collectors to acquire the DFA after the drop
                closes.
              </p>
            </li>
            <li>
              <p>
                <span>Auction: </span>DFAs with single edition sold through an
                auction with a set minimum price and bid increment. Collectors
                bid against each other to obtain the DFA and compete by placing
                increasingly higher bids until the auction concludes. The
                highest bidder at the end of the auction secures ownership of
                the artwork. The end timer resets to 5 minutes every time a bid
                is placed in the last 5 minutes of the Auction.
              </p>
              <p>
                Collectors can buy DFA from the marketplace, if it’s available
                or give an offer to other collectors to acquire the artwork
                after the auction concludes.
              </p>
            </li>
            <li>
              <p>
                <span>Ranked Auction: </span>DFAs with limited number of
                editions sold through a ranked auction with a set minimum price
                and bid increment. Collectors Bidders are guided on the minimum
                eligible bid to secure an artwork and become the highest bidder.
                The top bids at the end of the auction secures ownership of the
                artwork, for example if there are 10 editions of the DFA, the
                top 10 bids will secure the ownership of the DFA. The end timer
                resets to 5 minutes every time a bid is placed in the last 5
                minutes of the Auction.
              </p>
            </li>
            <li>
              <p>
                <span>Raffle: </span>Raffle drops offer collectors the chance to
                acquire the DFA through a randomized selection process at a
                fraction of the cost. Participants purchase tickets for a chance
                to own the DFA within a specified timeframe. Once the raffle
                concludes, winners are randomly selected from the pool of ticket
                holders, providing an element of luck in acquiring sought-after
                pieces.
              </p>
            </li>
            <li>
              <p>
                <span>Drawing: </span>Drawing drops involve a selection process
                where collectors submit their interest to acquire the DFA.
                Winners are then chosen through a random drawing, adding an
                element of excitement and fairness to the selection process.
              </p>
            </li>
          </ul>
          <p>
            In addition to the above, there could be few drops that are
            Collector only, where collectors must meet certain criteria set by
            the artist or platform. Requirements may include holding specific
            DFAs previously dropped by the artist.
          </p>
        </>
      ),
    },
    {
      id: 9,
      title: "What payment methods are accepted on NewArtX?",
      content:
        "We accept a variety of payment methods, including major credit cards, debit cards, UPI and Net Banking options. Our payment gateway ensures a secure and seamless transaction process.",
    },
    {
      id: 10,
      title: "Is my payment information secure on NewArtX?",
      content: (
        <>
          Yes, we prioritize the security and privacy of our users' information.
          Our platform utilizes advanced encryption technology and follows
          industry best practices to safeguard your payment information.
        </>
      ),
    },
    {
      id: 11,
      title: "Can I sell my Digital Fine Art on NewArtX?",
      content: (
        <>
          Absolutely! We welcome all Fine Artists to apply to join our platform
          and showcase their Digital Fine Art to our global audience. If you're
          an artist interested in selling your artwork on NewArtX, please reach
          out to us for more information on how to get started.
        </>
      ),
    },
    {
      id: 12,
      title:
        "How To Convert Fine Art into Digital Art and Digital Investable Assets?",
      content: (
        <>
          <p className="mb-3">
            Transforming Digital Art into Digital Fine Art involves several
            crucial steps to enhance its quality, authenticity, and investment
            potential:
          </p>
          <ul className="listFaq_Third">
            <li>
              <p>
                <span>Artistic Intent and Storytelling: </span>Start by
                understanding the artistic intent behind your creation. Consider
                the deeper meaning or narrative you want to convey through your
                Digital Art. Storytelling adds value and emotional resonance.
              </p>
            </li>
            <li>
              <p>
                <span>Digital Enhancement and Tools: </span>Utilize software
                tools like Adobe Photoshop and After Effects. Enhance your
                digital creation by adjusting colors, contrast, and composition
                and add dynamic elements to breathe life into your artwork.
              </p>
              <p>
                NAX Studio provides artists with resources, exposure, and
                opportunities to create Digital Fine Art.{" "}
              </p>
            </li>
            <li>
              <p>
                <span>Blockchain Integration: </span>To transform your Digital
                Fine Art into an investable asset, it needs to be minted as an
                NFT (Non-Fungible Token) on the blockchain. This process ensures
                an immutable record of provenance, authenticity, and ownership.
              </p>
              <p>
                All DFAs that are listed on NewArtX are minted on the Polygon
                blockchain.
              </p>
            </li>
          </ul>
          <p>
            Remember that the journey from digital art to digital fine art
            involves both technical skills and creative vision. Strive for
            excellence in both aspects to maximize the impact of your artwork.
          </p>
        </>
      ),
    },
    {
      id: 13,
      title: "Does the Value of Digital Fine Art Go Up?",
      content: (
        <>
          <p className="mb-3">
            The value of Digital Fine Art, like any other asset may increase or
            decrease on various factors.
          </p>
          <ul className="listFaq_Third">
            <li>
              <p>
                <span>Potentially: </span>Like traditional art, demand, artist
                reputation, and scarcity impact value.
              </p>
            </li>
            <li>
              <p>
                <span>Market Trends: </span>Some DFAs appreciate significantly,
                while others may not.
              </p>
            </li>
            <li>
              <p>
                <span>Collectability: </span>Rarity and cultural significance
                play a role.
              </p>
            </li>
          </ul>
          <p>
            Remember, the Fine Art space is dynamic, and individual outcomes
            vary!
          </p>
        </>
      ),
    },
    {
      id: 14,
      title: "How do I create an account on NewArtX?",
      content: (
        <>
          Creating an account on NewArtX is quick and easy. Simply visit our
          website and click on the "Sign Up" or "Create Account" button. Follow
          the prompts to provide the necessary information and complete the
          registration process and KYC.
        </>
      ),
    },
    {
      id: 15,
      title: "What is a custodial wallet, and how does it work on NewArtX?",
      content:
        "A custodial wallet is a secure digital wallet provided by NewArtX to store your Digital Fine Art assets. When you purchase artwork on our platform, your NFTs are securely stored in your custodial wallet until you decide to transfer or sell them.",
    },
    {
      id: 16,
      title: "How do I contact NewArtX for support or inquiries?",
      content: (
        <>
          If you have any questions, concerns, or need assistance, our dedicated
          support team is here to help. You can reach out to us through our
          website's contact form or by emailing [email protected]
        </>
      ),
    },
    {
      id: 17,
      title:
        "Is there a fee for buying or selling digital fine art on NewArtX?",
      content:
        "NewArtX charges a nominal transaction fee for each purchase or sale made on our platform. This fee helps us maintain and improve our services to better serve our community of artists and collectors.",
    },
  ];

  // Filter questions based on the search query
  const filteredQuestions = questions.filter((question) =>
    question.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <section className={styles.helpCenter}>
        <Helmet>
          <title>FAQ | Your Questions Answered | NewArtX</title>
          <meta
            name="description"
            content="Find answers to all your questions about NewArtX. Explore our FAQ section for detailed information on buying, selling, and managing digital fine art collections."
          />
          
          <script type="application/ld+json">{JSON.stringify(faq)}</script>
        </Helmet>

        <Container>
          <h1>FAQs</h1>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <div className={styles.searchInput}>
              <FormikControls
                control="input"
                type="text"
                name="search"
                placeholder="Search Topic"
                formik={formik}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
                leftIcon={<img src={searchIcon} alt="search" />}
              />
              {searchQuery && (
                <CommonBtn
                  role="btn"
                  title={<img src={crossIcon} alt="cross icon" />}
                  className={styles.resetBtn}
                  onClick={() => setSearchQuery("")}
                  type="reset"
                />
              )}
            </div>
          </form>
        </Container>
      </section>
      <section className={styles.faqSec}>
        <Container>
          <div className={styles.faqSecCategory}>
            <Accordion>
              {filteredQuestions.length > 0 ? (
                filteredQuestions.map((question) => (
                  <FaqItem
                    key={question.id}
                    eventKey={question.id.toString()}
                    title={question.title}
                    content={question.content}
                  />
                ))
              ) : (
                <p>No matching questions found.</p>
              )}
            </Accordion>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FaqPage;
