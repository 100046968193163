import { store } from "../App/store";
import { DECIMAL, ORIENTATION } from "../Constant/userConstant";
import { toasts } from "../Component/Common/Toast/Toast";
import { saveLatLong } from "../Features/user/userSlice";

export const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = getOrdinalSuffix(day);
    const year = date.getFullYear();
    const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
      date
    );
    return `${day}${suffix} ${month}, ${year}`;
  } else {
    return "";
  }
};

const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateWithTime = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = getOrdinalSuffix(day);
    const year = date.getFullYear();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
      date
    );
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${day}${suffix} ${month}, ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  } else {
    return "";
  }
};

export const getDateInIST = (utcDate) => {
  const istDate = new Date(
    utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );

  // Format the date as "21 Feb 2024"
  const formattedDate = `${istDate.getUTCDate()} ${getMonthAbbreviation(
    istDate.getUTCMonth()
  )} ${istDate.getUTCFullYear()}`;

  return formattedDate;
};

function getMonthAbbreviation(month) {
  const monthsAbbreviation = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthsAbbreviation[month];
}

export const getTimeInIST = (utcDate) => {
  // Convert to IST (Indian Standard Time)
  const istTime = new Date(
    utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  );

  // Format the time as "10:00 PM"
  const formattedTime = istTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return formattedTime;
};

export const getDateFromTimestamp = (timestamp) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateObject = new Date(Number(timestamp));
  const year = dateObject.getFullYear();
  const month = months[dateObject.getMonth()];
  const day = dateObject.getDate();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  // You can return the date in any desired format. For example:
  // Format: "Month DD, YYYY"
  return `${month} ${day}, ${year}`;
};

export const getTime = (time) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
  const formattedTime = `${formattedHours}:${String(minutes).padStart(
    2,
    "0"
  )} ${amOrPm}`;
  return formattedTime;
};

export const getNumberWithComma = (number) => {
  if (number == 0) {
    return 0;
  }
  if (number) {
    return Number(number).toLocaleString();
  }
  return "";
};

export const determineEventStatus = (startTimestamp, endTimestamp) => {
  const currentTimestamp = Date.now(); // Current time in seconds
  if (currentTimestamp < startTimestamp) {
    return "upcoming"; // The event is in the future
  } else if (
    currentTimestamp >= startTimestamp &&
    currentTimestamp <= endTimestamp
  ) {
    return "live"; // The event is currently ongoing
  } else {
    return "previous"; // The event has already ended
  }
};

export const PLATFORM_MAX_VALUE = 9999999999;

export const STRIP_MAX_VALUE = 700000;
export const STRIP_MIN_VALUE = 1;

export const determineEventStatusOfFCFB = (item) => {
  const currentTimestamp = Date.now(); // Current time in seconds
  if (currentTimestamp < item.start_time) {
    return "upcoming"; // The event is in the future
  } else if (item.no_of_editions > item.sold_no_of_editions) {
    return "live"; // The event is currently ongoing
  } else {
    return "previous"; // The event has already ended
  }
};

export const timeAgo = (date) => {
  const currentDate = new Date();
  const providedDate = new Date(date);
  const timeDifference = currentDate - providedDate;

  // Convert time difference to seconds, minutes, hours, and days
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days >= 1) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours >= 1) {
    return hours === 1 ? "1 hr ago" : `${hours} hrs ago`;
  } else if (minutes >= 1) {
    return minutes === 1 ? "1 min ago" : `${minutes} mins ago`;
  } else {
    return seconds <= 10 ? "Just now" : `${seconds} secs ago`;
  }
};

export const sliceChar = (string, length) => {
  if (string?.length > length && string) {
    return `${string.slice(0, length)}...`;
  } else {
    return string;
  }
};

export const handlePaste = (event, formik, name, maxLength) => {
  event.preventDefault();

  const clipboardData = event.clipboardData || window.clipboardData;
  let pastedText = clipboardData.getData("text");

  // Replace newline characters with spaces
  pastedText = pastedText.replace(/[\r\n]+/g, " ");

  // Ensure the pasted text does not exceed maxLength
  const clippedText = pastedText.substring(0, maxLength);

  const selectionStart = event.target.selectionStart;
  const selectionEnd = event.target.selectionEnd;

  const newText =
    formik.values[name].substring(0, selectionStart) +
    clippedText +
    formik.values[name].substring(selectionEnd);

  // Enforce maxLength by truncating the text
  const truncatedText = newText.substring(0, maxLength);
  formik.setFieldValue(name, truncatedText);

  // Move the cursor after the inserted text
  const newCursorPosition = selectionStart + clippedText.length;
  event.target.setSelectionRange(newCursorPosition, newCursorPosition);
};

export const obfuscateEmail = (email) => {
  const [localPart, domain] = email.split("@");
  // Check if the local part has at least 3 characters
  if (localPart.length < 3) {
    // Return the original email if the local part is too short
    return email;
  }
  const firstTwoChars = localPart.substring(0, 2);
  const lastChar = localPart.substring(localPart.length - 1);
  const obfuscatedLocalPart =
    firstTwoChars + "*".repeat(localPart.length - 3) + lastChar;
  const obfuscatedEmail = obfuscatedLocalPart + "@" + domain;
  return obfuscatedEmail;
};

export const getByLabelText = (offerPrice, counterPrice) => {
  //offerPrice by buyer
  //counterPrice by Seller

  if (
    offerPrice / DECIMAL == counterPrice / DECIMAL ||
    offerPrice / DECIMAL < counterPrice / DECIMAL
  ) {
    return "You'll Pay ";
  } else if (offerPrice / DECIMAL > counterPrice / DECIMAL) {
    return `You'll Recieve`;
  }
};

export const getByLabelValue = (offerPrice, counterPrice) => {
  //offerPrice by buyer
  //counterPrice by Seller

  if (offerPrice / DECIMAL == counterPrice / DECIMAL) {
    return "0";
  } else if (offerPrice / DECIMAL > counterPrice / DECIMAL) {
    return ` ${(offerPrice / DECIMAL - counterPrice / DECIMAL).toFixed(2)} `;
  } else if (offerPrice / DECIMAL < counterPrice / DECIMAL) {
    return `${(counterPrice / DECIMAL - offerPrice / DECIMAL).toFixed(2)} `;
  }
};

export const getByLabelValueIndividual = (offerPrice, counterPrice) => {
  return counterPrice / DECIMAL;
};

export const checkFileType = (url) => {
  // Define regular expressions for file extensions
  const mp4Regex = /\.(mp4)$/i;
  const oggRegex = /\.(ogg)$/i;
  const gifRegex = /\.(gif)$/i;
  const jpegRegex = /\.(jpeg|jpg)$/i;

  // Test the URL against each regular expression
  if (mp4Regex.test(url)) {
    return "MP4";
  } else if (oggRegex.test(url)) {
    return "OGG";
  } else if (gifRegex.test(url)) {
    return "GIF";
  } else if (jpegRegex.test(url)) {
    return "JPEG";
  } else {
    return "Unknown type";
  }
};

export const checkFeatureImageRuleBase = (item) => {
  if (item?.art_type_id === 1) {
    // for dynamic art art_type_id is 1
    if (Object.keys(item?.orientationMedia).length) {
      return store?.getState()?.users?.orientation === ORIENTATION.LANDSCAPE
        ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.orientationMedia?.landscape}`
        : `${process.env.REACT_APP_IMG_BASE_URL}${item?.orientationMedia?.portrait}`;
    }
    return `${process.env.REACT_APP_IMG_BASE_URL}${item?.art_image}`;
  } else {
    return `${process.env.REACT_APP_IMG_BASE_URL}${item?.art_image}`;
  }
};

export const getLongLat = async () => {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      // Request the user's location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          store.dispatch(
            saveLatLong({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            })
          );
        },
        (error) => {
          // Error: Reject with a default location or specific error handling
          console.error("Error getting location:", error);
          store.dispatch(
            saveLatLong({
              lat: 0,
              long: 0,
              error: "Failed to get location",
            })
          );
        }
      );
    } else {
      // Geolocation is not supported
      console.error("Geolocation is not supported in this browser.");
      store.dispatch(
        saveLatLong({
          lat: 0,
          long: 0,
          error: "Failed to get location",
        })
      );
    }
  });
};

export const handleCopyClick = (textToCopy, type) => {
  try {
    if (type) {
      // Use the Clipboard API if available (modern browsers)
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(textToCopy);
        toasts.success(`${type} copied to clipboard!`);
      } else {
        // Fallback to the deprecated execCommand method (older browsers)
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        toasts.success(`${type} copied to clipboard!`);
      }
    }
  } catch (error) {
    console.error("Failed to copy text: ", error);
  }
};
