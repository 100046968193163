import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./Header.module.scss";
import SearchIcon from "../../../Assets/icons/search-icon.png";
import Logo from "../../../Assets/images/logo.png";
import Bell from "../../../Assets/icons/bell.png";
import CommonModal from "../Ui/CommonModal/CommonModal";
import Login from "../../Public/Login/Login";
import LogoModal from "../../../Assets/icons/Fav.png";
import SearchModal from "../Ui/SearchModal/SearchModal";
import NotificationBox from "./NotificationBox/NotificationBox";
import { useFormik } from "formik";
import FormikControls from "../Ui/Formik/FormikControls";
import CommonBtn from "../Ui/CommonBtn/CommonBtn";
import Forget from "../../Public/Forget";
import Reset from "../../Public/Reset";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordAction,
  logoutAction,
  openPopUp,
  getUserProfile,
  saveSignUpCredential,
  setScreenOrientation,
  getNotificationList,
  resetMarketplaceFilter,
} from "../../../Features/user/userSlice";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import * as Yup from "yup";
import { getIp } from "../../../Features/auth/authSlice";
import TwoFaAuthentication from "../../Public/Login/TwoFaAuthentication";
import { ORIENTATION, USER_TYPE } from "../../../Constant/userConstant";
import {
  EnableDisableAnonymousUserFunction,
  getAnonymousUserFunction,
  saveAnonymousDetail,
} from "../../../Features/Anonymous/anonymousUserSlice";
import { saveUserType } from "../../../Features/persist/persistSlice";
import { sliceChar } from "../../../Helper/common";
import socket from "../../../Socket/socket";
import AddressQrModal from "../../Private/Userprofile/Artowned/components/AddressQrModal";
import personIcon from "../../../Assets/images/person_icon.jpg";
import { Routes_Urls } from "../../../Constant/Route_url";

const Header = () => {
  // redux
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = useParams();
  const ip = useSelector((state) => state?.persist?.ip);
  const loginCase = useSelector((state) => state?.users?.loginCase);
  const anonymouseFormData = useSelector(
    (state) => state?.anonymousUser?.anonymouseFormData
  );
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const profileData = useSelector((state) => state?.users?.profileData);
  const profileDataOther = useSelector(
    (state) => state?.users?.profileDataOther
  );
  const userType = useSelector((state) => state?.persist?.userType);
  const accessToken = useSelector((state) => state.persist.accessToken);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  //state
  const [searchModal, setSearchModal] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [data, setData] = useState({});
  const [email, setEmail] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showQrAddress, setShowQrAddress] = useState(false);
  const [importData, setImportData] = useState({});
  const [unreadCount, setUnreadCount] = useState(0);

  const dropdownRef = useRef(null);

  useEffect(() => {
    if (isUserLoggedin) {
      getUserDetail();
    }
  }, [isUserLoggedin]);

  const getUserDetail = async () => {
    try {
      await dispatch(getUserProfile());
    } catch (err) {}
  };

  useEffect(() => {
    socket.emit("socket_notification_room", { apiAccessToken: accessToken });
  }, []);

  socket.on("USER_NOTIFICATION", (data) => {
    let notificationListClone = [...notificationList];
    //pushing recent notification at the top of the list
    notificationListClone.unshift(data);
    if (notificationListClone.lenght > 10) {
      // removing element if length is greater than 10 because only top 10 notification should be shown
      notificationListClone.pop();
    }
    setNotificationList(notificationListClone);
    //if any new notification pop in socket than set the flag true which indication dot on bell icon
    setUnreadCount(unreadCount + 1);
  });

  useEffect(() => {
    if (isUserLoggedin) {
      getNotificationHandler();
    }
  }, [isUserLoggedin]);

  const getNotificationHandler = async () => {
    let params = {
      size: 10,
      type: 1, //BOX NOTIFICATION
    };
    try {
      const response = await dispatch(getNotificationList(params));
      setUnreadCount(response?.data?.unreadCount);
      setNotificationList(response.data.data);
    } catch (error) {}
  };

  //Yup and formik
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(
      inputFieldValidation.commonMessage.required("Old password")
    ),
    newPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("New password"))
      .max(16, "Password cannot be more than 16 characters")
      .matches(
        inputFieldValidation.password.regex.expresion,
        inputFieldValidation.password.regex.errorMessage
      )
      .test(
        "not-same-as-currentPassword",
        "*New password must be different from old password",
        function (value) {
          const oldPassword = this.parent.oldPassword || ""; // Access currentPassword value
          return value !== oldPassword; // Check if newPassword is different from currentPassword
        }
      ),
    confirmNewPassword: Yup.string()
      .required(
        inputFieldValidation.commonMessage.required("Confirm new password")
      )
      .oneOf([Yup.ref("newPassword"), null], "Password must match"),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data = {
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
        ipAddress: ip,
        deviceType: 0,
        confirmPassword: values.confirmNewPassword,
      };
      await dispatch(changePasswordAction(data)).then((response) => {});
      navigate("/");
      await dispatch(logoutAction());
      handleClose();
    },
  });

  // useEffect
  useEffect(() => {
    dispatch(getIp())
      .then((res) => {})
      .catch((err) => {});
  }, []);

  useEffect(() => {
    //It is used for handle the change in orientation of screen
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const handleOrientationChange = () => {
    dispatch(
      setScreenOrientation(
        window.matchMedia("(orientation: landscape)").matches
          ? ORIENTATION.LANDSCAPE
          : ORIENTATION.PORTRAIT
      )
    );
  };

  const toggleBtn = createRef(); // reference of toggle btn

  //function
  const handleSidebar = () => {
    // function for handling sidebar
    if (document.body.clientWidth < 991) {
      toggleBtn.current.click(); // click toggleBtn only if screenwidth is less than 991
    }
  };

  const handleSidebarMarketplace = () => {
    // function for handling sidebar
    if (document.body.clientWidth < 991) {
      toggleBtn.current.click(); // click toggleBtn only if screenwidth is less than 991
    }
    dispatch(resetMarketplaceFilter());
  };

  const handleClose = () => {
    dispatch(openPopUp(""));
    dispatch(saveSignUpCredential({ email: "", password: "" }));
    formik.resetForm();
  };

  const caseClick = (e) => {
    dispatch(openPopUp(e));
  };

  const callbackDataPass = (data) => {
    setData(data);
  };

  const logoutHandler = async (e) => {
    await dispatch(logoutAction());
  };

  useEffect(() => {
    if (
      !Object.keys(anonymouseFormData).length &&
      (userType === USER_TYPE.ARTIST || userType === USER_TYPE.ANONYMOUS)
    ) {
      getAnonymousDetail();
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const getAnonymousDetail = async () => {
    try {
      let response = await dispatch(getAnonymousUserFunction());
      dispatch(saveAnonymousDetail(response?.data?.data));
    } catch (err) {}
  };

  const anonymousOrUserToggle = async (isAnonymousUser) => {
    let data = {
      anonymous_user: isAnonymousUser,
    };
    try {
      let response = await dispatch(EnableDisableAnonymousUserFunction(data));
      await getUserDetail();
      await getNotificationHandler();
      if (profileDataOther.username !== username) {
        navigate(`/profile/${response.data.data.username}`);
      }
      if (location.pathname.includes("/checkout/art")) {
        //Checking when real user toggle to anonymous or anonymous toggle to real user than navigate it to back page while user is on checkout page
        navigate(-1);
      }
    } catch (err) {}
  };

  const handleProfileClick = (isAnonymousUser) => {
    if (isAnonymousUser == USER_TYPE.ARTIST) {
      if (!Object.keys(anonymouseFormData).length) {
        //extra parameter sended when there is anonymous user is created
        navigate(Routes_Urls.User_account_setting, {
          state: { tab: "addeditanonymous" },
        });
      } else {
        anonymousOrUserToggle(1);
        dispatch(saveUserType(USER_TYPE.ANONYMOUS));
      }
    } else if (isAnonymousUser == USER_TYPE.ANONYMOUS) {
      anonymousOrUserToggle(2);
      dispatch(saveUserType(USER_TYPE.ARTIST));
    }
  };

  const getActiveUserImage = (isAnonymousUser) => {
    //2 for artist
    //3 for ano user
    if (isAnonymousUser == USER_TYPE.ARTIST) {
      if (anonymouseFormData?.image_path) {
        return `${process.env.REACT_APP_IMG_BASE_URL}${anonymouseFormData?.image_path}`;
      } else {
        return personIcon;
      }
    } else if (isAnonymousUser == USER_TYPE.ANONYMOUS) {
      if (profileData?.profile_image_path) {
        return `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.profile_image_path}`;
      } else {
        return personIcon;
      }
    }
  };

  return (
    <>
      <div
        onClick={handleSidebar}
        className={`${styles.headerBackdrop} d-lg-none ${
          backdrop ? styles.active : ""
        }`}
      />
      <header className={styles.header}>
        <Navbar className={styles.header_navbar} expand="lg">
          <Container>
            <div className={styles.header_left}>
              <Link className={styles.header_logo} to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className={styles.header_right}>
              <div className={styles.header_search}>
                <Link
                  to="#"
                  onClick={() => {
                    setSearchModal(true);
                  }}
                >
                  <img src={SearchIcon} alt="SearchIcon" />
                </Link>
              </div>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={styles.header_navbarCollapse}
              >
                <Nav className={`ms-auto ${styles.header_nav}`}>
                  <NavDropdown
                    title="Buy"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Marketplace}
                      onClick={handleSidebarMarketplace}
                    >
                      Marketplace
                    </Link>
                    {/* <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Curated_Drop_page}
                      onClick={handleSidebar}
                    >
                      Curated Drops
                    </Link> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Display"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.FlauntPage}
                      onClick={handleSidebar}
                    >
                      How to Flaunt Your DFAs
                    </Link>
                    {/* <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Coming_Soon}
                      onClick={handleSidebar}
                    >
                      NewArtX App
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      // to="https://naxcanvas.com/"
                      to={Routes_Urls.Coming_Soon}
                      role="link"
                      target="_blank"
                    >
                      Buy NAXCanvas Digital Art Frames
                    </Link> */}
                  </NavDropdown>
                  <NavDropdown
                    title="About Us"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.About_dfa}
                      onClick={handleSidebar}
                    >
                      What are DFAs
                    </Link>

                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.About_newArtX}
                      onClick={handleSidebar}
                    >
                      About NewArtX
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Faq}
                      onClick={handleSidebar}
                    >
                      FAQs
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Contact_us}
                      onClick={handleSidebar}
                    >
                      Contact Us
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Create}
                      onClick={handleSidebar}
                    >
                      Apply to Create
                    </Link>
                  </NavDropdown>
                  {/* <NavDropdown
                    title="Stats"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to="/stats/activity"
                      onClick={handleSidebar}
                    >
                      Activity
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to="stats/leader-boards"
                      onClick={handleSidebar}
                    >
                      Leaderboard
                    </Link>
                  </NavDropdown> */}
                </Nav>
              </Navbar.Collapse>
              <div className={styles.header_action}>
                {/* before login */}

                <Dropdown
                  className={styles.header_notification_dropDown}
                  show={isOpen}
                  onClick={() => setIsOpen(!isOpen)}
                  ref={dropdownRef}
                >
                  <Dropdown.Toggle
                    className={unreadCount != 0 && "btnNotification"}
                  >
                    {console.log(unreadCount)}
                    <img src={Bell} alt="Bell Icon" />
                    {unreadCount != 0 && (
                      <span className="numberMail">
                        {unreadCount > 100 ? `99+` : unreadCount}
                      </span>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <NotificationBox
                      notificationList={notificationList}
                      getNotificationHandler={getNotificationHandler}
                      setIsOpen={setIsOpen}
                      setImportData={setImportData}
                      setShowQrAddress={setShowQrAddress}
                      setNotificationList={setNotificationList}
                      setUnreadCount={setUnreadCount}
                    />
                  </Dropdown.Menu>
                </Dropdown>
                <span className={styles.header_action_divider}></span>
                <Dropdown className={styles.header_userDropdown}>
                  <Dropdown.Toggle>
                    <img
                      src={
                        profileData?.image_path
                          ? `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.image_path}`
                          : personIcon
                      }
                      alt="Bell Icon"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className={styles.header_userDropdown__userIcon}>
                      <button
                        className={`${styles.btnProfileone} `}
                        onClick={() => {
                          navigate(`/profile/${profileData?.username}`);
                        }}
                      >
                        {/* LEFT*/}
                        <img
                          src={
                            profileData?.image_path
                              ? `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.image_path}`
                              : personIcon
                          }
                          alt="Bell Icon"
                        />
                      </button>

                      <h6 title={profileData?.username}>
                        {sliceChar(
                          profileData?.username,
                          userType == USER_TYPE.ARTIST ||
                            userType == USER_TYPE.ANONYMOUS
                            ? 8
                            : 12
                        )}
                      </h6>
                      {(userType == USER_TYPE.ARTIST ||
                        userType == USER_TYPE.ANONYMOUS) && (
                        <button
                          className={`${styles.user_changes} 
                          }`}
                          onClick={() => {
                            handleProfileClick(profileData?.user_type);
                          }}
                        >
                          {/* RIGHT  */}
                          <img
                            src={getActiveUserImage(profileData?.user_type)}
                            alt="Bell Icon"
                          />
                        </button>
                      )}
                    </div>

                    <div className={styles.drop_links}>
                      <Dropdown.Item>
                        <Link to={`/profile/${profileData?.username}`}>
                          Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={Routes_Urls.User_dashboard}>Dashboard</Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={Routes_Urls.Wallet}>Wallet</Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={Routes_Urls.User_account_setting}>
                          Account Settings
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={Routes_Urls.Link_devices}>
                          Display Devices
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link to={Routes_Urls.Create}>Apply To Create</Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link
                          to="/"
                          onClick={() => {
                            caseClick("change-password");
                          }}
                        >
                          Change Password
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link
                          to="/"
                          onClick={(e) => {
                            logoutHandler(e);
                          }}
                        >
                          Sign Out
                        </Link>
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Navbar.Toggle
                className={styles.header_toggleBtn}
                aria-controls="basic-navbar-nav"
                onClick={() => setBackdrop(!backdrop)}
                ref={toggleBtn}
              >
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </Navbar.Toggle>
            </div>
          </Container>
        </Navbar>
      </header>
      {/* Header popups */}
      {/* //This switch case mange the all major pop up and it handle by redux state such that it can be shown and hide form anywhere*/}
      {(() => {
        switch (loginCase) {
          case "TwoFa":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Two Factor Authentication</>}
                centered={true}
                className="authTwomodal"
                subTitle="Enter verification code sent on your registered email and phone number"
              >
                <TwoFaAuthentication handleClose={handleClose} email={email} />
              </CommonModal>
            );
          case "login":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle="Welcome Back."
                classSub="textyellow"
                // subTitle="Sign in and start creating & selling NFTs."
                subTitle="Discover and own exquisite DFAs from talented Fine Artists."
                centered={true}
                backdropclass={styles.backdrp_bg}
              >
                <Login
                  forgetClick={() => {
                    caseClick("forgot");
                  }}
                  onClick={() => {
                    caseClick("signUp");
                  }}
                  ip={ip}
                  setEmail={setEmail}
                />
              </CommonModal>
            );
          case "thankYou":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={
                  <>
                    Thanks For <br /> Signing up.
                  </>
                }
                centered={true}
              >
                <img src={LogoModal} alt="Logo" />
                <p className="stayTuned">
                  Stay tuned. <br />
                  We'll let you know about all our upcoming drops and news
                </p>
              </CommonModal>
            );
          case "forgot":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Forgot Password ?</>}
                centered={true}
                subTitle="No worries, we'll send you reset notifications."
              >
                <Forget
                  callbackDataPass={callbackDataPass}
                  handleClose={handleClose}
                  ip={ip}
                  setEmail={setEmail}
                />
              </CommonModal>
            );
          case "reset-password":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Set New Password</>}
                centered={true}
              >
                <Reset
                  handleClose={handleClose}
                  ip={ip}
                  data={data}
                  email={email}
                />
              </CommonModal>
            );
          case "change-password":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Change Password</>}
                centered={true}
                formik={formik}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <FormikControls
                        variant={styles.spaceField}
                        rightIcon={styles.iconblack}
                        className="fieldBlack"
                        control="password"
                        name="oldPassword"
                        placeholder="Old Password"
                        formik={formik}
                        onChange={formik.handleChange}
                        value={formik.values.oldPassword}
                        onBlur={formik.handleBlur}
                      />
                    </Col>
                    <Col xs={12}>
                      <FormikControls
                        variant={styles.spaceField}
                        rightIcon={styles.iconblack}
                        className="fieldBlack"
                        control="password"
                        name="newPassword"
                        placeholder="New Password"
                        formik={formik}
                        onChange={formik.handleChange}
                        value={formik.values.newPassword}
                        onBlur={formik.handleBlur}
                        maxLength={16}
                      />
                    </Col>
                    <Col xs={12}>
                      <FormikControls
                        variant={styles.spaceField}
                        rightIcon={styles.iconblack}
                        className="fieldBlack"
                        control="password"
                        name="confirmNewPassword"
                        placeholder="Confirm New Password"
                        formik={formik}
                        onChange={formik.handleChange}
                        value={formik.values.confirmNewPassword}
                        onBlur={formik.handleBlur}
                      />
                    </Col>
                    <Col xs={12}>
                      <CommonBtn
                        type={"submit"}
                        role="btn"
                        className="btn-yellow-black "
                        title="Save"
                      />
                    </Col>
                  </Row>
                </form>
              </CommonModal>
            );
          default:
            // code block
            return null;
        }
      })()}
      {searchModal && (
        <SearchModal
          show={searchModal}
          onHide={() => {
            setSearchModal(false);
            setSearchTerm("");
          }}
          setSearchModal={setSearchModal}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
      {showQrAddress && Object.keys(importData).length > 0 && (
        <AddressQrModal
          show={showQrAddress}
          onHide={() => setShowQrAddress(false)}
          importData={importData}
        />
      )}
    </>
  );
};
export default Header;
