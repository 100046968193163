import React from "react";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../../Common/Ui/CommonTable/CommonTable";
import style from "./Earningstable.module.scss";
import moment from "moment";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  getNumberWithComma,
  sliceChar,
} from "../../../../../Helper/common";
import { DECIMAL } from "../../../../../Constant/userConstant";
import Notfound from "../../../../Common/NotFound/Notfound";
import { useNavigate } from "react-router-dom";
import personIcon from "../../../../../Assets/images/person_icon.jpg";
import CustomPagination from "../../../../Common/Ui/Pagination/CustomPagination";
import { useSelector } from "react-redux";
import { Routes_Urls } from "../../../../../Constant/Route_url";

const Earningstable = ({ earningsData, earningsList, setPage, page }) => {
  const navigate = useNavigate();
  const userId = useSelector((state) => state.users.profileData?.id);

  const earnslist = [
    {
      labeltitle: "Total Buyers",
      headingtitle: <>{earningsData.total_buyers}</>,
    },
    {
      labeltitle: "Total Sales",
      headingtitle: <>{earningsData.total_sales}</>,
    },
    {
      labeltitle: "Revenue",
      headingtitle: (
        <>
          ₹
          {getNumberWithComma(
            (earningsData.total_revenue / DECIMAL).toFixed(2)
          )}
        </>
      ),
    },
    {
      labeltitle: "Active Offers ",
      headingtitle: <>{earningsData.active_offers}</>,
    },
  ];

  const artNavigation = (item) => {
    let type;
    if (item.drop_type != 3) {
      type = determineEventStatus(item.start_time, item.end_time);
    } else {
      type = determineEventStatusOfFCFB(item);
    }
    if (type === "previous") {
      navigate(`/marketplace/art/${item.art_id}`);
    } else {
      navigate(`/curated-drop/digital-art/${type}/${item.art_id}`);
    }
  };

  const checkNavigation = (username) => {
    navigate(`/profile/${username}`);
  };

  return (
    <>
      <section className={style.earnings_table}>
        <ul className={style.list_earns}>
          {earnslist.map((data) => (
            <li>
              <label>{data.labeltitle}</label>
              <h5>{data.headingtitle}</h5>
            </li>
          ))}
        </ul>
        <CommonTable className={style.earnings_table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Transaction ID</th>
              <th>Art details</th>
              <th>Purchased by</th>
              <th>Price</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody>
            {earningsList?.length > 0 ? (
              earningsList?.map((item, index) => (
                <tr key={index}>
                  <td>{moment(item.createdAt).format("DD MMM YYYY")}</td>
                  <td>{moment(item.createdAt).format("hh:mm A")}</td>
                  <td>{item.transaction_id}</td>
                  <td className="space_sizing">
                    <div className={style.art_details}>
                      <img
                        src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
                        alt="articon"
                        onClick={() => {
                          artNavigation(item);
                        }}
                        className="cursor-pointer"
                      />
                      <div className={style.text_field}>
                        <h5
                          title={item.art_name}
                          className="cursor-pointer"
                          onClick={() => {
                            artNavigation(item);
                          }}
                        >
                          {sliceChar(item.art_name, 15)}
                        </h5>
                        <div
                          onClick={() => {
                            checkNavigation(item.artist_username);
                          }}
                        >
                          <p title={item.artist_username}>
                            @{sliceChar(item.artist_username, 15)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="space_sizing">
                    <div className={style.art_purchased}>
                      <img
                        src={
                          item.collector_profile
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item.collector_profile}`
                            : personIcon
                        }
                        alt="articon"
                        className="cursor-pointer"
                        onClick={() => {
                          checkNavigation(item.collector_username);
                        }}
                      />
                      <div className={style.text_field}>
                        <label>Collector</label>
                        <div
                          onClick={() => {
                            checkNavigation(item.collector_username);
                          }}
                        >
                          <p className="cursor-pointer">
                            <strong title={item.collector_username}>
                              @{sliceChar(item.collector_username, 15)}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <h4>
                      ₹{getNumberWithComma((item.price / DECIMAL).toFixed(2))}
                    </h4>
                  </td>
                  {/* <td>
                    <CommonBtn
                      role="btn"
                      className={`bordred-black ${style.btneditsize}`}
                      title="Get Invoice"
                      onClick=""
                    />
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <Notfound />
                </td>
              </tr>
            )}
          </tbody>
        </CommonTable>

        {earningsData.totalItems > 10 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={earningsData.totalPages}
            setActivePage={setPage}
            maxVisiblePages={5}
          />
        )}

        <div className={style.btndownload}>
          <CommonBtn
            role="btn"
            className={`btn-yellow mt-3 ${style.btndownsize}`}
            title="Download Report"
            onClick={() => {}}
          />
        </div>
      </section>
    </>
  );
};

export default Earningstable;
