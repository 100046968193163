import React from "react";
import { Dropdown } from "react-bootstrap";
import {
  DECIMAL,
  OFFER_STATUS,
  offerArtOfferPerPage,
} from "../../../../../../Constant/userConstant";
import {
  formatDateWithTime,
  getNumberWithComma,
  timeAgo,
} from "../../../../../../Helper/common";
import CommonBtn from "../../../../../Common/Ui/CommonBtn/CommonBtn";
import Commondropdown from "../../../../../Common/Ui/CommonDropdown/Commondropdown";
import CommonTable from "../../../../../Common/Ui/CommonTable/CommonTable";
import FormikControls from "../../../../../Common/Ui/Formik/FormikControls";
import style from "./OfferReceivedInex.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomPagination from "../../../../../Common/Ui/Pagination/CustomPagination";

const OfferReceivedIndivital = (props) => {
  let {
    art,
    sortedData,
    artIndex,
    setActivePage,
    updateStatusOpenModal,
    setSortBy,
  } = props;

  const navigate = useNavigate();
  const userId = useSelector((state) => state.users.profileData?.id);

  const goToUserProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  const onOfferPageChange = (page) => {
    setActivePage(page, artIndex, art);
  };
  return (
    <>
      <div className={style.table_sectionoffier}>
        <CommonTable className={style.offer_receive_table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Offer by</th>
              <th>Offer Price</th>
              {/* <th>My Offer Amount</th> */}

              <th>Offer Ends in</th>
              <th>Status</th>
              <th>
                <div className="accept_check">
                  {/* <FormikControls
                    control="checkbox"
                    type="checkbox"
                    name="terms"
                    label="Accepting Offer"
                    className="black_check"
                    onChange={(e) => {
                      let activeStatus = art.art_owners[0]?.is_offer_accepting;
                      onChangeActiveDeactiveOffer(activeStatus, art, artIndex);
                    }}
                    checked={
                      art.art_owners[0]?.is_offer_accepting == 1 ? true : false
                    }
                  /> */}
                  <Commondropdown classdrop="" dropdowntitle="Sort By">
                    <Dropdown.Item href="#" onClick={() => setSortBy("desc")}>
                      Newest
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={() => setSortBy("asc")}>
                      Oldest
                    </Dropdown.Item>
                  </Commondropdown>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData(art?.art_offer).map((item, index) => (
              <tr key={index}>
                <td>{timeAgo(item.createdAt)}</td>
                <td
                  className="cursor-pointer"
                  onClick={() => goToUserProfile(item.register_list.offer_by)}
                >
                  @{item.register_list.offer_by}
                </td>
                <td>
                  <div className="offer_price">
                    <h6>
                      ₹
                      {getNumberWithComma(
                        (
                          Number(item?.total_art_price_inc_tax) / DECIMAL
                        ).toFixed(2)
                      )}
                    </h6>
                    <button
                      type="button"
                      onClick={() =>
                        updateStatusOpenModal(
                          OFFER_STATUS.accepted,
                          item,
                          artIndex,
                          art
                        )
                      }
                    >
                      {" "}
                      View breakup Individual
                    </button>
                  </div>
                </td>

                <td>{formatDateWithTime(item?.duration)}</td>
                <td>
                  <div className="offer_price">
                    {item?.counter == 1 &&
                      item.status == OFFER_STATUS.coutered && <p>Countered</p>}
                    <h6>
                      {item?.counter == 1 &&
                      item.status == OFFER_STATUS.coutered
                        ? `₹${getNumberWithComma(
                            Number(item?.counter_offer / DECIMAL).toFixed(2)
                          )}`
                        : item?.status}
                    </h6>
                  </div>
                </td>

                <td>
                  <div className="btn_section">
                    {(item.status == OFFER_STATUS.pending ||
                      item.status == OFFER_STATUS.coutered) &&
                      item.counter_user_id != userId && (
                        <CommonBtn
                          role="btn"
                          className={`black-btn-yellow me-4 btneditsize`}
                          title="Accept"
                          onClick={() =>
                            updateStatusOpenModal(
                              OFFER_STATUS.accepted,
                              item,
                              artIndex,
                              art
                            )
                          }
                        />
                      )}
                    {item.status != OFFER_STATUS.declined &&
                      item.status !== OFFER_STATUS.accepted && (
                        <CommonBtn
                          role="btn"
                          className={`bordred-gray me-4 btneditsize`}
                          title="Decline"
                          onClick={() =>
                            updateStatusOpenModal(
                              OFFER_STATUS.declined,
                              item,
                              artIndex,
                              art
                            )
                          }
                        />
                      )}

                    {item.counter != 1 &&
                      item.status == OFFER_STATUS.pending && (
                        <CommonBtn
                          role="btn"
                          className={`bordred-gray btneditsize`}
                          title="My Offer"
                          onClick={() =>
                            updateStatusOpenModal(
                              OFFER_STATUS.coutered,
                              item,
                              artIndex,
                              art
                            )
                          }
                        />
                      )}
                  </div>
                </td>
              </tr>
            ))}

            {art?.art_offer.length == 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="no_recordtable">No records found</p>
                </td>
              </tr>
            )}
          </tbody>
        </CommonTable>
        {Math.ceil(art.offer_count / offerArtOfferPerPage) > 1 && (
          <CustomPagination
            className="offer_pagination ms-auto"
            activePage={art?.page}
            totalPage={Math.ceil(art.offer_count / offerArtOfferPerPage)}
            setActivePage={onOfferPageChange}
            maxVisiblePages={5}
          />
        )}
      </div>
    </>
  );
};

export default OfferReceivedIndivital;
