import React, { useEffect, useState } from "react";
import style from "./Useraccountsetting.module.scss";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Carddetails from "../../Private/Userprofile/Carddetails/Carddetails";
import Editprofile from "./Editprofile/Editprofile";
import Kyc from "./Kyc_sec/Kyc";
import Notificationsettings from "./Notificationsettings/Notificationsettings";
import { useDispatch, useSelector } from "react-redux";
import {
  SendOtp,
  getKycDetail,
  getTwoFA,
  getDocList,
  getUserProfile,
  updateProfileData,
  setActiveTab,
  updateUserDetails,
  uploadCoverImage,
} from "../../../Features/user/userSlice";
import { parsePhoneNumber } from "react-phone-number-input";
import Security from "./Security/Security";
import Accountactivity from "./Accountactivity/Accountactivity";
import { Helmet } from "react-helmet";
import { USER_TYPE } from "../../../Constant/userConstant";

const Useraccountsetting = (props) => {
  const dispatch = useDispatch();
  const key = useSelector((state) => state?.users?.key);
  const userId = useSelector((state) => state?.persist?.userId?.realId);
  const userDetail = useSelector((state) => state?.users?.profileData);
  const userType = useSelector((state) => state?.persist?.userType);
  const [statusEnableDisable, setStatusEnableDisable] = useState(0);
  const [docOption, setDocOption] = useState([]);
  const [kycDetail, setKycDetail] = useState({});

  const [profileDetail, setProfileDetail] = useState({});
  const [resendOtp, setResendOtp] = useState(false);
  const [getOtpButton, setGetOtpButton] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [is_sms_auth_enabled, setIs_sms_auth_enabled] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    onTabChangeHandler();
    setTimer(60);
    setIsTimerRunning(false);
    setShowInput(false);
  }, [key]);

  useEffect(() => {
    let intervalId;
    if (timer === 0) return;
    if (isTimerRunning) {
      if (isTimerRunning) {
        intervalId = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      }
      setIsTimerRunning(true);
      if (timer == 0) {
        setIsTimerRunning(false);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning, showInput, timer]);

  const onTabChangeHandler = () => {
    switch (key) {
      case "editprofile":
        getUserProfileHandler();
        break;
      case "kyc":
        getUserKycHandler();
        break;
      case "security":
        GET2FA();
        // GetOTP();
        break;
      case "collection":
        break;
      case "privacysettings":
        break;
      default:
        break;
    }
  };

  const getUserKycHandler = () => {
    getDocListHandler();
  };

  const updateProfile = (data) => {
    handleEditProfile(data);
  };

  const handleEditProfile = async (values) => {
    try {
      let formData = new FormData();
      if (values.file != "") {
        formData.append("profilePic", values.file);
      }
      formData.append(
        "phoneNumber",
        parsePhoneNumber(values?.phoneNumber)?.nationalNumber
      );
      formData.append(
        "countryCode",
        "+" + parsePhoneNumber(values?.phoneNumber)?.countryCallingCode
      );
      formData.append("username", values.username);
      formData.append("firstName", values.firstName);
      formData.append("middleName", values.middleName ? values.middleName : "");
      formData.append("lastName", values.lastName);
      formData.append("bio", values.bio);
      formData.append("email", values.email);
      formData.append(
        "bio_setting",
        userDetail.bio_public_input_disabled
          ? userDetail.bio_setting
          : values.makepublicBio
          ? 1
          : 0
      );
      if (userType === USER_TYPE.USER) {
        formData.append("name_flag", values.makepublicName ? 1 : 0);
      }
      formData.append(
        "socialLinks",
        JSON.stringify({
          facebook: values.facebookUrl ? values.facebookUrl : "",
          instagram: values.instagramUrl ? values.instagramUrl : "",
          twitter: values.twitterUrl ? values.twitterUrl : "",
          linkedin: values.linkedinUrl ? values.linkedinUrl : "",
          isFacebookPublic: userDetail?.is_facebook_public_input_disabled
            ? userDetail.social_links.isFacebookPublic
            : values.makepublicFacebook
            ? 1
            : 0,
          isInstagramPublic: userDetail?.is_instagram_public_input_disabled
            ? userDetail.social_links.isInstagramPublic
            : values.makepublicInsta
            ? 1
            : 0,
          isTwitterPublic: userDetail?.is_twitter_public_input_disabled
            ? userDetail.social_links.isTwitterPublic
            : values.makepublicTwitter
            ? 1
            : 0,
          isLinkedinPublic: userDetail?.is_linkedin_public_input_disabled
            ? userDetail.social_links.isLinkedinPublic
            : values.makepublicLinkdin
            ? 1
            : 0,
        })
      );

      const response = await dispatch(updateProfileData(formData));
      if (response) {
        getUserProfileHandler();
      }
    } catch (err) {}
  };

  const getKycDetailHandler = async () => {
    try {
      let response = await dispatch(getKycDetail());
      setKycDetail(response.data.data);
    } catch (err) {}
  };

  const getDocListHandler = async () => {
    //Getting Doc type like Adhar Card, Pan Card, etc.
    try {
      if (Object.keys(kycDetail).length == 0) {
        let response = await dispatch(getDocList());
        let docArr = response.data.data;
        const customDocArr = docArr?.map((item) => {
          return { value: item.id, label: item?.doc_type };
        });
        setDocOption(customDocArr);
      }
      getKycDetailHandler();
    } catch (err) {}
  };

  const GET2FA = async () => {
    const response = await dispatch(getTwoFA());
    setStatusEnableDisable(response.data.data.is_sms_auth_enabled);
    setIs_sms_auth_enabled(response.data.data.is_sms_auth_enabled);
  };

  const GetOTP = async () => {
    try {
      let data = {
        service: "AUTHENTICATE",
      };
      let response = await dispatch(SendOtp(data));
      if (response.status == 200) {
        setIsTimerRunning(true);
        setResendOtp(true);
        setGetOtpButton(false);
        setTimer(60);
      }
    } catch (err) {}
  };

  const getUserProfileHandler = async () => {
    //userType 3 means real data
    let params = {};
    if (userType === 3) {
      params = {
        id: userId, // type is used for real data
      };
    }
    try {
      let response = await dispatch(getUserProfile(params));
      if (response) {
        let data = response.data.data.data;
        setProfileDetail(data);
        if (userType !== 3) {
          dispatch(updateUserDetails(data));
        }
      }
    } catch (err) {}
  };

  const getOTPSuccess = async () => {
    try {
      let data = {
        service: "AUTHENTICATE",
      };
      let response = await dispatch(SendOtp(data));
      if (response.status == 200) {
        handleResendOTP();
      }
    } catch (err) {}
  };
  const handleResendOTP = () => {
    setIsTimerRunning(true);
    setTimer(59);
  };

  const uploadCoverImageHandler = async (file) => {
    try {
      const formData = new FormData();
      formData.append("cover_image_path", file);
      let response = await dispatch(uploadCoverImage(formData));
      await dispatch(getUserProfile());
      // getUserProfileHandler();
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <>
      {Object.keys(userDetail).length > 0 && (
        <section className={style.Useraccount_setting}>
          <Helmet>
            <title>NewArtX</title>
            <meta name="description" content="" />
          </Helmet>
          <CommonHero
            backgroundImg={
              userDetail?.cover_image_path
                ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
                : CoverImage
            }
            tab={key}
            uploadCoverImageHandler={uploadCoverImageHandler}
          />

          <Carddetails tab={key} />
          <div className={style.Useraccount_setting_section}>
            <Container>
              <div className={style.tablist}>
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={key}
                  activeKey={key}
                  className="tabvertical"
                  onSelect={(tab) => {
                    dispatch(setActiveTab(tab));
                  }}
                >
                  <Row>
                    <Col xl={2} lg={3} md={12}>
                      <Nav variant="pills" className="nav_tabslist">
                        <Nav.Item>
                          <Nav.Link eventKey="editprofile">
                            Edit Profile
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="kyc">KYC</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="security">Security</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="privacysettings">
                            Privacy Settings
                          </Nav.Link>
                        </Nav.Item> */}
                        {/* <Nav.Item>
                          <Nav.Link eventKey="paymentsettings">
                            Payment Settings
                          </Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link eventKey="notificationsettings">
                            Notification Settings
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          {userType === 2 && (
                            <Nav.Link eventKey="accountactivity">
                              Account Activity
                            </Nav.Link>
                          )}
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col xl={10} lg={9} md={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="editprofile">
                          {key == "editprofile" && (
                            <Editprofile
                              updateProfile={updateProfile}
                              profileData={profileDetail}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="kyc">
                          {key == "kyc" && (
                            <Kyc
                              docOption={docOption}
                              kycDetail={kycDetail}
                              getKycDetailHandler={getKycDetailHandler}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="security">
                          {key == "security" && (
                            <Security
                              GET2FA={GET2FA}
                              GetOTP={GetOTP}
                              statusEnableDisable={statusEnableDisable}
                              resendOtp={resendOtp}
                              setResendOtp={setResendOtp}
                              getOtpButton={getOtpButton}
                              setGetOtpButton={setGetOtpButton}
                              key={key}
                              setStatusEnableDisable={setStatusEnableDisable}
                              setShowInput={setShowInput}
                              showInput={showInput}
                              is_sms_auth_enabled={is_sms_auth_enabled}
                              isTimerRunning={isTimerRunning}
                              getOTPSuccess={getOTPSuccess}
                              timer={timer}
                            />
                          )}
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="privacysettings">
                          {key == "privacysettings" && <Privacysettings />}
                        </Tab.Pane> */}
                        {/* <Tab.Pane eventKey="paymentsettings">
                          {key == "paymentsettings" && <Paymentsettings />}
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="notificationsettings">
                          {key == "notificationsettings" && (
                            <Notificationsettings />
                          )}
                        </Tab.Pane>
                        {key == "accountactivity" && (
                          <Tab.Pane eventKey="accountactivity">
                            <Accountactivity />
                          </Tab.Pane>
                        )}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Container>
          </div>
        </section>
      )}
    </>
  );
};

export default Useraccountsetting;
