import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./MainLayout.scss";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import HeaderBeforeLogin from "../../Header/HeaderBeforeLogin";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const MainLayout = (props) => {
  const location = useLocation();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);
  return (
    <div className="mainLayout">
      <div className="mainLayout_innerpart">
        {isUserLoggedin ? <Header /> : <HeaderBeforeLogin />}
        <main className="mainWrappe">{props.children}</main>
      </div>
      <Footer />
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
