import React from "react";
import SearchFilter from "../../../Common/SearchFilter/SearchFilter";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import style from "./Moremodal.module.scss";

const Moremodal = ({
  show,
  onHide,
  data,
  searchType,
  getCheckedStatus,
  artist,
  tag,
  series,
}) => {
  return (
    <>
      <CommonModal className={style.moremodal} show={show} onHide={onHide}>
        <div className={style.modalcontent}>
          <SearchFilter
            data={data}
            searchType={searchType}
            getCheckedStatus={getCheckedStatus}
            artist={artist}
            tag={tag}
            series={series}
          />
        </div>
      </CommonModal>
    </>
  );
};

export default Moremodal;
