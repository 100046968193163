import React, { useEffect, useState } from "react";
import style from "./Artowned.module.scss";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import Connectwallet from "./components/Connectwallet";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DECIMAL } from "../../../../Constant/userConstant";
import Sellnowmodal from "../../../Pages/Newdesigns/Sellnowmodal/Sellnowmodal";
import AcceptOffermodal from "../../../Pages/Newdesigns/AcceptOffermodal/AcceptOffermodal";
import {
  acceptOfferOnOff,
  updateOfferStatus,
} from "../../../../Features/offerSlice/offerSlice";
import Checkbox from "../../../Common/Ui/Formik/Checkbox";
import { selectDevice } from "../../../../Features/linkedDevice/linkedDeviceSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { SuccessModal } from "../../../Pages/NewModals/SuccessModal";
import { ExportWalletModal } from "../../../Pages/NewModals/ExportWalletModal";
import {
  useDisconnect,
  useSwitchNetwork,
  useWeb3Modal,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import ImportNftModal from "./components/ImportNftModal";
import { Routes_Urls } from "../../../../Constant/Route_url";

const Artowned = (props) => {
  let { data, setSearch, page, totalPage, setActivePage, getUserLikes } = props;
  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const chain = 80002;

  const { switchNetwork } = useSwitchNetwork();

  const dispatch = useDispatch();
  const customStyles = {
    option: (provided, state) => {
      // Log information specific to the option style
      return {
        ...provided,
        backgroundColor:
          state.data.is_art_played == 1 ? "#edc45d" : "#00000099", // Use the color property from the option
        color: state.data.is_art_played == 1 ? "black" : "#ffffff66",
      };
    },
  };

  const profileData = useSelector((state) => state?.users?.profileData);
  const navigate = useNavigate();
  const [showsell, setShowsell] = useState(false);
  const [showacceptoffer, setShowacceptoffer] = useState(false);
  const [sellItem, setSellItem] = useState({});
  const [saveActionDetails, setSaveActionDetails] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [showImportNft, setShowImportNft] = useState(false);
  const [selectedArtDetail, setSelectedArtDetail] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);

  const initialValue = {};
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (isConnected) {
      connectionHandler();
    }
  }, [isConnected]);

  const showSellpopUp = (item) => {
    setSellItem(item);
    setShowsell(true);
  };

  const connectionHandler = async () => {
    await switchNetwork(chain);
    setShowImportNft(true);
  };

  const goToUserProfile = (item) => {
    navigate(`/profile/${item?.username}`);
  };

  const getUpdateOfferStatus = async (item) => {
    let updateStatusData = {
      status: item.status,
      id: item.id,
    };

    try {
      let response = await dispatch(updateOfferStatus(updateStatusData));
      if (response) {
        setShowacceptoffer(false);
        props.getUserLikes();
      }
    } catch (err) {}
  };

  const acceptHighestOfferOpenModal = (item, index) => {
    let data = {
      index: 0,
      item: {
        art_base_price: item.art_base_price,
        gst_amount: item.gst_amount,
        service_charge_amount: item.service_charge_amount,
        gst_amount_on_service_charge: item.gst_amount_on_service_charge,
        offer_price: item.offer_price,
        total_art_price_inc_tax: item.art_price,
        id: item.highest_offer_id,
        edition_no: item.user_owned_no_of_editions,
      },
      art: {
        highest_offer_price: item.highest_offer_price,
        id: item.art_id,
        art_name: item.art_name,
        art_image: item.art_image,
        thumbnail_image: item.thumbnail_image,
        drop_type: item.drop_type,
        sold_no_of_editions: item.sold_no_of_editions,
        no_of_editions: item.total_no_of_editions,
        like_count: item.like_count,
        view_count: item.view_count,
        last_sell_price: item.last_sell_price,
        art_user: {
          username: item.username,
          artist_id: item.artist_id,
        },
        art_owner_editions: [
          {
            edition_no: item.user_owned_no_of_editions,
          },
        ],
        art_owners: [
          {
            purchase_price: item.last_buy_price,
            last_buy_price: item.last_buy_price,
            number_of_editions: item.total_no_of_editions,
          },
        ],
        offer_count: 1,
        art_offer: [
          {
            edition_no: item.user_owned_no_of_editions,
          },
        ],
      },
    };
    setSaveActionDetails(data);
    setShowacceptoffer(true);
  };

  const onChangeActiveDeactiveOffer = async (value, item) => {
    //0 means unselected 1 means selected
    let data = {
      art_id: item.art_id,
      is_offer_accepting: value == false ? 0 : 1,
      edition_no: item.user_owned_no_of_editions,
    };
    try {
      let response = await dispatch(acceptOfferOnOff(data));
      props.getUserLikes();
    } catch (err) {}
  };

  const artNavigation = (item) => {
    // checking the art is upcoming or live or previous
    let type;
    if (item.drop_type != 3) {
      type = determineEventStatus(item.start_time, item.end_time);
    } else {
      type = determineEventStatusOfFCFB(item);
    }
    if (type === "previous") {
      navigate(`/marketplace/art/${item.art_id}`);
    } else {
      navigate(`/curated-drop/digital-art/${type}/${item.art_id}`);
    }
  };

  const handleChange = async (device, art_id) => {
    //0 means unselected 1 means selected
    try {
      let data = {
        device_id: device.value,
        is_play_on_device: device.is_art_played === 1 ? 0 : 1,
      };
      let params = {
        art_id: art_id,
      };
      const response = await dispatch(selectDevice(data, params));
      if (response) {
        getUserLikes();
      }
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  const exportHandler = (item) => {
    setSelectedArtDetail(item);
    setShowExport(true);
  };

  return (
    <>
      <section className={style.art_owned}>
        <div className={style.searchbar}>
          <form>
            <>
              <div className="search_input">
                <div className="search_input_inner">
                  <button type="submit" className="search_input_icon">
                    <SearchIcon />
                  </button>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Arts, Artist"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </>
            <CommonBtn
              role="btn"
              type={"button"}
              title="Import Digital Art"
              className={`bordred-black ${style.btnimport}`}
              onClick={async () => {
                open();
              }}
            />
          </form>
          {/* <w3m-button /> */}
        </div>

        <div className={style.table_section}>
          <CommonTable className={style.artowned_table}>
            <thead>
              <tr>
                <th>Digital Art</th>
                <th>Edition No.</th>
                <th className="username">Artist Name</th>
                <th>Views</th>
                <th>Likes</th>
                <th>Price</th>
                <th>Offers</th>
                <th>Highest Offer</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                const array = [];
                item.linked_devices?.map((ele, index) => {
                  array.push({
                    value: ele.device_id,
                    label: ele.device_name,
                    is_art_played: ele?.is_art_played,
                  });
                });
                //0 means unselected 1 means selected
                return (
                  <tr key={index}>
                    <td onClick={() => artNavigation(item)}>
                      <div className="d-flex align-items-center thumb-outer cursor-pointer">
                        <img
                          className="thumb cursor-pointer"
                          src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
                          alt="thumb"
                        />
                        <h4 title={item.art_name}>
                          {sliceChar(item.art_name, 12)}
                        </h4>
                      </div>
                    </td>
                    <td>
                      {item.user_owned_no_of_editions == 0
                        ? "Updating..."
                        : `#${item.user_owned_no_of_editions}`}
                    </td>
                    <td
                      className="username clickable"
                      title={item.username}
                      onClick={() => goToUserProfile(item)}
                    >
                      @
                      {item.username?.length > 20
                        ? item.username.slice(0, 20) + "...."
                        : item.username}
                    </td>
                    <td>{item.view_count}</td>
                    <td>{item.like_count}</td>
                    <td>
                      ₹{getNumberWithComma(item.last_buy_price / DECIMAL)}
                    </td>
                    <td>{item.offer_count || "-"}</td>
                    <td>
                      {item.highest_offer_price
                        ? `₹${getNumberWithComma(
                            item.highest_offer_price / DECIMAL
                          )}`
                        : "-"}
                    </td>
                    <td className="select_multisign">
                      {item?.linked_devices.length ? (
                        <>
                          {/* <label htmlFor="device-select">Play on device</label> */}
                          <Select
                            options={array}
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => handleChange(e, item?.art_id)}
                            styles={customStyles}
                            defaultValue={array.filter(
                              (item) => item.is_art_played === 1
                            )}
                            placeholder={
                              selectedOption
                                ? "Play on device"
                                : "Play on device"
                            }
                            value={array.filter(
                              (item) => item.is_art_played === 1
                            )}
                          />
                        </>
                      ) : (
                        "No device selected"
                      )}
                    </td>
                    <td>
                      <Checkbox
                        className={"black_label_input mb-0"}
                        label="Accepting Offer"
                        onChange={(e) => {
                          onChangeActiveDeactiveOffer(e.target.checked, item);
                        }}
                        value={item.is_offer_accepting}
                        checked={item.is_offer_accepting}
                      />
                    </td>
                    <td>
                      {item.user_owned_no_of_editions != 0 && (
                        <div className="d-flex align-items-center justify-content-evenly">
                          {item.is_on_sell == 0 && (
                            <CommonBtn
                              role="btn"
                              title={"Sell"}
                              className={`black-btn ${style.notifiedBtn}`}
                              onClick={() => showSellpopUp(item)}
                            />
                          )}
                          <CommonBtn
                            role="btn"
                            title={"Accept Highest Offer"}
                            className={`bordred-black ${style.borderBtn}`}
                            onClick={() =>
                              acceptHighestOfferOpenModal(item, index)
                            }
                            disabled={!item.highest_offer_id}
                          />
                          <CommonBtn
                            role="btn"
                            title={"Export to External Wallet"}
                            className={`bordred-black ${style.borderBtn}`}
                            onClick={() => exportHandler(item)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {data.length == 0 && (
              <tr>
                <td colSpan={11}>
                  <p className="no_recordtable">No records found</p>
                </td>
              </tr>
            )}
          </CommonTable>
          {totalPage > 1 && (
            <CustomPagination
              className="ms-auto"
              activePage={page}
              totalPage={totalPage}
              maxVisiblePages={5}
              dispatch={dispatch}
              componentType={"Profile"}
            />
          )}
        </div>
        {showsell && (
          <Sellnowmodal
            sellItem={sellItem}
            show={showsell}
            onHide={() => setShowsell(false)}
            getUserLikes={getUserLikes}
          />
        )}
      </section>
      {/* {walletshow && (
        <Connectwallet
        show={walletshow}
        handleClose={() => setWalletshow(false)}
        />
      )} */}

      {showacceptoffer && (
        <AcceptOffermodal
          show={showacceptoffer}
          onHide={() => setShowacceptoffer(false)}
          makeOfferDetails={saveActionDetails}
          getUpdateOfferStatus={getUpdateOfferStatus}
          isHighestAccept={true}
        />
      )}
      {showExport && (
        <ExportWalletModal
          show={showExport}
          handleClose={() => setShowExport(false)}
          selectedArtDetail={selectedArtDetail}
        />
      )}
      {showImportNft && (
        <ImportNftModal
          show={showImportNft}
          setShowSuccess={setShowSuccess}
          onHide={() => {
            disconnect();
            setShowImportNft(false);
          }}
          address={address}
        />
      )}

      {showSuccess && (
        <SuccessModal
          show={showSuccess}
          onHide={() => {
            disconnect();
            setShowSuccess(false);
          }}
        />
      )}
    </>
  );
};

export default Artowned;
