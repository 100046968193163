import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "./Checkout.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import { PlusIcon, SubtractionIcon } from "../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import { useDispatch, useSelector } from "react-redux";
import "yup-phone";
import {
  buyArt,
  getArtDetailById,
  placeOrderByCurated,
} from "../../../Features/marketplace/marketplaceSlice";
import {
  formatDate,
  getNumberWithComma,
  sliceChar,
} from "../../../Helper/common";
import {
  externalPrimaryCheckout,
  externalSecondaryCheckout,
  couponVerify,
  getCouponList,
  getGstList,
  getWalletBalance,
  saveLatestPrice,
  cancelPayment,
} from "../../../Features/user/userSlice";
import { toasts } from "../../Common/Toast/Toast";
import { DECIMAL } from "../../../Constant/userConstant";
import { indianStates } from "../../../Constant/State";
import { citiesInIndia } from "../../../Constant/City";
import { parsePhoneNumber } from "react-phone-number-input";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import AvailableCouponModal from "../../Common/Header/AvailableCouponModal/AvailableCouponModal";
import { useDebounce } from "../../../customHooks/useDebounce";
import {
  startLoading,
  stopLoading,
} from "../../../Features/loading/loadingSlice";
import NewartxLogo from "../../../Assets/images/newArtX_Logo_Img.png";
import { Helmet } from "react-helmet";

const CuratedCheckoutSec = () => {
  const latestPrice = useSelector((state) => state?.users?.latestPrice);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [art, setArt] = useState({});
  const [quantity, setQuantity] = useState(latestPrice.quantity);
  const [checkedAddGstDetail, setCheckedAddGstDetail] = useState(false);
  const [checkedGstOption, setCheckedGstOption] = useState(false);
  const [gstOption, setGstOption] = useState(false);
  const [isResponse, setIsResponse] = useState(false);
  const [userWalletBalance, setUserWalletBalance] = useState("");
  const [internalPaymentCheck, setInternalPaymentCheck] = useState(true);
  const [externalPaymentCheck, setExternalPaymentCheck] = useState(false);
  const [cityOption, setCityOption] = useState([]);
  const [checkToAddProfile, setCheckAddToProfile] = useState(false);
  const [phoneCountryCode, setPhoneNumberCountryCode] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [couponList, setCouponList] = useState([]);
  const [isCouponVerify, setIsCouponVerify] = useState(false);
  const [finalAmount, setFinalAmount] = useState("");
  const [discountAmountData, setDiscountAmountData] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loading = useSelector((state) => state?.loading?.loading);

  const initialValue = {
    state: "",
    gst: "",
    legalBusinessName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    email: "",
    selectGST: "",
    code: "",
  };
  const gstSchema = Yup.object().shape({
    state: Yup.object().required(
      inputFieldValidation.commonMessage.required("State")
    ),
    gst: Yup.string()
      .matches(/^\S+$/, "*Space is not allowed")
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      )
      .min(
        inputFieldValidation.gst.minLength,
        `*Must be of ${inputFieldValidation.gst.minLength} digit`
      )
      .max(
        inputFieldValidation.gst.maxLength,
        `*Must be of ${inputFieldValidation.gst.minLength} digit`
      )
      .required(inputFieldValidation.commonMessage.required("GST number")),
    legalBusinessName: Yup.string()
      .matches(
        /^[a-zA-Z0-9\s]*$/,
        "*Only alphanumeric characters and spaces are allowed"
      )
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Business name should not start or end with a space"
      )
      .min(
        inputFieldValidation.businessName.minLength,
        `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
      )
      .max(
        inputFieldValidation.businessName.maxLength,
        `*Must be between ${inputFieldValidation.businessName.minLength}-${inputFieldValidation.businessName.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Business name")),
    addressLine1: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      )
      .min(
        inputFieldValidation.address.minLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .max(
        inputFieldValidation.address.maxLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Address")),
    addressLine2: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Address should not start or end with a space"
      )
      .min(
        inputFieldValidation.address.minLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .max(
        inputFieldValidation.address.maxLength,
        `*Must be between ${inputFieldValidation.address.minLength}-${inputFieldValidation.address.maxLength} characters`
      )
      .required(inputFieldValidation.commonMessage.required("Address")),
    city: Yup.string().required(
      inputFieldValidation.commonMessage.required("City")
    ),
    pinCode: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Pin code"))
      .matches(/^[1-9]\d{5}$/, "*Invalid PIN code"),
    phoneNumber: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Phone number"))
      .phone(phoneCountryCode, true, "*Invalid phone number"),
    email: Yup.string()
    .matches(
      inputFieldValidation.email.regexForSingup.expresionForSingup,
      inputFieldValidation.email.regexForSingup.errorMessageForSingup
    )
      .required(inputFieldValidation.commonMessage.required("Email")),
  });

  const gstOptionSchema = Yup.object().shape({
    selectGST: Yup.object().required(
      inputFieldValidation.commonMessage.required("GST")
    ),
  });

  const couponSchema = Yup.object().shape({
    code: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Code should not start or end with a space"
      )
      .min(
        inputFieldValidation.coupon_code.minLength,
        `*Must be of ${inputFieldValidation.coupon_code.minLength} characters`
      )
      .max(
        inputFieldValidation.coupon_code.maxLength,
        `*Must be of ${inputFieldValidation.coupon_code.maxLength} characters`
      ),
  });

  useEffect(() => {
    // Load the Razorpay script dynamically
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (latestPrice?.price > 0 && id) {
      getUserBalance();
      getArtDetailByIdHandler();
      getGstListHandler();
      getCouponListFunction();
    } else {
      navigate(-1);
      dispatch(saveLatestPrice({}));
    }
  }, [latestPrice?.price, id, ArtistId]);

  useEffect(() => {
    formik.resetForm({});
  }, [ArtistId]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: (values) => {
      if (latestPrice.market === "primary" && checkedAddGstDetail) {
        return Yup.lazy((values) => {
          return Yup.object().shape({
            ...couponSchema.fields, // Spread fields from couponSchema
            ...gstSchema.fields, // Spread fields from gstSchema
          });
        });
      }
      if (latestPrice.market === "primary" && checkedGstOption) {
        return Yup.lazy((values) => {
          return Yup.object().shape({
            ...couponSchema.fields, // Spread fields from couponSchema
            ...gstOptionSchema.fields, // Spread fields from gstSchema
          });
        });
      } else if (latestPrice.market === "primary") {
        return couponSchema;
      } else if (checkedAddGstDetail) {
        return gstSchema;
      } else if (checkedGstOption) {
        return gstOptionSchema;
      }
      return Yup.object({});
    },
    onSubmit: (values) => {
      if (internalPaymentCheck) {
        if (latestPrice.market == "primary") {
          checkoutPrimaryHandler(values);
        } else if (latestPrice.market === "secondary") {
          checkoutSecondaryHandler(values);
        }
      } else {
        // for external submission
        if (latestPrice.market == "primary") {
          externalPrimaryCheckoutHandler();
        } else if (latestPrice.market === "secondary") {
          externalSecondaryCheckoutHandler();
        }
      }
    },
  });

  const debouncedSearchTerm = useDebounce(formik.values.code, 1500);

  useEffect(() => {
    if (formik.values.code !== "" && formik.values.code.length == 10) {
      couponVerifyFunction();
    }
  }, [debouncedSearchTerm, quantity]);

  useEffect(() => {
    //checking if coupon is airdrop than art price become zero so preveting user to do payment of zero rs by external mode.
    if (Number(finalAmount) === 0) {
      setExternalPaymentCheck(false);
      setInternalPaymentCheck(true);
    }
  }, [isCouponVerify]);

  const getCouponListFunction = async () => {
    let params = {
      art_id: id,
    };
    try {
      let response = await dispatch(getCouponList(params));
      setCouponList(response?.data?.list);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const getGstListHandler = async () => {
    let params = {};
    try {
      const response = await dispatch(getGstList(params));
      let option = response.data?.data?.list.map((item) => {
        return { value: item?.gst_id, label: item?.gst_number };
      });
      setGstOption(option);
    } catch (error) {}
  };

  const checkoutPrimaryHandler = async () => {
    if (isSubmitting) {
      // Prevent multiple submissions
      return;
    }
    setIsSubmitting(true);

    let data = {
      art_id: id,
      amount: Number(latestPrice?.price),
      quantity: quantity,
      is_gst_info: 0,
    };

    if (latestPrice.hasOwnProperty("is_max_limit")) {
      data["is_max_limit"] = latestPrice?.is_max_limit;
    }

    // Conditionally adding coupon id
    if (latestPrice?.market === "primary") {
      // data.coupon_id = verifyCouponId ? verifyCouponId : "";
      data.coupon_code = formik.values.code;
    }

    if (checkedGstOption) {
      data.buyer_gst_id = formik.values.selectGST.value;
      data.is_gst_info = 0;
    }
    if (checkedAddGstDetail) {
      data.is_gst_info = 1;
      data.gst_info = {
        gst_number: formik?.values.gst,
        business_name: formik?.values.legalBusinessName,
        country_code: `+${
          parsePhoneNumber(formik?.values?.phoneNumber)?.countryCallingCode
        }`,
        mobile: parsePhoneNumber(formik?.values?.phoneNumber)?.nationalNumber,
        email: formik?.values.email,
        address_line_1: formik?.values.addressLine1,
        address_line_2: formik?.values.addressLine2,
        city: formik?.values.city,
        state: formik?.values.state.value,
        pincode: formik?.values.pinCode,
        is_add_on_profile: checkToAddProfile ? 1 : 0,
      };
    }
    if (internalPaymentCheck) {
      data.is_internal_wallet = 0;
    }
    dispatch(startLoading());
    try {
      if (quantity > 0) {
        let response = await dispatch(placeOrderByCurated(data));

        if (internalPaymentCheck) {
          navigate(-1);
        }
      } else {
        toasts.error("Quantity must be greater than 0");
      }
    } catch (err) {
      setIsSubmitting(false);
      navigate(-1);
    }
    dispatch(stopLoading());
  };

  const checkoutSecondaryHandler = async () => {
    let data = {
      art_id: id,
      amount: Number(latestPrice?.price),
      is_gst_info: 0,
      edition_number: latestPrice.editionNumber,
    };
    if (checkedGstOption) {
      data.buyer_gst_id = formik.values.selectGST.value;
      data.is_gst_info = 0;
    }
    if (checkedAddGstDetail) {
      data.is_gst_info = 1;
      data.gst_info = {
        gst_number: formik?.values.gst,
        business_name: formik?.values.legalBusinessName,
        country_code: `+${
          parsePhoneNumber(formik?.values?.phoneNumber)?.countryCallingCode
        }`,
        mobile: parsePhoneNumber(formik?.values?.phoneNumber)?.nationalNumber,
        email: formik?.values.email,
        address_line_1: formik?.values.addressLine1,
        address_line_2: formik?.values.addressLine2,
        city: formik?.values.city,
        state: formik?.values.state.value,
        pincode: formik?.values.pinCode,
        is_add_on_profile: checkToAddProfile ? 1 : 0,
      };
    }

    if (internalPaymentCheck) {
      data.is_internal_wallet = 0;
    }
    dispatch(startLoading());

    try {
      let response = await dispatch(buyArt(data));
      if (internalPaymentCheck) {
        navigate(-1);
      }
    } catch (err) {}

    dispatch(stopLoading());
  };

  const getArtDetailByIdHandler = async () => {
    let params = {
      id: id,
    };
    try {
      const response = await dispatch(getArtDetailById(params));
      setArt(response.data.data);
      // getCouponListFunction(response.data.data.id);
    } catch (err) {
      navigate(-1);
    }
  };

  const getUserBalance = async () => {
    try {
      const response = await dispatch(getWalletBalance());
      setUserWalletBalance(response.data.data.user_fiat_account.balance);
      setIsResponse(true);
    } catch (error) {}
  };

  const subHanlder = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  const addHanlder = () => {
    if (latestPrice.buyCount > quantity) {
      setQuantity((prev) => prev + 1);
    } else {
      toasts.error("Your maximum limit is reached.");
    }
  };
  const statewiseCity = (value) => {
    setCityOption(citiesInIndia[value]);
  };

  const couponVerifyFunction = async (values) => {
    try {
      let data = {
        coupon_code: values ? values : debouncedSearchTerm,
        amount: latestPrice?.price * Number(quantity),
        art_id: art.id,
        quantity:quantity,
      };
      const response = await dispatch(couponVerify(data));
      setFinalAmount(response.data.data.final_amount);
      setDiscountAmountData(response.data.data);
      if (response?.status == 200) {
        setIsCouponVerify(true);
      } else {
        setIsCouponVerify(false);
      }
    } catch (err) {
      setIsCouponVerify(false);
    }
  };

  const getDiscountAmount = () => {
    if (discountAmountData.coupon_type == "AIRDROP") {
      return getNumberWithComma(discountAmountData.discount_amount / DECIMAL);
    } else if (discountAmountData.coupon_usage == "MULTIPLE") {
      return getNumberWithComma(discountAmountData.discount_amount / DECIMAL);
    } else if (discountAmountData.coupon_usage == "SINGLE") {
      return getNumberWithComma(discountAmountData.discount_amount / DECIMAL);
    }
  };
  //primary
  const externalPrimaryCheckoutHandler = async () => {
    let data = {
      art_id: id,
      amount: Number(latestPrice?.price),
      quantity: quantity,
      is_gst_info: 0,
      orderType: 0,
      coupon_code: formik.values.code,
    };

    if (latestPrice.hasOwnProperty("is_max_limit")) {
      data["is_max_limit"] = latestPrice?.is_max_limit;
    }
    if (checkedGstOption) {
      data.buyer_gst_id = formik.values.selectGST.value;
      data.is_gst_info = 0;
    }
    if (checkedAddGstDetail) {
      data.is_gst_info = 1;
      data.gst_info = {
        gst_number: formik?.values.gst,
        business_name: formik?.values.legalBusinessName,
        country_code: `+${
          parsePhoneNumber(formik?.values?.phoneNumber)?.countryCallingCode
        }`,
        mobile: parsePhoneNumber(formik?.values?.phoneNumber)?.nationalNumber,
        email: formik?.values.email,
        address_line_1: formik?.values.addressLine1,
        address_line_2: formik?.values.addressLine2,
        city: formik?.values.city,
        state: formik?.values.state.value,
        pincode: formik?.values.pinCode,
        is_add_on_profile: checkToAddProfile ? 1 : 0,
      };
    }
    if (externalPaymentCheck) {
      data.is_internal_wallet = 1;
    }

    try {
      let response = await dispatch(externalPrimaryCheckout(data));
      handlePayment(response.data.data);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  //secondary
  const externalSecondaryCheckoutHandler = async () => {
    let data = {
      art_id: id,
      amount: Number(latestPrice?.price),
      is_gst_info: 0,
      edition_number: latestPrice.editionNumber,
      orderType: 1,
      quantity: 1, //as per backend static quantity send for secondary
    };
    if (checkedGstOption) {
      data.buyer_gst_id = formik.values.selectGST.value;
      data.is_gst_info = 0;
    }
    if (checkedAddGstDetail) {
      data.is_gst_info = 1;
      data.gst_info = {
        gst_number: formik?.values.gst,
        business_name: formik?.values.legalBusinessName,
        country_code: `+${
          parsePhoneNumber(formik?.values?.phoneNumber)?.countryCallingCode
        }`,
        mobile: parsePhoneNumber(formik?.values?.phoneNumber)?.nationalNumber,
        email: formik?.values.email,
        address_line_1: formik?.values.addressLine1,
        address_line_2: formik?.values.addressLine2,
        city: formik?.values.city,
        state: formik?.values.state.value,
        pincode: formik?.values.pinCode,
        is_add_on_profile: checkToAddProfile ? 1 : 0,
      };
    }
    if (externalPaymentCheck) {
      data.is_internal_wallet = 1;
    }
    try {
      let response = await dispatch(externalSecondaryCheckout(data));
      handlePayment(response.data.data);
    } catch (err) {
      console.log("error in catch", err);
    }
  };

  const handlePayment = async (values) => {
    let amount =
      latestPrice.market == "primary"
        ? Number(latestPrice?.price) * 100 * quantity
        : Number(latestPrice?.price) * 100;
    // Create an instance of Razorpay with options
    const rzp = new window.Razorpay({
      key: process.env.REACT_APP_RAZAOR_PAY, // Replace with your Razorpay Key ID
      amount: amount, // Amount is in currency subunits (paise)
      currency: "INR",
      name: "Newartx", // Your business name
      description: "Testing",
      image: NewartxLogo,
      order_id: values?.id,
      timeout: 300,
      theme: {
        color: "#51bc89", //"#3399cc",
      },
      handler: function (response) {
        document.body.style.overflow = "visible";
        if (response.razorpay_payment_id) {
          if (latestPrice?.market === "primary") {
            navigate(`/curated-drop/digital-art/live/${id}`);
          } else {
            navigate(`/marketplace/art/${id}`);
          }
        }
        // Add any additional logic here after payment success
      },
      // callback_url: "https://newartx.com//", // Your callback URL
      config: {
        display: {
          hide: [{ method: "paylater" }, { method: "wallet" }],
          preferences: { show_default_blocks: true },
        },
      },
      retry: {
        enabled: false,
      },
      modal: {
        ondismiss: function (error) {
          document.body.style.overflow = "visible";
          let data = {
            orderId: values?.id,
          };
          dispatch(cancelPayment(data));
          if (error?.error?.description) {
            toasts.error(error?.error?.description);
          }
          if (latestPrice?.market === "primary") {
            navigate(`/curated-drop/digital-art/live/${id}`);
          } else {
            navigate(`/marketplace/art/${id}`);
          }
        },
      },
    });
    // Open the payment modal
    rzp.open();
  };

  const checkInsufficientBalanceCondition = (isCouponVerify) => {
    let totalArtPrice = isCouponVerify
      ? Number(finalAmount / DECIMAL)
      : Number(latestPrice.price) * quantity;
    return totalArtPrice > userWalletBalance / DECIMAL;
  };

  return (
    <>
      <section className={style.check_out}>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
        <Container>
          <Row>
            <Col xl={6} lg={6}>
              <div className={style.check_out_artistdetails}>
                <div className={style.imgart}>
                  <img
                    src={`${process.env.REACT_APP_IMG_BASE_URL}${art?.thumbnail_image}`}
                    alt="artist_nft"
                  />
                </div>
                <ul className={style.check_out_artistdetails_list}>
                  <li>
                    <label>Art Name</label>
                    <span>:</span>
                    <h6>
                      <strong title={art?.art_name}>
                        {sliceChar(art?.art_name, 20)}
                      </strong>
                    </h6>
                  </li>
                  <li>
                    <label>Artist</label>
                    <span>:</span>
                    <h6>
                      <strong title={art?.artist_name}>
                        {sliceChar(art?.artist_name, 20)}
                      </strong>
                    </h6>
                  </li>
                  <li>
                    <label>Series</label>
                    <span>:</span>
                    <h6>
                      <strong title={art?.series_name}>
                        {sliceChar(art?.series_name, 20)}
                      </strong>
                    </h6>
                  </li>
                  <li>
                    <label>Released</label>
                    <span>:</span>
                    <h6> {formatDate(art?.createdAt)}</h6>
                  </li>

                  {latestPrice.market == "secondary" && (
                    <li>
                      <label>Edition No.</label>
                      <span>:</span>
                      <h6> #{`${latestPrice?.editionNumber}`}</h6>
                    </li>
                  )}
                </ul>
              </div>
            </Col>
            <Col xl={6} lg={6}>
              <div className={style.check_out_pricedetails}>
                <form onSubmit={formik.handleSubmit}>
                  <>
                    <ul className={style.check_out_pricedetails_list}>
                      <li>
                        <div className={style.form}>
                          <label>Price per art</label>
                          <span>:</span>{" "}
                          <h6
                            title={`₹${getNumberWithComma(
                              Number(latestPrice?.price).toFixed(2)
                            )}`}
                          >
                            ₹
                            {getNumberWithComma(
                              Number(latestPrice?.price).toFixed(2)
                            )}
                          </h6>
                        </div>
                      </li>
                      <li>
                        {art.drop_type != 1 &&
                          art.drop_type != 2 &&
                          latestPrice?.market === "primary" && (
                            <div className={style.form}>
                              <label>Quantity</label>
                              <span className="spacesme4">:</span>{" "}
                              <FormikControls
                                variant={`checkField `}
                                className="text-center"
                                control="input"
                                type="text"
                                name="number"
                                id="number"
                                placeholder="0"
                                onChange={(e) => {
                                  if (e.target.value != "") {
                                    if (/^-?\d+$/.test(e.target.value)) {
                                      setQuantity(Number(e.target.value));
                                      if (
                                        e.target.value > latestPrice?.buyCount
                                      ) {
                                        if (latestPrice?.buyCount == 0) {
                                          toasts.error(
                                            "Your maximum limit is reached."
                                          );
                                          setTimeout(() => {
                                            setQuantity("");
                                          }, [1000]);
                                        } else {
                                          setTimeout(() => {
                                            setQuantity("");
                                          }, [500]);
                                          toasts.error(
                                            `You can buy only ${
                                              latestPrice.buyCount
                                            } ${
                                              latestPrice.dropType
                                                ? `entries`
                                                : `editions`
                                            }.`
                                          );
                                        }
                                      }
                                    }
                                  } else {
                                    setQuantity("");
                                  }
                                }}
                                value={quantity}
                                leftIcon={<SubtractionIcon />}
                                leftIconClick={subHanlder}
                                rightIcon={<PlusIcon />}
                                rightIconClick={addHanlder}
                              />
                            </div>
                          )}
                      </li>
                      <li>
                        <div className={style.form}>
                          <label>Amount</label>
                          <span>:</span>{" "}
                          <h6
                            title={`₹${getNumberWithComma(
                              Number(latestPrice?.price) * Number(quantity)
                            )}`}
                          >
                            ₹
                            {getNumberWithComma(
                              (
                                Number(latestPrice?.price) * Number(quantity)
                              ).toFixed(2)
                            )}
                          </h6>
                        </div>
                      </li>

                      <li>
                        <div className={`${style.form} ${style.form_gstinfo}`}>
                          <FormikControls
                            className="checkselect"
                            control="checkbox"
                            name="checkGstOption"
                            formik={formik}
                            onChange={(e) => {
                              setCheckedGstOption(e.target.checked);
                              if (e.target.checked === true) {
                                setCheckedAddGstDetail(false);
                              }
                              formik.resetForm();
                            }}
                            value={checkedGstOption}
                            checked={checkedGstOption}
                            label={"Select GST"}
                          />
                          {checkedGstOption && (
                            <Row className="align-self-stretch">
                              <Col xxl={4} sm={6}>
                                <FormikControls
                                  control="select"
                                  options={gstOption}
                                  type="select"
                                  name="selectGST"
                                  placeholder="Select GST"
                                  onChange={(value) => {
                                    formik.setFieldValue("selectGST", value);
                                  }}
                                  value={formik.values.selectGST}
                                  formik={formik}
                                  className={`${style.filtersel} mb-4`}
                                />
                              </Col>
                            </Row>
                          )}
                          <FormikControls
                            className="checkselect"
                            control="checkbox"
                            name="checkAddGst"
                            formik={formik}
                            onChange={(e) => {
                              setCheckedAddGstDetail(e.target.checked);
                              if (e.target.checked === true) {
                                setCheckedGstOption(false);
                              }
                              formik.resetForm();
                            }}
                            value={checkedAddGstDetail}
                            checked={checkedAddGstDetail}
                            label={"Enter GST Information (Optional)"}
                          />
                          {checkedAddGstDetail && (
                            <div className={style.gst_details}>
                              <Row>
                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    options={indianStates}
                                    type="select"
                                    className={style.select}
                                    name="state"
                                    formik={formik}
                                    control="select"
                                    placeholder="Select State"
                                    onChange={(value) => {
                                      formik.setFieldValue("state", value);
                                      formik.setFieldValue("city", "");
                                      statewiseCity(value.value);
                                    }}
                                    value={formik.values.state}
                                  />
                                </Col>
                                <Col xxl={4} lg={6} className=""></Col>
                                <Col
                                  xl={4}
                                  lg={6}
                                  className="d-xxl-block d-sm-none"
                                ></Col>
                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="legalBusinessName"
                                    placeholder="Enter Business Name"
                                    formik={formik}
                                    onChange={formik.handleChange}
                                    value={formik.values.legalBusinessName}
                                    onBlur={formik.handleBlur}
                                  />
                                </Col>
                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="gst"
                                    placeholder="Enter GST Number"
                                    formik={formik}
                                    maxLength={15}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                      if (
                                        /^[a-zA-Z0-9\s]*$/.test(e.target.value)
                                      ) {
                                        formik.setFieldValue(
                                          "gst",
                                          e.target.value.toUpperCase()
                                        );
                                      }
                                    }}
                                    value={formik.values.gst}
                                  />
                                </Col>
                                <Col
                                  md={4}
                                  className="d-xxl-block d-sm-none"
                                ></Col>
                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="addressLine1"
                                    placeholder="Address Line 1"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addressLine1}
                                    onChange={formik.handleChange}
                                  />
                                </Col>
                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="addressLine2"
                                    placeholder="Address Line 2"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.addressLine2}
                                    onChange={formik.handleChange}
                                  />
                                </Col>
                                <Col
                                  xl={4}
                                  lg={6}
                                  className="d-xxl-block d-sm-none"
                                ></Col>

                                <Col xl={4} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="city"
                                    placeholder="City"
                                    // label="Select City"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    disabled={!formik.values.state.value}
                                  />
                                </Col>

                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="pinCode"
                                    placeholder="Pincode"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    maxLength={6}
                                    onChange={(e) => {
                                      //regex for only digit
                                      if (/^\d*$/.test(e.target.value)) {
                                        formik.setFieldValue(
                                          "pinCode",
                                          e.target.value
                                        );
                                      }
                                    }}
                                    value={formik.values.pinCode}
                                  />
                                </Col>
                                <Col
                                  xl={4}
                                  lg={6}
                                  className="d-xxl-block d-sm-none"
                                ></Col>
                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    control="phone"
                                    classinput="phone_border"
                                    type="input"
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    variant="input_number"
                                    formik={formik}
                                    onBlur={formik.handleBlur}
                                    onChange={async (value) => {
                                      await formik.setFieldTouched(
                                        "phoneNumber",
                                        true
                                      );
                                      if (value) {
                                        setPhoneNumberCountryCode(
                                          parsePhoneNumber(value)?.country
                                        );
                                      }
                                      formik.setFieldValue(
                                        "phoneNumber",
                                        value
                                      );
                                    }}
                                    value={formik.values.phoneNumber}
                                  />
                                </Col>
                                <Col xxl={4} sm={6}>
                                  <FormikControls
                                    variant="input_spaces"
                                    className="input_border"
                                    control="input"
                                    type="input"
                                    name="email"
                                    placeholder="Email Address"
                                    formik={formik}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </Col>
                              </Row>
                              <FormikControls
                                className="checkselect"
                                control="checkbox"
                                name="labelcheck"
                                formik={formik}
                                onChange={(e) =>
                                  setCheckAddToProfile(e.target.checked)
                                }
                                label={"Add this to profile"}
                              />
                            </div>
                          )}
                        </div>
                      </li>
                      {/* debouncedApiCall */}
                      {art.drop_type != 1 &&
                      art.drop_type != 2 &&
                      latestPrice?.market === "primary" ? (
                        <li>
                          <div className={style.coupen_info}>
                            <FormikControls
                              variant={style.couponAdd}
                              control="input"
                              type="text"
                              name="code"
                              placeholder="Enter Coupon Code"
                              formik={formik}
                              onBlur={formik.handleBlur}
                              onChange={async (e) => {
                                if (e.target.value == "") {
                                  setIsCouponVerify(false);
                                }
                                if (/^[a-zA-Z0-9\s]*$/.test(e.target.value)) {
                                  formik.setFieldValue("code", e.target.value);
                                }
                              }}
                              value={formik.values.code}
                              maxLength={10}
                            />
                            <CommonBtn
                              title="Available Coupons"
                              role="btn"
                              type="button"
                              className="bg-border-without"
                              onClick={() => setShowCoupon(true)}
                            />
                          </div>
                        </li>
                      ) : (
                        ""
                      )}

                      {art.drop_type != 1 &&
                      art.drop_type != 2 &&
                      isCouponVerify &&
                      formik.values.code !== "" &&
                      latestPrice?.market === "primary" ? (
                        <li>
                          <div className={style.form}>
                            <label>Discount</label>
                            <span>:</span> <h6>₹{getDiscountAmount()}</h6>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}

                      {isCouponVerify ? (
                        <li>
                          <div className={style.form}>
                            <label>Total Amount</label>
                            <span>:</span>{" "}
                            {formik.values.code && isCouponVerify ? (
                              <h6
                                title={`₹${getNumberWithComma(
                                  (finalAmount / DECIMAL).toFixed(2)
                                )}`}
                              >
                                {`₹${getNumberWithComma(
                                  (finalAmount / DECIMAL).toFixed(2)
                                )}`}
                              </h6>
                            ) : (
                              <h6
                                title={`₹${getNumberWithComma(
                                  (
                                    Number(latestPrice?.price) * quantity
                                  ).toFixed(2)
                                )}`}
                              >
                                ₹
                                {getNumberWithComma(
                                  (
                                    Number(latestPrice?.price) * quantity
                                  ).toFixed(2)
                                )}
                              </h6>
                            )}
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className={style.form}>
                            <label>Total Amount</label>
                            <span>:</span>{" "}
                            <h6
                              title={`₹${getNumberWithComma(
                                Number(latestPrice.price) * quantity
                              )}`}
                            >
                              ₹
                              {getNumberWithComma(
                                (Number(latestPrice.price) * quantity).toFixed(
                                  2
                                )
                              )}
                            </h6>
                          </div>
                        </li>
                      )}
                      <li>
                        <FormikControls
                          className="radio_btn"
                          control="radio"
                          name="labelcheck"
                          formik={formik}
                          value={internalPaymentCheck}
                          checked={internalPaymentCheck}
                          onChange={(e) => {
                            setInternalPaymentCheck(e.target.checked);
                            if (e.target.checked === true) {
                              setExternalPaymentCheck(false);
                            }
                          }}
                          label={
                            <>
                              <strong>Internal Wallet</strong>
                              <span>
                                Balance: ₹
                                {getNumberWithComma(
                                  (userWalletBalance / DECIMAL).toFixed(2)
                                )}
                              </span>
                              {isResponse &&
                                checkInsufficientBalanceCondition(
                                  isCouponVerify
                                ) && (
                                  <p
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Insufficient Wallet Balance
                                  </p>
                                )}
                            </>
                          }
                        />

                        <FormikControls
                          className="radio_btn"
                          control="radio"
                          name="labelcheck"
                          formik={formik}
                          value={externalPaymentCheck}
                          checked={externalPaymentCheck}
                          onChange={(e) => {
                            setExternalPaymentCheck(e.target.checked);
                            if (e.target.checked === true) {
                              setInternalPaymentCheck(false);
                            }
                          }}
                          label={
                            <>
                              <strong>Other Payment Method</strong>
                            </>
                          }
                          disabled={
                            formik.values.code !== "" &&
                            isCouponVerify &&
                            (finalAmount / DECIMAL).toFixed(2) == 0
                          }
                        />
                      </li>
                    </ul>
                    <div className={style.paymentmode}>
                      <div className={style.form}>
                        <CommonBtn
                          className={` ${style.paymentBtn}`}
                          role="btn"
                          title="Proceed Payment"
                          type="submit"
                          disabled={
                            isSubmitting ||
                            (loading &&
                              formik.values.code !== "" &&
                              !isCouponVerify) ||
                            (checkInsufficientBalanceCondition(
                              isCouponVerify
                            ) &&
                              !externalPaymentCheck)
                          }
                        />
                      </div>
                    </div>
                  </>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <AvailableCouponModal
        show={showCoupon}
        handleClose={() => setShowCoupon(false)}
        couponList={couponList}
        formik={formik}
        couponVerifyFunction={couponVerifyFunction}
      />
    </>
  );
};

export default CuratedCheckoutSec;
