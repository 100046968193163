import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import style from "./Auhtorization.module.scss";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import { useDispatch, useSelector } from "react-redux";
import {
  getTwoFAUpdate,
  logoutAction,
} from "../../../../../Features/user/userSlice";
import { obfuscateEmail } from "../../../../../Helper/common";
import { useNavigate } from "react-router-dom";

const Auhtorization = ({
  GET2FA,
  GetOTP,
  statusEnableDisable,
  setResendOtp,
  getOtpButton,
  setGetOtpButton,
  setStatusEnableDisable,
  setShowInput,
  showInput,
  is_sms_auth_enabled,
  getOTPSuccess,
  timer,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.users.profileData);

  const initialValue = {
    verificationCode: "",
  };

  const validationSchema = Yup.object({
    verificationCode: Yup.string()
      .required("Email verification code is required")
      .test("lengthCheck", "*Incorrect Code", (value) => {
        if (!value) {
          return false;
        }

        let valueString = value.toString();
        if (valueString.length < 6) {
          return false;
        }
        return true;
      }),
  });

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      enableDisable2faHandler(values);
      formik.resetForm();
    },
  });
  const enableDisable2faHandler = async (values) => {
    try {
      let params = {
        status: is_sms_auth_enabled == 0 ? 1 : 0,
        otp: values.verificationCode,
      };
      let response = await dispatch(getTwoFAUpdate(params));
      if (response.status == 200) {
        setShowInput(false);
        setGetOtpButton(true);
        setResendOtp(false);
        if (is_sms_auth_enabled == 0) {
          navigate("/");
          await dispatch(logoutAction());
        } else {
          GET2FA();
        }
      }
    } catch (err) {}
  };

  return (
    <>
      <section className={style.auhtorization}>
        <form onSubmit={formik.handleSubmit}>
          <div className={style.toggle_auht}>
            <FormikControls
              control="toggle"
              name="factor"
              label="2-Factor Authorization"
              className={style.setlimitcheck}
              statusEnableDisable={statusEnableDisable}
              onChange={() => {
                setShowInput(true);
                if (is_sms_auth_enabled == statusEnableDisable) {
                  GetOTP();
                  if (statusEnableDisable == 0) {
                    setStatusEnableDisable(1);
                  } else {
                    setStatusEnableDisable(0);
                  }
                }
              }}
            />
            <div className={style.text_fields}>
              <h6>Set up two-factor authentication</h6>
              <p>
                You must setup a two-factor authentication to go inline with our
                security guidelines
              </p>
            </div>
          </div>

          {showInput && (
            <>
              <div className={style.text_fields}>
                <h6>
                  A verification code was sent to {profileData?.country_code}
                  {profileData.mobile.substring(0, 2) +
                    "******" +
                    profileData.mobile.substring(8)}{" "}
                  and {obfuscateEmail(profileData?.email)}.
                </h6>
                <p>Input the code below to verify enrollment</p>
                <FormikControls
                  variant="border_input"
                  control="input"
                  label="Verification code"
                  type="text"
                  name="verificationCode"
                  value={formik.values.verificationCode}
                  formik={formik}
                  maxLength={6}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (/^-?\d+$/.test(value) || value == "") {
                      formik.setFieldValue("verificationCode", value);
                    }
                  }}
                  placeholder="Enter Verification code"
                  onBlur={formik.handleBlur}
                />
              </div>
              {/* <div>{resendOtp && <ResendOtp key={key} />}</div>
               */}
              <div
                className={`btn_resentotp otpSendsec ${
                  timer > 0 ? "afterOtp" : "otpbtnresend"
                }`}
              >
                <button
                  type="button"
                  onClick={() => getOTPSuccess()}
                  disabled={timer > 0}
                >
                  {timer > 0 ? "You can resend code after" : "Resend Code"}
                </button>
                {timer > 0 && <span>{timer} Seconds</span>}
              </div>
              <div className={style.btnsave}>
                <CommonBtn
                  role="btn"
                  type="submit"
                  className={`black-btn me-3 ${style.btnsize}`}
                  title={is_sms_auth_enabled == 0 ? "Enable" : "Disable"}
                />
                {/* {getOtpButton && (
                  <CommonBtn
                    type="button"
                    role="btn"
                    onClick={() => GetOTP()}
                    className={`black-btn ${style.btnsize}`}
                    title="Get otp"
                  />
                )} */}
              </div>
            </>
          )}
        </form>
      </section>
    </>
  );
};

export default Auhtorization;
