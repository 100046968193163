import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import personIcon from "../../../../Assets/images/person_icon.jpg";
import { formatDateWithTime } from "../../../../Helper/common";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import ShareBtn from "../../../Common/Ui/ShareBtn/ShareBtn";
import SocialMediaIcon from "../../../Common/Ui/SocialMediaIcon/SocialMediaIcon";
import style from "./Carddetails.module.scss";
import { openPopUp } from "../../../../Features/user/userSlice";
import DOMPurify from "dompurify";

const CarddetailsOtherUser = ({
  classHeading,
  classparagraph,
  followUser,
  userId,
  artOwned,
  isUserLoggedin,
}) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const userDetail = useSelector((state) => state?.users?.profileDataOther);
  const loginUserId = useSelector((state) => state?.users?.profileData?.id);

  const getBio = (text, maxLength) => {
    const truncatedText = text?.slice(0, maxLength);
    const displayText = isExpanded ? text : truncatedText;
    return displayText;
  };

  const sanitizedContent = (content) => {
    return { __html: DOMPurify.sanitize(content) };
  };
  const bioContent = isExpanded ? userDetail?.bio : getBio(userDetail.bio, 337);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const checkBeforFollowingHandler = () => {
    if (isUserLoggedin) {
      followUser(userDetail.is_followed != 1 ? "follow" : "unfollow", {
        user_id: userId,
      });
    } else {
      dispatch(openPopUp("login"));
    }
  };
  return (
    <>
      {Object.keys(userDetail).length > 0 && (
        <section className={style.card_details}>
          <Container>
            <div className={style.card_detailsThumb}>
              <img
                src={
                  userDetail.image_path
                    ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail.image_path}`
                    : personIcon
                }
                alt="img"
              />
            </div>

            <div className={`${style.card_head} ${classHeading}`}>
              <div className={style.texthead}>
                <h2>
                  {userDetail?.first_name +
                    " " +
                    userDetail?.middle_name +
                    " " +
                    userDetail?.last_name}
                </h2>
                <h4>@{userDetail?.username} </h4>
              </div>
              <ul className={style.owner_list}>
                <li>
                  <label>OWNED</label>

                  <p>{artOwned}</p>
                </li>
                <li>
                  <label>Joined On :</label>

                  <p>{formatDateWithTime(userDetail?.joined_on)}</p>
                </li>
              </ul>
            </div>

            <div className={style.card_details_containt}>
              <div className={style.list_info}>
                <ul className={style.view_list}>
                  <li>
                    <lable>Followers</lable>
                    <h6>{userDetail?.follower_count}</h6>
                  </li>
                  <li>
                    <lable>Views</lable>
                    <h6>{userDetail?.view_count}</h6>
                  </li>
                  <li>
                    {/* <button type="button" className={style.btnlikeshare}>
                      <HeartIcon />
                    </button> */}

                    <ShareBtn />
                    {userId != loginUserId && (
                      <CommonBtn
                        role="btn"
                        className={`black-btn-yellow ${style.btneditsize}`}
                        title={
                          userDetail.is_followed != 1 ? "Follow" : "Unfollow"
                        }
                        onClick={() => {
                          checkBeforFollowingHandler();
                        }}
                      />
                    )}
                  </li>
                </ul>
                {userDetail?.bio != "" && (
                  <div className={style.text_infor}>
                    <h6>Bio :</h6>
                    {/* <p className={classparagraph}>{userDetail?.bio}</p> */}
                    <div className={style.textInfo}>
                      <div
                        className={`textSection ${
                          isExpanded ? "showData" : "hideData"
                        }`}
                        dangerouslySetInnerHTML={sanitizedContent(bioContent)}
                      ></div>
                      {userDetail?.bio.length > 337 && (
                        <a className={style.readBtn} onClick={toggleExpand}>
                          {isExpanded ? "Read Less" : "Read More..."}
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {(userDetail?.social_links?.twitter ||
                userDetail?.social_links?.facebook ||
                userDetail?.social_links?.instagram ||
                userDetail?.social_links?.linkedin) && (
                <SocialMediaIcon
                  Asotherprofile
                  className={`${style.socialist} list_posi`}
                  userDetail={userDetail}
                />
              )}
            </div>
          </Container>
        </section>
      )}
    </>
  );
};

export default CarddetailsOtherUser;
