import React from "react";
import "./CommonCard.scss";
import Bell from "../../../../Assets/icons/bell.png";
import { sliceChar } from "../../../../Helper/common";
import CommonTooltip from "../../CommonTooltip/CommonTooltip";
import { InfoIcom } from "../../../../Assets/svgIcons/SVGicon";
const CommonCard = ({
  className,
  date,
  month,
  time,
  imgUrl,
  to,
  title,
  subtitle,
  notified,
  live,
  bottomTitle,
  bottomSubTitle,
  bottomRightTitle,
  bottomRightSubTitle,
  bell,
  onClick,
  starttime,
  waittime,
  bottomclass,
  linkAction,
  linkto,
  quickbuy,
  onClickbuy,
  onButtonClick,
  item,
  isCreatedArt,
  featureUrl,
  is_Disable,
  buttonStatusData,
  tagTitile,
  livedata,
}) => {
  return (
    <>
      <div
        to={to ? to : "javascript:void(0)"}
        className={`CommonCard ${className}`}
        onClick={onClick ? onClick : () => {}}
      >
        <div className="image_section_hover">
          <img className="thumnail_img" src={imgUrl} alt="" />
          <img className="featureimg" src={featureUrl} alt="" />
        </div>
        {date && (
          <div className="CommonCardDate">
            {date && (
              <>
                <p>
                  {month && <span>{month}</span>} <strong>{date}</strong>
                </p>
              </>
            )}
          </div>
        )}
        {time && <h6 class="timetext">{time}</h6>}
        {starttime && (
          <div className="startin">
            <label>{starttime}</label>
            <p>{waittime}</p>
          </div>
        )}

        {quickbuy && (
          <button type="button" onClick={onClickbuy} className="quickbuyBtn">
            {quickbuy}
          </button>
        )}
        {tagTitile && (
          <p className="tagBtn">
            {tagTitile}
            <CommonTooltip icon={<InfoIcom />}>
              {tagTitile === "DYNAMIC"
                ? `Adaptive artwork that changes in response to real-time events`
                : tagTitile}
            </CommonTooltip>
          </p>
        )}
        <div className="CommonCardContentHandle">
          <div className="CommonCardContentHandleTop">
            {title && (
              <div className="CommonCardContentHandleTopLeft">
                <div className="textHandle">
                  <h3 title={title}>{sliceChar(title, 15)}</h3>
                  <p
                    // onClick={(e) => {
                    //   checkNavigation(e);
                    // }}
                    title={subtitle}
                  >
                    {sliceChar(subtitle, 30)}
                  </p>
                </div>
                {item?.art_status === "live" && (
                  <div className="liveBlink">
                    <h5>
                      Live <span></span>
                    </h5>
                  </div>
                )}
              </div>
            )}
            {notified && (
              <div className="CommonCardContentHandleTopRight">
                <h3>{notified}</h3>
              </div>
            )}
            {live && (
              <div className="CommonCardContentHandleTopRight">
                <h3 className="CommonCardContentHandleTopRightLive">
                  {live} <span></span>
                </h3>
              </div>
            )}
          </div>
          <div className={`CommonCardContentHandleBottom ${bottomclass}`}>
            {bottomTitle && (
              <div className="CommonCardContentHandleBottomLeft">
                {isCreatedArt ? (
                  <p>Price : ₹{bottomTitle}</p>
                ) : (
                  <p> {bottomTitle}</p>
                )}

                <p>
                  <strong>{bottomSubTitle}</strong>
                </p>
              </div>
            )}
            {bottomRightTitle && (
              <div className="CommonCardContentHandleBottomRight">
                <p>{bottomRightTitle}</p>
                <p>
                  <strong>{bottomRightSubTitle}</strong>
                </p>
              </div>
            )}
            {bell && (
              <div className="CommonCardContentHandleBottomRight">
                <span className="CommonCardContentHandleBottomRightBell">
                  <img src={Bell} alt="bell icon" />
                </span>
              </div>
            )}
            {linkAction && (
              // item.sold_no_of_editions =0 means the art is not on sell
              <button
                onClick={onButtonClick}
                className="actionBtn"
                disabled={
                  buttonStatusData == 1
                    ? is_Disable
                    : item.is_notified == 1
                    ? true
                    : false
                }
              >
                {linkAction}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CommonCard;
