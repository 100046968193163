import React from "react";
import Form from "react-bootstrap/Form";
import { InfoIcom } from "../../../../Assets/svgIcons/SVGicon";
import CommonTooltip from "../../CommonTooltip/CommonTooltip";
import "./Input/Input.scss";
const Checkbox = (props) => {
  const {
    name,
    label,
    className,
    formik,
    checked,
    onChange,
    id,
    disabled,
    isInfo,
    infoMessage,
  } = props;
  return (
    <Form.Group className={`common_input ${className}`} controlId={name}>
      <Form.Check
        type="checkbox"
        label={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        id={id}
      />
      {isInfo && (
        <CommonTooltip icon={<InfoIcom />}>{infoMessage}</CommonTooltip>
      )}
      {formik?.touched[name] && (
        <p className="error_message">{formik?.errors[name]}</p>
      )}
    </Form.Group>
  );
};
export default Checkbox;
