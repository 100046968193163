import React, { useEffect, useState } from "react";
import style from "./Wallets.module.scss";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Banner from "../../../Assets/images/profile-bg.png";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Carddetails from "../Userprofile/Carddetails/Carddetails";
import Wallet_tab from "./Component/Wallet_tab";
import Ledger_tab from "./Component/Ledger_tab";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletBalance,
  getWalletLedger,
} from "../../../Features/user/userSlice";
import { PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Helmet } from "react-helmet";

const Wallets = () => {
  const dispatch = useDispatch();
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const [walletBalance, setWalletBalance] = useState([]);
  const [selectedTab, setSelectedTab] = useState("wallet");
  const [walletLedgerData, setWalletLedgerData] = useState([]);
  const [walletLedger, setWalletLedger] = useState({});
  const [page, setPage] = useState(1);
  const [paginationConfigs, setpaginationConfigs] = useState({});
  const [totalPages, setTotalPages] = useState(0);
  const userDetail = useSelector((state) => state?.users?.profileData);

  useEffect(() => {
    if (selectedTab == "wallet") {
      getWalletBalanceHandler();
    } else {
      getWalletLedgerHandler();
    }
  }, [selectedTab, page, ArtistId]);

  const getWalletLedgerHandler = async () => {
    let data;
    data = {
      page: page,
      size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
    };
    const response = await dispatch(getWalletLedger(data));
    setWalletLedgerData(response?.data?.data?.list);
    setWalletLedger(response?.data?.data);
    setTotalPages(response.data.data.totalPages);
  };

  const getWalletBalanceHandler = async () => {
    const response = await dispatch(getWalletBalance());
    setWalletBalance(response?.data?.data?.user_fiat_account?.balance);
  };

  return (
    <>
      <section className={style.wallets}>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
        <CommonHero
          backgroundImg={
            userDetail?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
              : CoverImage
          }
        />
        <Carddetails classHeading={style.bodernone} />
        <div className={style.wallets_section}>
          <Container>
            <div className={style.tablist}>
              <Tab.Container
                id="left-tabs-example"
                activeKey={selectedTab}
                onSelect={(e) => {
                  setSelectedTab(e);
                }}
                className="tabvertical"
              >
                <Row>
                  <Col xl={2} lg={3}>
                    <Nav variant="pills" className="nav_tabslist">
                      <Nav.Item>
                        <Nav.Link eventKey="wallet">Wallet</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="ledger">Ledger</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col xl={10} lg={9}>
                    <Tab.Content>
                      <Tab.Pane eventKey="wallet">
                        {selectedTab == "wallet" && (
                          <Wallet_tab
                            walletBalance={walletBalance}
                            getWalletBalanceHandler={getWalletBalanceHandler}
                          />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="ledger">
                        {selectedTab == "ledger" && (
                          <Ledger_tab
                            walletLedgerData={walletLedgerData}
                            walletLedger={walletLedger}
                            paginationConfigs={paginationConfigs}
                            page={page}
                            totalPages={totalPages}
                            setPage={setPage}
                          />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Wallets;
