import React, { useEffect, useState } from "react";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import style from "./AcceptCounterAcceptModal.module.scss";
import {
  getByLabelText,
  getByLabelValue,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import { DECIMAL, OFFER_STATUS } from "../../../../Constant/userConstant";
import { setRedirectionStripSuccess } from "../../../../Features/persist/persistSlice";
import { useNavigate } from "react-router-dom";
import { getWalletBalance } from "../../../../Features/user/userSlice";
import { useDispatch } from "react-redux";
import { Routes_Urls } from "../../../../Constant/Route_url";

const AcceptOfferMadeGlobal = ({
  show,
  onHide,
  makeOfferDetails,
  updateStatus,
}) => {
  const callAcceptOFFerApi = () => {
    let data = {
      status: OFFER_STATUS.accepted,
      id: makeOfferDetails.id,
      makeOfferDetails: makeOfferDetails,
    };

    updateStatus(data); //api calling method
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [walletBalance, setWalletBalance] = useState([]);

  useEffect(() => {
    getUserWalletBalance();
  }, []);

  const getUserWalletBalance = () => {
    dispatch(getWalletBalance()).then((response) => {
      setWalletBalance(response?.data?.data?.user_fiat_account?.balance);
    });
  };

  const enteredValue = makeOfferDetails?.offer?.counter_offer / DECIMAL;
  const isButtonDisabled =
    parseFloat(enteredValue) > (Number(walletBalance) / DECIMAL).toFixed(2);

  const addFundsHandler = () => {
    dispatch(setRedirectionStripSuccess("offer"));
    navigate(Routes_Urls.Wallet);
  };

  return (
    <>
      <CommonModal
        backdropclass="fade_clrbg"
        className={style.offerModal}
        centered
        show={show}
        onHide={onHide}
      >
        <div className="d-flex flex-md-row flex-column-reverse">
          <div className={style.offerModalLeft}>
            <div className={style.offerModalLeftInner}>
              <img
                src={`${process.env.REACT_APP_IMG_BASE_URL}${makeOfferDetails?.art?.thumbnail_image}`}
                alt="artist_nft"
              />
            </div>
          </div>
          <div className={style.offerModalRight}>
            <div className={style.artistDetails}>
              <h4>@{makeOfferDetails?.art?.art_user.username}</h4>
              <h3 title={makeOfferDetails?.art?.art_name}>
                {sliceChar(makeOfferDetails?.art?.art_name, 25)}
              </h3>
            </div>

            <div className={style.editionDetails}>
              <div className={style.enter_text}>
                <label>Offer Price</label>
                <p>(Inclusive of GST)</p>
              </div>
              <span>
                {" "}
                {makeOfferDetails?.offer.total_art_price_inc_tax
                  ? "₹" +
                    getNumberWithComma(
                      (
                        makeOfferDetails?.offer.total_art_price_inc_tax /
                        DECIMAL
                      ).toFixed(2)
                    )
                  : "--"}
              </span>
            </div>
            <div className={style.editionDetails}>
              <div className={style.enter_text}>
                <label>Counter Offer</label>
              </div>
              <span>
                {" "}
                {makeOfferDetails?.offer?.counter_offer
                  ? "₹" +
                    getNumberWithComma(
                      (
                        makeOfferDetails?.offer?.counter_offer / DECIMAL
                      ).toFixed(2)
                    )
                  : "--"}
              </span>
            </div>

            <div className={style.receive_payment}>
              <h4>
                {getByLabelText(
                  makeOfferDetails?.offer?.total_art_price_inc_tax,
                  makeOfferDetails?.offer?.counter_offer
                )}
                <span>
                  ₹
                  {getByLabelValue(
                    makeOfferDetails?.offer?.total_art_price_inc_tax,
                    makeOfferDetails?.offer?.counter_offer
                  )}
                </span>
              </h4>
            </div>
            {/* <p className={style.paragraph_text}>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p> */}
            <div className={style.buy_btn_box}>
              {isButtonDisabled &&
                makeOfferDetails?.offer?.total_art_price_inc_tax / DECIMAL <=
                  makeOfferDetails?.offer?.counter_offer / DECIMAL && (
                  <div className={style.insufficientBl}>
                    <p style={{ color: "red", fontWeight: "bold" }}>
                      Insufficient Balance
                    </p>
                    <button onClick={addFundsHandler}>Add Funds</button>
                  </div>
                )}
              <CommonBtn
                role="btn"
                title="Accept Offer"
                className={`${style.buy_btn} btn-yellow`}
                type="button"
                onClick={() => callAcceptOFFerApi()}
                disabled={
                  isButtonDisabled &&
                  makeOfferDetails?.offer?.total_art_price_inc_tax / DECIMAL <=
                    makeOfferDetails?.offer?.counter_offer / DECIMAL
                }
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AcceptOfferMadeGlobal;
