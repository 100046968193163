import React from "react";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../Common/Ui/CommonModal/CommonModal";
import style from "./AcceptOffermodal.module.scss";
import { getNumberWithComma, sliceChar } from "../../../../Helper/common";
import {
  DECIMAL,
  DIGITAL_ART_TYPE,
  OFFER_STATUS,
} from "../../../../Constant/userConstant";

const AcceptOffermodal = ({
  show,
  onHide,
  makeOfferDetails,
  getUpdateOfferStatus,
  isHighestAccept,
}) => {
  const callAcceptOFFerApi = () => {
    let data = {
      status: OFFER_STATUS.accepted,
      id: makeOfferDetails.item.id,
      makeOfferDetails: makeOfferDetails,
    };

    getUpdateOfferStatus(data); //api calling method
  };

  const getOfferPrice = (makeOfferDetails) => {
    if (!isHighestAccept) {
      if (makeOfferDetails?.item?.total_art_price_inc_tax) {
        return (
          "₹" +
          getNumberWithComma(
            (makeOfferDetails?.item?.total_art_price_inc_tax / DECIMAL).toFixed(
              2
            )
          )
        );
      } else {
        return "--";
      }
    } else {
      if (makeOfferDetails?.art?.highest_offer_price) {
        return (
          "₹" +
          getNumberWithComma(
            (makeOfferDetails?.art?.highest_offer_price / DECIMAL).toFixed(2)
          )
        );
      } else {
        return "--";
      }
    }
  };

 
  
  return (
    <>
      <CommonModal
        backdropclass="fade_clrbg"
        className={style.offerModal}
        centered
        show={show}
        onHide={onHide}
      >
        <div className="d-flex flex-md-row flex-column-reverse">
          <div className={style.offerModalLeft}>
            <div className={style.offerModalLeftInner}>
              <img
                src={`${process.env.REACT_APP_IMG_BASE_URL}${makeOfferDetails?.art?.thumbnail_image}`}
                alt="artist_nft"
              />
            </div>
          </div>
          <div className={style.offerModalRight}>
            <div className={style.artistDetails}>
              <h4>@{makeOfferDetails?.art?.art_user?.username}</h4>
              <h3 title={makeOfferDetails?.art?.art_name}>
                {sliceChar(makeOfferDetails?.art?.art_name, 25)}
              </h3>
              <div className={style.artistDetailsEdition}>
                <p>Edition :</p>
                <span>
                  {makeOfferDetails?.item?.edition_no} of{" "}
                  {makeOfferDetails.art.drop_type ==
                  DIGITAL_ART_TYPE.OpenEdition
                    ? makeOfferDetails?.art?.sold_no_of_editions
                    : makeOfferDetails?.art?.no_of_editions}
                </span>
              </div>
            </div>
            <div className={style.pricelist}>
              <h5>
                {/* //new changes */}
                Purchase Price
                <span>
                  {" "}
                  {makeOfferDetails?.art.art_owners[0]?.purchase_price
                    ? "₹" +
                      getNumberWithComma(
                        makeOfferDetails?.art.art_owners[0]?.purchase_price /
                          DECIMAL
                      )
                    : "--"}
                </span>
              </h5>
              <h5>
                Last Sale Price
                <span>
                  {" "}
                  {makeOfferDetails?.art.last_sell_price
                    ? "₹" +
                      getNumberWithComma(
                        (
                          makeOfferDetails?.art.last_sell_price / DECIMAL
                        ).toFixed(2)
                      )
                    : "--"}
                </span>
              </h5>
              <h5 className={style.highvalue}>
                Highest Offer
                <span>
                  {" "}
                  {makeOfferDetails?.art?.highest_offer_price
                    ? "₹" +
                      getNumberWithComma(
                        (
                          makeOfferDetails?.art?.highest_offer_price / DECIMAL
                        ).toFixed(2)
                      )
                    : "--"}
                </span>
              </h5>
            </div>
            <div className={style.editionDetails}>
              <div className={style.enter_text}>
                <label>Offer Price</label>
                <p>(Inclusive of GST)</p>
              </div>
              <span>{getOfferPrice(makeOfferDetails)}</span>
            </div>
            <div className={style.pricelist}>
              <h6>
                Basic Price
                <span>
                
                  {makeOfferDetails?.item?.art_base_price
                    ? "₹" +
                      getNumberWithComma(
                        (
                          makeOfferDetails?.item?.art_base_price / DECIMAL
                        ).toFixed(2)
                      )
                    : "--"}
                </span>
              </h6>
              <h6>
                Applicable GST
                <span>
                  {" "}
                  {makeOfferDetails?.item?.gst_amount
                    ? "₹" +
                      getNumberWithComma(
                        (makeOfferDetails?.item?.gst_amount / DECIMAL).toFixed(
                          2
                        )
                      )
                    : "--"}
                </span>
              </h6>
              <h6>
                Service Charge
                <span>
                  {" "}
                  {makeOfferDetails?.item?.service_charge_amount
                    ? "₹" +
                      getNumberWithComma(
                        (
                          makeOfferDetails?.item?.service_charge_amount /
                            DECIMAL +
                          makeOfferDetails?.item?.gst_amount_on_service_charge /
                            DECIMAL
                        ).toFixed(2)
                      )
                    : "--"}
                </span>
              </h6>
              <p>(Inclusive of Artist Royalty & Applicable Taxes)</p>
            </div>
            <div className={style.receive_payment}>
              <h4>
                You'll receive{" "}
                <span>
                  {" "}
                  {makeOfferDetails?.item?.offer_price
                    ? "₹" +
                      getNumberWithComma(
                        (makeOfferDetails?.item?.offer_price / DECIMAL).toFixed(
                          2
                        )
                      )
                    : "--"}
                </span>
              </h4>
            </div>
            {/* <p className={style.paragraph_text}>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p> */}
            <div className={style.buy_btn_box}>
              <CommonBtn
                role="btn"
                title="Accept Offer"
                className={`${style.buy_btn} btn-yellow`}
                type="button"
                onClick={() => callAcceptOFFerApi()}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AcceptOffermodal;
