import React from 'react'
import { useNavigate } from 'react-router-dom';
import CommonBtn from '../Ui/CommonBtn/CommonBtn'
import './ComingSoon.scss';
import { Helmet } from 'react-helmet';

const ComingSoon = () => {
  const navigate = useNavigate();
  return (

    <div className='not_found'>
       <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        
        
      </Helmet>
      <div className="not_found-inner">
        
        <p>Coming Soon</p>
        <CommonBtn onClick={() => navigate(-1)} className='btn-yellow-white ' role='btn' title="Go Back" />
      </div>
    </div>
  )
}

export default ComingSoon