import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import style from "./GlobalMadeOffers.module.scss";
import CommonTable from "../../../../Common/Ui/CommonTable/CommonTable";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import Commondropdown from "../../../../Common/Ui/CommonDropdown/Commondropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  formatDateWithTime,
  getNumberWithComma,
  sliceChar,
  timeAgo,
} from "../../../../../Helper/common";
import { DECIMAL, OFFER_STATUS } from "../../../../../Constant/userConstant";
import { closeGlobalOffer } from "../../../../../Features/offerSlice/offerSlice";
import Notfound from "../../../../Common/NotFound/Notfound";
import { Routes_Urls } from "../../../../../Constant/Route_url";

const GlobalMadeOffers = (props) => {
  const {
    data,

    updateStatusOpenModal,
    onCloseOfferSuccess,
  } = props;
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state?.users?.profileData);

  const navigate = useNavigate();

  const goToUserProfile = (username) => {
    navigate(`/profile/${username}`);
  };

  const closeMyAllGlobalOffer = async (art, art_index) => {
    let data = {
      art_id: art.id,
    };
    try {
      let response = await dispatch(closeGlobalOffer(data));
      if (response) {
        onCloseOfferSuccess(art_index);
      }
    } catch (err) {}
  };
  const navigateToArt = (id) => {
    navigate(`/marketplace/art/${id}`);
  };
  return (
    <>
      <div className={style.main_containt}>
        {data.map((art, index) => (
          <Row key={index} className={style.offier_recive_row}>
            <Col xl={3} lg={4}>
              <div className={style.artimgselect}>
                <img
                  className="cursor-pointer"
                  src={`${process.env.REACT_APP_IMG_BASE_URL}${art?.thumbnail_image}`}
                  alt="artist_nft"
                  onClick={() => navigateToArt(`${art?.id}`)}
                />
                <div className={style.text_fields}>
                  <h5 title={art.art_name}>{sliceChar(art?.art_name, 15)}</h5>

                  <p
                    className="cursor-pointer"
                    onClick={() => goToUserProfile(art?.art_user?.username)}
                  >
                    @{sliceChar(art?.art_user?.username, 15)}
                  </p>
                  <ul className={style.listart}>
                    <li>
                      <label>Views</label>
                      <h6>{art?.view_count}</h6>
                    </li>
                    <li>
                      <label>Likes</label>
                      <h6>{art?.like_count}</h6>
                    </li>
                  </ul>
                  <ul className={style.price_list}>
                    <li>
                      <label>Last Sale Price</label>
                      <h6>
                        {" "}
                        ₹
                        {getNumberWithComma(
                          (Number(art?.last_sell_price) / DECIMAL).toFixed(2)
                        )}
                      </h6>
                    </li>
                    <li>
                      <label>My Offer Price</label>
                      <h6>
                        {art?.art_offer[0].total_art_price_inc_tax
                          ? "₹" +
                            getNumberWithComma(
                              art?.art_offer[0].total_art_price_inc_tax /
                                DECIMAL
                            )
                          : "--"}
                      </h6>
                    </li>
                  </ul>
                  <CommonBtn
                    role="btn"
                    className={`black-btn-yellow ${style.btneditsize}`}
                    title="Close Offer"
                    onClick={() => closeMyAllGlobalOffer(art, index)}
                  />
                </div>
              </div>
            </Col>

            <Col xl={9} lg={8}>
              <div className={style.table_sectionoffier}>
                <CommonTable className={style.offer_receive_table}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Edition No.</th>

                      <th>Counter Offer by</th>
                      <th>Counter Offer Price</th>
                      <th>My Offer</th>

                      <th>Status</th>

                      <th>Offer Ends in</th>

                      <th>
                        <Commondropdown classdrop="" dropdowntitle="Sort By">
                          <Dropdown.Item href="#">Newest</Dropdown.Item>
                          <Dropdown.Item href="#">Oldest</Dropdown.Item>
                        </Commondropdown>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(art?.art_offer).map((item, index) => (
                      <tr>
                        <td>{timeAgo(item.createdAt)}</td>
                        <td>#{item?.edition_no}</td>

                        <td
                          className="cursor-pointer"
                          onClick={() =>
                            goToUserProfile(
                              item?.counter_user?.counter_username
                            )
                          }
                        >
                          @{item?.counter_user?.counter_username}{" "}
                        </td>
                        <td>
                          <div className="offer_price">
                            <h6>
                              {item?.counter_offer
                                ? "₹" +
                                  getNumberWithComma(
                                    item?.counter_offer / DECIMAL
                                  )
                                : "--"}
                            </h6>
                          </div>
                        </td>
                        <td>
                          <div className="offer_price">
                            <h6>
                              {item?.total_art_price_inc_tax
                                ? "₹" +
                                  getNumberWithComma(
                                    item?.total_art_price_inc_tax / DECIMAL
                                  )
                                : "--"}
                            </h6>
                          </div>
                        </td>

                        <td className="status_tab">{item?.status}</td>

                        <td>{formatDateWithTime(item?.duration)}</td>
                        <td>
                          <div className="btn_section">
                            {item.counter == 1 &&
                              item.status == OFFER_STATUS.coutered &&
                              item.counter_user_id !== profileData.id && (
                                <CommonBtn
                                  role="btn"
                                  className={`black-btn-yellow me-4 btneditsize`}
                                  title="Accept"
                                  onClick={() =>
                                    updateStatusOpenModal(
                                      OFFER_STATUS.accepted,
                                      item,
                                      art
                                    )
                                  }
                                />
                              )}
                            <CommonBtn
                              role="btn"
                              className={`bordred-gray me-4 btneditsize`}
                              title="Decline"
                              onClick={() =>
                                updateStatusOpenModal(
                                  OFFER_STATUS.declined,
                                  item,
                                  art
                                )
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </CommonTable>
              </div>
            </Col>
          </Row>
        ))}
        {data.length == 0 && <Notfound text={"No record found."} />}
      </div>
    </>
  );
};

export default GlobalMadeOffers;
