import { createSlice } from "@reduxjs/toolkit";
import { initialStateHistory } from "../InitialState";
// import { historyService } from "../../Services/historyService";
// import { startLoading, stopLoading } from "../loading/loadingSlice";

const initialState = {
  users: initialStateHistory,
};

export const historySlice = createSlice({
  name: "histroy",
  initialState,
  reducers: {},
});

export const { users } = historySlice.actions;
export default historySlice.reducer;
