import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Col, Row } from "react-bootstrap";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import { Link, useNavigate } from "react-router-dom";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import styles from "./login.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../../Features/user/userSlice";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import { QR_LOGIN_TEXT } from "../../../Constant/userConstant";
import { Routes_Urls } from "../../../Constant/Route_url";

const Login = (props) => {
  //Other
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();
  const buttonStatus = useSelector((state) => state?.loading?.disable);
  const saveDataSignup = useSelector((state) => state?.users?.signUpData);
  const nextRoute = useSelector((state) => state?.users?.nextRoute);
  const [loginUsingQrText, setLoginUsingQrText] = useState("");

  //state

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let qrLogin = queryParams.get("qr_login");
    setLoginUsingQrText(qrLogin);
  }, []);

  //Formik
  const LoginSchema = Yup.object({
    username: Yup.string().required(
      inputFieldValidation.commonMessage.required("Email/Username")
    ),
    password: Yup.string().required(
      inputFieldValidation.commonMessage.required("Password")
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: LoginSchema,
    initialValues: {
      username: saveDataSignup?.email,
      password: saveDataSignup?.password,
      deviceType: 0,
    },
    onSubmit: (values) => {
      values.username = values.username.trim();
      let obj = {
        ipAddress: props?.ip,
        ...values,
      };
      dispatch(loginAction(obj)).then((response) => {
        if (response?.status === 200) {
          props.setEmail(values.username);
          if (
            response.data.data.is_email_auth_enabled === 0 &&
            response.data.data.is_sms_auth_enabled === 0
          ) {
            if (loginUsingQrText == QR_LOGIN_TEXT) {
              navigate(Routes_Urls.Link_devices);
            } else {
              if (nextRoute != "") {
                navigate(nextRoute);
              }
            }
          }
        }
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12}>
            <FormikControls
              className="fieldBlack"
              control="input"
              type="username"
              name="username"
              id="username"
              placeholder="Email/User Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              formik={formik}
            />
            {/* {formik.touched.username && (
              <p className="error_message">{formik.errors.username}</p>
            )} */}
          </Col>
          <Col xs={12}>
            <FormikControls
              className="fieldBlack"
              rightIconClass="iconWhite"
              control="password"
              name="password"
              placeholder="Password"
              variant="dark"
              id="something"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              formik={formik}
            />
            {/* {formik.touched.password && (
              <p className="error_message">{formik.errors.password}</p>
            )} */}
          </Col>
          <Col xs={12}>
            <Link
              className={styles.LoginFormAsStrong}
              to="#"
              onClick={props.forgetClick}
            >
              Forgot Password ?
            </Link>
          </Col>
          <Col xs={12} className="mt-5">
            <CommonBtn
              className={`btn-yellow ${styles.LoginFormbtnHanlde}`}
              role="btn"
              title="Log In"
              disabled={buttonStatus}
              type="submit"
            />
          </Col>
          <Col xs={12} className="mt-5">
            <p className={styles.LoginFormSignUp}>
              Don't have an account?{" "}
              <Link onClick={props.onClick} to= {Routes_Urls.Signup}>
                Sign Up.
              </Link>
            </p>
          </Col>
        </Row>
      </form>
    </>
  );
};
export default Login;
