import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./Input.scss";

const Input = (props) => {
  const {
    label,
    name,
    variant,
    leftIcon,
    labelIconRight,
    labelIcon,
    rightIcon,
    leftIconClick,
    rightIconClass,
    labelIconClick,
    rightIconClick,
    className,
    placeholder,
    timertitle,
    timerClass,
    id,
    type,
    onChange,
    value,
    onBlur,
    formik,
    maxLength,
    autoFocus,
    disabled,
    validationtitle,
     btntype,
    btnInner,
   
  } = props;

  const [seconds, setSeconds] = useState();

  return (
    <>
      <Form.Group className={`common_input ${variant}`} controlId={name}>
        {label && (
          <Form.Label>
            {label}
            {labelIcon && (
              <span
                onClick={labelIconClick}
                className={`labelIcon ${
                  labelIconClick ? "cursor-pointer" : ""
                } ${labelIconRight ? "ms-auto" : ""}`}
              >
                {labelIcon}
              </span>
            )}
          </Form.Label>
        )}
        <div
          className={`common_input_inner ${leftIcon ? "leftIconInput" : ""} ${
            rightIcon ? "rightIconInput" : ""
          }`}
        >
          {leftIcon && (
            <span
              className={`${leftIconClick ? "cursor-pointer" : ""} leftIcon`}
              onClick={leftIconClick}
            >
              {leftIcon}
            </span>
          )}
          <input type="text" name={name} style={{ display: "none" }} />
          <input
            className={`form-control ${className}`}
            id={id}
            name={name}
            type={type || "text"}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            onBlur={onBlur}
            maxLength={maxLength}
            autoFocus={autoFocus}
            disabled={disabled}
            autocomplete="off"
          />
          {rightIcon && (
            <span
              className={`rightIcon ${
                rightIconClick ? "cursor-pointer" : ""
              } ${rightIconClass}`}
              onClick={rightIconClick}
            >
              {rightIcon}
            </span>
          )}
          {timertitle ? (
            <h6 className={`timerstart  ${timerClass}`}>
              {seconds < 10 ? "0" + seconds : seconds}
            </h6>
          ) : (
            ""
          )}

          {btnInner ? (
            <button type={btntype} className="btnStyle">
              {btnInner}
            </button>
          ) : (
            ""
          )}
        </div>
        {formik?.touched[name] && (
          <p className="error_message">{formik?.errors[name]}</p>
        )}
        {validationtitle ? (
          <h6 className="text_valida">{validationtitle}</h6>
        ) : (
          ""
        )}
      </Form.Group>
    </>
  );
};

export default Input;
