import React, { useEffect, useState } from "react";
import style from "../Dropaction/Dropaction.module.scss";
import { PlusIcon, SubtractionIcon } from "../../../Assets/svgIcons/SVGicon";
import { Tab, Tabs } from "react-bootstrap";
import CommonTable from "../../Common/Ui/CommonTable/CommonTable";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import {
  formatDateWithTime,
  getDateFromTimestamp,
  getNumberWithComma,
} from "../../../Helper/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import personIcon from "../../../Assets/images/person_icon.jpg";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import { toasts } from "../../Common/Toast/Toast";
import { DECIMAL, SALE_TYPE } from "../../../Constant/userConstant";
import Notfound from "../../Common/NotFound/Notfound";
import HowitWork from "../../Common/HowitWork/HowitWork";
import { Routes_Urls } from "../../../Constant/Route_url";
import DOMPurify from "dompurify";

const FCFBLive = ({
  artDetail,
  liveActivityDetail,
  setActivityPage,
  page,
  totalActivityPage,
  userId,
  type,
  id,
  setShowClosedDrop,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const [quantity, setQuantity] = useState(1);
  const [leftEditions, setLeftEditions] = useState(0);

  useEffect(() => {
    setLeftEditions(
      Number(artDetail?.no_of_editions) - Number(artDetail?.sold_no_of_editions)
    );
  }, [artDetail?.no_of_editions, artDetail?.sold_no_of_editions]);

  const initialValue = {
    quantity: 1,
  };
  const validationSchema = Yup.object({
    quantity: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const subHanlder = () => {
    if (quantity > 1) {
      setQuantity((prev) => Number(prev) - 1);
    }
  };

  const addHanlder = () => {
    if (artDetail.buy_limit - artDetail.buy_counter > quantity) {
      if (quantity < leftEditions) {
        setQuantity((prev) => Number(prev) + 1);
      }
    } else {
      toasts.error("Your maximum limit is reached.");
    }
  };

  const onClickHandler = () => {
    let userBuyLimit = artDetail.buy_limit - artDetail.buy_counter;
    if (isUserLoggedin) {
      if (artDetail.is_eligible_to_buy) {
        if (quantity > userBuyLimit && quantity) {
          toasts.error("Your maximum limit is reached.");
        } else {
          try {
            let data = {
              price: artDetail.price / DECIMAL,
              quantity: quantity,
              buyCount: artDetail.buy_limit - artDetail.buy_counter,
              market: "primary",
            };
            dispatch(saveLatestPrice(data));
            navigate(`/checkout/art/${id}`);
          } catch (error) {}
        }
      } else {
        setShowClosedDrop(true);
      }
    } else {
      dispatch(openPopUp("login"));
      dispatch(saveNextRoute(`/curated-drop/digital-art/${type}/${id}`));
    }
  };

  const bidActivity = () => {
    if (liveActivityDetail.length) {
      return liveActivityDetail.map((data, index) => (
        <tr key={data.id}>
          <td>
            {/* {(page - 1) * PER_PAGE_RECORD_LIMIT.ART_DETAIL_PRIMARY_PER_PAGE + (index + 1)} */}
            {data.editions_no == 0 ? "Updating..." : `#${data.editions_no}`}
          </td>
          <td>
            <div className="d-flex align-items-center thumb-outer">
              {data?.sale_type == SALE_TYPE.OFFLINE &&
              data?.is_reedemed == 1 ? (
                <>
                  <img className="thumb" src={personIcon} alt="userIcon" />
                  <h4>Offline Sale</h4>
                </>
              ) : (
                <>
                  <img
                    className="thumb"
                    src={
                      data?.auction_user[0]?.image_path
                        ? `${process.env.REACT_APP_IMG_BASE_URL}${data?.auction_user[0]?.image_path}`
                        : personIcon
                    }
                    alt="thumb"
                  />
                  <h4
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/profile/${data?.auction_user[0]?.username}`);
                    }}
                  >
                    @{data?.auction_user[0]?.username}
                  </h4>
                </>
              )}
            </div>
          </td>
          <td>{formatDateWithTime(data.createdAt)}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={3}>
            <Notfound text={"No activity found."} />
          </td>
        </tr>
      );
    }
  };

  const showToastAndSetValue = (value) => {
    toasts.error(`You can buy only ${value} editions.`);
    setTimeout(() => {
      setQuantity(value);
    }, [1000]);
  };
  return (
    <>
      <div className={style.dropaction_ended_rightsec_pricefloor}>
        <div className={style.headprice}>
          <ul>
            <li>
              <label>Price :</label>
              <h6>₹{getNumberWithComma(artDetail.price / DECIMAL)} each</h6>
            </li>
          </ul>
        </div>
        <div className={style.startinfo}>
          <div className={style.buyadd}>
            <div className={style.valuelist}>
              {leftEditions != 0 ? (
                <>
                  <label>Left</label>
                  <span>:</span>
                  <h3 className={style.left_art}>
                    {leftEditions} {""}
                    out of {artDetail?.no_of_editions}
                  </h3>
                </>
              ) : (
                <h3>Sold out</h3>
              )}
            </div>
            {leftEditions != 0 && (
              <>
                {
                  /* userId !== artDetail.artist_id && */ <div
                    className={style.bitlimitset}
                  >
                    {isUserLoggedin && (
                      <>
                        <FormikControls
                          variant={`checkField`}
                          className="text-center"
                          control="input"
                          type="text"
                          name="quantity"
                          id="number"
                          placeholder="Enter Quantity"
                          onChange={(e) => {
                            let inputQuantity = e.target.value;
                            // Calculate the buyer limit based on the difference between the buy limit and buy counter
                            let buyerLimit =
                              artDetail.buy_limit - artDetail.buy_counter;

                            if (inputQuantity != "") {
                              // Validate if the entered inputQuantity is a valid number
                              if (/^\d+(\.\d*)?$/.test(inputQuantity)) {
                                setQuantity(inputQuantity);
                                // Check if the entered amount is within the limits
                                if (
                                  inputQuantity <= leftEditions &&
                                  inputQuantity <= buyerLimit
                                ) {
                                  setQuantity(Number(inputQuantity));
                                } else {
                                  //show errors and enter particular limit to buy in input field
                                  if (buyerLimit === 0) {
                                    toasts.error(
                                      "Your maximum limit is reached."
                                    );
                                    setTimeout(() => {
                                      setQuantity(1);
                                    }, [1000]);
                                  } else {
                                    if (inputQuantity > leftEditions) {
                                      // If the entered input quantity exceeds the buyer limit but the buyer limit is less than the remaining editions
                                      if (
                                        inputQuantity > buyerLimit &&
                                        buyerLimit < leftEditions
                                      ) {
                                        showToastAndSetValue(buyerLimit);
                                      } // If the entered input quantity exceeds the remaining editions
                                      else if (inputQuantity > leftEditions) {
                                        showToastAndSetValue(leftEditions);
                                      } // If the remaining editions are less than the buyer limit
                                      else if (leftEditions < buyerLimit) {
                                        showToastAndSetValue(leftEditions);
                                      } // If the buyer limit is less than the remaining editions
                                      else if (buyerLimit < leftEditions) {
                                        showToastAndSetValue(buyerLimit);
                                      }
                                    } else {
                                      // If the entered amount is not greater than the remaining editions
                                      showToastAndSetValue(buyerLimit);
                                    }
                                  }
                                }
                              }
                            } else {
                              setQuantity("");
                            }
                          }}
                          value={quantity}
                          leftIcon={<SubtractionIcon />}
                          leftIconClick={subHanlder}
                          rightIcon={<PlusIcon />}
                          rightIconClick={addHanlder}
                        />
                        <h4 className={style.vaule_totleamount}>
                          ₹
                          {getNumberWithComma(
                            Number(artDetail?.price / DECIMAL) * quantity
                          )}
                        </h4>
                      </>
                    )}
                    <CommonBtn
                      title="Buy now"
                      role="btn"
                      className=""
                      disabled={quantity == 0 || quantity == ""}
                      onClick={onClickHandler}
                    />
                  </div>
                }
              </>
            )}
          </div>
        </div>
      </div>
      <div className={style.dropaction_ended_rightsec_tabpartlist}>
        <Tabs
          defaultActiveKey="aboutart"
          id=""
          className={`tablists ${style.art_tabcont}`}
        >
          <Tab eventKey="aboutart" title="About Art">
            <div className={style.artstab_containt}>
            <div className='textSection'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(artDetail?.bio),
                }}
              ></div>

              <ul className={style.about_artlist}>
                <li>
                  <label>
                    {artDetail?.no_of_editions == 1 ? "Edition" : "Editions"}
                  </label>
                  <span>:</span>
                  <h6>{artDetail?.no_of_editions}</h6>
                </li>
                <li>
                  <label>Type</label>
                  <span>:</span>
                  <h6>{artDetail?.art_type}</h6>
                </li>
                <li>
                  <label>Released</label>
                  <span>:</span>
                  <h6>{getDateFromTimestamp(artDetail.start_time)}</h6>
                </li>
              </ul>
            </div>
          </Tab>
          {/* <Tab eventKey="activity" title="Activity">
            <div className={style.bid_activity}>
              <div className={style.bid_table}>
                <CommonTable className={style.bidder_table}>
                  <thead>
                    <tr>
                      <th>
                        <span>Edition</span>
                      </th>
                      <th>
                        <span>Collector</span>
                      </th>
                      <th>
                        <span>Time</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{bidActivity()}</tbody>
                </CommonTable>
                {totalActivityPage > 1 && (
                  <CustomPagination
                    className="ms-auto mt-4"
                    activePage={page}
                    totalPage={totalActivityPage}
                    setActivePage={setActivityPage}
                    maxVisiblePages={5}
                  />
                )}
              </div>
            </div>
          </Tab> */}
          <Tab eventKey="howitworks" title="How it Works">
            <div className={`py-0 ${style.artstab_containt}`}>
              {/* <p>
                There’s a stage we reach in life where we start to realize our
                innocence is “gone.” We may have began to realize that we lost
                that childlike innocence ages ago. However, times of innocence
                seem to flutter by in adulthood.
              </p> */}
              <HowitWork artDetail={artDetail} />
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};

export default FCFBLive;
