import { createSlice } from "@reduxjs/toolkit";
import { initialStateLoadingSlice } from "../InitialState";

const initialState = initialStateLoadingSlice;

export const LoadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.loading = true;
    },
    startProfileLoading: (state) => {
      state.profileLoading = true;
    },
    startMarketplaceLoading: (state) => {
      state.marketplaceLoading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
    stopProfileLoading: (state) => {
      state.profileLoading = false;
    },
    stopMarketplaceLoading: (state) => {
      state.marketplaceLoading = false;
    },
    disableButton: (state) => {
      state.disable = false;
    },
    enableButton: (state) => {
      state.disable = true;
    },
  },
});

export const {
  startLoading,
  stopLoading,
  startProfileLoading,
  stopProfileLoading,
  startMarketplaceLoading,
  stopMarketplaceLoading,
  disableButton,
  enableButton,
} = LoadingSlice.actions;
export default LoadingSlice.reducer;
