import React from "react";
import { Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import CommonBtn from "../Common/Ui/CommonBtn/CommonBtn";
import FormikControls from "../Common/Ui/Formik/FormikControls";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ForgetPasswordAction, openPopUp } from "../../Features/user/userSlice";
import { inputFieldValidation } from "../../Constant/formikConstant";
import { Routes_Urls } from "../../Constant/Route_url";
import { Helmet } from "react-helmet";

function Forget(props) {
  const { callbackDataPass, ip, setEmail } = props;

  const dispatch = useDispatch();
  const ForgetSchema = Yup.object({
    email: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ForgetSchema,
    initialValues: {
      deviceType: 0,
      email: "",
    },

    onSubmit: (values) => {
      setEmail(values.email);
      callbackDataPass(values);
      let obj = {
        ipAddress: ip,
        ...values,
      };
      dispatch(ForgetPasswordAction(obj)).then((response) => {
        dispatch(openPopUp(Routes_Urls.Reset_password));
      });
    },
  });

  return (
    <>
     <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>

      <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={12}>
          <FormikControls
            className="fieldBlack"
            control="input"
            type="email"
            name="email"
            id="email"
            placeholder="Enter your Email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            formik={formik}
          />
        </Col>
        <Col xs={12}>
          <CommonBtn
            className="btn-yellow hovBtn"
            role="btn"
            title="Forgot Password"
            type="submit"
          />
        </Col>
      </Row>
    </form>

    </>
   
  );
}

export default Forget;
