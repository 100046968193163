import React, { useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  className,
  label,
  name,
  placeholder,
  formik,
  setLength,
  readOnly,
  validationtitle,
  maxLength,
}) => {
  const quillRef = useRef(null);

  useEffect(() => {
    if (quillRef.current) {
      const quillEditor = quillRef.current.getEditor();
      const lengthOfText = quillEditor.getLength() - 1; // Get the initial length of the content

      if (setLength) {
        setLength(lengthOfText);
      }
    }
  }, [formik.values[name]]);
  const toolbarOptions = [["bold", "italic", "underline"]];

  const modules = {
    toolbar: toolbarOptions,
  };

  const handleChange = (value, delta, source, editor) => {
    let lengthOfText = editor.getLength() - 1;
    if (lengthOfText <= maxLength) {
      formik.setFieldValue(name, value);
    } else {
      // Manually revert the change to prevent exceeding max length
      const quillEditor = quillRef.current.getEditor();
      const newDelta = quillEditor.getContents().slice(0, maxLength);
      quillEditor.setContents(newDelta);
      formik.setFieldValue(name, quillEditor.root.innerHTML);
    }
  };

  return (
    <>
      <div className={className}>
        <label htmlFor={label}>{label}</label>
        <ReactQuill
          ref={quillRef}
          theme={"snow"}
          value={formik.values?.[name]}
          onChange={handleChange}
          modules={modules}
          onBlur={() => formik.setFieldTouched(name, true)}
          placeholder={placeholder}
          id={name}
          readOnly={readOnly ? readOnly : false}
        />
      </div>

      {formik?.touched?.[name] && formik?.errors?.[name] && (
        <p
          style={{
            color: "#f00",
            marginTop: "0.3rem",
            fontSize: "1.4rem",
          }}
        >
          {formik?.errors?.[name]}
        </p>
      )}

      {validationtitle ? (
        <h6 className="text_valida">{validationtitle}</h6>
      ) : (
        ""
      )}
    </>
  );
};
export default TextEditor;
