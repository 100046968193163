export const inputFieldValidation = {
  firstName: {
    minLength: 2,
    maxLength: 30,
    Messages: {
      invalid: "*Invalid first name",
    },
    regex: {
      expresion: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
      // expresions: /^[A-Za-z. ]+$/,
      errorMessage: "*Only letters are allowed",
      spaceExpression: /^\S*$/,
      spaceErrorMessage: "First name should not contain spaces.",
    },
  },
  middleName: {
    minLength: 2,
    maxLength: 30,
    Messages: {
      invalid: "*Invalid middle name",
    },
    regex: {
      expresion: /^[A-Za-z][A-Za-z\s]*$/,
      errorMessage: "*Only letters are allowed",
      spaceExpression: /^\S*$/,
      spaceErrorMessage: "Middle name should not contain spaces.",
    },
  },
  lastName: {
    minLength: 2,
    maxLength: 30,
    messages: {
      invalid: "*Invalid last name",
    },
    regex: {
      expresion: /^[A-Za-z][A-Za-z\s]*$/,
      errorMessage: "*Only letters are allowed",
      spaceExpression: /^\S*$/,
      spaceErrorMessage: "Last name should not contain spaces.",
    },
  },
  commonExpression: {
    spaceStartAndEndNotAllowed: /^(?!\s)(.*\S)?$/,
  },
  username: {
    minLength: 5,
    maxLength: 32,
    messages: {
      invalid: "*Invalid last name",
    },
    regex: {
      // expresion:/^[0-9a-zA-Z]+([.]?[0-9a-zA-Z]+)*$/,
      expresion: /^[0-9a-zA-Z]+([.0-9a-zA-Z])*$/,
      errorMessage:
        "*You must use letters (a-z), number (0-9) and username should not be start with period (.)",
      spaceExpression: /^\S*$/,
      spaceErrorMessage: "*Username should not contain spaces.",
    },
  },
  email: {
    length: "",
    messages: {
      invalid: "*Invalid email",
    },
    regex: {
      expresion: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      errorMessage: "*Invalid Email",
    },
    regexForSingup: {
      // expresionForSingup: /^[A-Z0-9._%+-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i,
      // expresionForSingup: /^[A-Z0-9._%-]+@[A-Z0-9-]+\.[A-Z]{2,4}$/i,
      expresionForSingup:   /^(?!.*\.\.)[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      errorMessageForSingup: "*Invalid Email",
    },
  },  

  password: {
    messages: {
      invalid: "*Invalid password",
    },
    regex: {
      // expresion:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.~^+=,-])[A-Za-z\d@$!%*#?&.~^+=,-]{6,}$/,
      expresion:
        // /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#$%&'()*+,\/\-.:;<=>?@[\\\]^_`{|}~])([A-Za-z\d!"#$%&'()*+,\/\-.:;<=>?@[\\\]^_`{|}~]+)$/,
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#$%&'()*+,\/\-.:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\/\-.:;<=>?@[\\\]^_`{|}~]{6,}$/,
      errorMessage:
        "*Must contain at least 6 characters, 1 uppercase letter, 1 number and 1 special character.",
    },
  },
  commonMessage: {
    required: (type) => {
      return `*${type} is required`;
    },
    number: "*Must be a number",
    positive: "*Must be a positive number",
    integer: "*A phone number can't include a decimal point",
    phoneMin: "*Phone Number must be at least 10 characters",
    phoneMax: "*Phone Number must not greater than 20 characters",
  },
  bio: {
    minLength: 5,
    maxLength: 150,
    userMaxLength: 250,
    artistMaxLength: 1500,
    messages: {
      invalid: "*Invalid",
    },
  },

  gst: {
    minLength: 15,
    maxLength: 15,
    regex: {
      expresion: /^[A-Za-z][A-Za-z\s]*$/,
      errorMessage: "*Only letters are allowed",
    },
  },
  linkeddevice: {
    minLength: 6,
    maxLength: 6,
    regex: {
      expresion: /^[A-Za-z][A-Za-z\s]*$/,
      errorMessage: "*Only letters are allowed",
    },
  },
  coupon_code: {
    minLength: 10,
    maxLength: 10,
    // regex: {
    //   expresion: /^[A-Za-z][A-Za-z\s]*$/,
    //   errorMessage: "*Only letters are allowed",
    // },
  },
  address: {
    minLength: 5,
    maxLength: 100,
    regex: {},
  },
  businessName: {
    minLength: 5,
    maxLength: 50,
    regex: {},
  },
  nameDevice: {
    minLength: 3,
    maxLength: 15,
    regex: {},
  },
};
