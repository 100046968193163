import React, { useState } from "react";
import style from "./Artowned.module.scss";
import artist_nft_4 from "../../../../Assets/images/artist_nft_4.jpg";
import SellModal from "../Modals/SellModal";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CommonCardOther from "../../../Common/Ui/CommonCardsecond/CommonCardOther";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import Notfound from "../../../Common/NotFound/Notfound";
import MakeOffermodal from "./components/MakeOffermodal/MakeOffermodal";
import {
  openPopUp,
  saveLatestPrice,
} from "../../../../Features/user/userSlice";
import { DECIMAL } from "../../../../Constant/userConstant";
import { useNavigate } from "react-router-dom";

const Artownedsec = (props) => {
  let {
    data,
    setSearch,
    page,
    totalPage,
    getUserLikesArtOwn,
    isUserLoggedin,
    username,
    id
  } = props;
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showing, setShowing] = useState(false);
  const [makeOfferDetails, setMakeOfferDetails] = useState({});

  const saveMakeOfferDetail = (item) => {
    setShowing(true);
    setMakeOfferDetails(item);
  };

  const checkBeforMakingOfferHandler = (item) => {
    if (isUserLoggedin) {
      saveMakeOfferDetail(item);
    } else {
      dispatch(openPopUp("login"));
    }
  };

  return (
    <>
      <section className={style.art_owned}>
        <div className={style.searchbar}>
          <>
            <div className="search_input">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Arts, Artist"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </>
        </div>
        <Row>
          {data.map((item) => (
            <Col lg={6} md={6}>
              <CommonCardOther
                classtextfields={style.textfeilds}
                classBtn={item.classBtn}
                borderBtntitle={item.borderBtntitle}
                blackBtntitle={item.blackBtntitle}
                onBuyNow={() => {
                  dispatch(
                    saveLatestPrice({
                      price: Number(item.selling_price) / DECIMAL,
                      market: "secondary",
                      quantity: 1,
                      editionNumber: item.user_owned_no_of_editions,
                    })
                  );
                  navigate(`/checkout/art/${item.art_id}`);
                }}
                showMakeOffer={checkBeforMakingOfferHandler}
                item={item}
              />
            </Col>
          ))}
          {data.length == 0 && (
            <Col xs={12}>
              <Notfound />
            </Col>
          )}
        </Row>
        {totalPage > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPage}
            dispatch={dispatch}
            maxVisiblePages={5}
            componentTypes={"OtherUserProfile"}
          />
        )}
      </section>
      <SellModal
        show={show}
        onHide={() => setShow(false)}
        artImg={artist_nft_4}
        artistName="@artistname"
        edition={1}
        totalEditions={5}
      />
      {showing && (
        <MakeOffermodal
          show={showing}
          setShowing={setShowing}
          makeOfferDetails={makeOfferDetails}
          onHide={() => setShowing(false)}
          getSamePageDetails={getUserLikesArtOwn}
          username={username}
          offerPage="otherUserProfile"
        />
      )}
    </>
  );
};

export default Artownedsec;
