import { createSlice } from "@reduxjs/toolkit";
import { toasts } from "../../Component/Common/Toast/Toast";
import {
  startLoading,
  startMarketplaceLoading,
  stopLoading,
  stopMarketplaceLoading,
} from "../loading/loadingSlice";
import { store } from "../../App/store";
import { marketplaceService } from "../../Services/marketplaceService";
import { initialStateMarketPlace } from "../InitialState";

let token;

const initialState = initialStateMarketPlace;
export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    saveNft: (state, action) => {
      state.nft = action.payload;
    },
  },
});

export const getAllMarketPlaceNft = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startMarketplaceLoading());
        const response = await marketplaceService.getAllMarketPlaceNft(data);
        resolve(response);
        dispatch(stopMarketplaceLoading());
      } catch (err) {
        reject(err);
        dispatch(stopMarketplaceLoading());
      }
    });
  };
};

export const getAllSeries = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getAllSeries();
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getAllArtist = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getAllArtist();
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getAllTags = () => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getAllTags();
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getCoupon = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getCoupon(token, params);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const getArtDetailById = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getArtDetailById(
          token,
          params
        );
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const placeOrder = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.placeOrder(token, data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};

export const getDigitalArtDetail = (params, isLoading) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        if (isLoading) {
          dispatch(startLoading());
        }
        const response = await marketplaceService.getDigitalArtDetail(
          token,
          params
        );
        resolve(response.data);
        if (isLoading) {
          dispatch(stopLoading());
        }
      } catch (err) {
        reject(err);
        if (isLoading) {
          dispatch(stopLoading());
        }
      }
    });
  };
};

export const placeOrderByCurated = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        // dispatch(startLoading());
        const response = await marketplaceService.placeOrderByCurated(
          token,
          data
        );
        toasts.success(response.data.message);
        resolve(response);
        // dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const buyArt = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.buyArt(token, data);
        toasts.success(response.data.message);
        resolve(response);
        dispatch(stopLoading());
      } catch (err) {
        toasts.error(err.response.data.message);
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const getActivity = (params) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      token = store.getState().persist.accessToken;
      try {
        dispatch(startLoading());
        const response = await marketplaceService.getActivity(token, params);
        resolve(response.data);
        dispatch(stopLoading());
      } catch (err) {
        reject(err);
        dispatch(stopLoading());
      }
    });
  };
};
export const { saveNft } = marketplaceSlice.actions;
export default marketplaceSlice.reducer;
