//React,style
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../SignUp.module.scss";
import { Col, Row } from "react-bootstrap";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
//yup-formik
import * as Yup from "yup";
import "yup-phone";
import { useFormik } from "formik";
//constants
import { inputFieldValidation } from "../../../../Constant/formikConstant";
// other
import { openPopUp, signupAction } from "../../../../Features/user/userSlice";
import { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDebounce } from "../../../../customHooks/useDebounce";
import socket from "../../../../Socket/socket";
import ReCAPTCHA from "react-google-recaptcha";
import { Routes_Urls } from "../../../../Constant/Route_url";

const SignUpForm = ({
  setSignupStep,
  setSignupData,
  signupData,
  selelectedInputToEdit,
}) => {
  // redux
  const buttonStatus = useSelector((state) => state?.loading?.disable);
  const dispatch = useDispatch();

  const [phoneCountryCode, setPhoneNumberCountryCode] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestedUsername, setSuggestedUsername] = useState([]);
  const [usernameError, setUsernameError] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*First name should not start or end with a space"
      )
      .min(
        inputFieldValidation.firstName.minLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .max(
        inputFieldValidation.firstName.maxLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      // .matches(/^[A-Za-z]+$/, "*Invalid first name")
      .required(inputFieldValidation.commonMessage.required("First name"))
      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed"),
    lastName: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Last name should not start or end with a space"
      )
      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed")

      .required(inputFieldValidation.commonMessage.required("Last name"))
      .min(
        inputFieldValidation.lastName.minLength,
        `Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      )
      .max(
        inputFieldValidation.lastName.maxLength,
        `Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      ),
    username: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*User name should not start or end with a space"
      )
      .required(inputFieldValidation.commonMessage.required("Username"))
      .matches(
        inputFieldValidation.username.regex.expresion,
        inputFieldValidation.username.regex.errorMessage
      )
      .min(
        inputFieldValidation.username.minLength,
        `Must be between ${inputFieldValidation.username.minLength}-${inputFieldValidation.username.maxLength} letters`
      )
      .max(
        inputFieldValidation.username.maxLength,
        `Must be between ${inputFieldValidation.username.minLength}-${inputFieldValidation.username.maxLength} letters`
      )
      .test("maxTwoDot", "*Maximum two periods(.) are allowed", (value) => {
        if (value == undefined || value?.split(".").length > 3) {
          return false;
        }
        return true;
      })
      .test(
        "noStartOrEndDot",
        "Periods (.) are not allowed at the start or end",
        (value) => {
          if (value?.startsWith(".") || value?.endsWith(".")) {
            return false;
          }
          return true;
        }
      ),
    email: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),
    phoneNumber: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Phone number"))
      .phone(phoneCountryCode, true, "*Invalid phone number"),
    password: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Password"))
      .max(16, "Password cannot be more than 16 characters")
      .matches(
        inputFieldValidation.password.regex.expresion,
        inputFieldValidation.password.regex.errorMessage
      ),
    confirmPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Confirm password"))
      .oneOf([Yup.ref("password"), null], "*Must match with password"),
    captcha: Yup.string().required(
      inputFieldValidation.commonMessage.required("Captcha")
    ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: signupData?.firstName ? signupData?.firstName : "",
      lastName: signupData?.lastName ? signupData?.lastName : "",
      username: signupData?.username ? signupData?.username : "",
      email: signupData?.email ? signupData?.email : "",
      password: signupData?.password ? signupData?.password : "",
      confirmPassword: signupData?.confirmPassword
        ? signupData?.confirmPassword
        : "",
      captcha: "",
      phoneNumber: signupData?.phoneNumber ? signupData?.phoneNumber : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!usernameError) {
        setSignupData(values);
        let finalVal = Object.assign({ ...values });
        finalVal.firstName = values.firstName;
        finalVal.lastName = values.lastName;
        finalVal.username = values.username;
        finalVal.email = values.email;
        finalVal.password = values.password;
        finalVal.confirmPassword = values.confirmPassword;
        finalVal.captcha = values.captcha;
        finalVal.phoneNumber = parsePhoneNumber(
          values?.phoneNumber
        )?.nationalNumber;
        finalVal.countryCode =
          "+" + parsePhoneNumber(values?.phoneNumber)?.countryCallingCode;

        // calling singup API
        dispatch(signupAction(finalVal))
          .then((response) => {
            if (response?.status === 200) {
              setSignupStep(2);
            }
          })
          .catch(() => {
            window.grecaptcha.reset();
            formik.setFieldValue("captcha", "");
          });
      }
    },
  });

  //useEffect
  useEffect(() => {
    // emiting the event for getting available username
    if (debouncedSearchTerm) {
      socket.emit("get-username", { data: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    //removing the lisenter
    return () => {
      socket.off("get-username");
    };
  }, []);

  socket.on("get-username", (data) => {
    if (data?.is_exist) {
      setSuggestedUsername(JSON.parse(data?.data));
      setUsernameError(data?.is_exist);
    } else {
      setSuggestedUsername(JSON.parse(data?.data));
      setUsernameError(data?.is_exist);
    }
  });

  // setting captach value
  async function onChange(value) {
    formik.setFieldValue("captcha", value);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row className="align-items-start">
          <Col lg={6} md={6} xs={12}>
            <FormikControls
              variant={styles.spaceField}
              className={styles.fieldborder}
              control="input"
              type="text"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              formik={formik}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <FormikControls
              variant={styles.spaceField}
              className={styles.fieldborder}
              control="input"
              type="text"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              formik={formik}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={12}>
            <p className={styles.subInformat}>Your name is always public.</p>
          </Col>
          <Col lg={12} xs={12}>
            <div className={styles.userFields}>
              <h6>
                {suggestedUsername.length > 0 && (
                  <>
                    Available: {}
                    {suggestedUsername.map((name, index) => (
                      <>
                        <span
                          key={name}
                          onClick={() => {
                            formik.setFieldValue("username", name);
                            setUsernameError(false);
                          }}
                        >
                          <Link to="">{name}</Link>
                        </span>
                        , {/* <br /> */}
                      </>
                    ))}
                  </>
                )}
              </h6>
              <FormikControls
                variant={styles.spaceField}
                className={styles.fieldborder}
                control="input"
                type="text"
                name="username"
                placeholder="Username"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  formik.setFieldValue("username", e.target.value);
                  if (e.target.value === "") {
                    setUsernameError(false);
                    setSuggestedUsername([]);
                  }
                }}
                value={formik.values.username}
                formik={formik}
                onBlur={formik.handleBlur}
              />
              <div>
                <p style={{ color: "red" }}>
                  {usernameError && "*Username already taken"}
                </p>
              </div>
              <p className={styles.subInformat}>
                Your username will always be public. Use any combination of
                Letters (a-z), numbers (0-9), and periods (.). Username can be
                changed later from account settings.
              </p>
            </div>
          </Col>

          <Col lg={6} md={6} xs={12}>
            <FormikControls
              variant={styles.spaceField}
              className={styles.fieldborder}
              control="input"
              type="email"
              name="email"
              placeholder="Email Address"
              onChange={formik.handleChange}
              value={formik.values.email}
              formik={formik}
              onBlur={formik.handleBlur}
              autoFocus={selelectedInputToEdit == "email" ? true : false}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <FormikControls
              control="phone"
              countryCallingCodeEditable={false}
              type="text"
              variant={`${styles.spaceField} ${styles.errormsgchange}`}
              className={styles.fieldborder}
              placeholder="Enter phone number"
              name="phoneNumber"
              maxlength="20"
              international
              formik={formik}
              value={formik.values.phoneNumber}
              onChange={async (value) => {
                await formik.setFieldTouched("phoneNumber", true);
                if (value) {
                  setPhoneNumberCountryCode(parsePhoneNumber(value)?.country);
                }
                formik.setFieldValue("phoneNumber", value);
              }}
              autoFocus={selelectedInputToEdit == "phone" ? true : false}
            />
          </Col>
          <Col xs={12}>
            <p className={styles.subInformat}>
              You will receive OTPs on your mobile number and email for
              verification. It's recommended to use an email address and a phone
              number that is not shared or accessible by others to minimize the
              risk of unauthorized access.
            </p>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <FormikControls
              variant={styles.spaceField}
              className={styles.fieldborder}
              rightIcon={styles.iconblack}
              control="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
              formik={formik}
              onBlur={formik.handleBlur}
              maxLength={16}
            />
          </Col>
          <Col lg={6} md={6} xs={12}>
            <FormikControls
              variant={styles.spaceField}
              className={styles.fieldborder}
              rightIcon={styles.iconblack}
              control="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              formik={formik}
              onBlur={formik.handleBlur}
            />
          </Col>
          <Col xs={12}>
            <p className={styles.subInformat}>
              Create a strong, unique password for your marketplace account.
              Password should be atleast 6 characted. Use any combination of
              uppercase and lowercase letters, numbers, and special characters
              (!,@,#,$,%,^,&,*,_). Avoid using easily guessable passwords, such
              as common words, names, or sequential numbers.
            </p>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_CAPCHA_KEY}
              onChange={onChange}
              onExpired={() => window.grecaptcha.reset()}
              //   theme="dark"
            />
            <p className="error_message">
              {" "}
              {formik.errors.captcha && formik.errors.captcha}
            </p>
          </Col>
          <Col xs={12}>
            <div className={styles.bdrField}>
              <p>
                You agree to{" "}
                <Link to={Routes_Urls.Terms_of_use}>
                  NewArtX’s Terms & Conditions
                </Link>{" "}
                and <Link to={Routes_Urls.PrivacyPolicy}>Privacy Policy </Link>
                by signing up.
              </p>
            </div>
          </Col>
          <Col lg={6} sm={6} xs={12} className="align-self-center">
            <p className={`pt-0 border-0 ${styles.LoginFormSignUp}`}>
              Already have an account?{" "}
              <Link onClick={() => dispatch(openPopUp("login"))} to="#">
                Log in.
              </Link>
            </p>
          </Col>
          <Col lg={6} sm={6} xs={12}>
            <CommonBtn
              className={`btn-yellow-textblack ${styles.signUpBtn}`}
              title="Next"
              type="submit"
              disabled={buttonStatus}
              role="btn"
            />
          </Col>
        </Row>
      </form>
    </>
  );
};

export default SignUpForm;
