import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./HeroSec.module.scss";
import Slider from "react-slick";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import { ArrowRight } from "../../../../Assets/svgIcons/SVGicon";
import { sliceChar } from "../../../../Helper/common";
import YouTube from "react-youtube";
import { useSelector } from "react-redux";
const HeroSec = ({ item, sliderIndex }) => {
  // const videoRef = useRef(null);
  const [videoKey, setVideoKey] = useState(1); // Change this key to reset the video
  const loginCase = useSelector((state) => state?.users?.loginCase);

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 15000,
    autoplay: true,
    autoplaySpeed: 0,
    slidesToShow: 1,
    cssEase: "linear",
    vertical: true,
    draggable: false,
    focusOnSelect: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    centerMode: true,
    initialSlide: 1,
  };
  const getVideoIdFromUrl = (url) => {
    const match = url.match(
      /(?:youtu\.be\/|youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );
    return match ? match[1] : null;
  };

  const opts = {
    width: "100%",
    height: "420",
    playerVars: {
      autoplay: 0,
    },
  };

  const onReady = (event) => {
    // event.target.playVideo(); // For example, auto-play the video once it's ready
    // setPlayer(event.target);
  };

  const isValidYouTubeUrl = (url) => {
    const pattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    return pattern.test(url);
  };

  useEffect(() => {
    // stopVideo();
    // pauseVideo();
  }, [sliderIndex, loginCase]);

  // const stopVideo = () => {
  //   setVideoKey((prevKey) => prevKey + 1);
  // };

  // const pauseVideo = () => {
  //   if (videoRef.current) {
  //     videoRef.current.pause(); // Pause the video
  //   }
  // };
  //   useEffect(() => {
  //     if (videoRef.current) {
  //         // Ensure the video is muted for autoplay to work
  //         videoRef.current.muted = true;
  //         videoRef.current.play().catch(error => {
  //             console.error('Error attempting to play the video:', error);
  //         });
  //     }
  // }, []);

  return (
    <>
      {item?.banner_type == 1 && (
        <section className={`${styles.heroSec}`}>
          <Row>
            <Col md={8} className="order-md-last">
              <Row>
                <Col xs={6} className="pe-md-3">
                  <Slider {...settings} className={styles.heroSecLeftSlider}>
                    {item?.banner_media?.slice(0, 2)?.map((data) => (
                      <li>
                        <img
                          src={`${process.env.REACT_APP_IMG_BASE_URL}${data?.media_path}`}
                          alt="cardimg"
                        />
                      </li>
                    ))}
                  </Slider>
                </Col>
                <Col xs={6} className="ps-md-3">
                  <Slider {...settings} className={styles.heroSecRightSlider}>
                    {item?.banner_media?.slice(2)?.map((data) => (
                      <li>
                        <img
                          src={`${process.env.REACT_APP_IMG_BASE_URL}${data?.media_path}`}
                          alt="cardimg"
                        />
                      </li>
                    ))}
                  </Slider>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              {item?.banner_title && (
                <h1 title={item?.banner_title}>
                  {sliceChar(item?.banner_title, 25)}
                </h1>
              )}
              {item?.button_title && item.url && (
                <CommonBtn
                  className="mb-4 mb-md-0"
                  onClick={() => window.open(item.url, "_blank")}
                  title={sliceChar(item?.button_title, 15)}
                  tolltip={item?.button_title ? item?.button_title : ""}
                  icon={<ArrowRight />}
                  role="btn"
                />
              )}
            </Col>
          </Row>
        </section>
      )}

      {item?.banner_type == 2 && (
        <div className={styles.imageSection}>
          <div className={styles.imageTextSec}>
            <h2>{item?.file_title}</h2>
          </div>
          <img
            onClick={() => item.url && window.open(item.url, "_blank")}
            src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.banner_media[0]?.media_path}`}
            alt="LoginBg"
          />
        </div>
      )}
      {item?.banner_type == 3 && (
        <>
          {!isValidYouTubeUrl(item.url) ? (
            <div className={styles.videoSection}>
              {/* <div className={styles.imageTextSec}>
                <h2>{item?.file_title}</h2>
              </div> */}
              <video
                // ref={videoRef}
                autoPlay
                muted
                loop
                onClick={() => item.url && window.open(item.url, "_blank")}
                // controls
                width="100%"
                height="auto"
              >
                <source
                  src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.banner_media[0]?.media_path}`}
                />
              </video>
            </div>
          ) : (
            <div className={styles.videoSection}>
              <YouTube
                key={videoKey}
                videoId={getVideoIdFromUrl(item.url)}
                opts={opts}
                onReady={onReady}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HeroSec;
