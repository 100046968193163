import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import style from "./Wallet_tab.module.scss";
import {
  formatDateWithTime,
  getNumberWithComma,
} from "../../../../Helper/common";
import { DECIMAL, LEDGER_ORDER_TYPE } from "../../../../Constant/userConstant";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";

const Ledger_tab = ({
  walletLedgerData,
  walletLedger,
  page,
  totalPages,
  setPage,
}) => {
  return (
    <>
      <section className={style.ledger_tab}>
        <Tabs
          defaultActiveKey="transactions"
          id="uncontrolled-tab-example"
          className="tablists"
        >
          <Tab eventKey="transactions" title="Transactions">
            <div className={style.table_section}>
              <CommonTable className={style.artowned_table}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Transaction ID</th>
                    <th>Narration</th>
                    <th>Withdrawal</th>
                    <th>Deposit</th>
                    <th className="text-start">Closing Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {walletLedgerData.map((item, index) => (
                    <tr key={index}>
                      <td>{formatDateWithTime(item.createdAt)}</td>
                      <td>
                        {item.transaction_id ? item.transaction_id : "--"}
                      </td>
                      <td>{item.narration ? item.narration : "--"}</td>
                      <td>
                        {item.order_type == LEDGER_ORDER_TYPE.BUY ||
                        item.order_type == LEDGER_ORDER_TYPE.WITHDRAW
                          ? `₹${getNumberWithComma(
                              (Number(item.amount) / DECIMAL).toFixed(2)
                            )}`
                          : ""}
                      </td>
                      <td>
                        {item.order_type == LEDGER_ORDER_TYPE.SELL ||
                        item.order_type == LEDGER_ORDER_TYPE.DEPOSIT
                          ? `₹${getNumberWithComma(
                              (Number(item.amount) / DECIMAL).toFixed(2)
                            )}`
                          : ""}
                      </td>
                      <td className="text-start">
                        <b>
                          {`₹${getNumberWithComma(
                            (Number(item.balance) / DECIMAL).toFixed(2)
                          )}`}
                        </b>
                      </td>
                    </tr>
                  ))}
                  {walletLedgerData.length == 0 && (
                    <tr>
                      <td colSpan={6}>
                        <p className="no_recordtable">No records found.</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </CommonTable>
            </div>
            <div className="m-5">
              {walletLedger.totalPages > 1 && (
                <CustomPagination
                  className="ms-auto"
                  activePage={page}
                  totalPage={totalPages}
                  setActivePage={setPage}
                  maxVisiblePages={3}
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default Ledger_tab;
