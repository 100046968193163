export  const ALL_QR_LINKS = {
  ART_26: {
    QR_LINK: "/anjaneyulu-gundu/bottles/goli-soda",
    redirect_link: "/curated-drop/digital-art/live/26",
  },

  ART_27: {
    QR_LINK: "/bhaskar-rao-botcha/architectural-series/tree-of-life-1",
    redirect_link: "/curated-drop/digital-art/live/27",
  },
 


  ART_22: {
    QR_LINK: "/ganapati-hegde/gods-in-nature/ganesha-1",
    redirect_link: "/curated-drop/digital-art/live/22",
  },

  ART_21: {
    QR_LINK: "/gigi-scaria/phantom-limb/phantom-limb",
    redirect_link: "/curated-drop/digital-art/live/21",
  },

  ART_30: {
    QR_LINK: "/laxman-aelay/poolamma/poolamma-1",
    redirect_link: "/curated-drop/digital-art/live/30",
  },
  ART_31: {
    QR_LINK:
      "/masuram-ravikanth/reviving-the-yesterday/reviving-the-yesterday-1",
    redirect_link: "/curated-drop/digital-art/live/31",
  },
  ART_32: {
    QR_LINK: "/nagesh-goud/cow-series/kamadhenu-1",
    redirect_link: "/curated-drop/digital-art/live/32",
  },
  ART_33: {
    QR_LINK: "/ramesh-gorjala/govardhana-krishna",
    redirect_link: "/curated-drop/digital-art/live/33",
  },
  ART_34: {
    QR_LINK: "/shampa-sircar-das/prakriti/prakriti-1",
    redirect_link: "/curated-drop/digital-art/live/34",
  },
  ART_35: {
    QR_LINK: "/shovin-bhattacharjee/galaxy-on-earth/galactic-harmony",
    redirect_link: "/curated-drop/digital-art/live/62",
  },
  ART_36: {
    QR_LINK: "/siddharth-shindhade/marriage-series/wedding-procession-1",
    redirect_link: "/curated-drop/digital-art/live/36",
  },
  ART_37: {
    QR_LINK: "/suraj-kumar-kashi/metropolitan-lifestyle/touching-the-clouds",
    redirect_link: "/curated-drop/digital-art/live/37",
  },
  ART_38: {
    QR_LINK: "/tapasya-gupta/untitled/why-should-boys-have-all-the-fun",
    redirect_link: "/curated-drop/digital-art/live/61",
  },
  ART_40: {
    QR_LINK: "/valay-bhagwan-shende/untitled/untitled",
    redirect_link: "/curated-drop/digital-art/live/40",
  },

  ART_60: {
    QR_LINK: "/viraj-khanna/portraits/self-talk",
    redirect_link: "/curated-drop/digital-art/live/60",
  },
  ART_52: {
    QR_LINK: "/kandi-narsimlu/telangana-folk/telangana-folk-village-life",
    redirect_link: "/curated-drop/digital-art/live/52",
  },

  ART_51: {
    QR_LINK: "/k-laxma-goud/untitled/untitled-1",
    redirect_link: "/curated-drop/digital-art/live/51",
  },

  ART_39: {
    QR_LINK: "/madhuri-bahaduri/seascapes/transcedent-waves",
    redirect_link: "/curated-drop/digital-art/live/39",
  },
  
  ART_55: {
    QR_LINK: "/priyanka-aelay/revisiting-the-tale-of-balanagamma/the-echo-of-this-forest-is-unbroken",
    redirect_link: "/curated-drop/digital-art/live/55",
  },

  ART_53: {
    QR_LINK: "/chippa-sudhakar/migration/migration-1",
    redirect_link: "/curated-drop/digital-art/live/53",
  },

  ART_54: {
    QR_LINK: "/sayam-bharath-Yadav/bottles/doodhwala",
    redirect_link: "/curated-drop/digital-art/live/54",
  },



};
