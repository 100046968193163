import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  ResetPasswordAction,
  SendOtp,
  getLoginOtpAction,
  openPopUp,
} from "../../Features/user/userSlice";
import CommonBtn from "../Common/Ui/CommonBtn/CommonBtn";
import * as Yup from "yup";
import FormikControls from "../Common/Ui/Formik/FormikControls";
import { useDispatch, useSelector } from "react-redux";
import { inputFieldValidation } from "../../Constant/formikConstant";
import { Helmet } from "react-helmet";

function Reset(props) {
  const { data, ip, email } = props;
  //redux
  const buttonStatus = useSelector((state) => state?.loading?.disable);
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    // Exit the effect if the timer reaches 0
    if (seconds === 0) return;

    // Decrease the seconds by 1 every second
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the interval when the component unmounts or when seconds reach 0
    return () => clearInterval(timer);
  }, [seconds]);

  //yup & formik
  const ResetSchema = Yup.object({
    otp: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Code"))
      .test("lengthCheck", "*Incorrect Code", (value) => {
        let valueString = value?.toString();
        if (valueString && valueString?.length < 6) {
          return false;
        }
        return true;
      }),
    newPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Password"))
      .max(16, 'Password cannot be more than 16 characters')
      .matches(
        inputFieldValidation.password.regex.expresion,
        inputFieldValidation.password.regex.errorMessage
      ),
    confirmPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Confirm password"))
      .oneOf([Yup.ref("newPassword"), null], "Password must match"),
  });
  console.log("dhjhgdshds", email);
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ResetSchema,
    initialValues: {
      otp: "", 
      newPassword: "",
      confirmPassword: "",
      deviceType: 0,
      email: email,
    },
    onSubmit: (values) => {
      let obj = {
        type: "EMAIL",
        service: "FORGOTPASSWORD",
        ipAddress: ip,
        ...values,
      };
      //calling reset API
      dispatch(ResetPasswordAction(obj)).then((response) => {
        dispatch(openPopUp(""));
      });
    },
  });

  const getOTPSuccess = async () => {
    let data = {
      to: email,
      type: "EMAIL",
      service: "FORGOTPASSWORD",
    };

    dispatch(getLoginOtpAction(data)).then((response) => {
      if (response?.status === 200) {
        setSeconds(60);
      }
    });
  };

  return (
    <>
     <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
      <form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={12}>
          <div className="forgetOtp">
          <FormikControls
          variant='mb-0'
            className="fieldBlack"
            control="input"
            name="otp"
            type="text"
            placeholder="Enter the OTP sent on your email/phone number"
            maxLength={6}
            formik={formik}
            onChange={(e) => {
              //regex for only digit
              if (/^\d*$/.test(e.target.value)) {
                formik.setFieldValue("otp", e.target.value);
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.otp}
          />
          <div className={`btn_resentotp ${seconds > 0 ? "afterOtp" : ""}`}>
            <button
              type="button"
              onClick={() => getOTPSuccess()}
              disabled={seconds > 0 ? true : false}
            >
              {seconds > 0 ? "You can resend code after" : "Resend Code"}
            </button>
            {seconds > 0 && <span>{seconds} Seconds</span>}
          </div>
          </div>
        </Col>
        <Col xs={12}>
          <FormikControls
            className="fieldBlack"
            control="password"
            rightIconClass="iconWhite"
            name="newPassword"
            placeholder="New Password"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            maxLength={16} 

          />
        </Col>
        <Col xs={12}>
          <FormikControls
            className="fieldBlack"
            control="password"
            rightIconClass="iconWhite"
            name="confirmPassword"
            placeholder="Confirm New Password"
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            maxLength={16} 

          />
        </Col>
        <Col xs={12}>
          <CommonBtn
            disabled={buttonStatus}
            role="btn"
            className="btn-yellow hovBtn"
            title="Reset Password"
            type="submit"
          />
        </Col>
      </Row>
    </form>

    </>
   
  );
}

export default Reset;
