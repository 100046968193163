import React, { createRef, useEffect, useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import SearchIcon from "../../../Assets/icons/search-icon.png";
import Logo from "../../../Assets/images/logo.png";
import { LoginIcon, SignUpIcon } from "../../../Assets/svgIcons/SVGicon";
import CommonModal from "../Ui/CommonModal/CommonModal";
import Login from "../../Public/Login/Login";
import LogoModal from "../../../Assets/icons/Fav.png";
import SearchModal from "../Ui/SearchModal/SearchModal";
import { useFormik } from "formik";
import FormikControls from "../Ui/Formik/FormikControls";
import CommonBtn from "../Ui/CommonBtn/CommonBtn";
import Forget from "../../Public/Forget";
import Reset from "../../Public/Reset";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordAction,
  logoutAction,
  openPopUp,
  resetMarketplaceFilter,
  saveSignUpCredential,
  setScreenOrientation,
} from "../../../Features/user/userSlice";
import { inputFieldValidation } from "../../../Constant/formikConstant";
import * as Yup from "yup";
import { getIp } from "../../../Features/auth/authSlice";
import TwoFaAuthentication from "../../Public/Login/TwoFaAuthentication";
import { ORIENTATION } from "../../../Constant/userConstant";
import { Routes_Urls } from "../../../Constant/Route_url";

const HeaderBeforeLogin = () => {
  // redux
  const dispatch = useDispatch();

  const ip = useSelector((state) => state?.persist?.ip);
  const navigate = useNavigate();
  const loginCase = useSelector((state) => state?.users?.loginCase);

  //state
  const [searchModal, setSearchModal] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [data, setData] = useState({});
  const [email, setEmail] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  //Yup and formik
  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(
      inputFieldValidation.commonMessage.required("Old password")
    ),
    newPassword: Yup.string()
      .required(inputFieldValidation.commonMessage.required("New password"))
      .max(16, "Password cannot be more than 16 characters")
      .matches(
        inputFieldValidation.password.regex.expresion,
        inputFieldValidation.password.regex.errorMessage
      )
      .test(
        "not-same-as-currentPassword",
        "*New password must be different from old password",
        function (value) {
          const oldPassword = this.parent.oldPassword || ""; // Access currentPassword value
          return value !== oldPassword; // Check if newPassword is different from currentPassword
        }
      ),
    confirmNewPassword: Yup.string()
      .required(
        inputFieldValidation.commonMessage.required("Confirm new password")
      )
      .oneOf([Yup.ref("newPassword"), null], "Password must match"),
  });
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data = {
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
        ipAddress: ip,
        deviceType: 0,
        confirmPassword: values.confirmNewPassword,
      };
      await dispatch(changePasswordAction(data)).then((response) => {});
      navigate("/");
      await dispatch(logoutAction());
      handleClose();
    },
  });

  useEffect(() => {
    dispatch(getIp())
      .then((res) => {})
      .catch((err) => {});
  }, []);

  useEffect(() => {
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const handleOrientationChange = () => {
    dispatch(
      setScreenOrientation(
        window.matchMedia("(orientation: landscape)").matches
          ? ORIENTATION.LANDSCAPE
          : ORIENTATION.PORTRAIT
      )
    );
  };

  const toggleBtn = createRef(); // reference of toggle btn

  //function
  const handleSidebar = () => {
    // function for handling sidebar
    if (document.body.clientWidth < 991) {
      toggleBtn.current.click(); // click toggleBtn only if screenwidth is less than 991
    }
  };

  const handleSidebarMarketplace = () => {
    // function for handling sidebar
    if (document.body.clientWidth < 991) {
      toggleBtn.current.click(); // click toggleBtn only if screenwidth is less than 991
    }
    dispatch(resetMarketplaceFilter());
  };

  const handleClose = () => {
    dispatch(openPopUp(""));
    dispatch(saveSignUpCredential({ email: "", password: "" }));
    formik.resetForm();
  };

  const caseClick = (e) => {
    dispatch(openPopUp(e));
  };

  const callbackDataPass = (data) => {
    setData(data);
  };

  return (
    <>
      <div
        onClick={handleSidebar}
        className={`${styles.headerBackdrop} d-lg-none ${
          backdrop ? styles.active : ""
        }`}
      />
      <header className={styles.header}>
        <Navbar className={styles.header_navbar} expand="lg">
          <Container>
            <div className={styles.header_left}>
              <Link className={styles.header_logo} to="/">
                <img src={Logo} alt="Logo" />
              </Link>
            </div>
            <div className={styles.header_right}>
              <div className={styles.header_search}>
                <Link
                  to="#"
                  onClick={() => {
                    setSearchModal(true);
                  }}
                >
                  <img src={SearchIcon} alt="SearchIcon" />
                </Link>
              </div>
              <Navbar.Collapse
                id="basic-navbar-nav"
                className={styles.header_navbarCollapse}
              >
                <Nav className={`ms-auto ${styles.header_nav}`}>
                  <NavDropdown
                    title="Buy"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Marketplace}
                      onClick={handleSidebarMarketplace}
                    >
                      Marketplace
                    </Link>
                    {/* <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Curated_Drop_page}
                      onClick={handleSidebar}
                    >
                      Curated Drops
                    </Link> */}
                  </NavDropdown>
                  <NavDropdown
                    title="Display"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.FlauntPage}
                      onClick={handleSidebar}
                    >
                      How to Flaunt Your DFAs
                    </Link>
                  </NavDropdown>
                  <NavDropdown
                    title="About Us"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls?.About_dfa}
                      onClick={handleSidebar}
                    >
                      What are DFAs
                    </Link>

                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.About_newArtX}
                      onClick={handleSidebar}
                    >
                      About NewArtX
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Faq}
                      onClick={handleSidebar}
                    >
                      FAQs
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Contact_us}
                      onClick={handleSidebar}
                    >
                      Contact Us
                    </Link>
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to={Routes_Urls.Create}
                      onClick={handleSidebar}
                    >
                      Apply to Create
                    </Link>
                  </NavDropdown>
                  {/* <NavDropdown
                    title="Stats"
                    className={styles.header_dropDown}
                    renderMenuOnMount={true}
                  >
                    <Link
                      className={`dropdown-item ${styles.header_dropDownItem}`}
                      to="/stats/activity"
                      onClick={handleSidebar}
                    >
                      Activity
                    </Link>
                  </NavDropdown> */}
                </Nav>
              </Navbar.Collapse>
              <div className={styles.header_action}>
                <>
                  <Link
                    className={`nav-link ${styles.header_navlink}`}
                    to="#"
                    onClick={() => {
                      caseClick("login");
                    }}
                  >
                    <span>Log In</span>
                    <LoginIcon />
                  </Link>
                  <span className={styles.header_action_divider}></span>
                  <Link
                    className={`nav-link clr-yellow ${styles.header_navlink}`}
                    to={Routes_Urls.Signup}
                  >
                    <span>Sign Up</span>
                    <SignUpIcon />
                  </Link>
                </>
              </div>
              <Navbar.Toggle
                className={styles.header_toggleBtn}
                aria-controls="basic-navbar-nav"
                onClick={() => setBackdrop(!backdrop)}
                ref={toggleBtn}
              >
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </Navbar.Toggle>
            </div>
          </Container>
        </Navbar>
      </header>
      {/* Header popups */}
      {(() => {
        switch (loginCase) {
          case "TwoFa":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Two Factor Authentication</>}
                className="authTwomodal"
                centered={true}
                subTitle="Enter verification code sent on your registered email and phone number"
              >
                <TwoFaAuthentication handleClose={handleClose} email={email} />
              </CommonModal>
            );
          case "login":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle="Welcome Back."
                classSub="textyellow"
                subTitle="Discover and own exquisite DFAs from talented Fine Artists."
                centered={true}
                backdropclass={styles.backdrp_bg}
              >
                <Login
                  forgetClick={() => {
                    caseClick("forgot");
                  }}
                  onClick={() => {
                    caseClick("signUp");
                  }}
                  ip={ip}
                  setEmail={setEmail}
                />
              </CommonModal>
            );
          case "thankYou":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={
                  <>
                    Thanks For <br /> Signing up.
                  </>
                }
                centered={true}
              >
                <img src={LogoModal} alt="Logo" />
                <p className="stayTuned">
                  Stay tuned. <br />
                  We'll let you know about all our upcoming drops and news
                </p>
              </CommonModal>
            );
          case "forgot":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Forgot Password ?</>}
                centered={true}
                subTitle="No worries, we'll send you reset notifications."
              >
                <Forget
                  callbackDataPass={callbackDataPass}
                  handleClose={handleClose}
                  ip={ip}
                  setEmail={setEmail}
                />
              </CommonModal>
            );
          case "reset-password":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Set New Password</>}
                centered={true}
              >
                <Reset
                  handleClose={handleClose}
                  ip={ip}
                  data={data}
                  email={email}
                />
              </CommonModal>
            );
          case "change-password":
            return (
              <CommonModal
                show={true}
                onHide={handleClose}
                modalTitle={<>Change Password</>}
                centered={true}
                formik={formik}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <FormikControls
                        variant={styles.spaceField}
                        rightIcon={styles.iconblack}
                        className="fieldBlack"
                        control="password"
                        name="oldPassword"
                        placeholder="Old Password"
                        formik={formik}
                        onChange={formik.handleChange}
                        value={formik.values.oldPassword}
                        onBlur={formik.handleBlur}
                      />
                    </Col>
                    <Col xs={12}>
                      <FormikControls
                        variant={styles.spaceField}
                        rightIcon={styles.iconblack}
                        className="fieldBlack"
                        control="password"
                        name="newPassword"
                        placeholder="New Password"
                        formik={formik}
                        onChange={formik.handleChange}
                        value={formik.values.newPassword}
                        onBlur={formik.handleBlur}
                        maxLength={16}
                      />
                    </Col>
                    <Col xs={12}>
                      <FormikControls
                        variant={styles.spaceField}
                        rightIcon={styles.iconblack}
                        className="fieldBlack"
                        control="password"
                        name="confirmNewPassword"
                        placeholder="Confirm New Password"
                        formik={formik}
                        onChange={formik.handleChange}
                        value={formik.values.confirmNewPassword}
                        onBlur={formik.handleBlur}
                      />
                    </Col>
                    <Col xs={12}>
                      <CommonBtn
                        type={"submit"}
                        role="btn"
                        className="btn-yellow-black "
                        title="Save"
                      />
                    </Col>
                  </Row>
                </form>
              </CommonModal>
            );
          default:
            return null;
        }
      })()}
      {searchModal && (
        <SearchModal
          show={searchModal}
          onHide={() => {
            setSearchModal(false);
            setSearchTerm("");
          }}
          setSearchModal={setSearchModal}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      )}
    </>
  );
};
export default HeaderBeforeLogin;
