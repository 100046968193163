import React from "react";
import style from "./ArtCreated.module.scss";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";

import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon.jsx";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  getNumberWithComma,
  sliceChar,
} from "../../../../Helper/common";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { DECIMAL } from "../../../../Constant/userConstant";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const ArtCreated = (props) => {
  let { data, setSearch, page, totalPage } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationArt = (item) => {
    // checking the art is upcoming or live or previous
    let type;
    if (item.drop_type != 3) {
      type = determineEventStatus(item.start_time, item.end_time);
    } else {
      type = determineEventStatusOfFCFB(item);
    }
    if (type === "previous") {
      navigate(`/marketplace/art/${item.id}`);
    } else {
      navigate(
        `/curated-drop/digital-art/${
          item.drop_type != 3
            ? determineEventStatus(item.start_time, item.end_time)
            : determineEventStatusOfFCFB(item)
        }/${item.id}`
      );
    }
  };
  return (
    <>
      <section className={style.art_owned}>
        <div className={style.searchbar}>
          <form>
            <>
              <div className="search_input">
                <div className="search_input_inner">
                  <button type="submit" className="search_input_icon">
                    <SearchIcon />
                  </button>
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search Art"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </>
          </form>
        </div>

        <div className={style.table_section}>
          <CommonTable className={style.artowned_table}>
            <thead>
              <tr>
                <th>Digital Art</th>
                <th>Views</th>
                <th>Likes</th>
                <th>Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center thumb-outer">
                      <img
                        className="thumb"
                        src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
                        alt="thumb"
                      />
                      <h4 title={item.art_name}>
                        {sliceChar(item.art_name, 20)}
                      </h4>
                    </div>
                  </td>

                  <td>{item.view_count}</td>
                  <td>{item.like_count ? item.like_count : "-"}</td>
                  <td>₹{getNumberWithComma(item.price / DECIMAL)}</td>

                  <td>
                    <div className="d-flex align-items-center justify-content-evenly">
                      <CommonBtn
                        role="btn"
                        title={"View"}
                        className={`bordred-black ${style.borderBtn}`}
                        onClick={() => navigationArt(item)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {data.length == 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="no_recordtable">No records found</p>
                </td>
              </tr>
            )}
          </CommonTable>
          {totalPage > 1 && (
            <CustomPagination
              className="ms-auto"
              activePage={page}
              totalPage={totalPage}
              maxVisiblePages={5}
              dispatch={dispatch}
              componentType={"Profile"}

            />
          )}
        </div>
      </section>
    </>
  );
};

export default ArtCreated;
