import React from 'react';
import style from './AboutNewArtX.module..scss';
import BenefitsNewart from './BenefitsNewart/BenefitsNewart';
import NewArtXBanner from './NewArtXBanner/NewArtXBanner';
import { Helmet } from 'react-helmet';

const AboutNewArtX = () => {
    return (
        <>
            <section className={style.aboutNewart}>
            <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        
        
      </Helmet>

                <NewArtXBanner />
                <BenefitsNewart />
            </section>
        </>
    )
}

export default AboutNewArtX