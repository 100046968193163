import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./CommonTooltip.scss";

const CommonTooltip = ({ className, title, icon, children, id }) => {
  const renderTooltip = (props) => (
    <Tooltip id={id} {...props}>
      {children}
    </Tooltip>
  );
  return (
    <>
      <div className={`commonTooltip ${className || ""}`}>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <div className="btnSize">
            {title}
            <span>{icon}</span>
          </div>
        </OverlayTrigger>
      </div>
    </>
  );
};

export default CommonTooltip;
