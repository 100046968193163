import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SocialMediaIcon from "../../Ui/SocialMediaIcon/SocialMediaIcon";
import styles from "./GetUpdates.module.scss";
import FormikControls from "../../Ui/Formik/FormikControls";
import { ArrowRight } from "../../../../Assets/svgIcons/SVGicon";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { emailSubscribe } from "../../../../Features/user/userSlice";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import { useDispatch } from "react-redux";

const GetUpdates = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Reset form state when component unmounts
    return () => {
      emailSubScribeFormik.resetForm();
    };
  }, []);

  useEffect(() => {
    // Reset form state on location change
    emailSubScribeFormik.resetForm();
  }, [location]);

  const emailSubScribeSchema = Yup.object({
    subcribeEmail: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),
  });
  const emailSubScribeFormik = useFormik({
    validationSchema: emailSubScribeSchema,
    initialValues: {
      subcribeEmail: "",
    },
    onSubmit: (values) => {
      emailSubscribeHandle(values);
    },
  });

  const emailSubscribeHandle = async (values) => {
    let data = {
      email: values.subcribeEmail,
    };
    try {
      const result =  await dispatch(emailSubscribe(data));
      emailSubScribeFormik.resetForm();
    } catch (error) {
      console.error('error', error);
    }
  };
  return (
      <section
        className={`${styles.GetUpdates} ${
          location.pathname.includes("nax") ||
          location.pathname.includes("buy-display")
            ? styles.Dark
            : ""
        }`}
      >
        <Container>
          <p className={styles.GetUpdatesHead}>
            <span>Get The Latest Updates</span>
          </p>
          <div className="mt-3 pb-5">
            <Row className="align-items-center">
              <Col sm={6} md={5} lg={4}>
                <form onSubmit={emailSubScribeFormik.handleSubmit}>
                  <FormikControls
                    className="input_border"
                    control="input"
                    type="email"
                    name="subcribeEmail"
                    id="subcribeEmail"
                    placeholder="Your Email Address"
                    onChange={emailSubScribeFormik.handleChange}
                    onBlur={emailSubScribeFormik.handleBlur}
                    value={emailSubScribeFormik.values.subcribeEmail}
                    formik={emailSubScribeFormik}
                    // rightIcon={
                    //     <ArrowRight />
                    // }
                    // rightIconClick={}
                    btntype="sumbit"
                    btnInner={<ArrowRight />}
                    variant={`${styles.inputBox}`}
                  />
                </form>
              </Col>
              <Col>
                <SocialMediaIcon
                  className={`mt-sm-0 mt-4 ${styles.flex_changes}`}
                  AsFollow
                />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
  );
};
export default GetUpdates;
