import React from "react";
import style from "./Following.module.scss";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import personIcon from "../../../../Assets/images/person_icon.jpg";
import { Col, Row } from "react-bootstrap";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Notfound from "../../../Common/NotFound/Notfound";

const Following = (props) => {
  let { data, setSearch, page, totalPage, followUser, type } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getButtonText = (item) => {
    if (type == "self") {
      return "Unfollow";
    } else {
      return "";
    }
  };

  const goToUserProfile = (item) => {
    navigate(`profile/${item?.user?.username}`);
  };

  return (
    <>
      <section className={style.following}>
        <div className={style.searchbar}>
          <>
            <div className="search_input">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Artist, User"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </>
        </div>
        <div className={style.following_section}>
          <div className={style.following_section_arttable}>
            {/* <h5>Art</h5> */}
            <div className={style.following_table}>
              <Row>
                {data.map((item, index) => (
                  <Col xl={3} lg={4} md={4} sm={6} xs={12} key={index}>
                    <div className={style.info_user}>
                      <img
                        src={
                          item.user.image_path
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${item?.user.image_path}`
                            : personIcon
                        }
                        alt="userImg"
                        style={{ cursor: "pointer" }}
                        onClick={() => goToUserProfile(item)}
                      />

                      <div className={style.text_info}>
                        <h6 onClick={() => goToUserProfile(item)}>
                          {item?.user?.name}
                        </h6>
                        <p onClick={() => goToUserProfile(item)}>
                          @{item?.user?.username}
                        </p>
                        {type == "self" && (
                          <CommonBtn
                            role="btn"
                            title={getButtonText(item)}
                            className={`black-btn ${style.btnfollow}`}
                            onClick={() => followUser("unfollow", item)}
                            type={type}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                ))}

                {data.length == 0 && <Notfound />}
              </Row>
              {totalPage > 1 && (
                <CustomPagination
                  className="ms-auto"
                  activePage={page}
                  totalPage={totalPage}
                  maxVisiblePages={5}
                  dispatch={dispatch}
                  componentType={"Profile"}
                  componentTypes={"OtherUserProfile"}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Following;
