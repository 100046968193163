import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Timer = ({ startingTimestamp, type, id }) => {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(timer);
  }, []);

  let timeDifference;
  //   if (startingTimestamp > currentTime) {
  timeDifference = startingTimestamp - currentTime;
  //   } else {
  //     timeDifference = currentTime - startingTimestamp;
  //   }
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  useEffect(() => {
    if (seconds == 0) {
      // alert(1);
      if (type === "live") {
        navigate(`/marketplace/art/${id}`);
      } else if (type === "upcoming") {
        navigate(`/curated-drop/digital-art/live/${id}`);
      }
    } else if (seconds < 0) {
      navigate(-1);
    }
  }, [currentTime]);

  const getTime = () => {
    if (days > 0 && hours > 0 && minutes > 0 && seconds > 0) {
      return `${days}Days ${hours % 24}Hrs ${minutes % 60}Mins ${
        seconds % 60
      }Secs`;
    } else if (days == 0 && hours > 0 && minutes > 0 && seconds > 0) {
      return `${hours % 24}Hrs ${minutes % 60}Mins ${seconds % 60}Secs`;
    } else if (hours == 0 && minutes > 0 && seconds > 0) {
      return `${minutes % 60}Mins ${seconds % 60}Secs`;
    } else if (minutes == 0 && seconds > 0) {
      return `${seconds % 60}Secs`;
    }
  };

  return (
    <>
      {timeDifference > 0 && (
        <>
          <p>{getTime()}</p>
        </>
      )}
    </>
  );
};

export default Timer;
