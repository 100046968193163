import React from "react";
import style from "./Followers.module.scss";
import { Col, Row } from "react-bootstrap";
import CommonCardsecond from "../../../Common/Ui/CommonCardsecond/CommonCardsecond";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import Notfound from "../../../Common/NotFound/Notfound";
import { useDispatch } from "react-redux";

const Followers = (props) => {
  let { data, setSearch, page, totalPage, followUser, type } =
    props;

    const dispatch = useDispatch();

  const getButtonText = (item) => {
    if (type == "self") {
      return "Remove";
    } else {
      return "";
    }
  };

  return (
    <>
      <section className={style.followers}>
        <div className={style.searchbar}>
          <>
            <div className="search_input">
              <div className="search_input_inner">
                <button type="submit" className="search_input_icon">
                  <SearchIcon />
                </button>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Artist, User"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </>
        </div>
        <Row>
          {data.map((item) => (
            <Col lg={4} key={item.id}>
              <CommonCardsecond
                className={style.followerCard}
                classtextfields={style.textfeilds}
                bordergrayBtntitle={getButtonText(item)}
                followUser={followUser}
                item={item}
                type={type}
              />
            </Col>
          ))}
          {data.length == 0 && <Notfound />}
        </Row>
        {totalPage > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPage}
            maxVisiblePages={5}
            dispatch= {dispatch}
            componentTypes={"OtherUserProfile"}
            componentType={"Profile"}


          />
        )}
      </section>
    </>
  );
};

export default Followers;
