import React from "react";
import { Link } from "react-router-dom";
import {
  AppleIcon,
  FBIcon,
  GoogleIcon,
  InstaIcon,
  LinkedinIcon,
  LocationcircleIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../../../../Assets/svgIcons/SVGicon";
import "./SocialMediaIcon.scss";
const SocialMediaIcon = ({
  className,
  AsFollow,
  AsSignUp,
  AsUserFollow,
  AsSignUpSec,
  Asuserprofile,
  Asotherprofile,
  userDetail,
}) => {
  return (
    <>
      {AsFollow && (
        <>
          <ul className={`SocialMedia ${className}`}>
            {/* <li>
            <Link to="#" target="_blank">
              <GoogleIcon />
            </Link>
          </li> */}
            <li>
              <Link to="https://www.youtube.com/@NewArtXOfficial/" target="_blank">
                <YoutubeIcon />
              </Link>
            </li>
            {/* <li>
            <Link to="#" target="_blank">
              <TwitterIcon />
            </Link>
          </li> */}
            <li>
              <Link to="https://www.instagram.com/newartxofficial/" target="_blank">
                <InstaIcon />
              </Link>
            </li>
            <li>
              <Link to="https://www.facebook.com/NewArtXOfficial" target="_blank">
                <FBIcon />
              </Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/company/elephantcanvas/" target="_blank">
                <LinkedinIcon />
              </Link>
            </li>
            {/* <li>
            <Link to="#" target="_blank">
              <AppleIcon />
            </Link>
          </li> */}
          </ul>
        </>
      )}
      {AsSignUp && (
        <>
          <ul className={`SocialMedia ${className}`}>
            <li>
              <Link to="#">
                <AppleIcon />
              </Link>
            </li>
            <li>
              <Link to="#">
                <FBIcon />
              </Link>
            </li>
            <li>
              <Link to="#">
                <GoogleIcon />
              </Link>
            </li>
          </ul>
        </>
      )}

      {AsSignUpSec && (
        <>
          <ul className={`SocialMedia ${className}`}>
            <li>
              <Link to="#">
                <GoogleIcon />
              </Link>
            </li>
            <li>
              <Link to="#">
                <FBIcon />
              </Link>
            </li>
          </ul>
        </>
      )}
      {AsUserFollow && (
        <>
          <ul className={`SocialMedia vertical ${className}`}>
            <li>
              <Link to="#" target="_blank">
                <GoogleIcon />
              </Link>
            </li>
            <li>
              <Link to="#" target="_blank">
                <YoutubeIcon />
              </Link>
            </li>
            <li>
              <Link to="#" target="_blank">
                <TwitterIcon />
              </Link>
            </li>
            <li>
              <Link to="#" target="_blank">
                <InstaIcon />
              </Link>
            </li>
            <li>
              <Link to="#" target="_blank">
                <FBIcon />
              </Link>
            </li>
            <li>
              <Link to="#" target="_blank">
                <AppleIcon />
              </Link>
            </li>
          </ul>
        </>
      )}
      {Asuserprofile && (
        <>
          <ul className={`SocialMedia ${className}`}>
            {userDetail?.social_links?.twitter && (
              <li>
                <Link to={userDetail?.social_links?.twitter} target="_blank">
                  <TwitterIcon />
                </Link>
              </li>
            )}
            {userDetail?.social_links?.facebook && (
              <li>
                <Link to={userDetail?.social_links?.facebook} target="_blank">
                  <FBIcon />
                </Link>
              </li>
            )}
            {/* <li><Link to="#" target="_blank"><BrowsercircleIcon /></Link></li> */}
            {userDetail?.social_links?.instagram && (
              <li>
                <Link to={userDetail?.social_links?.instagram} target="_blank">
                  <InstaIcon />
                </Link>
              </li>
            )}
            {userDetail?.social_links?.linkedin && (
              <li>
                <Link to={userDetail?.social_links?.linkedin} target="_blank">
                  <LinkedinIcon />
                </Link>
              </li>
            )}
          </ul>
        </>
      )}
      {Asotherprofile && (
        <>
          <ul className={`SocialMedia vertical ${className}`}>
            {userDetail?.social_links?.twitter && (
              <li>
                <Link to={userDetail?.social_links?.twitter} target="_blank">
                  <TwitterIcon />
                </Link>
              </li>
            )}
            {userDetail?.social_links?.facebook && (
              <li>
                <Link to={userDetail?.social_links?.facebook} target="_blank">
                  <FBIcon />
                </Link>
              </li>
            )}
            {/* <li>
              <Link to="#" target="_blank">
                <BrowsercircleIcon />
              </Link>
            </li> */}
            {userDetail?.social_links?.instagram && (
              <li>
                <Link to={userDetail?.social_links?.instagram} target="_blank">
                  <InstaIcon />
                </Link>
              </li>
            )}
            {/* {userDetail?.social_links?.linkedin && (
              <li>
                <Link to={userDetail?.social_links?.linkedin} target="_blank">
                  <LocationcircleIcon />
                </Link>
              </li>
            )} */}
            {userDetail?.social_links?.linkedin && (
              <li>
                <Link to={userDetail?.social_links?.linkedin} target="_blank">
                  <LinkedinIcon />
                </Link>
              </li>
            )}
          </ul>
        </>
      )}
    </>
  );
};
export default SocialMediaIcon;
