import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./Wallet_tab.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { addFunds } from "../../../../Features/user/userSlice";
import AddFundsModal from "./AddFundsModal";
import {
  STRIP_MAX_VALUE,
  STRIP_MIN_VALUE,
  getNumberWithComma,
} from "../../../../Helper/common";
import { DECIMAL } from "../../../../Constant/userConstant";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import { useFormik } from "formik";
import * as Yup from "yup";
import ComingSoon from "./SuccessfullyModal/ComingSoon";
import { toasts } from "../../../Common/Toast/Toast";
import { useNavigate } from "react-router-dom";
import NewartxLogo from "../../../../Assets/images/newArtX_Logo_Img.png";

const Wallet_tab = ({ walletBalance, getWalletBalanceHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);
  const loading = useSelector((state) => state?.loading?.loading);
  const [showComingSoon, setShowComingSoon] = useState(false);


  const MakeSchema = Yup.object({
    funds: Yup.string()
      .test("greaterThanZero", "*Amount must be a positive number", (value) => {
        return value != 0;
      })
      .test(
        "not-ending-with-decimal",
        "*Value should not end with a decimal point",
        (value) => {
          if (!isNaN(value) && value !== parseInt(value, 10)) {
            return !/\.+$/.test(value.toString());
          }
          return true;
        }
      )
      .required("*Amount is required")

      .test(
        "notGreaterThanValue",
        "*Value is not accepted",
        (value) => Number(value) <= STRIP_MAX_VALUE
        // (value) => value < PLATFORM_MAX_VALUE
      )

      .test(
        "notSmallerThanValue",
        "*Minimum value 1 allowed",
        (value) => Number(value) >= STRIP_MIN_VALUE
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: MakeSchema,
    initialValues: {
      funds: "",
    },
    onSubmit: (values) => {
      addFundsHandler(values);
    },
  });

  useEffect(() => {
    // Load the Razorpay script dynamically
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async (values) => {
    // Create an instance of Razorpay with options
    const rzp = new window.Razorpay({
      key: process.env.REACT_APP_RAZAOR_PAY, // Replace with your Razorpay Key ID
      amount: Number(formik.values.funds) * 100, // Amount is in currency subunits (paise)
      currency: "INR",
      name: "Newartx", // Your business name
      description: "Testing",
      image: NewartxLogo,
      order_id: values?.id,
      // notes: {
      //   address: "Razorpay Corporate Office",
      // },
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        if (response.razorpay_payment_id) {
          document.body.style.overflow = "visible";
          getWalletBalanceHandler();
          formik.resetForm();
          navigate(`/wallet`);
        }
        // Add any additional logic here after payment success
      },
      retry: {
        enabled: false,
      },
      callback_url: "https://stage.newartx.com/", // Your callback URL
      config: {
        display: {
          hide: [{ method: "paylater" }, { method: "wallet" }],
          preferences: { show_default_blocks: true },
        },
      },
      modal: {
        ondismiss: function (error) {
          document.body.style.overflow = "visible";
          formik.resetForm();
          if (error?.error?.description) {
            toasts.error(error?.error?.description);
            navigate(`/wallet`);
          }
        },
      },
    });
    // Open the payment modal
    rzp.open();
    // Check modal visibility periodically
  };

  const addFundsHandler = async (values) => {
    try {
      let apiParmas = {
        amount: Number(values.funds),
      };
      const response = await dispatch(addFunds(apiParmas));
      let data = response.data.data;
      if (response) {
        setShowAddFundsModal(false);
        handlePayment(data);
      }
    } catch (error) {}
  };

  return (
    <>
      <section className={style.wallet_tab}>
        <Tabs
          defaultActiveKey="balance"
          id="uncontrolled-tab-example"
          className="tablists"
        >
          <Tab eventKey="balance" title="Balance">
            <div className={style.balance_fields}>
              <div className={style.values_balance}>
                <div className={style.text_fields}>
                  <label>Total </label>
                  <h2>
                    <span>₹</span>
                    {walletBalance
                      ? getNumberWithComma(
                          (Number(walletBalance) / DECIMAL).toFixed(2)
                        )
                      : "0.00"}
                  </h2>
                </div>
                <div className={style.btnfunds}>
                  <CommonBtn
                    role="btn"
                    title="Add Funds"
                    className={`black-btn ${style.addfundBtn}`}
                    onClick={() => setShowAddFundsModal(true)}
                  />

                  <CommonBtn
                    role="btn"
                    title="Cash Out"
                    className={`bordred-black ${style.borderBtn}`}
                    onClick={() => setShowComingSoon(true)}
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
        {showAddFundsModal && (
          <AddFundsModal
            modalTitle={"Add Funds"}
            show={showAddFundsModal}
            onHide={() => {
              setShowAddFundsModal(false);
              formik.resetForm();
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <div className={style.funds_add}>
                <div className={style.fund_inputs}>
                  <FormikControls
                    label="Enter the deposit amount"
                    control="input"
                    type="text"
                    name="funds"
                    id="funds"
                    placeholder="Enter amount"
                    variant="fund_input"
                    leftIcon="₹"
                    // leftIcon={getCurrencySymbol(formik.values.currency)}
                    onChange={(e) => {
                      //regex for only digit
                      if (
                        /^\d+(\.\d{0,2})?$/.test(e.target.value) ||
                        e.target.value == ""
                      ) {
                        formik.setFieldValue("funds", e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.funds}
                    formik={formik}
                  />
                </div>
                <div className={style.addbtns}>
                  <CommonBtn
                    role="btn"
                    title="Add"
                    type="submit"
                    className={`btn-yellow-black me-3 ${style.addfundBtn}`}
                    disabled={loading ? true : false}
                  />
                  <CommonBtn
                    role="btn"
                    title="Cancel"
                    type="button"
                    className={`bordred-gray ${style.borderBtn}`}
                    onClick={() => {
                      setShowAddFundsModal(false);
                      formik.resetForm();
                    }}
                  />
                </div>
              </div>
            </form>
          </AddFundsModal>
        )}

        {showComingSoon && (
          <ComingSoon
            show={showComingSoon}
            handleClose={() => setShowComingSoon(false)}
          />
        )}
      </section>
    </>
  );
};

export default Wallet_tab;
