import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import FormikControls from "../../../Common/Ui/Formik/FormikControls";
import { ArrowRight } from "../../../../Assets/svgIcons/SVGicon";
import * as Yup from "yup";
import SocialMediaIcon from "../../../Common/Ui/SocialMediaIcon/SocialMediaIcon";
import styles from "./NextRelease.module.scss";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import ApplyCreateModal from "../../../Common/Ui/ApplyCreateModal/ApplyCreateModal";
import { inputFieldValidation } from "../../../../Constant/formikConstant";
import { parsePhoneNumber } from "react-phone-number-input";
import "yup-phone";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { Createus, emailSubscribe } from "../../../../Features/user/userSlice";
import socket from "../../../../Socket/socket";
import NextReleaseTimer from "../../NextReleaseTimer";

const NextRelease = (props) => {
  const [phoneCountryCode, setPhoneNumberCountryCode] = useState("");
  const [nextReleaseTimestamp, setNextReleaseTimestamp] = useState(null);
  const dispatch = useDispatch();

  const CreateSchema = Yup.object({
    firstname: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*First name should not start or end with a space"
      )
      .matches(
        inputFieldValidation.firstName.regex.expresion,
        inputFieldValidation.firstName.regex.errorMessage
      )
      // .trim("*Name cannot start with a space")
      // .strict(true)
      .min(
        inputFieldValidation.firstName.minLength,
        `*Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .max(
        inputFieldValidation.firstName.maxLength,
        `*Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .required(inputFieldValidation.commonMessage.required("First name"))
      .matches(
        inputFieldValidation.firstName.regex.expresion,
        inputFieldValidation.firstName.regex.errorMessage
      ),
    lastname: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Last name should not start or end with a space"
      )
      .matches(
        inputFieldValidation.lastName.regex.expresion,
        inputFieldValidation.lastName.regex.errorMessage
      )
      // .matches(/^[^\s].*$/, "Last Name cannot start with a space")
      .required(inputFieldValidation.commonMessage.required("Last name"))
      .min(
        inputFieldValidation.lastName.minLength,
        `*Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      )
      .max(
        inputFieldValidation.lastName.maxLength,
        `*Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      ),
    email: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),

    description: Yup.string()
      .min(
        inputFieldValidation.bio.minLength,
        `Must be between ${inputFieldValidation.bio.minLength}-${inputFieldValidation.bio.maxLength} letters`
      )
      .max(
        inputFieldValidation.bio.maxLength,
        `Must be between ${inputFieldValidation.bio.minLength}-${inputFieldValidation.bio.maxLength} letters`
      )
      .required("*Message is required"),
    mobile: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Mobile number"))
      .phone(phoneCountryCode, true, "*Invalid Mobile Number"),
  });

  const emailSubScribeSchema = Yup.object({
    subcribeEmail: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: CreateSchema,
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      description: "",
      mobile: "",
    },
    onSubmit: (values) => {
      let finalVal = Object.assign({ ...values });
      finalVal.firstname = values.firstname;
      finalVal.lastname = values.lastname;
      finalVal.email = values.email;
      finalVal.description = values.description;
      finalVal.mobile = `+${
        parsePhoneNumber(values?.mobile)?.countryCallingCode
      }${parsePhoneNumber(values?.mobile)?.nationalNumber}`;
      // finalVal.countryCode =
      //   "+" + parsePhoneNumber(values?.phoneNumber)?.countryCallingCode;
      CreateUsHandler(finalVal);
      formik.resetForm();
    },
  });

  const emailSubScribeFormik = useFormik({
    validationSchema: emailSubScribeSchema,
    initialValues: {
      subcribeEmail: "",
    },
    onSubmit: (values) => {
      emailSubscribeHandle(values);
    },
  });

  const CreateUsHandler = async (finalVal) => {
    const response = await dispatch(Createus(finalVal));
  };

  useEffect(() => {
    socket.emit("latest_release_time");
    return () => {
      socket.off("latest_release_time");
    };
  }, []);

  socket.on("NFT_LATEST_RELEASE_TIME", (data) => {
    if (data?.start_time) {
      setNextReleaseTimestamp(JSON.parse(data?.start_time));
    } else {
      setNextReleaseTimestamp(null);
    }
  });

  const [createModal, setCreateModal] = useState(false);

  const emailSubscribeHandle = async (values) => {
    let data = {
      email: values.subcribeEmail,
    };
    try {
      const response = await dispatch(emailSubscribe(data));
      emailSubScribeFormik.resetForm();
    } catch (error) {}
  };
  return (
    <>
      <section className={`${styles.NextRelease} ${props.className || ""}`}>
        <Container>
          {props.releaseHeading && (
            <>
              {nextReleaseTimestamp != null &&
                nextReleaseTimestamp > new Date().getTime() && (
                  <h2
                    className={
                      props.releaseHeadingGradient
                        ? `${styles.NextReleaseHead} ${styles.NextReleaseHeadGradient}`
                        : styles.NextReleaseHead
                    }
                  >
                    {/* {props.releaseHeadingText} */}
                    Next Release :{" "}
                    <span>
                      <NextReleaseTimer
                        startingTimestamp={nextReleaseTimestamp}
                      />
                    </span>
                  </h2>
                )}
            </>
          )}
          <Row>
            <Col md={6}>
              {props.NextRelaseSubscription && (
                <div className={styles.NextReleaseLeft}>
                  <h3>Always Stay Updated, Never Miss Out</h3>
                  <p>
                    Subscribe to our exclusive drop list and be the first to
                    know about upcoming drops.
                  </p>
                  <form onSubmit={emailSubScribeFormik.handleSubmit}>
                    <FormikControls
                      className="input_border"
                      control="input"
                      type="email"
                      name="subcribeEmail"
                      id="subcribeEmail"
                      placeholder="Your Email Address"
                      onChange={emailSubScribeFormik.handleChange}
                      onBlur={emailSubScribeFormik.handleBlur}
                      value={emailSubScribeFormik.values.subcribeEmail}
                      formik={emailSubScribeFormik}
                      // rightIcon={
                      //     <ArrowRight />
                      // }
                      // rightIconClick={}
                      btntype="sumbit"
                      btnInner={<ArrowRight />}
                      variant={`${styles.inputBox}`}
                    />
                  </form>
                </div>
              )} 
              {props.ApplyToCreate && (
                <div className={styles.ApplyToCreate} ref={props.divCRef}>
                  <h2>Apply To Create.</h2>
                  <p>We’re here to answer any of your questions</p>
                  <div className={styles.form_fields}>
                    <form onSubmit={formik.handleSubmit}>
                      <Row>
                        <Col lg={6}>
                          <FormikControls
                            className="input_border"
                            control="input"
                            type="text"
                            name="firstname"
                            placeholder="First Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstname}
                            formik={formik}
                            variant={`${styles.inputBox}`}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormikControls
                            className="input_border"
                            control="input"
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastname}
                            formik={formik}
                            variant={`${styles.inputBox}`}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormikControls
                            control="phone"
                            classinput="phone_border"
                            countryCallingCodeEditable={false}
                            type="text"
                            placeholder="Your Mobile Number"
                            name="mobile"
                            maxlength="20"
                            international
                            onBlur={formik.handleBlur}
                            formik={formik}
                            value={formik.values.mobile}
                            onChange={async (value) => {
                              await formik.setFieldTouched("mobile", true);
                              if (value) {
                                setPhoneNumberCountryCode(
                                  parsePhoneNumber(value)?.country
                                );
                              }
                              formik.setFieldValue("mobile", value);
                            }}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormikControls
                            className="input_border"
                            control="input"
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Your Email Address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            formik={formik}
                            variant={`${styles.inputBox}`}
                          />
                        </Col>
                        <Col lg={12}>
                          <FormikControls
                            classField="input_border"
                            control="textarea"
                            name="description"
                            type="description"
                            id="description"
                            placeholder="Your Message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            formik={formik}
                          />
                        </Col>
                      </Row>
                      <CommonBtn
                        role="btn"
                        title="Submit"
                        className={styles.btn_sizing}
                      />
                    </form>
                  </div>
                </div>
              )}
            </Col>
            <Col md={6} className=" ps-md-0">
              <div className={styles.NextReleaseRight}>
                <h2 className={styles.NextReleaseRightheading}>Follow Us.</h2>
                <SocialMediaIcon
                  AsFollow
                  className={styles.NextReleaseRightBox}
                />
                {/* <div className={styles.NextReleaseRightBox}>
                  <h2 className="pt-0">Call Us.</h2>
                  <p className={styles.subDetailText}>
                    +91 98345 78333 / 34 / 35 / 36
                  </p>
                </div>
                <div className={styles.NextReleaseRightBox}>
                  <h2>Our Address.</h2>
                  <p className={styles.subDetailText}>
                    B 401-402, Neelam Centre, Worli Mumbai - 400030 INDIA
                  </p>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ApplyCreateModal
        show={createModal}
        onHide={() => setCreateModal(false)}
      />
    </>
  );
};

NextRelease.defaultProps = {
  releaseHeading: true,
  NextRelaseSubscription: true,
  ApplyToCreate: false,
  releaseHeadingGradient: true,
  releaseHeadingText: (
    <>
      Next Release : <span>01Hrs.34Mins.81Secs</span>
    </>
  ),
};

export default NextRelease;
