import React, { useEffect, useState } from "react";
import style from "./Accountinformation.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikControls from "../../../../Common/Ui/Formik/FormikControls";
import Commonprofileup from "../../../../Common/Ui/Commonprofileup/Commonprofileup";
import {
  FBIcon,
  InfoIcom,
  InstaIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../../../../Assets/svgIcons/SVGicon";
import CommonBtn from "../../../../Common/Ui/CommonBtn/CommonBtn";
import { inputFieldValidation } from "../../../../../Constant/formikConstant";
import { parsePhoneNumber } from "react-phone-number-input";
import "yup-phone";
import { fileUpload } from "../../../../../Helper/FileUpload";
import { handlePaste } from "../../../../../Helper/common";
import socket from "../../../../../Socket/socket";
import { useDebounce } from "../../../../../customHooks/useDebounce";
import { useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { toasts } from "../../../../Common/Toast/Toast";
import personIcon from "../../../../../Assets/images/person_icon.jpg";
import { USER_TYPE } from "../../../../../Constant/userConstant";
import TextEditor from "../TextEditor/TextEditor";

const Accountinformation = (props) => {
  let { updateProfile, profileData } = props;
  const [phoneCountryCode, setPhoneNumberCountryCode] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestedUsername, setSuggestedUsername] = useState([]);
  const [length, setLength] = useState(0);
  const [usernameError, setUsernameError] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [preview, setPreview] = useState("");
  const userDetail = useSelector((state) => state?.users?.profileData);
  const userType = useSelector((state) => state?.persist?.userType);

  let initialValue = {
    firstName: profileData?.first_name ? profileData?.first_name : "",
    middleName: profileData?.middle_name ? profileData?.middle_name : "",
    lastName: profileData?.last_name ? profileData?.last_name : "",
    username: profileData?.username ? profileData?.username : "",
    makepublicBio: profileData?.bio_setting,
    makepublicName: profileData?.name_flag,
    bio: profileData?.bio ? profileData?.bio : "",
    email: profileData?.email ? profileData?.email : "",
    phoneNumber: "",
    sociallinks: "",
    makepublicInsta: profileData?.social_links?.isInstagramPublic,
    makepublicFacebook: profileData?.social_links?.isFacebookPublic,
    makepublicTwitter: profileData?.social_links?.isTwitterPublic,
    makepublicLinkdin: profileData?.social_links?.isLinkedinPublic,
    file: profileData?.image_path
      ? `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.image_path}`
      : "",
    facebookUrl: profileData?.social_links?.facebook
      ? profileData?.social_links?.facebook
      : "",
    instagramUrl: profileData?.social_links?.instagram
      ? profileData?.social_links?.instagram
      : "",
    twitterUrl: profileData?.social_links?.twitter
      ? profileData?.social_links?.twitter
      : "",
    linkedinUrl: profileData?.social_links?.linkedin
      ? profileData?.social_links?.linkedin
      : "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*First name should not start or end with a space"
      )
      .min(
        inputFieldValidation.firstName.minLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .max(
        inputFieldValidation.firstName.maxLength,
        `Must be between ${inputFieldValidation.firstName.minLength}-${inputFieldValidation.firstName.maxLength} letters`
      )
      .required(inputFieldValidation.commonMessage.required("First name"))

      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed"),
    middleName: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Middle name should not start or end with a space"
      )
      .min(
        inputFieldValidation.middleName.minLength,
        `Must be between ${inputFieldValidation.middleName.minLength}-${inputFieldValidation.middleName.maxLength} letters`
      )
      .max(
        inputFieldValidation.middleName.maxLength,
        `Must be between ${inputFieldValidation.middleName.minLength}-${inputFieldValidation.middleName.maxLength} letters`
      )
      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed"),
    lastName: Yup.string()
      .matches(
        inputFieldValidation.commonExpression.spaceStartAndEndNotAllowed,
        "*Last name should not start or end with a space"
      )
      .required(inputFieldValidation.commonMessage.required("Last name"))
      .min(
        inputFieldValidation.lastName.minLength,
        `Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      )
      .max(
        inputFieldValidation.lastName.maxLength,
        `Must be between ${inputFieldValidation.lastName.minLength}-${inputFieldValidation.lastName.maxLength} letters`
      )

      .matches(/^[a-zA-Z\s.]+$/, "*Only letters, spaces, and dots are allowed"),
    username: Yup.string()
      .matches(
        inputFieldValidation.username.regex.spaceExpression,
        inputFieldValidation.username.regex.spaceErrorMessage
      )
      .required(inputFieldValidation.commonMessage.required("Username"))
      .matches(
        inputFieldValidation.username.regex.expresion,
        inputFieldValidation.username.regex.errorMessage
      )
      .min(
        inputFieldValidation.username.minLength,
        `Must be between ${inputFieldValidation.username.minLength}-${inputFieldValidation.username.maxLength} letters`
      )
      .max(
        inputFieldValidation.username.maxLength,
        `Must be between ${inputFieldValidation.username.minLength}-${inputFieldValidation.username.maxLength} letters`
      )
      .test("maxTwoDot", "*Maximum two periods(.) are allowed", (value) => {
        if (value == undefined || value?.split(".").length > 3) {
          return false;
        }
        return true;
      })
      .test(
        "noStartOrEndDot",
        "Periods (.) are not allowed at the start or end",
        (value) => {
          if (value?.startsWith(".") || value?.endsWith(".")) {
            return false;
          }
          return true;
        }
      ),
    makepublicInsta: Yup.boolean(),
    makepublicFacebook: Yup.boolean(),
    makepublicTwitter: Yup.boolean(),
    makepublicLinkdin: Yup.boolean(),
    makepublicBio: Yup.boolean(),
    makepublicName: Yup.boolean(),
    email: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Email"))
      .matches(
        inputFieldValidation.email.regexForSingup.expresionForSingup,
        inputFieldValidation.email.regexForSingup.errorMessageForSingup
      ),
    phoneNumber: Yup.string()
      .required(inputFieldValidation.commonMessage.required("Phone number"))
      .phone(phoneCountryCode, true, "*Invalid phone number"),
    bio: Yup.string().test(
      `minMaxLengthCheck`,
      `*Bio must be between ${inputFieldValidation.bio.minLength}-${
        userType === USER_TYPE.ARTIST
          ? inputFieldValidation.bio.artistMaxLength
          : inputFieldValidation.bio.userMaxLength
      }`,
      (value) => {
        const { minLength, userMaxLength, artistMaxLength } =
          inputFieldValidation.bio;
        const maxLength =
          userType === USER_TYPE.ARTIST ? artistMaxLength : userMaxLength;
        return length >= minLength && length <= maxLength;
      }
    ),
    facebookUrl: Yup.string()
      .url("Invalid Facebook URL")
      .test("is-facebook-url", "Invalid Facebook URL", (value) => {
        if (!value) return true; // Allow empty value
        // Validate the starting domain for Facebook URLs and check for consecutive slashes
        const regex = /^(https?:\/\/)?(www\.)?facebook\.com\/(?:[^\/]+\/?)*$/;
        return regex.test(value);
      }),
    instagramUrl: Yup.string()
      .url("Invalid Instagram URL")
      .test("is-instagram-url", "Invalid Instagram URL", (value) => {
        if (!value) return true; // Allow empty value
        // Validate the starting domain for Instagram URLs and check for consecutive slashes
        const regex = /^(https?:\/\/)?(www\.)?instagram\.com\/(?:[^\/]+\/?)*$/;
        return regex.test(value);
      }),
    twitterUrl: Yup.string()
      .url("Invalid Twitter URL")
      .test("is-twitter-url", "Invalid Twitter URL", (value) => {
        if (!value) return true; // Allow empty value
        // Validate the starting domain for Twitter URLs and check for consecutive slashes
        const regex = /^(https?:\/\/)?(www\.)?twitter\.com\/(?:[^\/]+\/?)*$/;
        return regex.test(value);
      }),
    linkedinUrl: Yup.string()
      .url("Invalid LinkedIn URL")
      .test("is-linkedin-url", "Invalid LinkedIn URL", (value) => {
        if (!value) return true; // Allow empty value
        // Validate the starting domain for LinkedIn URLs and check for consecutive slashes
        const regex =
          /^(https?:\/\/)?(?:[a-z]+\.)?(linkedin\.com\/(?:[^\/]+\/?)*)$/;
        return regex.test(value);
      }),
    file: Yup.mixed()
      .test(
        "fileFormat",
        "*File must be of type jpeg, jpg , png",
        function (value) {
          if (typeof value == "object") {
            if (!value) return true; // Allow empty value
            const allowedExtensions = [".jpg", ".jpeg", ".png", ".JPG"];

            const fileExtension = value.name
              .substring(value.name.lastIndexOf("."))
              .toLowerCase();
            return allowedExtensions.includes(fileExtension);
          } else {
            return true;
          }
        }
      )
      .test("fileSize", "File size is too large", function (value) {
        if (typeof value == "object") {
          if (!value) return true; // Allow empty value
          const maxSizeInBytes = 5 * 1024 * 1024; // 2MB (adjust as needed)
          return value.size <= maxSizeInBytes;
        } else {
          return true;
        }
      }),
  });
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateProfile(values);
    },
  });

  useEffect(() => {
    if (debouncedSearchTerm) {
      socket.emit("get-username", { data: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    // It sets up a cleanup function to remove the event listener for the "get-username" socket event.
    return () => {
      socket.off("get-username");
    };
  }, []);

  socket.on("get-username", (data) => {
    // If the data indicates that the username exists, set the suggested username and error accordingly.
    if (data?.is_exist) {
      setSuggestedUsername(JSON.parse(data?.data));
      setUsernameError(data?.is_exist);
    } else {
      // If the data indicates that the username does not exist, set the suggested username and error accordingly.
      setSuggestedUsername(JSON.parse(data?.data));
      setUsernameError(data?.is_exist);
    }
  });

  useEffect(() => {
    formik.setFieldValue(
      "phoneNumber",
      `${profileData?.country_code}${profileData?.mobile}`
    );
    if (profileData?.image_path) {
      setPreview(
        `${process.env.REACT_APP_IMG_BASE_URL}${profileData?.image_path}`
      );
    }
    formik.setFieldValue("bio", profileData?.bio ? profileData?.bio : "");
    formik.setFieldValue(
      "firstName",
      profileData?.first_name ? profileData?.first_name : ""
    );
    formik.setFieldValue(
      "middleName",
      profileData?.middle_name ? profileData?.middle_name : ""
    );
    formik.setFieldValue(
      "lastName",
      profileData?.last_name ? profileData?.last_name : ""
    );
    formik.setFieldValue("email", profileData?.email ? profileData?.email : "");
    formik.setFieldValue(
      "username",
      profileData?.username ? profileData?.username : ""
    );
  }, [profileData]);

  const makePublic = (e, name) => {
    formik.setFieldValue(name, e.target.checked);
  };

  const handleKeyDownTextArea = (event) => {
    // Prevent Enter key press
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleCopy = (text, result) => {
    if (result) {
      toasts.success("Text copied to clipboard");
    } else {
      toasts.error("Failed to copy text");
    }
  };

  const returnDisableStatement = (type) => {
    // Check if profileData exists and if bio_setting is 1 (private) and if type is "make public only".
    if (profileData?.bio_setting === 1 && type == "make public only") {
      return "Make private request already sent to admin";
    }
    // Check if profileData exists and if bio_setting is 0 (public) and if type is "make public only".
    else if (profileData?.bio_setting === 0 && type == "make public only") {
      return "Make public request already sent to admin";
    }
    // Check if profileData exists and if bio_setting is 1 (private) and if type is "make public and edit".
    if (profileData?.bio_setting === 1 && type == "make public and edit") {
      return "Edit and make private request already sent to admin";
    }
    // Check if profileData exists and if bio_setting is 0 (public) and if type is "make public and edit".
    else if (profileData?.bio_setting === 0 && type == "make public and edit") {
      return "Edit and make public request already sent to admin";
    }
  };

  return (
    <>
      <section className={style.account_information}>
        <form onSubmit={formik.handleSubmit}>
          <div className={style.userImage}>
            <Commonprofileup
              className="accountUpload"
              texttitle="Update Photo"
              imageUp={preview ? preview : personIcon}
              id="file"
              name="file"
              onBlur={formik.handleBlur}
              onChange={(event) => {
                fileUpload.yupValidationImage(
                  event,
                  formik,
                  setPreview,
                  "file"
                );
              }}
              disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
              title={preview == "" ? "No file choosen" : ""}
            />
            {formik?.touched.file && (
              <p className="error_message">{formik?.errors.file}</p>
            )}
            <h4 className="size_tag">Max accepted size 5 MB</h4>
          </div>
          <div className={style.displayfield}>
            <h6>Display Name</h6>
            <div className={style.input_check}>
              {userDetail?.user_type == USER_TYPE.USER && (
                <FormikControls
                  className="checkboxInner"
                  control="checkbox"
                  label="Make public"
                  name="makepublicName"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={(e) => makePublic(e, "makepublicName")}
                  checked={formik.values.makepublicName}
                />
              )}
              <FormikControls
                variant={
                  formik?.errors["firstName"]
                    ? "border_input error_space"
                    : "border_input"
                }
                control="input"
                label="First Name"
                type="text"
                name="firstName"
                formik={formik}
                placeholder="Enter First Name"
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                disabled={
                  profileData?.first_middle_last_input_disabled ||
                  userDetail?.user_type == USER_TYPE.ANONYMOUS
                }
                validationtitle={
                  profileData?.first_middle_last_input_disabled && (
                    <>
                      <InfoIcom /> Edit request already sent to admin
                    </>
                  )
                }
              />
            </div>
            <FormikControls
              variant={
                formik?.errors["middleName"]
                  ? "border_input error_space"
                  : "border_input"
              }
              control="input"
              label="Middle Name (Optional)"
              type="middleName"
              name="middleName"
              formik={formik}
              placeholder="Enter Middle Name"
              onBlur={formik.handleBlur}
              value={formik.values.middleName}
              onChange={formik.handleChange}
              disabled={
                profileData?.first_middle_last_input_disabled ||
                userDetail?.user_type == USER_TYPE.ANONYMOUS
              }
              validationtitle={
                profileData?.first_middle_last_input_disabled && (
                  <>
                    <InfoIcom /> Edit request already sent to admin
                  </>
                )
              }
            />
            <FormikControls
              variant={
                formik?.errors["lastName"]
                  ? "border_input error_space"
                  : "border_input"
              }
              control="input"
              label="Last Name"
              type="text"
              name="lastName"
              formik={formik}
              placeholder="Enter Last Name"
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              disabled={
                profileData?.first_middle_last_input_disabled ||
                userDetail?.user_type == USER_TYPE.ANONYMOUS
              }
              validationtitle={
                profileData?.first_middle_last_input_disabled && (
                  <>
                    <InfoIcom /> Edit request already sent to admin
                  </>
                )
              }
            />
          </div>
          <FormikControls
            variant="input_spaces"
            className="input_border"
            control="input"
            label="Username"
            type="text"
            name="username"
            formik={formik}
            placeholder="Enter Username"
            onBlur={formik.handleBlur}
            value={formik.values.username}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              formik.setFieldValue("username", e.target.value);
              if (e.target.value === "") {
                setUsernameError(false);
                setSuggestedUsername([]);
              }
            }}
            disabled={
              profileData?.userName_input_disabled ||
              userDetail?.user_type == USER_TYPE.ANONYMOUS
            }
            validationtitle={
              profileData?.userName_input_disabled && (
                <>
                  <InfoIcom /> Edit request already sent to admin
                </>
              )
            }
          />
          <div>
            <p style={{ color: "red" }}>
              {usernameError && "*Username already taken"}
            </p>
          </div>
          <h6 className={style.info_available}>
            {suggestedUsername.length > 0 && (
              <>
                Available: {}
                {suggestedUsername.map((name, index) => (
                  <>
                    <span
                      key={name}
                      onClick={() => {
                        formik.setFieldValue("username", name);
                        setUsernameError(false);
                      }}
                    >
                      <a>{name}</a>
                    </span>
                    , {/* <br /> */}
                  </>
                ))}
              </>
            )}
          </h6>
          <CopyToClipboard
            text={`${process.env.REACT_APP_USER_PROFILE}Other-userprofile/${profileData?.id}`}
            onCopy={handleCopy}
          >
            <p className={style.profile_url}>
              Profile URL:{" "}
              {`${process.env.REACT_APP_USER_PROFILE}Other-userprofile/${profileData?.id}`}
            </p>
          </CopyToClipboard>
          <div className={`mb-25 ${style.input_check}`}>
            {!formik.errors["bio"] &&
              !profileData?.bio_public_input_disabled && (
                <FormikControls
                  className="checkbox_input"
                  control="checkbox"
                  label="Make public"
                  name="makepublicBio"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={(e) => makePublic(e, "makepublicBio")}
                  checked={formik.values.makepublicBio}
                  disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
                />
              )}
            {/* <div className={style.bio_text}> */}
            {/* <FormikControls
                className="input_spaces mb-0"
                classField="input_border"
                control="textarea"
                label="Bio"
                type="text"
                name="bio"
                formik={formik}
                placeholder="Enter Bio"
                value={formik.values.bio}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  if (formik.errors["bio"]) {
                    formik.setFieldValue("makepublicBio", false);
                  }
                  formik.setFieldValue("bio", e.target.value);
                }}
                maxLength={
                  userType === USER_TYPE.ARTIST
                    ? inputFieldValidation.bio.artistMaxLength
                    : inputFieldValidation.bio.userMaxLength
                }
                onKeyDown={handleKeyDownTextArea}
                onPaste={(event) =>
                  handlePaste(
                    event,
                    formik,
                    "bio",
                    userType === USER_TYPE.ARTIST
                      ? inputFieldValidation.bio.artistMaxLength
                      : inputFieldValidation.bio.userMaxLength
                  )
                }
                disabled={
                  profileData?.bio_input_disabled ||
                  userDetail?.user_type == USER_TYPE.ANONYMOUS
                }
                validationtitle={
                  (profileData?.bio_input_disabled ||
                    profileData?.bio_public_input_disabled) && (
                    <>
                      <InfoIcom />
                      {profileData?.bio_input_disabled &&
                      profileData?.bio_public_input_disabled
                        ? returnDisableStatement("make public and edit")
                        : profileData?.bio_input_disabled
                        ? " Edit request already sent to admin"
                        : returnDisableStatement("make public only")}
                    </>
                  )
                }
              /> */}
            <TextEditor
              className={"input_spaces mb-3"}
              label={"Bio"}
              id={"bio"}
              name={"bio"}
              placeholder="Enter Bio"
              formik={formik}
              onKeyDown={handleKeyDownTextArea}
              setLength={setLength}
              onPaste={(event) => handlePaste(event, formik, "bio")}
              readOnly={
                profileData?.bio_input_disabled ||
                userDetail?.user_type == USER_TYPE.ANONYMOUS
              }
              maxLength={
                userType === USER_TYPE.ARTIST
                  ? inputFieldValidation.bio.artistMaxLength
                  : inputFieldValidation.bio.userMaxLength
              }
              validationtitle={
                (profileData?.bio_input_disabled ||
                  profileData?.bio_public_input_disabled) && (
                  <>
                    <InfoIcom />
                    {profileData?.bio_input_disabled &&
                    profileData?.bio_public_input_disabled
                      ? returnDisableStatement("make public and edit")
                      : profileData?.bio_input_disabled
                      ? " Edit request already sent to admin"
                      : returnDisableStatement("make public only")}
                  </>
                )
              }
            />
            {!profileData?.bio_input_disabled && (
              <span className="infoBio">
                {length}/
                {userType === USER_TYPE.ARTIST
                  ? inputFieldValidation.bio.artistMaxLength
                  : inputFieldValidation.bio.userMaxLength}
              </span>
            )}
          </div>
          {/* </div> */}
          <FormikControls
            variant="input_spaces"
            className="input_border"
            control="input"
            label="Email"
            type="text"
            name="email"
            formik={formik}
            placeholder="Enter Email"
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            disabled={
              profileData?.email_input_disabled ||
              userDetail?.user_type == USER_TYPE.ANONYMOUS
            }
            validationtitle={
              profileData?.email_input_disabled && (
                <>
                  <InfoIcom /> Edit request already sent to admin
                </>
              )
            }
          />

          <FormikControls
            label="Mobile Number"
            control="phone"
            classinput="phone_border"
            countryCallingCodeEditable={false}
            type="text"
            variant="input_number"
            placeholder="Enter phone number"
            name="phoneNumber"
            maxlength="20"
            international
            formik={formik}
            value={formik.values.phoneNumber}
            onChange={async (value) => {
              await formik.setFieldTouched("phoneNumber", true);
              if (value) {
                setPhoneNumberCountryCode(parsePhoneNumber(value)?.country);
              }
              formik.setFieldValue("phoneNumber", value);
            }}
            disabled={
              profileData?.mobile_input_disabled ||
              userDetail?.user_type == USER_TYPE.ANONYMOUS
            }
            validationtitle={
              profileData?.mobile_input_disabled && (
                <>
                  <InfoIcom /> Edit request already sent to admin
                </>
              )
            }
          />
          <div className={style.input_check}>
            {!formik.errors["facebookUrl"] &&
              formik.values.facebookUrl != "" &&
              !profileData?.is_facebook_public_input_disabled && (
                <FormikControls
                  className="checkbox_input"
                  control="checkbox"
                  label="Make public"
                  name="makepublicFacebook"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={(e) => makePublic(e, "makepublicFacebook")}
                  checked={formik.values.makepublicFacebook}
                  disabled={
                    profileData?.is_facebook_public_input_disabled ||
                    userDetail?.user_type == USER_TYPE.ANONYMOUS
                  }
                />
              )}
            <FormikControls
              variant="input_spaces"
              className="input_border"
              control="input"
              label="Facebook Link"
              type="text"
              name="facebookUrl"
              leftIcon={<FBIcon />}
              formik={formik}
              placeholder="Enter Facebook Link"
              onBlur={formik.handleBlur}
              value={formik.values.facebookUrl}
              onChange={(e) => {
                if (formik.errors["facebookUrl"]) {
                  formik.setFieldValue("makepublicFacebook", false);
                }
                formik.setFieldValue("facebookUrl", e.target.value);
              }}
              validationtitle={
                profileData?.is_facebook_public_input_disabled && (
                  <>
                    <InfoIcom /> Make{" "}
                    {profileData?.social_links?.isFacebookPublic == 0
                      ? "public"
                      : "private"}{" "}
                    request already sent to admin
                  </>
                )
              }
              disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
            />
          </div>
          <div className={style.input_check}>
            {!formik.errors["instagramUrl"] &&
              formik.values.instagramUrl != "" &&
              !profileData?.is_instagram_public_input_disabled && (
                <FormikControls
                  className="checkbox_input"
                  control="checkbox"
                  label="Make public"
                  name="makepublicInsta"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={(e) => makePublic(e, "makepublicInsta")}
                  checked={formik.values.makepublicInsta}
                  disabled={
                    profileData?.is_instagram_public_input_disabled ||
                    userDetail?.user_type == USER_TYPE.ANONYMOUS
                  }
                />
              )}
            <FormikControls
              variant="input_spaces"
              className="input_border"
              control="input"
              label="Instagram Link"
              type="text"
              name="instagramUrl"
              leftIcon={<InstaIcon />}
              //   rightIcon={<CloseIcon />}
              formik={formik}
              placeholder="Enter Instagram Link"
              onBlur={formik.handleBlur}
              value={formik.values.instagramUrl}
              onChange={(e) => {
                if (formik.errors["instagramUrl"]) {
                  formik.setFieldValue("makepublicInsta", false);
                }
                formik.setFieldValue("instagramUrl", e.target.value);
              }}
              validationtitle={
                profileData?.is_instagram_public_input_disabled && (
                  <>
                    <InfoIcom /> Make{" "}
                    {profileData?.social_links?.isInstagramPublic == 0
                      ? "public"
                      : "private"}{" "}
                    request already sent to admin
                  </>
                )
              }
              disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
            />
          </div>

          <div className={style.input_check}>
            {!formik.errors["twitterUrl"] &&
              formik.values.twitterUrl != "" &&
              !profileData?.is_twitter_public_input_disabled && (
                <FormikControls
                  className="checkbox_input"
                  control="checkbox"
                  label="Make public"
                  name="makepublicTwitter"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={(e) => makePublic(e, "makepublicTwitter")}
                  checked={formik.values.makepublicTwitter}
                  disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
                />
              )}
            <FormikControls
              variant="input_spaces"
              className="input_border"
              control="input"
              label="Twitter Link"
              type="text"
              name="twitterUrl"
              leftIcon={<TwitterIcon />}
              //   rightIcon={<CloseIcon />}
              formik={formik}
              placeholder="Enter Twitter Link"
              onBlur={formik.handleBlur}
              value={formik.values.twitterUrl}
              // onChange={formik.handleChange}
              onChange={(e) => {
                if (formik.errors["twitterUrl"]) {
                  formik.setFieldValue("makepublicTwitter", false);
                }
                formik.setFieldValue("twitterUrl", e.target.value);
              }}
              validationtitle={
                profileData?.is_twitter_public_input_disabled && (
                  <>
                    <InfoIcom /> Make{" "}
                    {profileData?.social_links?.isTwitterPublic == 0
                      ? "public"
                      : "private"}{" "}
                    request already sent to admin
                  </>
                )
              }
              disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
            />
          </div>
          <div className={style.input_check}>
            {!formik.errors["linkedinUrl"] &&
              formik.values.linkedinUrl != "" &&
              !profileData?.is_linkedin_public_input_disabled && (
                <FormikControls
                  className="checkbox_input"
                  control="checkbox"
                  label="Make public"
                  name="makepublicLinkdin"
                  formik={formik}
                  onBlur={formik.handleBlur}
                  onChange={(e) => makePublic(e, "makepublicLinkdin")}
                  checked={formik.values.makepublicLinkdin}
                  disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
                />
              )}
            <div className={style.social_media}>
              <FormikControls
                variant="input_spaces"
                className="input_border"
                control="input"
                label="Linkedin Link"
                type="text"
                name="linkedinUrl"
                leftIcon={<LinkedinIcon />}
                //   rightIcon={<CloseIcon />}
                formik={formik}
                placeholder="Enter Linkedin Link"
                onBlur={formik.handleBlur}
                value={formik.values.linkedinUrl}
                // onChange={formik.handleChange}
                onChange={(e) => {
                  if (formik.errors["linkedinUrl"]) {
                    formik.setFieldValue("makepublicLinkdin", false);
                  }
                  formik.setFieldValue("linkedinUrl", e.target.value);
                }}
                validationtitle={
                  profileData?.is_linkedin_public_input_disabled && (
                    <>
                      <InfoIcom /> Make{" "}
                      {profileData?.social_links?.isLinkedinPublic == 0
                        ? "public"
                        : "private"}{" "}
                      request already sent to admin
                    </>
                  )
                }
                disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
              />
            </div>
          </div>

          <div className={style.btnsave}>
            <CommonBtn
              role="btn"
              className={`black-btn ${style.btnsize}`}
              title="Save Changes"
              disabled={userDetail?.user_type == USER_TYPE.ANONYMOUS}
            />
          </div>
        </form>
      </section>
    </>
  );
};

export default Accountinformation;
