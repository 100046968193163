import React from "react";
import style from "./Userdashboard.module.scss";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Banner from "../../../Assets/images/profile-bg.png";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import Carddetails from "../../Private/Userprofile/Carddetails/Carddetails";
import Transactions from "./Component/TrasactionTabComponents/Transactions";
import Couponstab from "./Component/Couponstab";
import Othertransactions from "./Component/Othertransactions";
import Offertab from "./Component/Offertab";
import { useDispatch, useSelector } from "react-redux";
import Earnings from "./Earnings/Earnings";
import Activecollection from "./Activecollection/Activecollection";
import Activedigitalart from "./Activedigitalart/Activedigitalart";
// import Accounthistory from "./Accounthistory/Accounthistory";
import { setDashboardActiveTab } from "../../../Features/user/userSlice";
import { useSearchParams } from "react-router-dom";
import { USER_TYPE } from "../../../Constant/userConstant";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Helmet } from "react-helmet";

const   Userdashboard = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state?.persist?.userType);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const activeTab = useSelector((state) => state?.users?.activeTab);
  const [searchParams, setSearchParams] = useSearchParams();
  // const [activeTab, setActiveTab] = useState("offers");
  const userDetail = useSelector((state) => state?.users?.profileData);

  const onTabChange = (e) => {
    dispatch(setDashboardActiveTab(e));
    setSearchParams({ page: 1 });
  };

  return (
    <>
      <section className={style.user_dashboard}>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
        <CommonHero
          backgroundImg={
            userDetail?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
              : CoverImage
          }
        />
        <Carddetails tab={"dashboard"} />
        <div className={style.user_dashboard_section}>
          <Container>
            <div className={style.tablist}>
              <Tab.Container
                id="left-tabs-example"
                className="tabvertical"
                activeKey={activeTab}
                onSelect={onTabChange}
              >
                <Row>
                  <Col xl={2} lg={3} md={12}>
                    <Nav variant="pills" className="nav_tabslist">
                      {/* user dashboardlist */}
                      <Nav.Item>
                        <Nav.Link eventKey="offers">
                          Transaction Activity
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="transactions">
                          Transactions
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="coupons">Coupons</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="othertransactions">
                          Other Transactions
                        </Nav.Link>
                      </Nav.Item>

                      {/* Artist dashboardlist */}
                      {userType === USER_TYPE.ARTIST && (
                        <Nav.Item>
                          <Nav.Link eventKey="earnings">Earnings</Nav.Link>
                        </Nav.Item>
                      )}
                      {userType === USER_TYPE.ARTIST && (
                        <Nav.Item>
                          <Nav.Link eventKey="drops">Drops</Nav.Link>
                        </Nav.Item>
                      )}
                      {userType === USER_TYPE.ARTIST && (
                        <Nav.Item>
                          <Nav.Link eventKey="digitalart">Digital Art</Nav.Link>
                        </Nav.Item>
                      )}
                      {/* {userType === 2 && (
                        <Nav.Item>
                          <Nav.Link eventKey="accounthistory">
                            Account History
                          </Nav.Link>
                        </Nav.Item>
                      )} */}
                    </Nav>
                  </Col>
                  <Col xl={10} lg={9} md={12}>
                    <Tab.Content>
                      {/* user dashboardlist */}
                      <Tab.Pane eventKey="offers">
                        {activeTab == "offers" && (
                          <Offertab ArtistId={ArtistId} />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="transactions">
                        {activeTab == "transactions" && (
                          <Transactions ArtistId={ArtistId} />
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="coupons">
                        {activeTab == "coupons" && (
                          <Couponstab ArtistId={ArtistId} />
                        )}
                      </Tab.Pane>
                      {/* This tab can be used further */}
                      <Tab.Pane eventKey="othertransactions">
                        {activeTab == "othertransactions" && (
                          <Othertransactions />
                        )}
                      </Tab.Pane>

                      {/* Artist dashboardlist */}
                      <Tab.Pane eventKey="earnings">
                        {activeTab == "earnings" &&
                          userType === USER_TYPE.ARTIST && <Earnings />}
                      </Tab.Pane>
                      <Tab.Pane eventKey="drops">
                        {activeTab == "drops" &&
                          userType === USER_TYPE.ARTIST && <Activecollection />}
                      </Tab.Pane>
                      <Tab.Pane eventKey="digitalart">
                        {activeTab == "digitalart" &&
                          userType === USER_TYPE.ARTIST && <Activedigitalart />}
                      </Tab.Pane>
                      {/* This tab can be used further */}
                      {/* <Tab.Pane eventKey="accounthistory">
                        {activeTab == "accounthistory" &&
                          userType === USER_TYPE.ARTIST && <Accounthistory />}
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Userdashboard;
