import React, { useEffect, useRef, useState } from "react";
import CommonModal from "../../../../../Common/Ui/CommonModal/CommonModal";
import CommonBtn from "../../../../../Common/Ui/CommonBtn/CommonBtn";
import { DownloadIcom } from "../../../../../../Assets/svgIcons/SVGicon";
import jsPDF from "jspdf";
import "./InvoiceTemplate.scss";
import "jspdf-autotable";
import "jspdf/dist/polyfills.es.js";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../../../../Features/loading/loadingSlice";
import Practiceinvoice from "./Practiceinvoice";
import imageCompression from "browser-image-compression";
import inviceLogo from "../../../../../../Assets/images/inviceLogo.svg";

const styles = {
  paddingtop: {
    paddingTop: "0 !important",
  },
  maintable: {
    maxWidth: "100%",
    width: "100%",
    margin: "15px auto !important",
    backgroundColor: "#fff",
    fontFamily: "Roboto, sans-serif",
    padding: "0",
    borderCollapse: "collapse",
    // backgroundImage: url("./Images/BG.png"),
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    border: "none",
    position: "relative",
    zIndex: "1",
  },

  main_table: {
    padding: "20px 25px",
    width: "100%",
    display: "block",
  },
  header_text: {
    width: "600px",
    height: "40px",
    backgroundColor: "white",
    borderCollapse: "collapse",
    paddingBottom: "30px",
  },
  tbodyMain: {
    width: "100%",
    display: "inline-table",
  },
  back_ground: {
    position: "absolute",
    zIndex: "0",
  },
  main_conteint: {
    position: "relative",
    zIndex: "1",
    width: "100%;",
  },
  heading_table: {
    display: "inline-table",
    width: "100%",
  },
  headingTr: {
    display: "inline-table",
    width: "100%",
  },
  heading_logo: {
    display: "inline-table",
    width: "60%",
  },

  heading_td: {
    display: "inline-table",
    width: "40%",
  },

  h6code: {
    textAlign: "right",
    fontSize: "12px",
    lineHeight: "1",
    color: "#000",
  },

  invoicDetails: {
    borderTop: "1px solid #D4D4D4",
    padding: "20px 0",
  },
  listLi: {
    paddingBottom: "10px",
    fontFamily: "'Roboto', sans-serif",
  },
  labelInv: {
    minWidth: "100px",
    fontSize: "10px",
    fontWeight: "400",
    color: "#000",
    fontFamily: "'Roboto', sans-serif",
    float: "left",
    paddingRight: "27px",
  },

  headh6: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#000",
    fontFamily: "'Roboto', sans-serif",
  },
  subSpace: {
    paddingRight: "7px",
    bottom: "1px",
    fontSize: "10px",
    fontWeight: "400",
  },
  conaint: {
    width: "100%",
  },
  detailsCard: {
    display: "inline-table",
    height: "100%",
    width: "100%",
  },
  addressDetails: {
    width: "50%",
    verticalAlign: "top",
  },

  leftDetails: {
    width: "100%",
    maxWidth: "94%",
  },

  topHeading: {
    fontSize: "10px",
    fontWeight: "400",
    borderBottom: "1px solid #D4D4D4",
    width: "190px",
    display: "inline-block",
    lineHeight: "18px",
    marginBottom: "11px",
    fontFamily: "'Roboto', sans-serif !important",
  },

  Headingtitle: {
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "16px",
    fontFamily: "'Roboto', sans-serif !important",
  },
  perInfo: {
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "18px",
    fontFamily: "'Roboto', sans-serif !important",
    color: "#000",
  },
  lineHoriz: {
    margin: " 20px 0",
    height: "1px",
    width: "100%",
    backgroundColor: "#D4D4D4",
    display: "inline-table",
  },
  imagCard: {
    width: "20%",
    minWidth: "150px",
    maxWidth: "150px",
  },
  upImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top center",
  },
  w_100: {
    width: "100%",
  },

  rightSide: {
    width: "80%",
    maxWidth: "80%",
    paddingLeft: "30px",
  },

  artName: {
    fontSize: "16px",
    fontWeight: "800",
    marginBottom: "5px",
    lineHeight: "20px",
    color: "#000",
    fontFamily: "'Roboto', sans-serif !important",
  },

  edition: {
    display: "inline-table",
    width: "100%",
  },
  editLabel: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    float: "left",
    color: "#000",
    fontFamily: "'Roboto', sans-serif !important",
    minWidth: "80px",
  },

  editPera: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "16px",
    color: "#000",
    fontFamily: "'Roboto', sans-serif !important",
  },

  artistDetail: {
    border: "1px solid #D4D4D4",
    borderLeft: "0",
    borderRight: "0",
  },
  artdetails: {
    width: "50%",
    display: "inline-block",
    borderRight: "1px solid #D4D4D4",
  },

  detailstable: {
    width: "100%",
    margin: "8px 0",
  },

  artistImg: {
    width: "36px",
    height: "36px",
  },

  artisInfo: {
    paddingLeft: "10px",
  },
  infoPera: {
    color: "#6E6E6E",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "14px",
    fontFamily: "'Roboto', sans-serif",
  },

  artNamelab: {
    color: "#000000",
    fontSize: "10px",
    lineHeight: "15px",
    fontFamily: "'Raleway', sans-serif",
  },

  seriesInfo: {
    width: "50%",
    display: "inline-block",
    paddingLeft: "15px",
  },
  seriesPer: {
    color: "#6E6E6E",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "16px",
    fontFamily: "'Roboto', sans-serif",
  },

  seriesNam: {
    color: "#000000",
    fontSize: "10px",
    lineHeight: "16px",
    fontFamily: "'Raleway', sans-serif",
  },
  priceInfo: {
    paddingTop: "20px",
    display: "inline-block",
  },
  priceInfoTd: {
    fontSize: "16px",
    lineHeight: "1.2",
  },
  listingprice: {
    width: "100%",
    maxWidth: "150px",
  },
  priceInfoSpan: {
    width: "100%",
    display: "inline-block",
    paddingBottom: "0",
    fontFamily: "'Roboto', sans-serif",
  },
  priceInfoLabeldisable: {
    minWidth: "85px",
    fontSize: "10px",
    fontWeight: "bold",
    color: "#6E6E6E",
    fontFamily: "'Roboto', sans-serif",
    float: "left",
    paddingRight: "15px",
    textAlign: "right",
  },
  priceInfoSubdisable: {
    marginRight: "0",
    bottom: "6px",
    color: "#6E6E6E",
  },
  priceInfoPeradisable: {
    textAlign: "right",
    fontSize: "10px",
    fontWeight: "400",
    color: "#6E6E6E",
    fontFamily: "'Roboto', sans-serif",
    float: "right",
    width: "59px",
  },

  priceInfoLabel: {
    minWidth: "85px",
    fontSize: "10px",
    fontWeight: "bold",
    color: "#000",
    fontFamily: "'Roboto', sans-serif",
    float: "left",
    paddingRight: "15px",
  },

  priceInfoSub: {
    marginRight: "0",
    bottom: "6px",
    color: "#000",
  },

  priceInfoPera: {
    textAlign: "right",
    fontSize: "10px",
    fontWeight: "400",
    color: "#000",
    fontFamily: "'Roboto', sans-serif",
    float: "right",
    width: "59px",
  },
  signatory: {
    padding: " 20px 0 40px",
    display: "inline-block",
    width: "100%",
    maxWidth: "150px",
  },
  signatorytd: {
    textAlign: "center",
    display: "inline-table",
    width: "100%",
  },
  signImg: {
    width: "71px",
    height: "36px",
    objectFit: "cover",
  },
  signatoryh6: {
    fontSize: "10px",
    fontWeight: "700",
    lineHeight: "24px",
    paddingTop: "5px",
    color: "#000",
    fontFamily: "'Roboto', sans-serif",
  },
  colorBlack: {
    color: "#000",
  },
  ftr_link: {
    backgroundColor: "#fff",
    height: "50px",
    padding: "0 15px 15px",
    textAlign: "center",
  },

  ftr_link_info: {
    color: "#6f6f6f",
    fontSize: "10px",
    fontFamily: "'Roboto', sans-serif",
    paddingBottom: "10px",
  },
};

export default function InvoiceTemplate(props) {
  const dispatch = useDispatch();
  const [backgroundImage, setBackgroundImage] = useState("");
  const [artImage, setArtImage] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [artistImg, setArtistImg] = useState("");
  const [artistSign, setArtistSign] = useState("");

  const contentRef = useRef(null);

  let { show, handleClose, data } = props;

  const downloadPDF = () => {
    dispatch(startLoading());
    const content = contentRef.current;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4", // Standard A4 size
    });

    const scale = 0.8; // Scale down to 80% of the original size

    pdf.html(content, {
      callback: () => {
        pdf.save("invoice.pdf");
        dispatch(stopLoading());
        handleClose();
      },
      x: 0,
      y: 0,
      width: 210 * scale, // Scale width to fit A4
      windowWidth: content.scrollWidth * scale, // Match the content's width
    });
  };

  console.log(data);

  useEffect(() => {
    getBase64Image(
      `${process.env.REACT_APP_IMG_BASE_URL}nfts/invoice_bg.png`,
      "bgImg"
    );
    getBase64Image(`${inviceLogo}`, "logo");
    getBase64Image(
      `${process.env.REACT_APP_IMG_BASE_URL}${data?.art?.thumbnail_image}`,
      "artImg"
    );
    if (data?.art?.artist_image) {
      getBase64Image(
        `${process.env.REACT_APP_IMG_BASE_URL}${data?.art?.artist_image}`,
        "artistImg"
      );
    }
    if (data?.art?.artist_sign) {
      getBase64Image(
        `${process.env.REACT_APP_IMG_BASE_URL}${data?.art?.artist_sign}`,
        "artistSign"
      );
    }
  }, [data]);

  const getBase64Image = async (imageUrl, type) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // Enable cross-origin requests
    img.src = imageUrl;

    img.onload = async () => {
      try {
        // Create a canvas to draw the image and get its data URL
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Get the data URL of the image
        const dataURL = canvas.toDataURL("image/png");

        // Create a Blob from the data URL
        const byteString = atob(dataURL.split(",")[1]);
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });

        // Compress the Blob
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedBlob = await imageCompression(blob, options);

        // Convert the compressed Blob back to a data URL
        const compressedDataURL = await imageCompression.getDataUrlFromFile(
          compressedBlob
        );

        if (type === "bgImg") {
          setBackgroundImage(compressedDataURL);
        }
        if (type === "logo") {
          setLogoImage(dataURL);
        }
        if (type === "artImg") {
          setArtImage(compressedDataURL);
        }
        if (type === "artistImg") {
          setArtistImg(compressedDataURL);
        }
        if (type === "artistSign") {
          setArtistSign(compressedDataURL);
        }
      } catch (error) {
        console.error("Error compressing the image:", error);
      }
    };

    img.onerror = (error) => {
      console.error("Error loading the image:", error);
    };
  };

  return (
    <CommonModal
      centered
      className="invoice_modal"
      modalTitle={data?.art?.art_name}
      show={show}
      onHide={handleClose}
    >
      <div className="get_invoice">
        <CommonBtn
          role="btn"
          onClick={() => downloadPDF()}
          className={`bordred-yellow btnsize`}
          title={
            <>
              Download PDF <DownloadIcom />
            </>
          }
          disabled={
            backgroundImage === "" || logoImage === "" || artImage === ""
          }
        />
        <table style={styles.maintable} class="main-handle" ref={contentRef}>
          <tbody style={styles.main_conteint}>
            <tr style={styles.w_100}>
              <td style={styles.w_100}>
                <table style={styles.main_table} className="main_table">
                  <tbody style={styles.tbodyMain}>
                    <tr style={styles.headingTr}>
                      <td style={styles.header_text} class="header-text">
                        <table
                          style={styles.heading_table}
                          class="heading_table"
                        >
                          <tbody style={styles.headingTr}>
                            <tr style={styles.headingTr}>
                              <td
                                style={styles.heading_logo}
                                className="heading_logo"
                              >
                                <img
                                  style={styles.img_logo}
                                  src={logoImage}
                                  // src={inviceLogo}
                                  alt="naut_img"
                                />
                              </td>
                              <td
                                style={styles.heading_td}
                                className="heading_td"
                              >
                                <h6 style={styles.h6code}>Tax Invoice</h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style={styles.invoicDetails}
                        className="invoicDetails"
                      >
                        <ul style={styles.list} className="list">
                          <li style={styles.listLi}>
                            <label style={styles.labelInv}>Date</label>
                            <h6 style={styles.headh6}>
                              <sub style={styles.subSpace}>:</sub> {data.date}{" "}
                            </h6>
                          </li>
                          <li style={styles.listLi}>
                            <label style={styles.labelInv}>
                              Invoice Number
                            </label>
                            <h6 style={styles.headh6}>
                              <sub style={styles.subSpace}>:</sub>{" "}
                              {data?.invoice_number || "---"}
                            </h6>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr style={styles.innerSec} className="innerSec">
                      <td>
                        <table style={styles.conaint} className="conaint">
                          <tbody>
                            <tr>
                              <td
                                style={styles.addressDetails}
                                className="addressDetails"
                              >
                                <table
                                  style={styles.leftDetails}
                                  className="leftDetails"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={styles.topHeading}
                                        className="topHeading"
                                      >
                                        Seller Details
                                      </td>
                                    </tr>
                                    <tr
                                      style={styles.infoSeller}
                                      className="infoSeller"
                                    >
                                      <td>
                                        <h6
                                          style={styles.Headingtitle}
                                          className="Headingtitle"
                                        >
                                          {data?.seller.name}
                                        </h6>
                                        <ul
                                          // style={styles.listing}
                                          className="listing"
                                        >
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {data.seller.address_line_1 &&
                                                data.seller.address_line_1}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {data.seller.address_line_2 &&
                                                data.seller.address_line_2}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {data.seller.city &&
                                                `${data.seller.city}, `}
                                              {""}
                                              {data.seller.state &&
                                                `${data.seller.state}, `}
                                              {""}
                                              {data.seller.pincode &&
                                                `${data.seller.pincode}`}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {data.seller.country &&
                                                data.seller.country}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              GST Registration No. :{" "}
                                              {data?.seller?.gst_number
                                                ? data?.seller?.gst_number
                                                : "---"}
                                            </p>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td
                                style={styles.addressDetails}
                                className="addressDetails"
                              >
                                <table
                                  style={styles.leftDetails}
                                  className="leftDetails"
                                >
                                  <tbody>
                                    <tr className="topHeading">
                                      <td style={styles.topHeading}>
                                        Buyer Details
                                      </td>
                                    </tr>
                                    <tr
                                      style={styles.infoSeller}
                                      className="infoSeller"
                                    >
                                      <td>
                                        <h6
                                          style={styles.Headingtitle}
                                          className="Headingtitle"
                                        >
                                          {data?.buyer.name}
                                        </h6>
                                        <ul
                                          // style={styles.listing}
                                          className="listing"
                                        >
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {data?.buyer?.address_line_1 &&
                                                data?.buyer?.address_line_1}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {data?.buyer?.address_line_2}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {data.buyer.city &&
                                                `${data.buyer.city}, `}
                                              {""}
                                              {data.buyer.state &&
                                                `${data.buyer.state}, `}
                                              {""}
                                              {data.buyer.pincode &&
                                                `${data.buyer.pincode}`}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              {" "}
                                              {data?.buyer?.country}
                                            </p>
                                          </li>
                                          <li className="listingLi">
                                            <p style={styles.perInfo}>
                                              GST Registration No. :{" "}
                                              {data?.buyer?.gst_number
                                                ? data?.buyer?.gst_number
                                                : "---"}
                                            </p>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr style={styles.innerSec} className="innerSec">
                      <td>
                        <table style={styles.conaint} className="conaint">
                          <tbody>
                            <tr
                              style={styles.lineHoriz}
                              className="lineHoriz"
                            />
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr style={styles.innerSec} className="innerSec">
                      <td>
                        <table style={styles.conaint} className="conaint">
                          <tbody>
                            <tr
                              style={styles.detailsCard}
                              className="detailsCard"
                            >
                              <td style={styles.imagCard} className="imagCard">
                                {artImage && (
                                  <img
                                    src={artImage}
                                    alt="img"
                                    className="up-Img"
                                    style={styles.upImg}
                                  />
                                )}
                              </td>
                              <td
                                style={styles.rightSide}
                                className="rightSide"
                              >
                                <table style={styles.w_100}>
                                  <tbody>
                                    <tr
                                      style={styles.detailId}
                                      className="detailId"
                                    >
                                      <td style={styles.w_100}>
                                        <h4
                                          style={styles.artName}
                                          className="artName"
                                        >
                                          {data?.art?.art_name}
                                        </h4>
                                        <div
                                          style={styles.edition}
                                          className="edition"
                                        >
                                          <label style={styles.editLabel}>
                                            Edition No.
                                          </label>
                                          <p style={styles.editPera}>
                                            <sub style={styles.subSpace}>:</sub>{" "}
                                            20 / 100
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr
                                      style={styles.artistDetail}
                                      className="artistDetail"
                                    >
                                      <td
                                        style={styles.artdetails}
                                        className="details"
                                      >
                                        <table style={styles.detailstable}>
                                          <tbody>
                                            <tr>
                                              <td
                                                style={styles.artistImg}
                                                className="artistImg"
                                              >
                                                {artistImg && (
                                                  <img
                                                    src={artistImg}
                                                    alt="img"
                                                    className="creatImg"
                                                    style={styles.creatImg}
                                                  />
                                                )}
                                              </td>
                                              <td
                                                style={styles.artisInfo}
                                                className="artisInfo"
                                              >
                                                <p style={styles.infoPera}>
                                                  Artist Name
                                                </p>
                                                <h6 style={styles.artNamelab}>
                                                  {data?.art?.artist_name}
                                                </h6>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td
                                        style={styles.seriesInfo}
                                        className="seriesInfo"
                                      >
                                        <p style={styles.seriesPer}>Series</p>
                                        <h6 style={styles.seriesNam}>
                                          {data?.art?.series_name}
                                        </h6>
                                      </td>
                                    </tr>
                                    <tr
                                      style={styles.priceInfo}
                                      className="priceInfo"
                                    >
                                      <td style={styles.priceInfoTd}>
                                        <div
                                          style={styles.listingprice}
                                          className="listingprice"
                                        >
                                          <span style={styles.priceInfoSpan}>
                                            <label
                                              style={styles.priceInfoLabel}
                                            >
                                              Unit Price
                                            </label>
                                            <sub style={styles.priceInfoSub}>
                                              :
                                            </sub>
                                            <p style={styles.priceInfoPera}>
                                              {" "}
                                              Rs. {data?.art?.price}
                                            </p>
                                          </span>
                                          <span style={styles.priceInfoSpan}>
                                            <label
                                              style={styles.priceInfoLabel}
                                            >
                                              Quantity
                                            </label>
                                            <sub style={styles.priceInfoSub}>
                                              :
                                            </sub>
                                            <p style={styles.priceInfoPera}>
                                              {" "}
                                              {data?.art?.quantity}
                                            </p>
                                          </span>
                                          <span style={styles.priceInfoSpan}>
                                            <label
                                              style={styles.priceInfoLabel}
                                            >
                                              Net Amount
                                            </label>
                                            <sub style={styles.priceInfoSub}>
                                              :
                                            </sub>
                                            <p style={styles.priceInfoPera}>
                                              {" "}
                                              {`Rs. ${data?.art?.total_price}`}
                                            </p>
                                          </span>
                                          <span
                                            style={styles.priceInfoSpan}
                                            className="disable"
                                          >
                                            <label
                                              style={
                                                styles.priceInfoLabeldisable
                                              }
                                            >
                                              CGST -{" "}
                                              {
                                                data?.art
                                                  ?.service_charge_percentage
                                              }
                                              %
                                            </label>
                                            <sub
                                              style={styles.priceInfoSubdisable}
                                            >
                                              :
                                            </sub>
                                            <p
                                              style={
                                                styles.priceInfoPeradisable
                                              }
                                            >
                                              {" "}
                                              Rs.{" "}
                                              {data?.art?.service_charge_amount}
                                            </p>
                                          </span>
                                          <span
                                            style={styles.priceInfoSpan}
                                            className="disable"
                                          >
                                            <label
                                              style={
                                                styles.priceInfoLabeldisable
                                              }
                                            >
                                              SGST -{" "}
                                              {
                                                data?.art
                                                  ?.service_charge_percentage
                                              }
                                              %
                                            </label>
                                            <sub
                                              style={styles.priceInfoSubdisable}
                                            >
                                              :
                                            </sub>
                                            <p
                                              style={
                                                styles.priceInfoPeradisable
                                              }
                                            >
                                              {" "}
                                              Rs.{" "}
                                              {data?.art?.service_charge_amount}
                                            </p>
                                          </span>
                                          <span style={styles.priceInfoSpan}>
                                            <label
                                              style={styles.priceInfoLabel}
                                            >
                                              TAX Amount
                                            </label>
                                            <sub style={styles.priceInfoSub}>
                                              :
                                            </sub>
                                            <p style={styles.priceInfoPera}>
                                              {" "}
                                              Rs.{" "}
                                              {
                                                data?.art
                                                  ?.total_service_charge_amount
                                              }
                                            </p>
                                          </span>
                                          <span style={styles.priceInfoSpan}>
                                            <label
                                              style={styles.priceInfoLabel}
                                            >
                                              Total Amount
                                            </label>
                                            <sub style={styles.priceInfoSub}>
                                              :
                                            </sub>
                                            <p style={styles.priceInfoPera}>
                                              {" "}
                                              Rs. {data?.art?.total_art_amount}
                                            </p>
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr style={styles.signatory} className="signatory">
                              <td style={styles.signatorytd}>
                                {artistSign && (
                                  <img
                                    src={artistSign}
                                    alt="img"
                                    className="signImg"
                                    style={styles.signImg}
                                  />
                                )}

                                <h6 style={styles.signatoryh6}>
                                  Authorized{" "}
                                  <span style={styles.colorBlack}>
                                    &nbsp;Signatory
                                  </span>
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr style={styles.w_100}>
              <td style={styles.ftr_link} className="header-text ftr_link">
                <p style={styles.ftr_link_info}>
                  Please visit www.newartx.com for Terms Of Service, Shipping
                  Policy & Refund and Returns Policy.
                </p>
                <p style={styles.ftr_link_info}>
                  Feel free to email us at support@newartx.com for any queries.
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        {/* <Practiceinvoice
          data={data}
          logoImage={logoImage}
          backgroundImage={backgroundImage}
          artImage={artImage}
          artistImg={artistImg}
          artistSign={artistSign}
          contentRef={contentRef}
        /> */}
      </div>
    </CommonModal>
  );
}
