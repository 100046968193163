import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CommonBtn from "../../../../../Common/Ui/CommonBtn/CommonBtn";
import CommonModal from "../../../../../Common/Ui/CommonModal/CommonModal";
import FormikControls from "../../../../../Common/Ui/Formik/FormikControls";
import style from "./MakeOffermodal.module.scss";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createMakeOffer } from "../../../../../../Features/offerSlice/offerSlice";
import {
  PLATFORM_MAX_VALUE,
  getNumberWithComma,
  sliceChar,
} from "../../../../../../Helper/common";
import {
  DECIMAL,
  DIGITAL_ART_TYPE,
} from "../../../../../../Constant/userConstant";
import {
  cancelPayment,
  getWalletBalance,
} from "../../../../../../Features/user/userSlice";
import { toasts } from "../../../../../Common/Toast/Toast";
import { setRedirectionStripSuccess } from "../../../../../../Features/persist/persistSlice";
import { Routes_Urls } from "../../../../../../Constant/Route_url";
import NewartxLogo from "../../../../../../Assets/images/newArtX_Logo_Img.png";

const showoption = [
  { label: "3 days", value: "3" },
  { label: "5 days", value: "5" },
  { label: "7 days", value: "7" },
];

const MakeOffermodal = ({
  show,
  onHide,
  makeOfferDetails,
  setShowing,
  getSamePageDetails,
  username,
  offerPage,
  id,
}) => {
  const [walletBalance, setWalletBalance] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const MakeSchema = Yup.object({
    offer_price: Yup.string()
      // .positive("Offer amount must be greater than 0")
      .required("Offer amount is required")
      .test(
        "greaterThanZero",
        "*Offer amount must be greater than 0",
        (value) => {
          return value != 0;
        }
      )
      .test(
        "not-ending-with-decimal",
        "*Value should not end with a decimal point",
        (value) => {
          if (!isNaN(value) && value !== parseInt(value, 10)) {
            return !/\.+$/.test(value.toString());
          }
          return true;
        }
      )
      .test(
        "notGreaterThanValue",
        "*Value is not accepted",
        (value) => value < PLATFORM_MAX_VALUE
      ),
    duration: Yup.object().required("*Duration is required"),
    is_internal_wallet: Yup.string().required("Please select an option"),
  });

  useEffect(() => {
    getUserWalletBalance();
  }, []);

  useEffect(() => {
    // Load the Razorpay script dynamically
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async (values) => {
    // Create an instance of Razorpay with options
    const rzp = new window.Razorpay({
      key: process.env.REACT_APP_RAZAOR_PAY, // Replace with your Razorpay Key ID
      amount: Number(formik.values.offer_price) * 100, // Amount is in currency subunits (paise)
      currency: "INR",
      name: "Newartx", // Your business name
      description: "Testing",
      image: NewartxLogo,
      order_id: values?.payment_id,
      // notes: {
      //   address: "Razorpay Corporate Office",
      // },
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        if (response.razorpay_payment_id) {
          document.body.style.overflow = "visible";
          formik.resetForm();
          getSamePageDetails();
          if (offerPage === "otherUserProfile") {
            navigate(`/profile/${username}`);
          } else if (offerPage === "secondaryPage") {
            navigate(`/marketplace/art/${id}`);
          }
        }
        // Add any additional logic here after payment success
      },
      retry: {
        enabled: false,
      },
      callback_url: "https://stage.newartx.com/", // Your callback URL
      config: {
        display: {
          hide: [{ method: "paylater" }, { method: "wallet" }],
          preferences: { show_default_blocks: true },
        },
      },
      modal: {
        ondismiss: function (error) {
          document.body.style.overflow = "visible";
          formik.resetForm();
          let data = {
            orderId: values?.payment_id,
          };
          dispatch(cancelPayment(data));
          if (error?.error?.description) {
            toasts.error(error?.error?.description);
          }
          if (offerPage === "otherUserProfile") {
            navigate(`/profile/${username}`);
          } else if (offerPage === "secondaryPage") {
            navigate(`/marketplace/art/${id}`);
          }
        },
      },
    });
    // Open the payment modal
    rzp.open();
    // Check modal visibility periodically
  };

  const getUserWalletBalance = () => {
    dispatch(getWalletBalance()).then((response) => {
      setWalletBalance(response?.data?.data?.user_fiat_account?.balance);
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: MakeSchema,
    initialValues: {
      offer_price: "",
      duration: "",
      is_internal_wallet: 0, // 0 for internal and 1 for external
    },
    onSubmit: (values) => {
      createOfferHandler(values);
    },
  });

  const createOfferHandler = async (values) => {
    let data = {
      art_id: makeOfferDetails?.art_id,
      offer_price: values.offer_price,
      duration: parseInt(values.duration.value),
      to_user_id: makeOfferDetails?.user_id,
      edition_no: makeOfferDetails?.user_owned_no_of_editions,
      is_internal_wallet: formik?.values.is_internal_wallet,
    };
    try {
      if (formik?.values.is_internal_wallet === 0) {
        let balance = Number(walletBalance) / DECIMAL;
        if (values.offer_price <= balance) {
          let response = await dispatch(createMakeOffer(data));
          if (response) {
            setShowing(false);
            formik.resetForm();
            getSamePageDetails();
          }
        } else {
          toasts.error("Insufficient balance");
        }
      } else {
        // second argument is for status of to show success toast messgae or not
        let response = await dispatch(createMakeOffer(data, false));
        setShowing(false);
        handlePayment(response.data.data);
      }
    } catch (error) {
      setShowing(false);
    }
  };

  const addFundsHandler = () => {
    dispatch(setRedirectionStripSuccess("offer"));
    navigate(Routes_Urls.Wallet);
  };

  const enteredValue = formik.values.offer_price.replace(/,/g, ""); // Remove commas from the entered value
  const isButtonDisabled =
    parseFloat(enteredValue) > (Number(walletBalance) / DECIMAL).toFixed(2);

  return (
    <>
      <CommonModal
        backdropclass="fade_clrbg"
        className={style.offerModal}
        centered
        show={show}
        onHide={onHide}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-md-row flex-column-reverse">
            <div className={style.offerModalLeft}>
              <div className={style.offerModalLeftInner}>
                <img
                  src={`${process.env.REACT_APP_IMG_BASE_URL}${makeOfferDetails?.thumbnail_image}`}
                  alt="artist_nft"
                />
              </div>
            </div>
            <div className={style.offerModalRight}>
              <div className={style.artistDetails}>
                <h4>@{makeOfferDetails?.username}</h4>
                <h3 title={makeOfferDetails?.art_name}>
                  {sliceChar(makeOfferDetails?.art_name, 25)}
                </h3>
                <div className={style.artistDetailsEdition}>
                  <p>Edition :</p>
                  <span>
                    {" "}
                    {makeOfferDetails?.user_owned_no_of_editions} of{" "}
                    {makeOfferDetails.drop_type == DIGITAL_ART_TYPE.OpenEdition
                      ? makeOfferDetails?.sold_no_of_editions
                      : makeOfferDetails?.total_no_of_editions}
                  </span>
                </div>
              </div>
              <div className={style.pricelist}>
                <h5>
                  Purchase Price
                  <span>
                    {" "}
                    {makeOfferDetails?.last_buy_price
                      ? "₹" +
                        getNumberWithComma(
                          makeOfferDetails?.last_buy_price / DECIMAL
                        )
                      : "--"}
                  </span>
                </h5>
                <h5>
                  Last Sale Price
                  <span>
                    {" "}
                    {makeOfferDetails?.last_sell_price // changed by shawinder
                      ? "₹" +
                        getNumberWithComma(
                          makeOfferDetails?.last_sell_price / DECIMAL
                        )
                      : "--"}
                  </span>
                </h5>
                <h5 className={style.highvalue}>
                  Highest Offer
                  <span>
                    {makeOfferDetails?.highest_offer_price
                      ? "₹" +
                        getNumberWithComma(
                          makeOfferDetails?.highest_offer_price / DECIMAL
                        )
                      : "--"}
                  </span>
                </h5>
              </div>
              <div className={style.editionDetails}>
                <div className={style.enter_text}>
                  <label>Enter Offer Price</label>
                  <p>(Inclusive of GST)</p>
                </div>
                <FormikControls
                  control="input"
                  type="text"
                  name="offer_price"
                  id="offer_price"
                  placeholder="Enter Your Offer"
                  leftIcon="₹"
                  onChange={(e) => {
                    //regex for only digit
                    if (
                      /^\d+(\.\d{0,2})?$/.test(e.target.value) ||
                      e.target.value == ""
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik?.values?.offer_price}
                  formik={formik}
                  maxLength={18}
                />
              </div>
              <div className={style.durationDetails}>
                <label>Select Duration</label>
                <FormikControls
                  control="select"
                  className={`border_input ${style.select_changes}`}
                  type="duration"
                  name="duration"
                  id="duration"
                  onChange={(item) => {
                    formik.setFieldValue("duration", item);
                  }}
                  value={formik?.values?.duration}
                  placeholder="Duration"
                  formik={formik}
                  options={showoption}
                  onBlur={formik.handleBlur}
                  isSearchable={false}
                />
              </div>
              <div className={style.check_offer}>
                <FormikControls
                  className="radio_btn"
                  control="radio"
                  formik={formik}
                  value={0}
                  name="is_internal_wallet"
                  checked={formik.values.is_internal_wallet == 0}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue("is_internal_wallet", 0);
                    }
                  }}
                  label={
                    <div
                      onClick={() => {
                        formik.setFieldValue("is_internal_wallet", 0);
                      }}
                    >
                      <strong>Internal Wallet Balance: </strong>
                      <span>
                        ₹
                        {getNumberWithComma(
                          (Number(walletBalance) / DECIMAL).toFixed(2)
                        )}
                      </span>
                    </div>
                  }
                />
                <FormikControls
                  className="radio_btn"
                  control="radio"
                  formik={formik}
                  value={1}
                  name="is_internal_wallet"
                  checked={formik.values.is_internal_wallet == 1}
                  onChange={(e) => {
                    formik.setFieldValue("is_internal_wallet", 1);
                  }}
                  label={
                    <div
                      onClick={() => {
                        formik.setFieldValue("is_internal_wallet", 1);
                      }}
                    >
                      <strong>Other Payment Method</strong>
                    </div>
                  }
                />
              </div>
              {isButtonDisabled && formik.values.is_internal_wallet === 0 && (
                <div className={style.insufficientBl}>
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Insufficient Balance
                  </p>
                  {/* <button onClick={addFundsHandler}>Add Funds</button> */}
                </div>
              )}
              {/* <p className={style.paragraph_text}>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </p> */}
              <div className={style.buy_btn_box}>
                <CommonBtn
                  role="btn"
                  title="Make Offer"
                  className={`${style.buy_btn} btn-yellow`}
                  type="submit"
                  disabled={
                    isButtonDisabled && formik.values.is_internal_wallet === 0
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default MakeOffermodal;
