import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import styles from "./Marketplace.module.scss";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import {
  CloseIcon,
  FilterIcon,
  SearchIcon,
} from "../../../Assets/svgIcons/SVGicon";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormikControls from "../../Common/Ui/Formik/FormikControls";
import CommonCard from "../../Common/Ui/CommonCard/CommonCard";
import artImg from "../../../Assets/images/art_collection4.jpg";
import QuickBuyModal from "../../Common/Ui/QuickBuyModal/QuickBuyModal";
import {
  getAllMarketPlaceNft,
  getAllTags,
  getAllArtist,
  getAllSeries,
} from "../../../Features/marketplace/marketplaceSlice";
import { useDispatch, useSelector } from "react-redux";
import Moremodal from "./Moremodal/Moremodal";
import { toasts } from "../../Common/Toast/Toast";
import Commondropdown from "../../Common/Ui/CommonDropdown/Commondropdown";
import CustomPagination from "../../Common/Ui/Pagination/CustomPagination";
import Notfound from "../../Common/NotFound/Notfound";
import {
  openPopUp,
  saveLatestPrice,
  saveNextRoute,
  setMarketplaceFilter,
} from "../../../Features/user/userSlice";
import { DECIMAL } from "../../../Constant/userConstant";
import { checkFileType, getNumberWithComma } from "../../../Helper/common";
import HoverVideoPlayer from "react-hover-video-player";
import { Routes_Urls } from "../../../Constant/Route_url";
import { Helmet } from "react-helmet";

const Marketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = useSelector((state) => state?.users?.profileData?.id);
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const marketplaceFilter = useSelector(
    (state) => state?.users?.marketplaceFilter
  );

  const [searchArtist, setSearchArtist] = useState("");
  const [searchSeries, setSearchSeries] = useState("");
  const [searchTag, setSearchTag] = useState("");
  const [searchInType, setSearchInType] = useState("");
  const [searchType, setSearchType] = useState("");

  const [marketPlaceNfts, setMarketPlaceNfts] = useState([]);
  const [pageMarketplace, setPageMarketplace] = useState(1);
  const [show, setShow] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [totalPages, setTotalPages] = useState(0);
  const [showmore, setShowmore] = useState(false);
  const [artistList, setArtistList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [fileterAllList, setFilterAllList] = useState([]);
  const [MarketFilterList, setMarketFilterList] = useState([
    {
      title: "Status",
      options: [
        {
          id: 1,
          name: "Available for Primary Sale",
          label: "Available for Primary Sale",
          isInfo: true,
          infoMessage:
            "Exclusive minted Digital Fine Arts (DFAs) directly from the artists, ensuring authenticity and unique ownership",
        },
        {
          id: 2,
          name: "Available for Secondary Sale",
          label: "Available for Secondary Sale",
          isInfo: true,
          infoMessage:
            "Exclusive authenticated resales of Digital Fine Arts (DFAs) from other collectors",
        },
      ],
    },
    {
      Searchbar: true,
      title: "Artist",
      options: artistList,
    },
    {
      Searchbar: true,
      title: "Series",
      options: seriesList,
    },
    {
      minMaxvalue: true,
      title: "Price",
      options: [
        { id: 1, name: "Under ₹20,000", label: "Under ₹20,000" },
        { id: 2, name: "₹20,000 - ₹50,000", label: "₹20,000 - ₹50,000" },
        { id: 3, name: "₹50,000 - ₹80,000", label: "₹50,000 - ₹80,000" },
        { id: 4, name: "₹80,000 - ₹1,50,000", label: "₹80,000 - ₹1,50,000" },
        { id: 5, name: "₹1,50,000 and above", label: "₹1,50,000 and above" },
      ],
    },
    // {
    //   title: "Rarity",
    //   options: [
    //     { id: 1, name: "Unique", label: "Unique" },
    //     { id: 2, name: "2-5 Edition", label: "2-5 Edition" },
    //     { id: 3, name: "6-12 Edition", label: "6-12 Edition" },
    //     { id: 4, name: "13-25 Edition", label: "13-25 Edition" },
    //     { id: 5, name: "26-50 Edition", label: "26-50 Edition" },
    //     { id: 6, name: "50 Above Edition", label: "50 Above Edition" },
    //   ],
    // },
    {
      Searchbar: true,
      title: "Type",
      options: [
        {
          id: 1,
          name: "Dynamic",
          label: "Dynamic",
          isInfo: true,
          infoMessage:
            "Adaptive artwork that changes in response to real-time events",
        },
        // { id: 2, name: "Generative", label: "Generative" },
        { id: 3, name: "Standard", label: "Standard" },
      ],
    },
    {
      Searchbar: true,
      title: "Tags",
      options: tagList,
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [marketPlaceNfts]);
  // useEffect to update filter options when artistList, seriesList, or tagList change
  useEffect(() => {
    // Update Artist filter options if artistList is updated
    if (artistList.length > 0) {
      let list = [...MarketFilterList];
      list.forEach((element) => {
        if (element.title == "Artist") {
          element.options = artistList;
        }
      });
      setMarketFilterList(list);
    }
    // Update Series filter options if seriesList is updated
    if (seriesList.length > 0) {
      let list = [...MarketFilterList];
      list.forEach((element) => {
        if (element.title == "Series") {
          element.options = seriesList;
        }
      });
      setMarketFilterList(list);
    }
    // Update Tags filter options if tagList is updated
    if (tagList.length > 0) {
      let list = [...MarketFilterList];
      list.forEach((element) => {
        if (element.title == "Tags") {
          element.options = tagList;
        }
      });
      setMarketFilterList(list);
    }
  }, [artistList, seriesList, tagList]);

  useEffect(() => {
    getAllMarketPlaceApi();
  }, [
    pageMarketplace,
    marketplaceFilter.artist,
    marketplaceFilter.artType,
    marketplaceFilter.price,
    marketplaceFilter.rarity,
    marketplaceFilter.series,
    marketplaceFilter.tags,
    marketplaceFilter.status,
    marketplaceFilter.optionFilter.value,
  ]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPageMarketplace(Number(searchParams.get("page")));
    } else {
      setPageMarketplace(1);
    }
  }, [searchParams.get("page")]);

  useEffect(() => {
    getAllArtistList();
    getAllSeriesList();
    getAllTagList();
  }, []);

  /**
   * Function to determine the minimum and maximum price values based on selected price options
   * @returns An object containing the minimum and maximum price values
   */
  const getPriceValues = async () => {
    // Initialize an empty object to store the minimum and maximum price values
    let priceMinMax = {};

    // Define an array of price options with their corresponding minimum and maximum values
    let priceOptions = [
      { id: 1, min: 0, max: 20000 },
      { id: 2, min: 20001, max: 50000 },
      { id: 3, min: 50001, max: 80000 },
      { id: 4, min: 80001, max: 150000 },
      { id: 5, min: 150001, max: 150001 },
    ];

    // Check if only one price option is selected
    if (marketplaceFilter.price.length == 1) {
      // Filter the price options array to find the selected option
      let filteredRecord = await priceOptions.filter(
        (option) => option.id === marketplaceFilter.price[0]
      );
      // Assign the minimum and maximum values of the selected option to the priceMinMax object
      priceMinMax = {
        min: filteredRecord[0].min,
        max: filteredRecord[0].max,
      };
      if (filteredRecord[0].min === filteredRecord[0].max) {
        delete priceMinMax.max;
      }
    } else {
      // If multiple price options are selected, determine the minimum and maximum values
      let recordWithBiggestId = marketplaceFilter.price.reduce(
        (prevRecord, currentRecord) => {
          return currentRecord > prevRecord ? currentRecord : prevRecord;
        }
      );
      let recordWithSmallestId = marketplaceFilter.price.reduce(
        (prevRecord, currentRecord) => {
          return currentRecord < prevRecord ? currentRecord : prevRecord;
        }
      );

      // Filter the price options array to find the options with the smallest and biggest IDs
      let filteredRecordMax = await priceOptions.filter(
        (option) => option.id === recordWithBiggestId
      );
      let filteredRecordMin = await priceOptions.filter(
        (option) => option.id === recordWithSmallestId
      );

      // Assign the minimum and maximum values of the selected options to the priceMinMax object
      priceMinMax = {
        min: filteredRecordMin[0].min,
        max: filteredRecordMax[0].max,
      };
      if (filteredRecordMax[0].max === 150001) {
        delete priceMinMax.max;
      }
    }

    // Return the object containing the minimum and maximum price values
    return priceMinMax;
  };

  /**
   * Function to determine the minimum and maximum rarity values based on selected rarity options
   * @returns An object containing the minimum and maximum rarity values
   */
  const getPriceRarity = async () => {
    // Initialize an empty object to store the minimum and maximum rarity values
    let rarityMinMax = {};

    // Define an array of rarity options with their corresponding minimum and maximum values
    let rarityOptions = [
      { id: 1, min: 1, max: 1 },
      { id: 2, min: 2, max: 5 },
      { id: 3, min: 6, max: 12 },
      { id: 4, min: 13, max: 25 },
      { id: 5, min: 26, max: 50 },
      { id: 6, min: 51, max: 51 },
    ];

    // Check if only one rarity option is selected
    if (marketplaceFilter.rarity.length == 1) {
      // Filter the rarity options array to find the selected option
      let filteredRecord = await rarityOptions.filter(
        (option) => option.id === marketplaceFilter.rarity[0]
      );
      // Assign the minimum and maximum values of the selected option to the rarityMinMax object
      rarityMinMax = {
        min: filteredRecord[0].min,
        max: filteredRecord[0].max,
      };
    } else {
      // If multiple rarity options are selected, determine the minimum and maximum values
      let recordWithBiggestId = marketplaceFilter.rarity.reduce(
        (prevRecord, currentRecord) => {
          return currentRecord > prevRecord ? currentRecord : prevRecord;
        }
      );
      let recordWithSmallestId = marketplaceFilter.rarity.reduce(
        (prevRecord, currentRecord) => {
          return currentRecord < prevRecord ? currentRecord : prevRecord;
        }
      );

      // Filter the rarity options array to find the options with the smallest and biggest IDs
      let filteredRecordMax = await rarityOptions.filter(
        (option) => option.id === recordWithBiggestId
      );
      let filteredRecordMin = await rarityOptions.filter(
        (option) => option.id === recordWithSmallestId
      );

      // Assign the minimum and maximum values of the selected options to the rarityMinMax object
      rarityMinMax = {
        min: filteredRecordMin[0].min,
        max: filteredRecordMax[0].max,
      };
    }

    // Return the object containing the minimum and maximum rarity values
    return rarityMinMax;
  };

  // const getStatus = (type) => {
  //   console.log(marketplaceFilter.status);
  //   if (marketplaceFilter.status.length > 1) {
  //     return true;
  //   } else if (
  //     marketplaceFilter.status.length === 1 &&
  //     type === "sale" &&
  //     marketplaceFilter.status[0] === 1
  //   ) {
  //     return true;
  //   } else if (
  //     marketplaceFilter.status.length === 1 &&
  //     type === "live" &&
  //     marketplaceFilter.status[0] === 2
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const getAllMarketPlaceApi = async () => {
    let rarityMinMax = {};
    let priceMinMax = {};
    if (marketplaceFilter.price.length > 0) {
      priceMinMax = await getPriceValues();
    }
    if (marketplaceFilter.rarity.length > 0) {
      rarityMinMax = await getPriceRarity();
    }

    if (marketplaceFilter.price.length == 0) {
      //for input minmax value
      if (Object.keys(marketplaceFilter.priceInputValue).length > 0) {
        priceMinMax = {
          min: Number(marketplaceFilter.priceInputValue.minVal),
          max: Number(marketplaceFilter.priceInputValue.maxVal),
        };
      }
    }
    let data = {
      size: 16,
      page: pageMarketplace,
      series_id: marketplaceFilter.series,
      no_of_editions: rarityMinMax, //rarity
      tags: marketplaceFilter.tags,
      price_value: priceMinMax,
      artist_ids: marketplaceFilter.artist,
      art_type: marketplaceFilter.artType,
      mktplace_type: marketplaceFilter.status[0] === 1 ? 0 : 1,
      // is_live: getStatus("live") ? 1 : 0,
      filter: marketplaceFilter.optionFilter.value,
    };
    try {
      let response = await dispatch(getAllMarketPlaceNft(data));

      setMarketPlaceNfts(response.data.data.rows);
      setTotalPages(response.data.data.totalPages);
    } catch (err) {}
  };

  const getAllArtistList = async () => {
    try {
      let response = await dispatch(getAllArtist());
      setArtistList(response.data.data);
    } catch (err) {}
  };

  const getAllSeriesList = async () => {
    try {
      let response = await dispatch(getAllSeries());
      setSeriesList(response.data.data);
    } catch (err) {}
  };

  const getAllTagList = async () => {
    try {
      let response = await dispatch(getAllTags());
      setTagList(response.data.data);
    } catch (err) {}
  };

  const handalClick = () => {
    setToggleMenu(!toggleMenu);
  };

  const onCheckUncheckFilter = (e, item, id) => {
    let isChecked = e.target.checked;
    setSearchParams({ page: "1" });
    console.log(item, id);
    switch (item.title) {
      case "Status": {
        let statusItem = [...marketplaceFilter.status];
        if (id !== statusItem[0]) {
          statusItem.pop(); // remove the element is just for we have should the only one checkbox checked at a one time
          statusItem.push(id);
          dispatch(setMarketplaceFilter({ type: "status", data: statusItem }));
        }
        // else {
        //   const indexed = statusItem.indexOf(id);
        //   if (indexed !== -1) {
        //     statusItem.splice(indexed, 1);
        //   }
        //   dispatch(setMarketplaceFilter({ type: "status", data: statusItem }));
        // }
        break;
      }
      case "Artist": {
        let artistItem = [...marketplaceFilter.artist];
        if (isChecked) {
          artistItem.push(id);
          dispatch(setMarketplaceFilter({ type: "artist", data: artistItem }));
        } else {
          const indexed = artistItem.indexOf(id);
          if (indexed !== -1) {
            artistItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "artist", data: artistItem }));
        }
        break;
      }
      case "Series": {
        let seriesItem = [...marketplaceFilter.series];
        if (isChecked) {
          seriesItem.push(id);
          dispatch(setMarketplaceFilter({ type: "series", data: seriesItem }));
        } else {
          const indexed = seriesItem.indexOf(id);
          if (indexed !== -1) {
            seriesItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "series", data: seriesItem }));
        }
        break;
      }
      case "Price": {
        dispatch(
          setMarketplaceFilter({
            type: "priceInputValue",
            data: { minVal: "", maxVal: "" },
          })
        );
        let priceItem = [...marketplaceFilter.price];
        if (isChecked) {
          priceItem.push(id);
          dispatch(setMarketplaceFilter({ type: "price", data: priceItem }));
        } else {
          const indexed = priceItem.indexOf(id);
          if (indexed !== -1) {
            priceItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "price", data: priceItem }));
        }
        break;
      }
      case "Rarity": {
        let rarityItem = [...marketplaceFilter.rarity];
        if (isChecked) {
          rarityItem.push(id);
          dispatch(setMarketplaceFilter({ type: "rarity", data: rarityItem }));
        } else {
          const indexed = rarityItem.indexOf(id);
          if (indexed !== -1) {
            rarityItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "rarity", data: rarityItem }));
        }
        break;
      }

      case "Type": {
        let typeItem = [...marketplaceFilter.artType];

        if (isChecked) {
          typeItem.push(id);
          // setType(typeItem);
          dispatch(setMarketplaceFilter({ type: "artType", data: typeItem }));
        } else {
          const indexed = typeItem.indexOf(id);
          if (indexed !== -1) {
            typeItem.splice(indexed, 1);
          }
          // setType(typeItem);
          dispatch(setMarketplaceFilter({ type: "artType", data: typeItem }));
        }
        break;
      }
      case "Tags": {
        let tagsItem = [...marketplaceFilter.tags];
        if (isChecked) {
          tagsItem.push(id);
          dispatch(setMarketplaceFilter({ type: "tags", data: tagsItem }));
        } else {
          const indexed = tagsItem.indexOf(id);
          if (indexed !== -1) {
            tagsItem.splice(indexed, 1);
          }
          dispatch(setMarketplaceFilter({ type: "tags", data: tagsItem }));
        }
        break;
      }
    }
  };
  const getCheckedStatus = (item, id) => {
    switch (item.title) {
      case "Status": {
        if (marketplaceFilter.status.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Artist": {
        if (marketplaceFilter.artist.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Series": {
        if (marketplaceFilter.series.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Price": {
        if (marketplaceFilter.price.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Rarity": {
        if (marketplaceFilter.rarity.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Type": {
        if (marketplaceFilter.artType.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
      case "Tags": {
        if (marketplaceFilter.tags.includes(id)) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const setSearchByType = (e, type) => {
    let searchText = e.target.value;
    setSearchType(type);
    switch (type) {
      case "Artist": {
        setSearchArtist(searchText);
        break;
      }
      case "Series": {
        setSearchSeries(searchText);
        break;
      }
      case "Type": {
        setSearchInType(searchText);
        break;
      }
      case "Tags": {
        setSearchTag(searchText);
        break;
      }
    }
  };

  const filteredData = (data, type) => {
    switch (type) {
      case "Artist": {
        let filterData = data.filter(
          (artist) =>
            artist.name?.toLowerCase().includes(searchArtist.toLowerCase())
          /* ||
            artist.first_name
              ?.toLowerCase()
              .includes(searchArtist.toLowerCase()) ||
            artist.last_name?.toLowerCase().includes(searchArtist.toLowerCase()) */
        );
        return filterData.slice(0, 5);
      }
      case "Series": {
        let filterData = data.filter((series) =>
          series.name.toLowerCase().includes(searchSeries.toLowerCase())
        );
        return filterData.slice(0, 5);
      }
      case "Type": {
        let filterData = data.filter((type) =>
          type.name.toLowerCase().includes(searchInType.toLowerCase())
        );
        return filterData.slice(0, 5);
      }
      case "Tags": {
        let filterData = data.filter((tags) =>
          tags.tag_name.toLowerCase().includes(searchTag.toLowerCase())
        );
        return filterData.slice(0, 5);
      }
      case "Price": {
        return data;
      }
      case "Rarity": {
        return data;
      }
      case "Status": {
        return data;
      }
    }
  };

  const showAllListItem = (type) => {
    setSearchType(type);
    setShowmore(true);

    switch (type) {
      case "Artist": {
        setFilterAllList(artistList);
        break;
      }
      case "Series": {
        setFilterAllList(seriesList);
        break;
      }

      case "Tags": {
        setFilterAllList(tagList);
        break;
      }
    }
  };

  const getRecordsAsperMinMax = () => {
    if (
      Number(marketplaceFilter.priceInputValue.minVal) <=
      Number(marketplaceFilter.priceInputValue.maxVal)
    ) {
      dispatch(setMarketplaceFilter({ type: "price", data: [] }));
      getAllMarketPlaceApi();
    } else {
      toasts.error("Max value should greater than or equal to min value");
    }
  };
  const dropvalue = [
    { href: "#", droptitle: "Newest", value: 1 },
    { href: "#", droptitle: "Oldest", value: 2 },
    { href: "#", droptitle: "Price- Low to high", value: 3 },
    { href: "#", droptitle: "Price- High to low", value: 4 },
  ];

  const clearSearchTextFxn = (type) => {
    switch (type) {
      case "Artist": {
        setSearchArtist("");
        break;
      }
      case "Series": {
        setSearchSeries("");
        break;
      }
      case "Type": {
        setSearchInType("");
        break;
      }
      case "Tags": {
        setSearchTag("");
        break;
      }
    }
  };

  const getEdtionInfoRender = (item) => {
    if (item?.drop_type === 4) {
      return `Edition Of ${getNumberWithComma(item?.sold_no_of_editions)}`;
    } else {
      return `Edition Of ${getNumberWithComma(item?.no_of_editions)}`;
    }
  };

  const getPriceToRender = (item) => {
    if (marketplaceFilter.status[0] === 1) {
      return `₹${getNumberWithComma(Number(item?.price) / DECIMAL)}`;
    } else {
      if (Number(item?.min_selling_price) === 0) {
        return `Not available for sale`;
      }
      return `₹${getNumberWithComma(
        Number(item?.min_selling_price) / DECIMAL
      )}`;
    }
  };

  const getUsernameToRender = (item) => {
    if (marketplaceFilter.status[0] === 1) {
      return `@${item?.art_user?.username}`;
    } else {
      return `@${item?.username}`;
    }
  };

  const getArtTypeHandler = (item) => {
    let artId =
      marketplaceFilter.status[0] === 1 ? item?.art_type_id : item?.art_type;
    switch (artId) {
      case 1:
        return "DYNAMIC";
      case 2:
        return "GENERATIVE";
      case 3:
        return "";
      case 4:
        return "RESPONSIVE";
    }
  };

  const navigationOfArtHandler = (item) => {
    if (marketplaceFilter.status[0] === 1) {
      navigate(`/curated-drop/digital-art/live/${item?.id}`);
    } else {
      navigate(`/marketplace/art/${item?.art_id}`);
    }
  };

  const getImageSection = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          muted={false}
          // onClick={() => onclickHandler(item)}
          onClick={() => navigationOfArtHandler(item)}
          className={`video_hover_sec ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <CommonCard
              className={styles.cardmarket}
              imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
              to={item.to}
              title={item.art_name}
              subtitle={getUsernameToRender(item)}
              bottomclass="align-items-center"
              bottomTitle={getPriceToRender(item)}
              bottomSubTitle={getEdtionInfoRender(item)}
              linkAction={
                marketplaceFilter.status[0] === 1 ? "Buy Now" : "Make Offer"
              }
              // quickbuy={
              //   item?.user_id != userId &&
              //   marketplaceFilter.isSale != 0 &&
              //   "Quick Buy"
              // }
              onClickbuy={(e) => {
                e.stopPropagation();
                if (isUserLoggedin) {
                  dispatch(
                    saveLatestPrice({
                      price: Number(item.min_selling_price) / DECIMAL,
                      market: "secondary",
                      quantity: 1,
                      editionNumber: item.edition_number,
                    })
                  );
                  navigate(`/checkout/art/${item.id}`);
                } else {
                  dispatch(saveNextRoute(Routes_Urls.Marketplace));
                  dispatch(openPopUp("login"));
                }
              }}
              tagTitile={getArtTypeHandler(item)}
              item={item}
              onClick={() => navigationOfArtHandler(item)}
            />
          }
        />
      );
    } else {
      return (
        <CommonCard
          className={styles.cardmarket}
          imgUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.thumbnail_image}`}
          featureUrl={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          to={item.to}
          title={item.art_name}
          subtitle={getUsernameToRender(item)}
          bottomclass="align-items-center"
          livedata="Live"
          bottomTitle={getPriceToRender(item)}
          bottomSubTitle={getEdtionInfoRender(item)}
          linkAction={
            marketplaceFilter.status[0] === 1 ? "Buy Now" : "Make Offer"
          }
          // quickbuy={
          //   item?.user_id != userId &&
          //   marketplaceFilter.isSale != 0 &&
          //   "Quick Buy"
          // }
          onClickbuy={(e) => {
            e.stopPropagation();
            if (isUserLoggedin) {
              dispatch(
                saveLatestPrice({
                  price: Number(item.min_selling_price) / DECIMAL,
                  market: "secondary",
                  quantity: 1,
                  editionNumber: item.edition_number,
                })
              );
              navigate(`/checkout/art/${item.id}`);
            } else {
              dispatch(saveNextRoute(Routes_Urls.Marketplace));
              dispatch(openPopUp("login"));
            }
          }}
          tagTitile={getArtTypeHandler(item)}
          item={item}
          onClick={() => navigationOfArtHandler(item)}
        />
      );
    }
  };

  return (
    <>
      <section className={styles.Marketplace}>
        <Helmet>
          <title>Marketplace | Buy and Sell Digital Art | NewArtX</title>
          <meta
            name="description"
            content="Explore NewArtX Marketplace to buy and sell premium digital fine art. Connect with artists, collectors, and discover unique digital masterpieces."
          />
        </Helmet>

        <Container>
          <Row>
            <div className={styles.Marketplace_Titles}>
              <div className={styles.headingtitle}>
                <h1>Marketplace</h1>
              </div>
              <ul className={styles.btnfilter}>
                <li className={styles.arttoggleBtn}>
                  <button onClick={handalClick}>
                    <FilterIcon />
                  </button>
                </li>
                <li>
                  <Commondropdown
                    dropdowntitle={
                      marketplaceFilter.optionFilter.title == ""
                        ? "Sort By"
                        : marketplaceFilter.optionFilter.title
                    }
                  >
                    {dropvalue.map((data) => (
                      <Dropdown.Item
                        href={data.href}
                        onClick={() => {
                          dispatch(
                            setMarketplaceFilter({
                              type: "optionFilter",
                              data: {
                                title: data.droptitle,
                                value: data.value,
                              },
                            })
                          );
                        }}
                      >
                        {data.droptitle}
                      </Dropdown.Item>
                    ))}
                  </Commondropdown>
                </li>
              </ul>
            </div>
          </Row>
          <div className={styles.MarketplaceMain}>
            <Row>
              <Col
                md={3}
                lg={3}
                xl={2}
                className={`sidefilter ${toggleMenu == true ? "show" : "hide"}`}
              >
                <div className={styles.MarketplaceMainLeft}>
                  <form>
                    <ul>
                      {MarketFilterList.map((item, index) => (
                        <li>
                          <div className={styles.vaule_selected}>
                            <div className={styles.serch_head}>
                              <h5>{item.title}</h5>
                              {item.Searchbar && (
                                <div className={styles.serch_value}>
                                  <FormikControls
                                    variant={styles.searchBar}
                                    rightIcon={
                                      (item.title == "Artist" &&
                                        searchArtist == "") ||
                                      (item.title == "Series" &&
                                        searchSeries == "") ||
                                      (item.title == "Tags" &&
                                        searchTag == "") ||
                                      (item.title == "Type" &&
                                        searchInType == "") ? (
                                        <SearchIcon />
                                      ) : (item.title == "Artist" &&
                                          searchArtist !== "") ||
                                        (item.title == "Series" &&
                                          searchSeries !== "") ||
                                        (item.title == "Tags" &&
                                          searchTag !== "") ||
                                        (item.title == "Type" &&
                                          searchInType !== "") ? (
                                        <CloseIcon />
                                      ) : (
                                        ""
                                      )
                                    }
                                    control="input"
                                    type="text"
                                    name="search"
                                    rightIconClass={
                                      (item.title == "Artist" &&
                                        !(searchArtist == "")) ||
                                      (item.title == "Series" &&
                                        !(searchSeries == "")) ||
                                      (item.title == "Tags" &&
                                        !(searchTag == "")) ||
                                      (item.title == "Type" &&
                                        !(searchInType == ""))
                                        ? styles.clickable
                                        : ""
                                    }
                                    rightIconClick={() => {
                                      if (
                                        (item.title == "Artist" &&
                                          searchArtist !== "") ||
                                        (item.title == "Series" &&
                                          searchSeries !== "") ||
                                        (item.title == "Tags" &&
                                          searchTag !== "") ||
                                        (item.title == "Type" &&
                                          searchInType !== "")
                                      ) {
                                        clearSearchTextFxn(item.title);
                                      }
                                    }}
                                    placeholder={`Search ${item.title}`}
                                    onChange={(e) =>
                                      setSearchByType(e, item.title)
                                    }
                                    value={
                                      item.title == "Artist"
                                        ? searchArtist
                                        : item.title == "Series"
                                        ? searchSeries
                                        : item.title == "Tags"
                                        ? searchTag
                                        : searchInType
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            {filteredData(item.options, item.title).map(
                              (data, index) => (
                                <FormikControls
                                  key={index}
                                  className="fieldBlack checkInfoBtn"
                                  control="checkbox"
                                  label={
                                    item.title == "Artist"
                                      ? data.name
                                      : item.title == "Series"
                                      ? data.name
                                      : item.title == "Series"
                                      ? data.name
                                      : item.title == "Tags"
                                      ? data.tag_name
                                      : data.label
                                  }
                                  checked={getCheckedStatus(
                                    item,
                                    item.title == "Tags"
                                      ? data.tag_name
                                      : data.id
                                  )}
                                  onChange={(e) => {
                                    onCheckUncheckFilter(
                                      e,
                                      item,
                                      item.title == "Tags"
                                        ? data.tag_name
                                        : data.id
                                    );
                                  }}
                                  isInfo={data.isInfo}
                                  infoMessage={data.infoMessage}
                                />
                              )
                            )}

                            {filteredData(item.options, item.title).length ==
                              0 && <p>No records found</p>}

                            {((item.title == "Artist" && searchArtist == "") ||
                              (item.title == "Series" && searchSeries == "") ||
                              (item.title == "Tags" && searchTag == "") ||
                              (item.title == "Type" && searchInType == "")) &&
                              item?.options.length > 5 && (
                                <CommonBtn
                                  className={`bg-border-without ${styles.morebtn}`}
                                  role="btn"
                                  title={
                                    <>
                                      <span>+</span> {item?.options.length - 5}{" "}
                                      more
                                    </>
                                  }
                                  type="button"
                                  onClick={() => showAllListItem(item?.title)}
                                />
                              )}
                          </div>
                          {item.minMaxvalue && (
                            <div className={styles.min_max_value}>
                              <FormikControls
                                key={index}
                                className={styles.borderform}
                                control="input"
                                type="text"
                                name="minvalue"
                                id="minvalue"
                                placeholder="Min."
                                value={marketplaceFilter.priceInputValue.minVal}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*\.?\d*$/.test(value)) {
                                    dispatch(
                                      setMarketplaceFilter({
                                        type: "priceInputValue",
                                        data: {
                                          ...marketplaceFilter.priceInputValue,
                                          minVal: value,
                                        },
                                      })
                                    );
                                  }
                                }}
                              />
                              <FormikControls
                                key={index}
                                className={styles.borderform}
                                control="input"
                                name="maxvalue"
                                type="text"
                                id="maxvalue"
                                placeholder="Max."
                                value={marketplaceFilter.priceInputValue.maxVal}
                                onChange={(e) => {
                                  //regex for only digit
                                  const value = e.target.value;
                                  if (/^\d*\.?\d*$/.test(value)) {
                                    dispatch(
                                      setMarketplaceFilter({
                                        type: "priceInputValue",
                                        data: {
                                          ...marketplaceFilter.priceInputValue,
                                          maxVal: value,
                                        },
                                      })
                                    );
                                  }
                                }}
                              />
                              <CommonBtn
                                className={`black-btn ${styles.btngo}`}
                                role="btn"
                                title="GO"
                                type="button"
                                onClick={() => getRecordsAsperMinMax()}
                              />
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  </form>
                </div>
              </Col>
              <Col md={12} lg={9} xl={10}>
                <div className={styles.market_cardsec}>
                  <Row>
                    {marketPlaceNfts.map((item, index) => (
                      <Col xxl={3} xl={4} lg={6} md={6} sm={6}>
                        {getImageSection(item)}
                      </Col>
                    ))}
                  </Row>
                  {marketPlaceNfts.length === 0 && (
                    <Notfound text={"No digital art found."} />
                  )}
                </div>
                {totalPages > 1 && (
                  <CustomPagination
                    className="ms-auto"
                    activePage={pageMarketplace}
                    totalPage={totalPages}
                    setSearchParams={setSearchParams}
                    setActivePage={setPageMarketplace}
                    maxVisiblePages={5}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <QuickBuyModal
        show={show}
        onHide={() => setShow(false)}
        artImg={artImg}
        creatorFees="xhsdbssdfj"
        processingFees={"fkgfakdf"}
        texas={"gfsgdf"}
        totalAmount={"NXJSKWK"}
        artistName="@artistname"
        edition={1}
        totalEditions={5}
      />
      {toggleMenu && (
        <div className={styles.ovarlay} onClick={() => setToggleMenu(false)} />
      )}
      {showmore && (
        <Moremodal
          show={showmore}
          data={fileterAllList}
          onHide={() => setShowmore(false)}
          searchType={searchType}
          getCheckedStatus={getCheckedStatus}
          artist={marketplaceFilter.artist}
          tag={marketplaceFilter.tags}
          series={marketplaceFilter.series}
        />
      )}
    </>
  );
};
export default Marketplace;
