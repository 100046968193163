import React from "react";
import { Field, ErrorMessage } from "formik";
import Form from "react-bootstrap/Form";
import TextError from "./TextError/TextError";

const Radio = (props) => {
  const {name, label, className, formik, checked, onChange, disabled, ...rest } = props;
  return (
    // <Form.Group className="common_radio" controlId={name}>
    //   <Form.Label>{label}</Form.Label>
    //   <Field name={name} {...rest}>
    //     {({ field }) => {
    //       return options.map((option) => {
    //         return (
    //           <React.Fragment key={option.key}>
    //             <input
    //               type="radio"
    //               id={option.value}
    //               {...field}
    //               value={option.value}
    //               checked={field.value === option.value}
    //             />
    //             <label htmlFor={option.value}>{option.key}</label>
    //           </React.Fragment>
    //         );
    //       });
    //     }}
    //   </Field>
    //   <ErrorMessage name={name} component={TextError} />
    // </Form.Group>
    
    <Form.Group className={`common_input ${className}`} controlId={name}>
      <Form.Check
        type="radio"
        label={label}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {/* <ErrorMessage name={name} component={TextError} /> */}
      {formik?.touched[name] && (
        <p className="error_message">{formik?.errors[name]}</p>
      )}
    </Form.Group>
  );
};

export default Radio;
