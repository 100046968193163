import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./Footer.module.scss";
import Logo from "../../../Assets/images/logo.png";
import { Link } from "react-router-dom";
import GetUpdates from "./GetUpdates/GetUpdates";
import { useSelector } from "react-redux";
import { Routes_Urls } from "../../../Constant/Route_url";

const Footer = () => {
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  return (
    <div>
      <GetUpdates />
      <footer className={styles.footer}>
        <Container>
          <Row className={styles.footer_top}>
            <Col lg={3} className={styles.footercontent}>
              <img className={styles.footer_Logo} src={Logo} alt="Logo" />
              <div className={styles.infologs}>
                <p>
                  A curated Digital Fine Art marketplace that is redefining the
                  boundaries of Fine Art by leveraging technology and
                  blockchain. We aim to create an end to end ecosystem of
                  Digital Fine Art - from Fine Art digital creation to exchange
                  to display.{" "}
                </p>
              </div>
            </Col>
            <Col lg className={styles.linkList}>
              <h4>Buy</h4>
              <ul>
                {/* <li>
                  <Link to={Routes_Urls.Curated_Drop_page}>Curated Drops</Link>
                </li> */}
                <li>
                  <Link to={Routes_Urls.Marketplace}>Marketplace</Link>
                </li>
              </ul>
            </Col>
            <Col lg className={styles.linkList}>
              <h4>Display</h4>
              <ul>
                <li>
                  <Link to={Routes_Urls.FlauntPage}>
                    How to Flaunt Your DFAs
                  </Link>
                </li>
                {/* <li>
                    <Link to={Routes_Urls.NewArtXApp}>NewArtX App</Link>
                  </li>
                  <li>
                    <Link to="https://naxcanvas.com/">
                      Buy NAXCanvas Digital Art Frames
                    </Link>
                  </li> */}
              </ul>
            </Col>
            <Col lg className={styles.linkList}>
              <h4>NewArtX</h4>
              <ul>
                <li>
                  <Link to={Routes_Urls.About_dfa}>What are DFAs</Link>
                </li>
                <li>
                  <Link to={Routes_Urls.Create}>Create With NAX Studio</Link>
                </li>
                <li>
                  <Link to={Routes_Urls.About_newArtX}>About NewArtX</Link>
                </li>
                <li>
                  <Link to={Routes_Urls.Faq}>FAQs</Link>
                </li>
                <li>
                  <Link to={Routes_Urls.Contact_us}>Contact Us</Link>
                </li>
                {/* <li>
                    <Link to="/nax-blog">NAX Blog</Link>
                  </li>
                  <li>
                    <Link to="/help">Help</Link>
                  </li> */}
              </ul>
            </Col>
            <Col lg className={`${styles.linkList} ${styles.listHide}`}>
              {/* <h4></h4> */}
              <ul>
                <li>
                  <Link to={Routes_Urls.About_us}>About Us</Link>
                </li>
                {/* <li><Link to="#">Mission Statement</Link></li> */}
                <li>
                  <Link to={Routes_Urls.Terms_of_use}>Terms Of Service</Link>
                </li>
                <li>
                  <Link to={Routes_Urls.PrivacyPolicy}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={Routes_Urls.Shiping_policy}>Shipping Policy</Link>
                </li>
                <li>
                  <Link to={Routes_Urls.Refund_return_policy}>
                    Refund and Returns Policy
                  </Link>
                </li>
              </ul>
            </Col>
            {/* <Col lg className={styles.linkList}>
                <h4>Stats</h4>
                <ul>
                  <li>
                    <Link to="/stats/activity">Activity</Link>
                  </li>
                  {isUserLoggedin && (
                    <li>
                      <Link to="/stats/leader-boards">Leaderboard</Link>
                    </li>
                  )}
                </ul>
              </Col> */}
          </Row>
        </Container>
        <div className={styles.footer_copyright}>
          <Container>
            <p>&copy;Elephant Canvas LLP</p>
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
