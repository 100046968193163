import React from "react";
import "./NewsCard.scss";
import { sliceChar } from "../../../../Helper/common";

const NewsCard = ({
  to,
  className,
  imgUrl,
  title,
  subtitle,
  onClick,
  notified,
  bottomTitle,
  bottomclass,
  bottomSubTitle,
  isCreatedArt,
}) => {
  return (
    <>
      <div
        to={to ? to : "javascript:void(0)"}
        className={`CommonCard ${className}`}
        onClick={onClick ? onClick : () => {}}
      >
        <div className="image_section_hover">
          <img className="thumnail_img" src={imgUrl} alt="" />
        </div>
        <div className="CommonCardContentHandle">
          <div className="CommonCardContentHandleTop">
            <div className="CommonCardContentHandleTopLeft">
              <h3 title={title}>{sliceChar(title, 30)}</h3>
              <p title={subtitle}>{sliceChar(subtitle, 30)}</p>
            </div>
            <div className="CommonCardContentHandleTopRight">
              <h3>{notified}</h3>
            </div>
          </div>

          <div className={`CommonCardContentHandleBottom ${bottomclass}`}>
            <div className="CommonCardContentHandleBottomLeft">
              {isCreatedArt ? (
                <p>Price : ₹{bottomTitle}</p>
              ) : (
                <p> {bottomTitle}</p>
              )}

              <p>
                <strong>{bottomSubTitle}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsCard;
