import React from "react";
import { Link } from "react-router-dom";
import CommonModal from "../../Common/Ui/CommonModal/CommonModal";
import style from "./ClosedDropModal.module.scss";

const ClosedDropModal = ({ show, handleClose, artDetail, onclickHandler }) => {
  return (
    <>
      <CommonModal
        backdropclass="fade_clrbg"
        className={style.closedDropModal}
        centered
        show={show}
        onHide={handleClose}
        modalTitle="Closed Drop"
      >
        <div className={style.innerSec}>
          <p>
            Gain entry to the realm of <span>[{artDetail?.art_name}]</span> –
            Exclusivity granted only to owners of{" "}
            {artDetail?.art_restricted_id?.length > 0 && (
              <>
                [
                {artDetail?.art_restricted_id?.map((item) => (
                  <>
                    {(artDetail.art_restricted_type == 1 ||
                      artDetail.art_restricted_type == 4) && (
                      <Link
                        to={onclickHandler(
                          item,
                          artDetail.art_restricted_type == 1 ? "artist" : "art"
                        )}
                        onClick={() => handleClose()}
                      >
                        {item.name}
                        {","}
                      </Link>
                    )}
                    {(artDetail.art_restricted_type == 2 ||
                      artDetail.art_restricted_type == 3) && (
                      <>
                        {item.name}
                        {", "}
                      </>
                    )}
                  </>
                ))}
                ].
              </>
            )}
            Elevate your status and secure your membership to unlock the unique
            world of digital art. Don't miss out, become an exclusive member of
            <span>
              {" "}
              {artDetail?.art_restricted_id?.length > 0 && (
                <>
                  [
                  {artDetail?.art_restricted_id?.map((item) => (
                    <> {item.name},</>
                  ))}
                  ].
                </>
              )}
            </span>{" "}
            by purchasing it.
          </p>
        </div>
      </CommonModal>
    </>
  );
};

export default ClosedDropModal;
