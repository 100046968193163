import React, { useEffect, useState } from "react";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Banner from "../../../Assets/images/profile-bg.png";
import style from "./Userprofile.module.scss";
import Carddetails from "./Carddetails/Carddetails";
import { Container, Tab, Tabs } from "react-bootstrap";
import Artowned from "./Artowned/Artowned";
import Followers from "./Followers/Followers";
import Following from "./Following/Following";
import Like from "./Like/Like";
import {
  getUserArtFollowersLikes,
  followUnfollowAndRemove,
  getUserLikesData,
  likeUnlikeArt,
  setPageUserProfile,
  setTabTypeUserProfile,
} from "../../../Features/user/userSlice";

import { useDispatch, useSelector } from "react-redux";
import {
  COMPARISION_CONSTANT_NAMES,
  PER_PAGE_RECORD_LIMIT,
  USER_PROFILE_TAB,
  USER_TYPE,
} from "../../../Constant/userConstant";
import { useDebounce } from "../../../customHooks/useDebounce";
import { getArts } from "../../../Features/drop/dropSlice";
import ArtCreated from "./ArtCreated/ArtCreated";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Helmet } from "react-helmet";

const Userprofile = () => {
  const dispatch = useDispatch();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [likes, setLikes] = useState([]);
  const [artOwned, setArtOwned] = useState([]);
  const [createdArt, setCreatedArt] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);
  const userType = useSelector((state) => state.persist.userType);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const pageUserProfile = useSelector((state) => state.users.pageUserProfile);
  const userDetail = useSelector((state) => state?.users?.profileData);

  const tabTypeUserProfile = useSelector(
    (state) => state.users.tabTypeUserProfile
  );

  useEffect(() => {
    if (
      debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
      debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE
    ) {
      if (
        tabTypeUserProfile == USER_PROFILE_TAB.LIKES ||
        tabTypeUserProfile == USER_PROFILE_TAB.ART_OWN
      ) {
        getUserLikes();
      } else if (tabTypeUserProfile == USER_PROFILE_TAB.ART_CREATED) {
        getUserCreatedArt();
      } else {
        getUserArtFollowersLikesAPI();
      }
    }
  }, [tabTypeUserProfile, pageUserProfile, debouncedSearchTerm, ArtistId]);

  const getUserCreatedArt = async () => {
    let data = {
      id: ArtistId,
      size: PER_PAGE_RECORD_LIMIT.ART_CREATED_LIMIT,
      page: search !== "" ? 1 : pageUserProfile,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getArts(data));
      if (response) {
        let record = response.data.data;
        setTotalPage(record.totalPages);
        setCreatedArt(record?.arts);
      }
    } catch (err) { }
  };

  const getUserLikes = async () => {
    let data = {
      filter: tabTypeUserProfile,
      page: search !== "" ? 1 : pageUserProfile,
      size: PER_PAGE_RECORD_LIMIT.PROFILE_TAB_PER_PAGE,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getUserLikesData(data));
      if (response) {
        let record = response.data.data;
        if (tabTypeUserProfile == USER_PROFILE_TAB.ART_OWN) {
          setTotalPage(record.totalPages);
          setArtOwned(record.data);
        }
        if (tabTypeUserProfile == USER_PROFILE_TAB.LIKES) {
          setTotalPage(record.totalPages);
          setLikes(record.data);
        }
      }
    } catch (err) { }
  };

  const getUserArtFollowersLikesAPI = async () => {
    let data = {
      filter: tabTypeUserProfile,
      page: search !== "" ? 1 : pageUserProfile,
      size: PER_PAGE_RECORD_LIMIT.PROFILE_TAB_PER_PAGE,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getUserArtFollowersLikes(data));
      if (response) {
        let record = response.data.data;
        setTotalPage(record.totalPages);
        if (tabTypeUserProfile == "FOLLOWING") {
          setFollowing(record.data);
        }
        if (tabTypeUserProfile == "FOLLOWERS") {
          setFollowers(record.data);
        }
      }
    } catch (err) { }
  };

  const onTabChange = (e) => {
    setSearch("");
    dispatch(setPageUserProfile(1));
    setTotalPage(0);
    dispatch(setTabTypeUserProfile(e));
  };

  const followUnfollowAndRemoveApi = async (type, data) => {
    // type=0 (UNFOLLOW) , 1 ( FOLLOW) , 2 (REMOVE)
    let record = {
      type: type == "remove" ? 2 : type == "follow" ? 1 : 0,
      id:
        type == "remove"
          ? data.user.id
          : type == "unfollow"
            ? data.user.id
            : "",
    };
    try {
      let response = await dispatch(followUnfollowAndRemove(record));
      if (response) {
        getUserArtFollowersLikesAPI();
      }
    } catch (err) { }
  };

  const likeUnlikeLikeArt = async (art_id) => {
    let record = {
      type: 0,
      art_id: art_id,
    };
    try {
      let response = await dispatch(likeUnlikeArt(record));
      if (response) {
        getUserLikes();
      }
    } catch (err) { }
  };

  return (
    <>
      <section className={style.user_profile}>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
        <CommonHero
          backgroundImg={
            userDetail?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
              : CoverImage
          }
        />
        <Carddetails tab="profile" Btnshare />
        <Container>
          <div className={style.tablist}>
            <Tabs
              activeKey={tabTypeUserProfile}
              id="uncontrolled-tab-example"
              className="tablists"
              onSelect={onTabChange}
            >
              <Tab eventKey={USER_PROFILE_TAB.ART_OWN} title={"ART OWN"}>
                {tabTypeUserProfile === USER_PROFILE_TAB.ART_OWN && (
                  <Artowned
                    data={artOwned}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    getUserLikes={getUserLikes}
                  />
                )}
              </Tab>
              {userType == USER_TYPE.ARTIST && (
                <Tab
                  eventKey={USER_PROFILE_TAB.ART_CREATED}
                  title={"ART CREATED"}
                >
                  {tabTypeUserProfile === USER_PROFILE_TAB.ART_CREATED && (
                    <ArtCreated
                      data={createdArt}
                      setSearch={setSearch}
                      search={search}
                      page={pageUserProfile}
                      totalPage={totalPage}
                      followUser={followUnfollowAndRemoveApi}
                      getUserLikes={getUserLikes}
                    />
                  )}
                </Tab>
              )}
              <Tab
                eventKey={USER_PROFILE_TAB.FOLLOWERS}
                title={USER_PROFILE_TAB.FOLLOWERS}
              >
                {tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWERS && (
                  <Followers
                    data={followers}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    type="self"
                  />
                )}
              </Tab>
              <Tab
                eventKey={USER_PROFILE_TAB.FOLLOWING}
                title={USER_PROFILE_TAB.FOLLOWING}
              >
                {tabTypeUserProfile === USER_PROFILE_TAB.FOLLOWING && (
                  <Following
                    data={following}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    type="self"
                  />
                )}
              </Tab>
              <Tab
                eventKey={USER_PROFILE_TAB.LIKES}
                title={USER_PROFILE_TAB.LIKES}
              >
                {tabTypeUserProfile === USER_PROFILE_TAB.LIKES && (
                  <Like
                    data={likes}
                    setSearch={setSearch}
                    search={search}
                    page={pageUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    likeUnlikeArt={likeUnlikeLikeArt}
                    type="self"
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </Container>

        {/* <ContactsupportModal
          show={showContact}
          handleClose={() => setShowContact(false)}
        /> */}
      </section>
    </>
  );
};

export default Userprofile;
