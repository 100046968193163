import React, { useEffect, useState } from "react";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import CommonTable from "../../../Common/Ui/CommonTable/CommonTable";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import style from "./Connnecteddevice.module.scss";
import Removedevicemodal from "./Removedevicemodal";
import Renamedevicenamemodal from "./Renamedevicenamemodal";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { sliceChar } from "../../../../Helper/common";

const Connnecteddevice = ({
  artClick,
  setDeviceId,
  selectedTab,
  ConnectedDeviceList,
  deviceList,
  page,
  setPage,
  totalPages,
  setId,
  setArtId,
  setDummyData
}) => {
  const [showRemove, setShowRemove] = useState(false);
  const [showRename, setShowRename] = useState(false);

  const [dataSave, setDataSave] = useState();

  const dispatch = useDispatch();
  
  const HandleData = (data) => {
    setDataSave(data);
  };
  return (
    <>
      <section className={style.connnected_device}>
        <CommonTable className={style.bidder_table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Device ID</th>
              <th>Location</th>
              <th>Art</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deviceList.map((data,index) => (
              <tr>
                <td title={data?.device_name}>{sliceChar(data?.device_name, 15)}</td>
                <td title={data.device_info}>{sliceChar(data.device_info,15)}</td>
                <td title={data.location}>{sliceChar(data.location,15)}</td>
                <td>
                  <div className="art_devices">
                   {data?.art?.length > 0 ? <img
                      src={
                        data?.art?.length > 0
                         && `${process.env.REACT_APP_IMG_BASE_URL}${data?.art[0]?.thumbnail_image}`
                      }
                      alt=""
                    /> : " " }
                    <span title={data?.art?.length > 0 && data?.art[0]?.art_name}>
                      {data?.art?.length > 0 && sliceChar(data?.art[0]?.art_name,15)
                        ? sliceChar(data?.art[0]?.art_name,15)
                        : "------------"}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="btns_devices">
                    <CommonBtn
                      title={data?.art?.length > 0 ? "Change Art" : "Add Art"}
                      role="btn"
                      className="bordred-black btn_size me-3"
                      onClick={() => {
                        artClick();
                        setDeviceId(data.device_info);
                        if(data.art.length){
                          setArtId(data.art[0].id);
                        }else{
                          setArtId(0);
                        }
                      
                        setDummyData(data?.art?.length);
                        // dispatch(setDeviceIds(data.device_id));
                        // updateSelectedArtHandler(data.device_id);
                      }}
                    />
                    <CommonBtn
                      title="Rename Device"
                      role="btn"
                      className="bordred-black btn_size me-3"
                      onClick={() => {
                        setShowRename(true);
                        HandleData(data);
                      }}
                    />
                    <CommonBtn
                      title="Remove Device"
                      role="btn"
                      className="bordred-black btn_size"
                      onClick={() => {
                        setShowRemove(true);
                        HandleData(data);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          {deviceList.length == 0 && (
            <tr>
              <td colSpan={9}>
                <p className="no_recordtable">No records found</p>
              </td>
            </tr>
          )}
        </CommonTable>
        {totalPages > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={totalPages}
            // setActivePage={setPage}
            maxVisiblePages={5}
            dispatch= {dispatch}
            componentType={"LinkDevice"}
          />
        )}
      </section>
      {showRemove && (
        <Removedevicemodal
          show={showRemove}
          handleClose={() => setShowRemove(false)}
          dataSave={dataSave}
          ConnectedDeviceList={ConnectedDeviceList}
        />
      )}
      <Renamedevicenamemodal
        show={showRename}
        handleClose={() => setShowRename(false)}
        dataSave={dataSave}
        ConnectedDeviceList={ConnectedDeviceList}
      />
    </>
  );
};

export default Connnecteddevice;
