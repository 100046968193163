import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import style from "./Addandchangeart.module.scss";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { PER_PAGE_RECORD_LIMIT } from "../../../../Constant/userConstant";
import { artOwnedList } from "../../../../Features/linkedDevice/linkedDeviceSlice";
import { useDispatch } from "react-redux";
import Notfound from "../../../Common/NotFound/Notfound";

const Addandchangeart = ({ updateSelectedArtHandler, artId, ArtistId }) => {
  const targetRef = useRef(null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    artOwnedListHandler();
  }, [page, ArtistId]);

  const handleScroll = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  const artOwnedListHandler = async () => {
    try {
      let params = {
        page: page,
        size: PER_PAGE_RECORD_LIMIT.ART_OWNED_SELECT_LIST_PER_PAGE,
      };
      const response = await dispatch(artOwnedList(params));
      setList(response.data.list);
      setData(response.data);
    } catch (error) {
      console.log("error in catch", error);
    }
  };
  return (
    <>
      <section className={style.add_changeart}>
        <Row ref={targetRef}>
          {list.map((item) => (
            <Col xl={3} lg={4} md={6} sm={6}>
              <div className={style.card_art}>
                <img
                  src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
                />
                <div class={style.card_art__body}>
                  <div class="relative mt-auto">
                    <h4 class={style.artist_name}>{item.art_name}</h4>
                    <h5 class={style.links_userId}>@{item.username}</h5>

                    {(item?.art_id != artId || artId == 0) && (
                      <div className={style.amount_Buy}>
                        <CommonBtn
                          title="Select Art"
                          role="btn"
                          type="button"
                          className={style.buy_select_srt}
                          onClick={() => {
                            updateSelectedArtHandler(item?.art_id);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        {list.length == 0 && <Notfound text={"No data found."} />}

        {data.totalPages > 1 && (
          <CustomPagination
            className="ms-auto"
            activePage={page}
            totalPage={data.totalPages}
            setActivePage={setPage}
            maxVisiblePages={5}
            handleScroll={handleScroll}
          />
        )}
      </section>
    </>
  );
};

export default Addandchangeart;
