import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loadingReducer from "../Features/loading/loadingSlice";
import userReducer from "../Features/user/userSlice";
import walletReducer from "../Features/wallet/walletSlice";
import historyReducer from "../Features/histroy/histroySlice";
import peristReducer from "../Features/persist/persistSlice";
import marketplaceReducer from "../Features/marketplace/marketplaceSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import offerSliceReducer from "../Features/offerSlice/offerSlice";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import anonymousUserReducer from "../Features/Anonymous/anonymousUserSlice";

const combinedReducer = combineReducers({
  loading: loadingReducer,
  users: userReducer,
  wallet: walletReducer,
  histroy: historyReducer,
  persist: peristReducer,
  marketplace: marketplaceReducer,
  offers: offerSliceReducer,
  anonymousUser: anonymousUserReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["persist"],
  stateReconciler: autoMergeLevel1,
};
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const rootReducer = (state, action) => {
  if (action.type === "users/logoutUser") {
    // Define your initial state for each reducer
    state = undefined;
    storage.removeItem("persist:root"); // Clear the root persist storage
    window.location.reload();
  }

  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});
