import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Carddetails from "../../Private/Userprofile/Carddetails/Carddetails";
import Banner from "../../../Assets/images/profile-bg.png";
import style from "./LinkDevices.module.scss";
import Devicesadd from "./Devicesadd/Devicesadd";
import Connnecteddevice from "./Connnecteddevice/Connnecteddevice";
import Addandchangeart from "./Connnecteddevice/Addandchangeart";
import {
  updateSelectedArt,
  // userTab,
} from "../../../Features/linkedDevice/linkedDeviceSlice";
import { useDispatch, useSelector } from "react-redux";
import { PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import { getConnectedDeviceList } from "../../../Features/linkedDevice/linkedDeviceSlice";
import { setLinkActiveTab } from "../../../Features/persist/persistSlice";
import { setPageLinkedDevice } from "../../../Features/user/userSlice";
import CoverImage from "../../../Assets/images/User-Cover.png";
import socket from "../../../Socket/socket";
import { Helmet } from "react-helmet";

const LinkDevices = () => {
  const [active, setActive] = useState(true);
  const [deviceId, setDeviceId] = useState("");
  const dispatch = useDispatch();
  const [deviceList, setDeviceList] = useState([]);
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [artId, setArtId] = useState("");
  const [dummyData, setDummyData] = useState("");
  const linkActiveTab = useSelector((state) => state?.persist?.linkActiveTab);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const pageLinkedDevice = useSelector(
    (state) => state?.users?.pageLinkedDevice
  );
  const userDetail = useSelector((state) => state?.users?.profileData);
  const accessToken = useSelector((state) => state.persist.accessToken);

  useEffect(() => {
    dispatch(setLinkActiveTab(linkActiveTab));
  }, [ArtistId]);

  useEffect(() => {
    if (linkActiveTab == "connecteddevices") {
      ConnectedDeviceList();
    }
  }, [pageLinkedDevice, linkActiveTab, ArtistId]);


  useEffect(() => {
    socket.emit("socket_notification_room", { apiAccessToken: accessToken });
  }, []);

  socket.on("w_rename_device", (data) => {
    if(data.type == "W_RENAME_DEVICE"){
      dispatch(setLinkActiveTab("connecteddevices"));
    }
  });

  useEffect(() => {
    // Clean up by unsubscribing from the event and resetting the liveActivityDetail array
    return () => {
      socket.off("leave");
    };
  }, []);
  

  const ConnectedDeviceList = async () => {
    let params = {
      page: pageLinkedDevice,
      size: PER_PAGE_RECORD_LIMIT.EARNINGS_LIST_PER_PAGE,
    };
    try {
      const response = await dispatch(getConnectedDeviceList(params));
      setDeviceList(response?.data?.data?.list);
      setTotalPages(response?.data?.data?.totalPages);
      // setPage(response?.data?.data?.currentPage);
      dispatch(setPageLinkedDevice(response?.data?.data?.currentPage));
    } catch (err) {}
  };

  const updateSelectedArtHandler = async (artId) => {
    try {
      let data = {
        art_ids: [artId],
        device_id: deviceId,
      };
      const response = await dispatch(updateSelectedArt(data));
      if (response.status == 200) {
        setActive(true);
        ConnectedDeviceList();
      }
    } catch (error) {
      console.log("error in catch", error);
    }
  };

  const setSelectedTabHandler = (e) => {
    // setSelectedTab(e);
    dispatch(setLinkActiveTab(e));
    dispatch(setPageLinkedDevice(1));
    if (e === "linkdevices") {
      setActive(true);
    }
  };

  return (
    <>
      <section className={style.link_devices}>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
        <CommonHero
          backgroundImg={
            userDetail?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${userDetail?.cover_image_path}`
              : CoverImage
          }
        />
        <Carddetails tab={"dashboard"} />
        <div className={style.link_devices_section}>
          <Container>
            <div className={style.tablist}>
              <Tab.Container
                id="left-tabs-example"
                className="tabvertical"
                // defaultActiveKey={linkActiveTab}
                activeKey={linkActiveTab}
                onSelect={(e) => {
                  setSelectedTabHandler(e);
                }}
              >
                <Row>
                  <Col xl={2} lg={3} md={12}>
                    <Nav variant="pills" className="nav_tabslist">
                      {/* user dashboardlist */}
                      <Nav.Item>
                        <Nav.Link eventKey="linkdevices">Link Devices</Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        onClick={() => {
                          setActive(true);
                        }}
                      >
                        <Nav.Link eventKey="connecteddevices">
                          Connected Devices
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col xl={10} lg={9} md={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="linkdevices">
                        {linkActiveTab == "linkdevices" && <Devicesadd />}
                      </Tab.Pane>
                      <Tab.Pane eventKey="connecteddevices">
                        {active ? (
                          linkActiveTab === "connecteddevices" && (
                            <Connnecteddevice
                              artClick={() => {
                                setActive(false);
                              }}
                              setDeviceId={setDeviceId}
                              selectedTab={linkActiveTab}
                              deviceList={deviceList}
                              page={pageLinkedDevice}
                              // setPage={setPage}
                              totalPages={totalPages}
                              ConnectedDeviceList={ConnectedDeviceList}
                              setArtId={setArtId}
                              setDummyData={setDummyData}
                            />
                          )
                        ) : (
                          <Addandchangeart
                            ArtistId={ArtistId}
                            updateSelectedArtHandler={updateSelectedArtHandler}
                            artId={artId}
                          />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default LinkDevices;
