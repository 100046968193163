import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuctionComingSoon from "../../Pages/Dropaction/AuctionComingSoon";
import AuctionLive from "../../Pages/Dropaction/AuctionLive";
import style from "../../Pages/Dropaction/Dropaction.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import personIcon from "../../../Assets/images/person_icon.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { HeartIcon, HeartIconRed } from "../../../Assets/svgIcons/SVGicon";
import FCFBLive from "../../Pages/Dropfirst/FCFBLive";
import FCFBComingSoon from "../../Pages/Dropfirst/FCFBComingSoon";
import OpenEditionLive from "../../Pages/Dropopenedition/OpenEditionLive";
import OpenEditionComingSoon from "../../Pages/Dropopenedition/OpenEditionComingSoon";
import {
  getActivity,
  getDigitalArtDetail,
} from "../../../Features/marketplace/marketplaceSlice";
import RaffleLive from "../../Pages/Dropraffle/RaffleLive";
import RaffleComingSoon from "../../Pages/Dropraffle/RaffleComingSoon";
import RankedAuctionLive from "../../Pages/Droprankedauction/RankedAuctionLive";
import RankedAuctionComingsoon from "../../Pages/Droprankedauction/RankedAuctionComingsoon";
import Timer from "../../Pages/Timer";
import {
  followUnfollowArt,
  getNotify,
  openPopUp,
  saveNextRoute,
} from "../../../Features/user/userSlice";
import {
  checkFileType,
  determineEventStatus,
  determineEventStatusOfFCFB,
  sliceChar,
} from "../../../Helper/common";
import { PER_PAGE_RECORD_LIMIT } from "../../../Constant/userConstant";
import socket from "../../../Socket/socket";
import HoverVideoPlayer from "react-hover-video-player";
import styles from "./CuratedDropDigitalArt.module.scss";
import Feature_Modal from "../../Common/Feature_Modal/Feature_Modal";
import ShareBtn from "../../Common/Ui/ShareBtn/ShareBtn";
import ClosedDropModal from "../../Pages/ClosedDropModal/ClosedDropModal";
import {
  startLoading,
  stopLoading,
} from "../../../Features/loading/loadingSlice";
import CommonBtn from "../../Common/Ui/CommonBtn/CommonBtn";
import { Helmet } from "react-helmet";

const CuratedDropDigitalArt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type, id } = useParams();

  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);
  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  const userId = useSelector((state) => state?.users?.profileData?.id);
  const latLong = useSelector((state) => state?.users?.latLong);

  const [artDetail, setArtDetail] = useState({});
  const [liveActivityDetail, setLiveActivityDetail] = useState([]);
  const [highestBidAuction, setHighestBidAuction] = useState([]);
  const [bidderActivityDetail, setBidderActivity] = useState([]);
  const [activityPage, setActivityPage] = useState(1);
  const [bidderPage, setBidderPage] = useState(1);
  const [totalActivityPage, setTotalActivityPage] = useState("");
  const [totalBidderPage, setTotalBidderPage] = useState("");
  const [showfeature, setShowfeature] = useState(false);
  const [isDisableArt, setIsDisableArt] = useState(false);
  const notifyFromTop = useSelector((state) => state?.users?.notifyFromTop);
  const [showClosedDrop, setShowClosedDrop] = useState(false);
  const [lastBidAmount, setLastBidAmount] = useState(null);

  const [liveActivityResponse, setLiveActivityResponse] = useState(false);

  useEffect(() => {
    getDigitalArtDetailHandler();
  }, [
    id,
    type,
    bidderPage,
    activityPage,
    isUserLoggedin,
    ArtistId,
    latLong?.lat,
    latLong?.long,
  ]);

  useEffect(() => {
    let artId = id;
    // Set interval to refresh API every 500000 milliseconds
    const refreshApiInterval = setInterval(() => {
      getDigitalArtDetailHandler(false, artId);
    }, 500000);
    // Cleanup function to clear the interval
    return () => clearInterval(refreshApiInterval);
  }, []);

  useEffect(() => {
    if (Object.keys(artDetail).length && artDetail?.drop_type == 3) {
      if (artDetail?.no_of_editions == artDetail?.sold_no_of_editions) {
        dispatch(startLoading());
        navigate(`/marketplace/art/${id}`);
        dispatch(stopLoading());
      }
    }
  }, [artDetail]);

  useEffect(() => {
    // Emit socket event when the art ID or type changes
    socket.emit("currentArt", { art_id: id });
    return () => {
      // Emit socket event to turn off current art and reset live activity detail
      socket.emit(`currentArtOff`, { art_id: id });
      setLiveActivityDetail([]);
    };
  }, [id, type]);

  const getActivityHandler = async (data, artId) => {
    try {
      let params = {
        id: artId ? artId : id,
        drop_time: type.toUpperCase(),
        activity_type: "ACTIVITY",
        size: PER_PAGE_RECORD_LIMIT.ART_DETAIL_PRIMARY_PER_PAGE,
        page: activityPage,
      };
      if (data.drop_type === 2) {
        delete params.size;
      }
      const response = await dispatch(getActivity(params));
      setLiveActivityDetail([...response?.data?.art_auctions]);
      setHighestBidAuction(response.data.highest_rank);
      setTotalActivityPage(response.data.totalPages);
      setLiveActivityResponse(true);
    } catch (error) {}
  };

  const getBidderHandler = async (data, artId) => {
    try {
      let params = {
        id: artId ? artId : id,
        drop_time: type.toUpperCase(),
        activity_type: "BIDDERS",
        size: PER_PAGE_RECORD_LIMIT.ART_DETAIL_PRIMARY_PER_PAGE,
        page: bidderPage,
      };
      if (data.drop_type === 2) {
        delete params.size;
      }

      const response = await dispatch(getActivity(params));
      setBidderActivity(response.data.art_auctions);
      setTotalBidderPage(response.data.totalPages);
    } catch (error) {}
  };
  const getDigitalArtDetailHandler = async (isLoading = true, artId) => {
    let params = {
      id: artId ? artId : id,
      drop_time: type.toUpperCase(),
    };
    try {
      const response = await dispatch(getDigitalArtDetail(params, isLoading));
      setArtDetail(response?.data);
      const check = response.data?.is_notified == 1 ? true : false;
      setIsDisableArt(check);
      if (isLoading && type !== "upcoming") {
        getBidderHandler(response.data, artId);
        getActivityHandler(response.data, artId);
      }
    } catch (error) {
      navigate(-1);
    }
  };

  const followUnfollowArtHandler = async () => {
    if (isUserLoggedin) {
      let data = {
        type: artDetail.is_liked == 1 ? 0 : 1,
        art_id: id,
      };
      try {
        const response = await dispatch(followUnfollowArt(data));
        getDigitalArtDetailHandler(false);
      } catch (error) {}
    } else {
      dispatch(saveNextRoute(`/curated-drop/digital-art/${type}/${id}`));
      dispatch(openPopUp("login"));
    }
  };

  const getArtDetailsByDropType = () => {
    let data = {
      price: {},
      editions: {},
      artType: "",
    };
    switch (artDetail?.drop_type) {
      case 1:
        data.price = {
          text: "Floor Price",
        };
        data.artType = "Auction";
        return data;
      case 2:
        data.price = {
          text: "Floor Price",
        };
        data.artType = "Ranked Auction";
        return data;
      case 3:
        data.price = {
          text: "Fixed Price",
        };
        data.artType = "First Come First Buy";
        return data;
      case 4:
        data.price = {
          text: "Fixed Price",
        };
        data.artType = "Open Edition";
        return data;
      case 5:
        data.price = {
          text: "Entry Price",
        };
        data.artType = "Raffle";
        return data;
    }
  };

  const creatorOnclickHandler = () => {
    navigate(`/profile/${artDetail?.artist_name}`);
  };

  useEffect(() => {
    // Clone the liveActivityDetail array to avoid mutating the state directly
    let oldBidUser = [...liveActivityDetail];
    const updateBidHandler = (data) => {
      // Check if the incoming data is related to the current art
      if (id == data.art_id) {
        const currentDate = new Date().toISOString();
        // Check the drop type of the art
        if (artDetail.drop_type == 2) {
          // Ranked Auction
          // If the current user placed a bid, update the last bid amount
          if (data?.auction_user[0].id == userId) {
            setLastBidAmount(data?.offer_price);
          }
          const index = oldBidUser?.findIndex(
            (element) =>
              element?.auction_user[0]?.id == data?.auction_user[0].id
          );
          // If the bid already exists in the array, update it with the new data
          if (index >= 0) {
            oldBidUser[index] = {
              ...data,
              createdAt: currentDate,
            };
          } else {
            // If the bid doesn't exist, add it to the array
            oldBidUser.push({
              ...data,
              createdAt: currentDate,
            });
          }
          // Update the liveActivityDetail state with the updated array
          setLiveActivityDetail(oldBidUser);
        } else if (artDetail.drop_type == 1) {
          // Auction
          // For Auctions, replace the entire array with the new bid
          setLiveActivityDetail([{ ...data, createdAt: currentDate }]);
        }
      }
    };
    // Subscribe to the "updateArtBids" event from the socket server
    socket.on("updateArtBids", updateBidHandler);
  }, [artDetail, id, liveActivityDetail]);

  useEffect(() => {
    // Clean up by unsubscribing from the event and resetting the liveActivityDetail array
    return () => {
      socket.off("updateArtBids");
      setLiveActivityDetail([]);
    };
  }, []);

  const getImageSection = (item) => {
    let type = checkFileType(
      `${process.env.REACT_APP_IMG_BASE_URL}${item?.feature_file}`
    );
    if (type == "MP4" || type == "OGG") {
      return (
        <HoverVideoPlayer
          loop={true}
          restartOnPaused
          unloadVideoOnPaused={true}
          disableRemotePlayback={false}
          paused={true}
          muted={false}
          className={`video_hover_sec_second ${styles.image_sec}`}
          videoSrc={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
          pausedOverlay={
            <img
              src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
              alt="icon"
            />
          }
        />
      );
    } else {
      return (
        <div className="image_section_hover">
          <img
            className="thumnail_img"
            src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.thumbnail_image}`}
            alt=""
          />
          <img
            className="featureimg"
            src={`${process.env.REACT_APP_IMG_BASE_URL}${item.feature_file}`}
            alt=""
          />
        </div>
      );
    }
  };

  const getNotifiedHandler = async (e) => {
    if (isUserLoggedin) {
      try {
        let data = {
          id: artDetail.id,
          event_type: "ART",
          status: 1,
          // status:isTrue == true ? 1 : 0
        };
        // return
        const response = await dispatch(getNotify(data));

        if (response?.status == 200) {
          getDigitalArtDetailHandler(false);
        }
      } catch (err) {}
    } else {
      dispatch(openPopUp("login"));
    }
  };

  const onclickHandler = (item, categoryType) => {
    if (categoryType === "art") {
      let type;
      if (item.drop_type != 3) {
        type = determineEventStatus(item.start_time, item.end_time);
      } else {
        type = determineEventStatusOfFCFB(item);
      }
      if (type === "previous") {
        return `/marketplace/art/${item.id}`;
      } else {
        return `/curated-drop/digital-art/${
          item.drop_type != 3
            ? determineEventStatus(item.start_time, item.end_time)
            : determineEventStatusOfFCFB(item)
        }/${item.id}`;
      }
    } else if (categoryType === "artist") {
      return `/profile/${item?.username}?tab=ART_CREATED`;
    }
  };

  return (
    <div>
      <section className={style.dropaction_ended}>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>

        <Container>
          <Row>
            <Col xl={5} lg={6}>
              <div className={style.dropaction_ended_leftsec}>
                <div
                  className={style.dropaction_ended_leftsec__imageart}
                  onClick={() => setShowfeature(true)}
                >
                  {/* <img
                    src={`${process.env.REACT_APP_IMG_BASE_URL}${artDetail?.thumbnail_image}`}
                    alt="icon"
                  /> */}
                  {getImageSection(artDetail)}
                </div>
                <ul className={style.dropaction_ended_leftsec__listing}>
                  {artDetail?.tags &&
                    artDetail?.tags.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                </ul>
              </div>
            </Col>
            <Col xl={7} lg={6}>
              <div className={style.dropaction_ended_rightsec}>
                {type === "upcoming" && (
                  <div className={style.dropaction_comingsoon_rightsec_auction}>
                    <label className={style.auction_heading}>
                      {getArtDetailsByDropType()?.artType}
                    </label>
                    <>
                      <h6>
                        Starts in :{" "}
                        <Timer
                          startingTimestamp={Number(artDetail?.start_time)}
                        />
                      </h6>
                      <button
                        className={style.notifiedBtn}
                        onClick={() => getNotifiedHandler()}
                        disabled={notifyFromTop || isDisableArt}
                      >
                        Get Notified
                      </button>
                    </>
                  </div>
                )}
                {type === "live" && (
                  <div className={style.dropaction_comingsoon_rightsec_auction}>
                    <label className={style.auction_heading}>
                      {getArtDetailsByDropType()?.artType}
                    </label>
                    <h6 className={style.textlive}>
                      Live <span></span>
                    </h6>
                  </div>
                )}
                <div
                  className={`${style.dropaction_ended_rightsec_headtext} ffliveBtn`}
                >
                  <div className='textSection'>
                    <h2 className={style.titlehead} title={artDetail?.art_name}>
                      {sliceChar(artDetail?.art_name, 20)}
                    </h2>
                    {artDetail.drop_type != 4 && (
                      <p>
                        {artDetail.no_of_editions == 1 ? "Edition" : "Editions"}{" "}
                        : {artDetail.no_of_editions}
                      </p>
                    )}
                  </div>
                  {artDetail?.sold_no_of_editions > 0 &&
                    artDetail.drop_type == 4 &&
                    artDetail?.drop_type === 3 && (
                      <CommonBtn
                        role="btn"
                        className="bordred-black"
                        title="View in Marketplace"
                        onClick={() => navigate(`/marketplace/art/${id}`)}
                      />
                    )}
                </div>
                <div className={style.dropaction_ended_rightsec_creatorInfo}>
                  <div className={style.creator_details}>
                    <div className={style.creator_details_userImage}>
                      <img
                        className="cursor-pointer"
                        src={
                          artDetail?.artist_image
                            ? `${process.env.REACT_APP_IMG_BASE_URL}${artDetail?.artist_image}`
                            : personIcon
                        }
                        onClick={() => {
                          creatorOnclickHandler();
                        }}
                        alt="icon"
                      />
                    </div>
                    <div className={style.creator_details_texts}>
                      <label>Creator</label>
                      <h6
                        title={artDetail?.artist_name}
                        className="clickable"
                        onClick={() => {
                          creatorOnclickHandler();
                        }}
                      >
                        {artDetail?.artist_name &&
                          `@${sliceChar(artDetail?.artist_name, 15)}`}
                      </h6>
                    </div>
                  </div>
                  <div className={style.series_details}>
                    {/* <div className={style.series_details_userImage}>
                      <img src={collectorimg2} alt="icon" />
                    </div> */}
                    <div className={style.series_details_texts}>
                      <label>Series</label>
                      <h6 title={artDetail?.series_name}>
                        {sliceChar(artDetail?.series_name, 30)}
                      </h6>
                    </div>
                  </div>
                  <div className={style.shareIcon}>
                    <ul className={style.shareIcon_list}>
                      <li>
                        <button
                          type="button"
                          onClick={() => followUnfollowArtHandler()}
                          className={style.btnlikeshare}
                        >
                          {artDetail.is_liked ? (
                            <HeartIconRed />
                          ) : (
                            <HeartIcon />
                          )}
                        </button>
                      </li>
                      <li>
                        <ShareBtn />
                      </li>
                      {/* <li>
                        <button
                          type="button"
                          onClick={() => {
                            shareOnclickHandler();
                          }}
                          className={style.btnlikeshare}
                        >
                          <ShareIcon />
                        </button>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* need socket implementation */}
                {type === "live" && artDetail.drop_type == 1 && (
                  <AuctionLive
                    artDetail={artDetail}
                    liveActivityDetail={liveActivityDetail}
                    setActivityPage={setActivityPage}
                    page={activityPage}
                    totalActivityPage={totalActivityPage}
                    userId={userId}
                    type={type}
                    id={id}
                    setShowClosedDrop={setShowClosedDrop}
                    lastBidAmount={lastBidAmount}
                  />
                )}
                {type === "upcoming" && artDetail.drop_type == 1 && (
                  <AuctionComingSoon
                    artDetail={artDetail}
                    id={id} /* getNotifiedHandler={getNotifiedHandler} */
                  />
                )}
                {/* need socket implementation */}
                {type === "live" && artDetail.drop_type == 2 && (
                  <RankedAuctionLive
                    artDetail={artDetail}
                    liveActivityDetail={liveActivityDetail}
                    setActivityPage={setActivityPage}
                    page={activityPage}
                    totalActivityPage={totalActivityPage}
                    userId={userId}
                    highestBidAuction={highestBidAuction}
                    type={type}
                    id={id}
                    setShowClosedDrop={setShowClosedDrop}
                    lastBidAmount={lastBidAmount}
                    ArtistId={ArtistId}
                    liveActivityResponse={liveActivityResponse}
                  />
                )}
                {type === "upcoming" && artDetail.drop_type == 2 && (
                  <RankedAuctionComingsoon artDetail={artDetail} id={id} />
                )}
      
                {type === "live" && artDetail.drop_type == 3 && (
                  <FCFBLive
                    artDetail={artDetail}
                    liveActivityDetail={liveActivityDetail}
                    setActivityPage={setActivityPage}
                    page={activityPage}
                    totalActivityPage={totalActivityPage}
                    userId={userId}
                    type={type}
                    id={id}
                    setShowClosedDrop={setShowClosedDrop}
                  />
                )}
                {type === "upcoming" && artDetail.drop_type == 3 && (
                  <FCFBComingSoon artDetail={artDetail} id={id} />
                )}

                {type === "live" && artDetail.drop_type == 4 && (
                  <OpenEditionLive
                    artDetail={artDetail}
                    liveActivityDetail={liveActivityDetail}
                    setActivityPage={setActivityPage}
                    page={activityPage}
                    totalActivityPage={totalActivityPage}
                    userId={userId}
                    type={type}
                    id={id}
                    setShowClosedDrop={setShowClosedDrop}
                  />
                )}
                {type === "upcoming" && artDetail.drop_type == 4 && (
                  <OpenEditionComingSoon artDetail={artDetail} id={id} />
                )}
               
                {type === "live" && artDetail.drop_type == 5 && (
                  <RaffleLive
                    artDetail={artDetail}
                    liveActivityDetail={liveActivityDetail}
                    setActivityPage={setActivityPage}
                    page={activityPage}
                    totalActivityPage={totalActivityPage}
                    userId={userId}
                    type={type}
                    id={id}
                    setShowClosedDrop={setShowClosedDrop}
                  />
                )}
                {type === "upcoming" && artDetail.drop_type == 5 && (
                  <RaffleComingSoon artDetail={artDetail} id={id} />
                )}
               
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {showfeature && Object.keys(artDetail)?.length > 0 && (
        <Feature_Modal
          show={showfeature}
          artDetail={artDetail}
          handleClose={() => setShowfeature(false)}
        ></Feature_Modal>
      )}
      {showClosedDrop && (
        <ClosedDropModal
          show={showClosedDrop}
          handleClose={() => setShowClosedDrop(false)}
          artDetail={artDetail}
          onclickHandler={onclickHandler}
        />
      )}
    </div>
  );
};

export default CuratedDropDigitalArt;
