import React from "react";
import style from "./Like.module.scss";
import CommonBtn from "../../../Common/Ui/CommonBtn/CommonBtn";
import { Col, Row } from "react-bootstrap";
import CustomPagination from "../../../Common/Ui/Pagination/CustomPagination";
import { SearchIcon } from "../../../../Assets/svgIcons/SVGicon";
import {
  determineEventStatus,
  determineEventStatusOfFCFB,
  sliceChar,
} from "../../../../Helper/common";
import Notfound from "../../../Common/NotFound/Notfound";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes_Urls } from "../../../../Constant/Route_url";

const Like = (props) => {
  let { data, setSearch, page, totalPage, likeUnlikeArt, type } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.users.profileData);

  const getButtonText = (item) => {
    if (type == "self") {
      return "Unlike";
    } else {
      if (item.follow_type == 0) {
        return "Like";
      } else {
        return "Unlike";
      }
    }
  };

  const goToUserProfile = (item) => {
    navigate(`/profile/${item?.art?.user?.username}`);
  };

  const artNavigation = async (item) => {
    // checking the art is upcoming or live or previous
    let type;
    if (item.drop_type != 3) {
      type = determineEventStatus(item.start_time, item.end_time);
    } else {
      type = determineEventStatusOfFCFB(item);
    }
    if (type === "previous") {
      navigate(`/marketplace/art/${item.id}`);
    } else {
      navigate(
        `/curated-drop/digital-art/${
          item.drop_type != 3
            ? determineEventStatus(item.start_time, item.end_time)
            : determineEventStatusOfFCFB(item)
        }/${item.id}`
      );
    }
  };

  return (
    <>
      <section className={style.like}>
        <div className={style.searchbar}>
          <div className="search_input">
            <div className="search_input_inner">
              <button type="submit" className="search_input_icon">
                <SearchIcon />
              </button>
              <input
                type="search"
                className="form-control"
                placeholder="Search Arts"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={style.like_section}>
          <div className={style.like_section_arttable}>
            {/* <h5>Art</h5> */}
            <div className={style.like_table}>
              <Row>
                {data.map((item) => (
                  <Col xl={3} lg={4} md={4} sm={6} xs={12}>
                    <div className={style.info_user}>
                      <img
                        onClick={() => artNavigation(item?.art)}
                        src={`${process.env.REACT_APP_IMG_BASE_URL}${item?.art?.thumbnail_image}`}
                        alt="userImg"
                      />
                      <div className={style.text_info}>
                        <h6 onClick={() => artNavigation(item?.art)}>
                          {sliceChar(item?.art?.art_name, 20)}
                        </h6>
                        <p
                          className="cursor-pointer"
                          onClick={() => goToUserProfile(item)}
                        >
                          @{item?.art?.user?.username}
                        </p>
                        {type == "self" && (
                          <CommonBtn
                            role="btn"
                            title={getButtonText(item)}
                            className={`black-btn ${style.btnfollow}`}
                            onClick={() => likeUnlikeArt(item.art_id)}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                ))}
                {data.length == 0 && <Notfound />}
              </Row>
              {totalPage > 1 && (
                <CustomPagination
                  className="ms-auto"
                  activePage={page}
                  totalPage={totalPage}
                  maxVisiblePages={5}
                  dispatch={dispatch}
                  componentType={"Profile"}
                  componentTypes={"OtherUserProfile"}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Like;
