import React from 'react';
import { Container } from 'react-bootstrap';
import CommonSlider from '../../../Common/Ui/CommonSlider/CommonSlider';
import style from './BenefitsNewart.module.scss';

const BenefitsNewart = () => {
    const articles = [
        {
            id: 1,
            title: "Pioneering Excellence",
            content: ["NewArtX stands as one of the world’s first and India’s premier marketplace dedicated solely to Digital Fine Arts (DFAs), ushering in a new era of artistic expression."]
        },
        {
            id: 2,
            title: "Curated Excellence",
            content: ['Unlike open platforms, NewArtX is meticulously curated to ensure the highest quality and authenticity of listed DFAs, transforming them into investable assets that enrich both artists and collectors.']
        },
        {
            id: 3,
            title: "Immutable Provenance",
            content: ['Every DFA on our platform is minted on the Polygon blockchain, guaranteeing immutable provenance, authenticity, and ownership, thus preserving the value of each digital masterpiece.']
        },
        {
            id: 4,
            title: "Unleashing Creativity",
            content: ['With NewArtX, artists are empowered with boundless creative freedom. Dynamic DFAs allow them to craft digital masterpieces that transcend boundaries, seamlessly integrating with real-time events and evolving narratives']
        },
        {
            id: 5,
            title: "Empowering Artists",
            content: ['We believe in empowering artists. With NewArtX, creators receive royalties on each DFA transaction, ensuring an ongoing revenue stream that sustains them and benefits future generations.']
        },
        {
            id: 6,
            title: "Immutable Provenance",
            content: ['Every DFA on our platform is minted on the Polygon blockchain, guaranteeing immutable provenance, authenticity, and ownership, thus preserving the value of each digital masterpiece.']
        },
        {
            id: 7,
            title: "Unleashing Creativity",
            content: ['With NewArtX, artists are empowered with boundless creative freedom. Dynamic DFAs allow them to craft digital masterpieces that transcend boundaries, seamlessly integrating with real-time events and evolving narratives']
        },
    ]
    return (
        <>

            <section className={style.articlesSec}>
                <Container>
                    <div className={style.articlTophead}>
                        <h3>Benefits of NewArtX</h3>
                        <p>NewArtX is the one of the first in the world and only marketplace in India that is focused on Digital Fine Arts.</p>
                    </div>
                    <CommonSlider
                        dots={false}
                        autoplay={true}
                        infinite={true}
                        speed={3000}
                        slidesToShow={5}
                        arrows={true}
                        slidesToScroll={1}
                        className={style.articlesSecSlider}
                        responsive={[
                            {
                                breakpoint: 1499,
                                settings: {
                                    slidesToShow: 4
                                }
                            },
                            {
                                breakpoint: 1199,
                                settings: {
                                    slidesToShow: 3
                                }
                            },
                            {
                                breakpoint: 991,
                                settings: {
                                    slidesToShow: 2
                                }
                            },
                            {
                                breakpoint: 575,
                                settings: {
                                    slidesToShow: 1
                                }
                            }
                        ]}
                        pauseOnHover={true}
                    >
                        {
                            articles.map(article => (
                                <div className={style.ArticleBox} key={article.id}>
                                    <h5>{article.title}</h5>
                                    <div className={style.articlesSecContent}>
                                        {
                                            article.content.map(item => (
                                                <p key={item}>{item}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </CommonSlider>
                </Container>
            </section>
        </>
    )
}

export default BenefitsNewart