export const PER_PAGE_RECORD_LIMIT = {
  PREVIOUS_DROP_LIST_PER_PAGE_LIMIT: 10,
  ART_CREATED_LIMIT: 12,
  CURRATED_UPCOMING_DROP: 4,
  DIGTIAL_ART_LIMIT: 10,
  DIGTIAL_ART_ON_DROP_LIMIT: 12,
  PROFILE_TAB_PER_PAGE: 12,
  EARNINGS_LIST_PER_PAGE: 10,
  ART_DETAIL_SECONDARY_PER_PAGE: 5,
  ART_DETAIL_PRIMARY_PER_PAGE: 5,
  ART_OWNED_SELECT_LIST_PER_PAGE: 12,
};

export const FEE = {
  GST: 18,
  PLATFORM: 4,
};
export const DEVICE_NOTIFICATION = {
  EMAIL: 1,
  PHONE: 2,
};

export const OFFER_STATUS = {
  accepted: "ACCEPTED",
  declined: "DECLINED",
  closed: "CLOSED",
  active: "ACTIVE",
  coutered: "COUNTERED",
  pending: "PENDING",
  sold: "SOLD",
  expired: "EXPIRED",
};
export const DECIMAL = 10 ** 8;

export const EVENT_KEYS_FOR_TABS = {
  TWENTYFOUR_HOURS: "24hours",
  SEVEN_DAYS: "7days",
  THREE_MONTH: "3month",
  SIX_MONTH: "6month",
  TWELVE_MONTH: "12month",
};
export const DIGITAL_ART_TYPE = {
  Auction: 1,
  RankedAuction: 2,
  FirstComeFirstBuy: 3,
  OpenEdition: 4,
  Raffle: 5,
};

export const filterOnMadeOffer = {
  ACCEPETED: "ACCEPTED",
  DECLINED: "DECLINED",
  COUNTERED: "COUNTERED",
};
export const sortByOnOfferMade = {
  MOST_RECENT: 0,
  ENDING_SOON: 1,
};

export const tabName = {
  OFFER_RECIEVED: "RECIEVED",
  OFFER_MADE: "MADE",
};

export const offerType = {
  SINGLE: "SINGLE",
  GLOBAL: "GLOBAL",
};

export const sortByOnOfferRecieved = {
  NEWEST: 0, // desending
  ENDING_SOON: 1,
};

export const offerArtPerPage = 10;
export const offerArtOfferPerPage = 6; // as per shawinder
export const FIAT_TRANSACTIONS_TYPE = {
  DEPOSIT: 1,
  ORDER: 2,
  MAKE_OFFER: 3,
};

export const ARTIST_PROFILE_REQUEST_TYPE = {
  NAME: 1,
  EMAIL: 2,
  MOBILE: 3,
  BIO: 4,
  USER_NAME: 5,
  FACEBOOK_PUBLIC: 6,
  INSTAGRAM_PUBLIC: 7,
  TWITTER_PUBLIC: 8,
  LINKEDIN_PUBLIC: 9,
  BIO_PUBLIC: 10,
};
export const ARTIST_PROFILE_REQUEST_STATUS = {
  PENDING: 1,
  APPORVED: 2,
  REJECTED: 3,
  CANCELD_BY_USER: 4,
};

export const ORIENTATION = {
  LANDSCAPE: "landscape",
  PORTRAIT: "portrait",
};

export const NOTIFICATION_CATEGORY = {
  OFFERS_MADE: 1,
  OFFERS_RECEIVED: 2,
  DROP: 3,
  PROFILE: 4,
  DFA_LANDING: 5,
  TAGS: 6,
  DFA_OFFER: 7,
  KYC: 9,
  BIO: 10,
  COUPONS: 11,
  COLLECTOR_PROFILE: 11,
  NEW_ARTIST: 12,
  IMPORT: 13,
};
export const userType = {
  ARTIST: 2,
  ANONYMOUS: 3,
};
export const USER_TYPE = {
  ARTIST: 2,
  ANONYMOUS: 3,
  USER: 1,
};

export const MARKET_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};
export const USER_PROFILE_TAB = {
  ART_OWN: "ART_OWN",
  LIKES: "LIKES",
  ART_CREATED: "ART_CREATED",
  FOLLOWING: "FOLLOWING",
  FOLLOWERS: "FOLLOWERS",
};
export const LEDGER_ORDER_TYPE = {
  BUY: "BUY",
  SELL: "SELL",
  DEPOSIT: "DEPOSIT",
  WITHDRAW: "WITHDRAW",
};

export const DROP_TYPE = {
  AUCTION: 1,
  RANKED_AUCTION: 2,
  FIRST_COME_FIRST_BUY: 3,
  OPEN_EDITIONS: 4,
  RAFFLES: 5,
};
export const COMPARISION_CONSTANT_NAMES = {
  ZERO: 0,
  THREE: 3,
};

export const QR_LOGIN_TEXT = "loginWithQr";

export const SALE_TYPE = {
  ONLINE: 1,
  OFFLINE: 2,
};

export const APP_LINK ="https://play.google.com/store/apps/details?id=com.newartx.app";