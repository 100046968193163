import React, { useState, useRef } from "react";
import ApplyCreateModal from "../../Common/Ui/ApplyCreateModal/ApplyCreateModal";
import CreateSec from "../Home/CreateSec/CreateSec";
import NextRelease from "../Home/NextRelease/NextRelease";
import styles from "./CreatePage.module.scss";
import WhoCanApply from "./WhoCanApply/WhoCanApply";
import { Helmet } from "react-helmet";

const CreatePage = () => {
  const [createModal, setCreateModal] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const divCRef = useRef(null);

  const scrollToDiv = () => {
    if (divCRef.current) {
      divCRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleButtonClick = () => {
    setCreateModal(true); // You can add any other redirection logic here
  };

  return (
    <>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>

      <CreateSec
        className={styles.createSec}
        btnTitle="Apply To Create"
        btnVariant={`${styles.applyBtn}`}
        subText="NewArtX is on a mission to showcase the world’s most captivating Digital Fine Art masterpieces. We invite artists from all corners of the globe 
        to collaborate with us and share their visionary creations with a global audience."
        subTextSec="Artists have two pathways to bring their Digital Fine Art to life on NewArtX. They can choose to drop their existing works directly onto our 
        platform or collaborate with our in-house studio, NAX Studio, on a revenue-sharing model to co-create stunning DFAs tailored for our marketplace."
        subTextThird="If you’re an artist eager to join our creative community, simply fill out the form below and share your portfolio with us. The more 
        information you provide, the better we can understand your unique vision and take the next steps together."
        smallheading="Create with NAX Studio"
        scrollToDiv={scrollToDiv}
        onButtonClick={handleButtonClick}
      />
      <WhoCanApply />
      <NextRelease
        className={styles.NextRelease}
        releaseHeading={false}
        NextRelaseSubscription={false}
        ApplyToCreate={true}
        isScroll={isScroll}
        divCRef={divCRef}
        onButtonClick={handleButtonClick}
      />
      {createModal && (
        <ApplyCreateModal
          isScroll={isScroll}
          show={createModal}
          onHide={() => setCreateModal(false)}
        />
      )}
    </>
  );
};

export default CreatePage;
