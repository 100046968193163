import React from "react";
import styles from "./SearchItem.module.scss";
import { sliceChar } from "../../../../../Helper/common";

const SearchItem = (props) => {
  const {
    className,
    icon,
    name,
    artistName,
    iconRounded,
    redirectionClick,
    tagname,
  } = props;
  return (
    <div className={`cursor-pointer ${styles.searchItem + " " + className}`}>
      {icon && (
        <img
          src={icon}
          className={iconRounded ? "rounded-circle" : ""}
          alt=""
          onClick={redirectionClick}
        />
      )}
      <div className="searchItemInner" onClick={redirectionClick}>
        {name && artistName && (
          <>
            <h4 title={name}>{sliceChar(name, 15)}</h4>
            <p title={artistName}>{sliceChar(artistName, 15)}</p>
          </>
        )}
        {!name && artistName && (
          <h4 title={artistName}>{sliceChar(artistName, 15)}</h4>
        )}
        {tagname && <h4 title={tagname}>{sliceChar(tagname, 15)}</h4>}
      </div>
    </div>
  );
};

export default SearchItem;
