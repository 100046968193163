import React from 'react'
import styles from "./PrivacyPolicy.module.scss"
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
    return (
        <>
            <div className={styles.privacy_policy}>
            <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
                <Container>
                    <div className={styles.privacy_policy_in}>
                        <h1>Privacy Policy</h1>
                        <h4>1. Introduction</h4>
                        <p> At NewArtX, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you interact with our platform. By using our platform, you consent to the practices described in this policy.</p>

                        <h4> 2. Personal data we collect and how we collect it </h4>
                        <div className={styles.num_lines}>
                            <ol type="a">
                                <li>Information You Provide To Us</li>
                                <li> Information We Collect Automatically</li>
                                <li>Information We Collect from 3rd Parties</li>
                            </ol>
                        </div>
                        <h6> We may collect various types of information, including but not limited to</h6>
                        <ul>
                            <li>Personal information (e.g., name, email address, contact information)</li>
                            <li>Payment information (e.g., credit card details, cryptocurrency wallet addresses) </li>
                            <li> NFT transaction history and metadata </li>
                            <li>Usage and interaction data (e.g., device information, IP address, browsing activity) </li>
                            <li>Communications and correspondence with us</li>
                        </ul>
                        <h4>3. How We Use Your Information</h4>
                        <h6>  We use the collected information for purposes including, but not limited to</h6>
                        <ul>
                            <li>Providing and improving our platform’s services</li>
                            <li>Facilitating transactions and payments </li>
                            <li> Verifying your identity </li>
                            <li>Communicating with you about updates, promotions, and offers</li>
                            <li>Analyzing usage patterns and enhancing user experience</li>
                            <li> Complying with legal obligations</li>
                            <li> Other Business purposes</li>
                        </ul>
                        <h4>4. Data Retention </h4>
                        <h6>We may share your information with third parties under the following circumstances</h6>
                        <ul>
                            <li> With your consent or instruction</li>
                            <li>For processing payments and facilitating transactions</li>
                            <li> With service providers assisting us in various operations </li>
                            <li> To comply with legal requirements or protect our rights</li>
                            <li>In connection with business transfers or acquisitions </li>
                        </ul>
                        <h4>5. Security Measures</h4>
                        <p> We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. (SSL encryption, optional two factor authentication etc) Unfortunately, sending information over public networks, like the internet, isn’t entirely foolproof in terms of security. While we take extensive measures to safeguard your personal data, we can’t provide an absolute assurance regarding the security of data you transmit through our Services.Any sharing of personal data is done at your own risk. It’s important to note that we cannot be held accountable for any attempts to bypass privacy settings or security measures present on our Services
                        </p>

                        <h4>6. Cookies and Tracking Technologies</h4>
                        <p>Our platform uses cookies and similar tracking technologies to enhance user experience, analyze usage patterns, and deliver targeted content. </p>

                        <h4>7. Your Choices</h4>
                        <p>You have the right to access, update, and correct your personal information. You can also unsubscribe from marketing communications at any time. Please note that certain information is necessary for the proper functioning of the platform. </p>

                        <h4>8. Children’s Privacy</h4>
                        <p>Our platform is not intended for use by individuals under the age of 18. We do not knowingly collect or store personal information from children. </p>


                        <h4>9. Changes to this Policy</h4>
                        <p>We may update this Privacy Policy periodically to reflect changes in our practices. We will notify you of any significant changes by posting a prominent notice on our platform or by other means. </p>

                        <h4>10. Contact Us</h4>
                        <p>If you have any questions, concerns, or requests regarding your privacy or this Privacy Policy, please contact us at – namaste@newartx.com.</p>
                        <br />
                        <p> By using NewArtX, you signify your understanding and acceptance of this Privacy Policy.</p>


                    </div>
                </Container>

            </div>
        </>
    )
}

export default PrivacyPolicy