import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  EmailIcon,
  FBtransparentIcon,
  LinkIcon,
  ShareIcon,
  TwitterTransparentIcon,
  WhatsAppIcon,
  // TelegramIcon,
  InstaIcon,
  FBIcon,
} from "../../../../Assets/svgIcons/SVGicon";
import "./ShareBtn.scss";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";

import { toasts } from "../../Toast/Toast";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const CustomButton = ({ onClick, children }) => (
  <button onClick={onClick}>
    {/* Your custom button content */}
    {children}
  </button>
);
const BASE_URL = process.env.REACT_APP_USER_PROFILE;
const ShareButton = ({ url, title, type, onClose }) => {
  const [bodyLink, setBodyLink] = useState("");

  const handleClick = (type, url, title) => {
    if (type === "email") {
      const subject = encodeURIComponent(title);
      const body = encodeURIComponent("Check out this link: " + url);
      const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
      setBodyLink(mailtoLink);
      window.location.href = mailtoLink;
    }
    if (onClose) onClose();
  };

  return (
    <div className="btnShare">
      {/* Twitter Share Button */}
      {type == "twitter" && (
        <TwitterShareButton
          url={url}
          title={title}
          onClick={handleClick}
          className="shareIcons"
        >
          {/* Add your Twitter icon or label */}
          <TwitterTransparentIcon />
          Twitter
        </TwitterShareButton>
      )}
      {/* Facebook Share Button */}
      {type == "facebook" && (
        <FacebookShareButton
          // url={"https://stage.newartx.com/"}
          url={url}
          onClick={handleClick}
          className="shareIcons"
          // hashtag={`#${url}`}
        >
          <FBtransparentIcon />
          Facebook
        </FacebookShareButton>
      )}

      {/* WhatsApp Share Button */}
      {type == "whatsApp" && (
        <WhatsappShareButton
          url={url}
          onClick={handleClick}
          title={title}
          className="shareIcons"
        >
          {/* Add your WhatsApp icon or label */}
          <WhatsAppIcon />
          WhatsApp
        </WhatsappShareButton>
      )}

      {/* Email Share Button */}
      {type == "email" && (
          
          
      <div onClick={() => handleClick(type, url, title)} className="shareIcons">

        <EmailShareButton
          url={url}
          onClick={() => handleClick(type, url, title)}
          title={title}
          subject={title}
          body={bodyLink}
          separator=" - "
          className="shareIcons"
          

        >
          <EmailIcon />
          {/* Add your Email icon or label */}
          Email
        </EmailShareButton>
        </div>
      )}
    </div>
  );
};

const ShareBtn = ({ className }) => {
  const params = useParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const ArtistId = useSelector((state) => state?.users?.profileData?.id);
  // const baseUrl = window.location.origin;

  const urlToShareUserProfile = `${BASE_URL}Other-userprofile/${ArtistId}`;
  const urlToShareOther = `${BASE_URL}Other-userprofile/${params?.id}`;
  const urlToShareArtDetailPage = `${BASE_URL}${window?.location?.pathname.slice(
    1
  )}`;
  const commonUrl = window?.location?.pathname?.split("/")[1];

  const getPath = () => {
    if (commonUrl == "user-profile") {
      return urlToShareUserProfile;
    } else if (commonUrl == "Other-userprofile") {
      return urlToShareOther;
    } else return urlToShareArtDetailPage;
  };

  const titleToShare = "Welcome to website ";

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(getPath())
      .then(() => {
        if (commonUrl == "user-profile") {
          toasts.success("User profile copied to clipboard!");
        } else if (commonUrl == "Other-userprofile") {
          toasts.success("User profile copied to clipboard!");
        } else toasts.success("Art Link copied to clipboard!");
        closeDropdown();
      })
      .catch((err) => {
        console.error("Error in copying text: ", err);
      });
  };

  // Toggle Dropdown
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  // Close Dropdown
  const closeDropdown = () => setShowDropdown(false);

  // This effect ensures that clicking outside the dropdown will close it.
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <Dropdown
        className={`ShareBtn ${className || ""}`}
        show={showDropdown}
        toggle={toggleDropdown}
        ref={dropdownRef}
        /* autoClose="outside" */
      >
        <Dropdown.Toggle id="dropdown-basic" onClick={toggleDropdown}>
          <ShareIcon />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            href="#"
            onClick={copyToClipboard}
            className="shareIcons"
          >
            <span>
              <LinkIcon />
            </span>{" "}
            Copy Link
          </Dropdown.Item>
          <Dropdown.Item href="#" >
            <ShareButton
              url={getPath()}
              title={titleToShare}
              type="twitter"
              onClose={closeDropdown}
            />
          </Dropdown.Item>
          <Dropdown.Item href="#">
            <ShareButton
              url={getPath()}
              title={titleToShare}
              type="whatsApp"
              onClose={closeDropdown}
            />
          </Dropdown.Item>
          <Dropdown.Item href="#">
            <ShareButton
              url={getPath()}
              title={titleToShare}
              type="facebook"
              onClose={closeDropdown}
            />
          </Dropdown.Item>
          <Dropdown.Item href="#">
            <ShareButton
              url={getPath()}
              title={titleToShare}
              type="email"
              onClose={closeDropdown}
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ShareBtn;
