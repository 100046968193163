import React, { useEffect, useState } from "react";
import CommonHero from "../../Common/Ui/CommonHero/CommonHero";
import Banner from "../../../Assets/images/profile-bg.png";
import CarddetailsOtherUser from "./Carddetails/CarddetailOtherUser";
import { Container, Tab, Tabs } from "react-bootstrap";
import Followers from "./Followers/Followers";
import Following from "./Following/Following";
import Like from "./Like/Like";
import Artownedsec from "./Artowned/Artownedsec";
import {
  getUserProfile,
  getUserArtFollowersLikes,
  followUnfollowAndRemove,
  getUserLikesData,
  incremenentViews,
  setPageOtherUserProfile,
  setTabTypeOtherUserProfile,
  saveUserProfileOther,
} from "../../../Features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPARISION_CONSTANT_NAMES,
  PER_PAGE_RECORD_LIMIT,
} from "../../../Constant/userConstant";
import { useDebounce } from "../../../customHooks/useDebounce";
import { useNavigate, useParams } from "react-router-dom";
import { getArts } from "../../../Features/drop/dropSlice";
import ArtistDigitalArtOtherProfile from "../../Pages/Userdashboard/Activedigitalart/ArtistDigitalArtOtherProfile";
import { useLocation } from "react-router-dom";
import style from "./Userprofile.module.scss";
import CoverImage from "../../../Assets/images/User-Cover.png";
import { Routes_Urls } from "../../../Constant/Route_url";
import { Helmet } from "react-helmet";

const Otheruserprofile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { username } = useParams();
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [likes, setLikes] = useState([]);
  const [artOwned, setArtOwned] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [search, setSearch] = useState("");

  const debouncedSearchTerm = useDebounce(search, 500);
  const otherUserProfile = useSelector(
    (state) => state?.users?.profileDataOther
  );
  const userProfile = useSelector((state) => state?.users?.profileData);
  const isUserLoggedin = useSelector((state) => state?.persist?.isUserLoggedin);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const queryTab = params.get("tab");

  const pageOtherUserProfile = useSelector(
    (state) => state.users.pageOtherUserProfile
  );
  const tabTypeOtherUserProfile = useSelector(
    (state) => state.users.tabTypeOtherUserProfile
  );

  useEffect(() => {
    // incremenentViewForUsers();
    getUserPersonalDetails();
    return () => {
      dispatch(saveUserProfileOther({}));
    };
  }, [username]);

  useEffect(() => {
    if (queryTab === "ART_CREATED") {
      // setTabType(queryTab);
      dispatch(setTabTypeOtherUserProfile(queryTab));
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userProfile)) {
      if (userProfile?.username == username) {
        navigate(Routes_Urls.User_Profile);
      }
    }
  }, [userProfile, otherUserProfile]);

  const incremenentViewForUsers = async () => {
    try {
      let data = {
        id: otherUserProfile.id,
      };
      let response = await dispatch(incremenentViews(data));
      if (response.status == 200) {
        getUserPersonalDetails();
      }
    } catch (err) {
      navigate(-1);
    }
  };

  useEffect(() => {
    if (
      (debouncedSearchTerm.length == COMPARISION_CONSTANT_NAMES.ZERO ||
        debouncedSearchTerm.length >= COMPARISION_CONSTANT_NAMES.THREE) &&
      otherUserProfile.id
    ) {
      if (
        tabTypeOtherUserProfile == "LIKES" ||
        tabTypeOtherUserProfile == "ART_OWN"
      ) {
        getUserLikesArtOwn();
      } else if (tabTypeOtherUserProfile == "ART_CREATED") {
        getUserCreatedArt();
      } else {
        getUserArtFollowersLikesAPI();
      }
    }
  }, [
    tabTypeOtherUserProfile,
    pageOtherUserProfile,
    debouncedSearchTerm,
    otherUserProfile.id,
  ]);

  const getUserCreatedArt = async () => {
    let data = {
      id: otherUserProfile.id,
      size: PER_PAGE_RECORD_LIMIT.PROFILE_TAB_PER_PAGE,
      page: pageOtherUserProfile,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getArts(data));
      if (response) {
        let record = response.data.data;

        setTotalPage(record.totalPages);
      }
    } catch (err) {}
  };

  const getUserLikesArtOwn = async () => {
    let data = {
      id: otherUserProfile.id,
      filter: tabTypeOtherUserProfile,
      page: search !== "" ? 1 : pageOtherUserProfile,
      size: PER_PAGE_RECORD_LIMIT.PROFILE_TAB_PER_PAGE,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }
    try {
      let response = await dispatch(getUserLikesData(data));
      if (response) {
        let record = response.data.data;

        setTotalPage(record.totalPages);
        if (tabTypeOtherUserProfile == "ART_OWN") {
          setArtOwned(record.data);
        }
        if (tabTypeOtherUserProfile == "LIKES") {
          setLikes(record.data);
        }
      }
    } catch (err) {}
  };

  const getUserArtFollowersLikesAPI = async () => {
    let data = {
      id: otherUserProfile.id,
      filter: tabTypeOtherUserProfile,
      page: search !== "" ? 1 : pageOtherUserProfile,
      size: PER_PAGE_RECORD_LIMIT.PROFILE_TAB_PER_PAGE,
      search: debouncedSearchTerm.trim(),
    };

    if (debouncedSearchTerm.length < 3) {
      delete data.search;
    }

    try {
      let response = await dispatch(getUserArtFollowersLikes(data));
      if (response) {
        let record = response.data.data;
        setTotalPage(record.totalPages);

        if (tabTypeOtherUserProfile == "FOLLOWING") {
          setFollowing(record.data);
        }
        if (tabTypeOtherUserProfile == "FOLLOWERS") {
          setFollowers(record.data);
        }
      }
    } catch (err) {}
  };

  const getUserPersonalDetails = async () => {
    try {
      let data = {
        username: username,
      };
      await dispatch(getUserProfile(data));
    } catch (err) {
      navigate(-1);
    }
  };
  const onTabChange = (e) => {
    setSearch("");
    dispatch(setPageOtherUserProfile(1));
    setTotalPage(0);
    dispatch(setTabTypeOtherUserProfile(e));
  };

  const followUnfollowAndRemoveApi = async (type, data) => {
    // type=0 (UNFOLLOW) , 1 ( FOLLOW) , 2 (REMOVE)
    let record = {
      type: type == "remove" ? 2 : type == "follow" ? 1 : 0,
      id:
        type == "remove"
          ? data.follower_id
          : type == "follow"
          ? data.user_id
          : type == "unfollow"
          ? data.user_id
          : "",
    };
    try {
      let response = await dispatch(followUnfollowAndRemove(record));
      if (response) {
        if (
          tabTypeOtherUserProfile == "LIKES" ||
          tabTypeOtherUserProfile == "ART_OWN"
        ) {
          getUserLikesArtOwn();
        } else if (tabTypeOtherUserProfile == "ART_CREATED") {
          getUserCreatedArt();
        } else {
          getUserArtFollowersLikesAPI();
        }
        getUserPersonalDetails();
      }
    } catch (err) {}
  };

  return (
    <>
      <section className={style.user_profile}>
      <Helmet>
        <title>NewArtX</title>
        <meta name="description" content="" />
        

      </Helmet>
        <CommonHero
          backgroundImg={
            otherUserProfile?.cover_image_path
              ? `${process.env.REACT_APP_IMG_BASE_URL}${otherUserProfile?.cover_image_path}`
              : CoverImage
          }
        />
        <CarddetailsOtherUser
          followUser={followUnfollowAndRemoveApi}
          userId={otherUserProfile.id}
          artOwned={artOwned.length}
          isUserLoggedin={isUserLoggedin}
        />
        <Container>
          <div className={style.tablist}>
            <Tabs
              id="uncontrolled-tab-example"
              className="tablists"
              defaultActiveKey={tabTypeOtherUserProfile}
              activeKey={tabTypeOtherUserProfile}
              onSelect={onTabChange}
            >
              <Tab eventKey="ART_OWN" title="ART OWNED">
                {tabTypeOtherUserProfile == "ART_OWN" && (
                  <Artownedsec
                    data={artOwned}
                    setSearch={setSearch}
                    search={search}
                    page={pageOtherUserProfile}
                    totalPage={totalPage}
                    type="other"
                    getUserLikesArtOwn={getUserLikesArtOwn}
                    isUserLoggedin={isUserLoggedin}
                    username={username}
                  />
                )}
              </Tab>
              {otherUserProfile?.user_type == 2 && (
                <Tab eventKey="ART_CREATED" title="ART CREATED">
                  {tabTypeOtherUserProfile == "ART_CREATED" && (
                    <ArtistDigitalArtOtherProfile
                      id={otherUserProfile.id}
                      isUserLoggedin={isUserLoggedin}
                    />
                  )}
                </Tab>
              )}

              <Tab eventKey="FOLLOWERS" title="FOLLOWERS">
                {tabTypeOtherUserProfile == "FOLLOWERS" && (
                  <Followers
                    data={followers}
                    setSearch={setSearch}
                    search={search}
                    page={pageOtherUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    type="other"
                    isUserLoggedin={isUserLoggedin}
                  />
                )}
              </Tab>

              <Tab eventKey="FOLLOWING" title="FOLLOWING">
                {tabTypeOtherUserProfile == "FOLLOWING" && (
                  <Following
                    data={following}
                    setSearch={setSearch}
                    search={search}
                    page={pageOtherUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    type="other"
                    isUserLoggedin={isUserLoggedin}
                  />
                )}
              </Tab>

              <Tab eventKey="LIKES" title="LIKES">
                {tabTypeOtherUserProfile == "LIKES" && (
                  <Like
                    data={likes}
                    setSearch={setSearch}
                    search={search}
                    page={pageOtherUserProfile}
                    totalPage={totalPage}
                    followUser={followUnfollowAndRemoveApi}
                    type="other"
                    isUserLoggedin={isUserLoggedin}
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Otheruserprofile;
